export enum TemplateTypes {
    DonorWelcomeEmail = 1,
    RegistrationReminderEmail = 2,
    BasicsIncompleteReminderEmail = 3,
    BasicsIncompleteReminderText = 4,
    PreliminaryHistoryIncompleteReminderEmail = 5,
    PreliminaryHistoryIncompleteReminderText = 6,
    MedicalIssuesIncompleteReminderEmail = 7,
    MedicalIssuesIncompleteReminderText = 8,
    PersonalHistoryIncompleteReminderEmail = 9,
    PersonalHistoryIncompleteReminderText = 10,
    FirstDonorInterviewRequestEmail = 11,
    FirstDonorInterviewRequestText = 12,
    SecondDonorInterviewRequestEmail = 13,
    SecondDonorInterviewRequestText = 14,
    DonorInterviewReminderEmail = 15,
    DonorInterviewReminderText = 16,
    DonorConsentSignatureRequestEmail = 17,
    DocumentsToBeSignedReminderEmail = 18,
    RecipientsWelcomeEmail = 19,
    TestSampleSchedulingEmail = 20,
    TestSampleSchedulingReminderText = 21,
    TestSampleAppointmentReminderEmail = 22,
    TestSampleAppointmentReminderText = 23,
    StillInterestedIncompleteApplicationEmail = 24,
    StillInterestedIncompleteApplicationText = 25,
}
