import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { OutcomeDynamicControls, IOutcomeDynamicControlsParameters } from '../form-controls/outcome.form-controls';
import { IOutcome } from '../interfaces/outcome';

export class OutcomeDynamicControlsPartial extends OutcomeDynamicControls {
    constructor(outcomePartial?: IOutcome, additionalParameters?: IOutcomeDynamicControlsParameters) {
        super(outcomePartial, additionalParameters);

        // FORM
        (<DynamicField>this.Form.HcgValue).labelHtml = '<label> hCG Value </label>';
        (<DynamicField>this.Form.HcgDate).labelHtml = '<label> hCG Date </label>';
        (<DynamicField>this.Form.DateUs).labelHtml = '<label> Date US </label>';
        (<DynamicField>this.Form.FetalSacCount).labelHtml = '<label> # Fetal Sacs </label>';
        (<DynamicField>this.Form.FhbCount).labelHtml = '<label> # FHB </label>';
        (<DynamicField>this.Form.NumberOfEmbryosTransferred).labelHtml = '<label> # of Embryos Transferred </label>';

        // VIEW
        (<DynamicLabel>this.View.HcgValue).label = 'hCG Value';
        (<DynamicLabel>this.View.HcgDate).label = 'hCG Date';
        (<DynamicLabel>this.View.DateUs).label = 'Date US';
        (<DynamicLabel>this.View.FetalSacCount).label = '# Fetal Sacs';
        (<DynamicLabel>this.View.FhbCount).label = '# FHB';
        (<DynamicLabel>this.View.NumberOfEmbryosTransferred).label = '# of Embryos Transferred';
    }
}
