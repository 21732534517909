import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IShipping } from '@model/interfaces/shipping';
import { IShippingDynamicControlsParametersPartial, ShippingDynamicControlsPartial } from '@model/partials/shipping-partial.form-controls';

export class ShippingDynamicConfig<T extends IShipping> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private shipping: T, private additionalParams: IShippingDynamicControlsParametersPartial, private configControls?: string[]) {
        super();

        const dynamicControls = new ShippingDynamicControlsPartial(this.shipping, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'ShippingStatusId',
                'ShipmentTypeId',
                'ProposedShipDate',
                'ShippingMethodId',
                'ExpectedShipDate',
                'ActualShipDate',
                'ReturnDate',
                'ShipToClinicId',
                'ShipperId',
                'ClinicalBoundAirwayBillNumber',
                'TwebBoundAirwayBillNumber',
                'ShippingInvoiceNumber',
                'NumberOfVialsShipped',
                'TankNumber',
                'DataLoggerNumber',
                'DeliveryDate',
                'PrintSummary',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
