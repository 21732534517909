import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    ICountry,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IFamilyHistory } from '../interfaces/family-history';
import { IEducationCompletedType } from '../interfaces/education-completed-type';
import { IEyeColor } from '../interfaces/eye-color';
import { INaturalHairColor } from '../interfaces/natural-hair-color';
import { IHairTexture } from '../interfaces/hair-texture';
import { IUnitsOfMeasurement } from '../interfaces/units-of-measurement';
import { IRace } from '../interfaces/race';
import { ISkinType } from '../interfaces/skin-type';

export interface IFamilyHistoryDynamicControlsParameters {
    formGroup?: string;
    heightUnits?: IUnitsOfMeasurement[];
    weightUnits?: IUnitsOfMeasurement[];
    eyeColors?: IEyeColor[];
    hairColors?: INaturalHairColor[];
    hairTextures?: IHairTexture[];
    skinTypes?: ISkinType[];
    educationCompletedTypes?: IEducationCompletedType[];
    races?: IRace[];
    countries?: ICountry[];
}

export class FamilyHistoryDynamicControls {

    formGroup: string;
    heightUnits: IUnitsOfMeasurement[];
    weightUnits: IUnitsOfMeasurement[];
    eyeColors: IEyeColor[];
    hairColors: INaturalHairColor[];
    hairTextures: IHairTexture[];
    skinTypes: ISkinType[];
    educationCompletedTypes: IEducationCompletedType[];
    races: IRace[];
    countries?: ICountry[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private familyhistory?: IFamilyHistory, additionalParameters?: IFamilyHistoryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'FamilyHistory';
        this.heightUnits = additionalParameters && additionalParameters.heightUnits || undefined;
        this.weightUnits = additionalParameters && additionalParameters.weightUnits || undefined;
        this.eyeColors = additionalParameters && additionalParameters.eyeColors || undefined;
        this.hairColors = additionalParameters && additionalParameters.hairColors || undefined;
        this.hairTextures = additionalParameters && additionalParameters.hairTextures || undefined;
        this.skinTypes = additionalParameters && additionalParameters.skinTypes || undefined;
        this.educationCompletedTypes = additionalParameters && additionalParameters.educationCompletedTypes || undefined;
        this.races = additionalParameters && additionalParameters.races || undefined;
        this.countries = additionalParameters && additionalParameters.countries || undefined;

        this.Form = {
            Age: new DynamicField({
                formGroup: this.formGroup,
                label: 'Age',
                name: 'Age',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.Age || null,
            }),
            AgeAtDeath: new DynamicField({
                formGroup: this.formGroup,
                label: 'Age At Death',
                name: 'AgeAtDeath',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.AgeAtDeath || null,
            }),
            CauseOfDeath: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cause Of Death',
                name: 'CauseOfDeath',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.familyhistory && this.familyhistory.hasOwnProperty('CauseOfDeath') && this.familyhistory.CauseOfDeath !== null ? this.familyhistory.CauseOfDeath.toString() : '',
            }),
            Deceased: new DynamicField({
                formGroup: this.formGroup,
                label: 'Deceased',
                name: 'Deceased',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.hasOwnProperty('Deceased') && this.familyhistory.Deceased !== null ? this.familyhistory.Deceased : false,
            }),
            EducationCompletedTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Education Completed Type',
                name: 'EducationCompletedTypeId',
                options: this.educationCompletedTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.EducationCompletedTypeId || null,
            }),
            EyeColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Eye Color',
                name: 'EyeColorId',
                options: this.eyeColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.EyeColorId || null,
            }),
            HairColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hair Color',
                name: 'HairColorId',
                options: this.hairColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.HairColorId || null,
            }),
            HairTextureId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hair Texture',
                name: 'HairTextureId',
                options: this.hairTextures,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.HairTextureId || null,
            }),
            HeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height Unit',
                name: 'HeightUnitId',
                options: this.heightUnits,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.HeightUnitId || 1,
            }),
            HeightValue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height Value',
                name: 'HeightValue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.HeightValue || null,
            }),
            IsFullSibling: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Full Sibling',
                name: 'IsFullSibling',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.hasOwnProperty('IsFullSibling') && this.familyhistory.IsFullSibling !== null ? this.familyhistory.IsFullSibling : false,
            }),
            IsHalfSiblingFather: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Half Sibling Father',
                name: 'IsHalfSiblingFather',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.hasOwnProperty('IsHalfSiblingFather') && this.familyhistory.IsHalfSiblingFather !== null ? this.familyhistory.IsHalfSiblingFather : false,
            }),
            IsHalfSiblingMother: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Half Sibling Mother',
                name: 'IsHalfSiblingMother',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.hasOwnProperty('IsHalfSiblingMother') && this.familyhistory.IsHalfSiblingMother !== null ? this.familyhistory.IsHalfSiblingMother : false,
            }),
            IsSibling: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Sibling',
                name: 'IsSibling',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.hasOwnProperty('IsSibling') && this.familyhistory.IsSibling !== null ? this.familyhistory.IsSibling : false,
            }),
            Occupation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Occupation',
                name: 'Occupation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.familyhistory && this.familyhistory.hasOwnProperty('Occupation') && this.familyhistory.Occupation !== null ? this.familyhistory.Occupation.toString() : '',
            }),
            PlaceOfBirth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Place Of Birth',
                name: 'PlaceOfBirth',
                options: this.countries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: InputTypes.Textbox,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { required: true },
                value: this.familyhistory && this.familyhistory.PlaceOfBirth || null,
            }),
            RaceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Race',
                name: 'RaceId',
                options: this.races,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.RaceId || null,
            }),
            Skills: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skills',
                name: 'Skills',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.familyhistory && this.familyhistory.hasOwnProperty('Skills') && this.familyhistory.Skills !== null ? this.familyhistory.Skills.toString() : '',
            }),
            SkinTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skin Type',
                name: 'SkinTypeId',
                options: this.skinTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.SkinTypeId || null,
            }),
            WeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight Unit',
                name: 'WeightUnitId',
                options: this.weightUnits,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.WeightUnitId || 3,
            }),
            WeightValue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight Value',
                name: 'WeightValue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.familyhistory && this.familyhistory.WeightValue || null,
            }),
        };

        this.View = {
            Age: new DynamicLabel(
                'Age',
                this.familyhistory && this.familyhistory.Age || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            AgeAtDeath: new DynamicLabel(
                'Age At Death',
                this.familyhistory && this.familyhistory.AgeAtDeath || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            CauseOfDeath: new DynamicLabel(
                'Cause Of Death',
                this.familyhistory && this.familyhistory.hasOwnProperty('CauseOfDeath') && this.familyhistory.CauseOfDeath !== null ? this.familyhistory.CauseOfDeath.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Deceased: new DynamicLabel(
                'Deceased',
                this.familyhistory && this.familyhistory.hasOwnProperty('Deceased') && this.familyhistory.Deceased !== null ? this.familyhistory.Deceased : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            EducationCompletedTypeId: new DynamicLabel(
                'Education Completed Type',
                getMetaItemValue(this.educationCompletedTypes, this.familyhistory && this.familyhistory.hasOwnProperty('EducationCompletedTypeId') && this.familyhistory.EducationCompletedTypeId !== null ? this.familyhistory.EducationCompletedTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            EyeColorId: new DynamicLabel(
                'Eye Color',
                getMetaItemValue(this.eyeColors, this.familyhistory && this.familyhistory.hasOwnProperty('EyeColorId') && this.familyhistory.EyeColorId !== null ? this.familyhistory.EyeColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HairColorId: new DynamicLabel(
                'Hair Color',
                getMetaItemValue(this.hairColors, this.familyhistory && this.familyhistory.hasOwnProperty('HairColorId') && this.familyhistory.HairColorId !== null ? this.familyhistory.HairColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HairTextureId: new DynamicLabel(
                'Hair Texture',
                getMetaItemValue(this.hairTextures, this.familyhistory && this.familyhistory.hasOwnProperty('HairTextureId') && this.familyhistory.HairTextureId !== null ? this.familyhistory.HairTextureId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HeightUnitId: new DynamicLabel(
                'Height Unit',
                getMetaItemValue(this.heightUnits, this.familyhistory && this.familyhistory.hasOwnProperty('HeightUnitId') && this.familyhistory.HeightUnitId !== null ? this.familyhistory.HeightUnitId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HeightValue: new DynamicLabel(
                'Height Value',
                this.familyhistory && this.familyhistory.HeightValue || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            ),
            IsFullSibling: new DynamicLabel(
                'Is Full Sibling',
                this.familyhistory && this.familyhistory.hasOwnProperty('IsFullSibling') && this.familyhistory.IsFullSibling !== null ? this.familyhistory.IsFullSibling : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsHalfSiblingFather: new DynamicLabel(
                'Is Half Sibling Father',
                this.familyhistory && this.familyhistory.hasOwnProperty('IsHalfSiblingFather') && this.familyhistory.IsHalfSiblingFather !== null ? this.familyhistory.IsHalfSiblingFather : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsHalfSiblingMother: new DynamicLabel(
                'Is Half Sibling Mother',
                this.familyhistory && this.familyhistory.hasOwnProperty('IsHalfSiblingMother') && this.familyhistory.IsHalfSiblingMother !== null ? this.familyhistory.IsHalfSiblingMother : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsSibling: new DynamicLabel(
                'Is Sibling',
                this.familyhistory && this.familyhistory.hasOwnProperty('IsSibling') && this.familyhistory.IsSibling !== null ? this.familyhistory.IsSibling : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Occupation: new DynamicLabel(
                'Occupation',
                this.familyhistory && this.familyhistory.hasOwnProperty('Occupation') && this.familyhistory.Occupation !== null ? this.familyhistory.Occupation.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            RaceId: new DynamicLabel(
                'Race',
                getMetaItemValue(this.races, this.familyhistory && this.familyhistory.hasOwnProperty('RaceId') && this.familyhistory.RaceId !== null ? this.familyhistory.RaceId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Skills: new DynamicLabel(
                'Skills',
                this.familyhistory && this.familyhistory.hasOwnProperty('Skills') && this.familyhistory.Skills !== null ? this.familyhistory.Skills.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            SkinTypeId: new DynamicLabel(
                'Skin Type',
                getMetaItemValue(this.skinTypes, this.familyhistory && this.familyhistory.hasOwnProperty('SkinTypeId') && this.familyhistory.SkinTypeId !== null ? this.familyhistory.SkinTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            WeightUnitId: new DynamicLabel(
                'Weight Unit',
                getMetaItemValue(this.weightUnits, this.familyhistory && this.familyhistory.hasOwnProperty('WeightUnitId') && this.familyhistory.WeightUnitId !== null ? this.familyhistory.WeightUnitId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            WeightValue: new DynamicLabel(
                'Weight Value',
                this.familyhistory && this.familyhistory.WeightValue || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
        };

    }
}
