import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from '@mt-ng2/base-service';
import { INote } from '@model/interfaces/note';
import { SearchParams } from '@mt-ng2/common-classes';
import { NoteEntities } from '@model/enums/note-entities.enum';

export const emptyNote: INote = {
    CategoryId: 0,
    DateCreated: null,
    Id: 0,
    IsArchived: false,
    NoteText: null,
    Title: null,
    UserId: 0,
};

const NOTE_TYPE_MAP = {
    [NoteEntities.Donor]: 'donors',
    [NoteEntities.Recipient]: 'recipients',
    [NoteEntities.Clinic]: 'clinics',
    [NoteEntities.DonorTestSample]: 'donor-test-samples',
    [NoteEntities.Donations]: 'donation-samples',
};

@Injectable({
    providedIn: 'root',
})
export class NoteService extends BaseService<INote> {
    constructor(public http: HttpClient) {
        super('/', http);
    }

    getEmptyNote(): INote {
        return { ...emptyNote };
    }

    getNotes(noteEntity: NoteEntities, parentId: number, searchparameters: SearchParams): Observable<HttpResponse<INote[]>> {
        let params = this.getHttpParams(searchparameters);
        const type = NOTE_TYPE_MAP[noteEntity];
        return this.http
            .get<INote[]>(`/${type}/${parentId}/notes/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(
                catchError(this.handleError),
                );
    }

    saveNote(noteEntity: NoteEntities, parentId: number, note: INote, linkedEntity?: number[]): Observable<number> {
        const type = NOTE_TYPE_MAP[noteEntity];
        if (!note.Id) {
            note.Id = 0;
            return this.http.post<number>(`/${type}/${parentId}/notes`, note);
        } else {
            return this.http.put<number>(`/${type}/${parentId}/notes`, note, { responseType: 'text' as 'json' });
        }
    }

    deleteNote(noteEntity: NoteEntities, parentId: number, noteId: number): Observable<object> {
        const type = NOTE_TYPE_MAP[noteEntity];
        return this.http.delete(`/${type}/${parentId}/notes/${noteId}`, {
            responseType: 'text' as 'json',
        });
    }
}
