<div class="form-section-border">
     <h4>
          {{ sectionTitle }}
     </h4>
</div>
<div *ngIf="metaData">
     <div *ngIf="isSibling">
          <div class="content-padded">
               <b>Is Full Sibling:</b>
               <span>{{ individual.IsFullSibling | yesNoPipe }}</span>
          </div>
          <div *ngIf="isHalfSibling">
               <div class="content-padded">
                    <b>Is Half Sibling Mother:</b>
                    <span>{{ individual.IsHalfSiblingMother | yesNoPipe }}</span>
               </div>
               <div class="content-padded">
                    <b>Is Half Sibling Father:</b>
                    <span>{{ individual.IsHalfSiblingFather | yesNoPipe }}</span>
               </div>
          </div>
     </div>
     <div class="content-padded">
          <b>Deceased:</b>
          <span>{{ individual.Deceased | yesNoPipe }}</span>
     </div>
     <div *ngIf="individual.Deceased">
          <div class="content-padded">
               <b>Cause Of Death:</b>
               <span>{{ individual.CauseOfDeath }}</span>
          </div>
          <div class="content-padded">
               <b>Age At Death:</b>
               <span>{{ individual.AgeAtDeath }}</span>
          </div>
     </div>
     <div *ngIf="!individual.Deceased" class="content-padded">
          <b>Age:</b>
          <span>{{ individual.Age }}</span>
     </div>
     <div class="content-padded">
          <b>Height:</b>
          <span>{{ adjustedHeight }}</span>
     </div>
     <div class="content-padded">
          <b>Height Unit:</b>
          <span>{{ heightUnit }}</span>
     </div>
     <div class="content-padded">
          <b>Weight:</b>
          <span>{{ individual.WeightValue }}</span>
     </div>
     <div class="content-padded">
          <b>Weight Unit:</b>
          <span>{{ weightUnit }}</span>
     </div>
     <div class="content-padded">
          <b>Eye Color:</b>
          <span>{{ eyeColor }}</span>
     </div>
     <div class="content-padded">
          <b>Hair Color:</b>
          <span>{{ hairColor }}</span>
     </div>
     <div class="content-padded">
          <b>Hair Texture:</b>
          <span>{{ hairTexture }}</span>
     </div>
     <div class="content-padded">
          <b>Skin Type:</b>
          <span>{{ skinType }}</span>
     </div>
     <div class="content-padded">
          <b>Education Completed:</b>
          <span>{{ individual.EducationCompletedType?.Name }}</span>
     </div>
     <div class="content-padded">
          <b>Occupation:</b>
          <span>{{ individual.Occupation }}</span>
     </div>
     <div class="content-padded">
          <b>Skills:</b>
          <span>{{ individual.Skills }}</span>
     </div>
     <div class="content-padded">
          <b>Race:</b>
          <span>{{ race }}</span>
     </div>
     <div class="content-padded">
          <b>Place of Birth:</b>
          <span>{{ individual.PlaceOfBirth }}</span>
     </div>
</div>