import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IDonor } from '@model/interfaces/donor';

@Component({
    selector: 'sales-order-linked-donor',
    template: `
        <div [ngClass]="{ 'display-padded': !isEditing }">
            <b>Donor Name:</b>
            <span>{{ donor.DonorUser.FirstName }} {{ donor.DonorUser.LastName }}</span>
            <button
                *ngIf="isEditing"
                type="button"
                class="btn btn-sm fa fa-trash pull-right"
                (mtConfirm)="deleteDonor()"
                [mtConfirmOptions]="customConfirmOptions"
            ></button>
        </div>
        <div [ngClass]="{ 'display-padded': !isEditing }">
            <b>Donor Id:</b>
            <span>{{ donor.Id }}</span>
        </div>
        <div [ngClass]="{ 'display-padded': !isEditing }">
            <b>Donor Email:</b>
            <span>{{ donor.DonorUser.Email }}</span>
        </div>
        <div></div>
    `,
})
export class SalesOrderLinkedDonorComponent {
    @Input()
    set donor(value: IDonor) {
        this._donor = value;
    }
    get donor(): IDonor {
        return this._donor;
    }
    private _donor: IDonor;
    @Input() index: number;
    @Input() isEditing: boolean;

    @Output() onRemoveDonor = new EventEmitter<number>();

    customConfirmOptions: IModalOptions = {
        text: 'Donor and associated inventory will be immedately unlinked from order. Unsaved changes may be overwritten',
        type: 'question',
    };

    constructor() {}

    deleteDonor(): void {
        this.onRemoveDonor.emit(this.index);
    }
}
