import { QcInvestigationDynamicControls, IQcInvestigationDynamicControlsParameters } from '../form-controls/qc-investigation.form-controls';
import { IQcInvestigation } from '../interfaces/qc-investigation';
import { FormYesNoButtonComponent, IYesNoDynamicField } from '@angular-common/components/form-yes-no-button.component';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';

export class QcInvestigationDynamicControlsPartial extends QcInvestigationDynamicControls {
    constructor(qcinvestigationPartial?: IQcInvestigation, additionalParameters?: IQcInvestigationDynamicControlsParameters) {
        super(qcinvestigationPartial, additionalParameters);

        (<DynamicField>this.Form.QcDecisionId).labelHtml = '<label>QC Decision</label>';
        (<DynamicField>this.Form.NumberFreeVials).labelHtml = '<label># Free Vials</label>';
        (<DynamicField>this.Form.QcComments).labelHtml = '<label>QC Comments</label>';
        (<DynamicField>this.Form.QcProblemId).labelHtml = '<label>QC Problem</label>';
        (<DynamicField>this.Form.QcReasonId).labelHtml = '<label>Reason for QC Investigation</label>';
        // Yes/No Fields
        (<DynamicField>this.Form.Investigation).labelHtml = '<label>QC Investigation?</label>';
        (<DynamicField>this.Form.Investigation).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HaltSalesOrder).labelHtml = '<label>Recommend Halting of Sales Order?</label>';
        (<DynamicField>this.Form.HaltSalesOrder).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.WatchlistDonor).labelHtml = '<label>Watchlist Donor?</label>';
        (<DynamicField>this.Form.WatchlistDonor).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.DiscontinueDonor).labelHtml = '<label>Recommend Discontinuing Donor?</label>';
        (<IYesNoDynamicField>this.Form.DiscontinueDonor).component = FormYesNoButtonComponent;
        (<IYesNoDynamicField>this.Form.DiscontinueDonor).confirmOnYes = true;
        (<IYesNoDynamicField>this.Form.DiscontinueDonor).confirmMessage = 'Are you sure you want to discontinue this donor?';

        (<DynamicLabel>this.View.DiscontinueDonor).label = 'Recommend Discontinuing Donor?';
        (<DynamicLabel>this.View.HaltSalesOrder).label = 'Recommend Halting of Sales Order?';
        (<DynamicLabel>this.View.Investigation).label = 'QC Investigation?';
        (<DynamicLabel>this.View.NumberFreeVials).label = '# Free Vials';
        (<DynamicLabel>this.View.QcComments).label = 'QC Comments';
        (<DynamicLabel>this.View.QcDecisionId).label = 'QC Decision';
        (<DynamicLabel>this.View.QcProblemId).label = 'QC Problem';
        (<DynamicLabel>this.View.QcReasonId).label = 'Reason for QC Investigation';
        (<DynamicLabel>this.View.WatchlistDonor).label = 'Watchlist Donor?';
    }
}
