import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import {
    DonorDonationSampleDynamicControlsPartial,
    IDonorDonationSampleDynamicControlsPartialParameters,
} from '@model/partials/donor-donation-sample-partial.form-controls';
import { IDonorSample } from '@model/interfaces/donor-sample';

export class DonorSampleDynamicConfig<T extends IDonorSample> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private donorSample: T,
        private additionalParams: IDonorDonationSampleDynamicControlsPartialParameters,
        private configControls?: string[],
    ) {
        super();
        const dynamicControls = new DonorDonationSampleDynamicControlsPartial(this.donorSample, this.additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'SampleDate',
                'DonationStatusId',
                'SampleStatusId',
                'AbbreviatedScreeningComplete',
                'PrepMethodId',
                'TimeProduced',
                'TimeProcessed',
                'HoursOfAbstinence',
                'SampleProcessorId',
                'EligibleQuarantineReleaseDate',
                'PreQuarantineTestingId',
                'InitialScreeningTestingId',
                'QuarantineReleaseTestingId',
                'FollowUpScreeningTestingId',
                'TissueEligibleForTransfer',
                'EligibilityDeterminationDate',
                'InitialsOfReleasingPerson',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
