<div class="mile-editable miles-card padded">
    <div>
        <h4>Lab Data: Cryo Prep</h4>
        <div class="display-padded">
            <b><label>Guarantee TMC Per Sample (x10<sup>6</sup>/ml)</label>:</b>
            <span >{{ calculatedFields.GuaranteeTMCPerSample }}</span>
        </div>
        <div class="display-padded">
            <b><label>Desired Final Motile Concentration (x10<sup>6</sup>/ml)</label>:</b>
            <span >{{ calculatedFields.DesiredFinalMotileConcentration }}</span>
        </div>
        <div class="display-padded">
            <b><label>Final Volume</label>:</b>
            <span >{{ calculatedFields.FinalVolume }}</span>
        </div>
        <div class="display-padded">
            <b><label>Arctic Added</label>:</b>
            <span >{{ calculatedFields.ArcticAdded }}</span>
        </div>
        <div class="display-padded">
            <b><label>Added Wash Media</label>:</b>
            <span >{{ calculatedFields.AddedWashMedia }}</span>
        </div>
    </div>
</div>
