import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDonorImage } from '@model/interfaces/donor-image';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DonorPhotoService {
    constructor(private http: HttpClient) {}

    deletePhoto(donorId: number, photoId: number): Observable<object> {
        return this.http.delete(`/donors/photos/${donorId}/${photoId}`, {
            responseType: 'text' as 'json',
        });
    }

    togglePhotoApproval(donorId: number, photoId: number): Observable<boolean> {
        return this.http.put<boolean>(`/donors/photos/${donorId}/${photoId}/toggle-approval`, {});
    }

    setPhotoAsMain(donorId: number, photo: IDonorImage): Observable<IDonorImage[]> {
        return this.http.put<IDonorImage[]>(`/donors/photos/${donorId}/${photo.ImageId}/set-main`, photo);
    }

    savePhotoOrder(donorId: number, photos: IDonorImage[]): Observable<IDonorImage[]> {
        return this.http.put<IDonorImage[]>(`/donors/photos/${donorId}/order`, photos);
    }

    uploadPhotos(donorId: number, files: File[]): Observable<IDonorImage[]> {
        let formData: FormData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i], files[i].name);
        }
        return this.http.post<IDonorImage[]>(`/donors/photos/${donorId}`, formData, { observe: 'body' });
    }
}
