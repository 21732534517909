<div class="miles-card padded">
    <h4>Required Reviews</h4>
    <ul class="list-group" style="max-height: 305px; overflow-y: auto">
        <li class="list-group-item" *ngFor="let donorReview of donorReviews; last as isLast">
            <div *ngIf="showThisReview(donorReview)">
                <span><strong>{{ donorReview.ReviewType.Name }}</strong>:</span>
                <span>{{ getReviewStatus(donorReview.ReviewStatusId) }}</span>
                <span class="pull-right">
                    <button type="button" (click)="showDonorReview(donorReview)"><i
                            class="fa fa-lg fa-edit"></i></button>
                </span>
            </div>
            <div *ngIf="isSelectedForEdit(donorReview)">
                <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)"
                    (submitted)="formSubmitted($event)">
                    <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                        Class="btn btn-flat btn-success">
                        Save
                    </button>
                    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
                </app-dynamic-form>
            </div>
        </li>
    </ul>
</div>