<strong>
    <span>{{ medicalIssue.Name }}</span>
</strong>
<ul>
    <li style="list-style-type: none" *ngFor="let relationship of selectedRelationships">
        <span>{{ relationship.Name }}</span> <span style="float: right">{{ medicalIssue[relationship.Name] }}</span>
    </li>
</ul>
<div style="padding-left: 20px" *ngIf="subIssues && subIssues.length">
    <strong style="padding-left: 5px">
        <span>{{ subIssues[0].Name }}</span>
    </strong>
    <ul>
        <li style="list-style-type: none" *ngFor="let relationship of subissue1Relationships">
            <span>{{ relationship.Name }}</span> <span style="float: right">{{ subIssues[0][relationship.Name] || 0 }}</span>
        </li>
    </ul>
    <div style="padding-left: 20px" *ngIf="subIssues[1]">
        <strong style="padding-left: 5px">
            <span>{{ subIssues[1].Name }}</span>
        </strong>
        <ul>
            <li style="list-style-type: none" *ngFor="let relationship of subissue2Relationships">
                <span>{{ relationship.Name }}</span
                ><span style="float: right">{{ subIssues[1][relationship.Name] || 0 }}</span>
            </li>
        </ul>
    </div>
</div>
