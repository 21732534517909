import { Validators } from '@angular/forms';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import {
    DonorSampleRemainderLabDataDynamicControls,
    IDonorSampleRemainderLabDataDynamicControlsParameters,
} from '../form-controls/donor-sample-remainder-lab-data.form-controls';
import { IDonorSampleRemainderLabData } from '../interfaces/donor-sample-remainder-lab-data';

export class DonorSampleRemainderLabDataDynamicControlsPartial extends DonorSampleRemainderLabDataDynamicControls {
    constructor(
        donorsampleremainderlabdataPartial?: IDonorSampleRemainderLabData,
        additionalParameters?: IDonorSampleRemainderLabDataDynamicControlsParameters,
    ) {
        super(donorsampleremainderlabdataPartial, additionalParameters);

        // Validation
        (<DynamicField>this.Form.LabelVerifiedBy).validation.push(Validators.required);
        (<DynamicField>this.Form.LabelVerifiedBy).validators.required = true;
        (<DynamicField>this.Form.TestThawMotileConcentration).validation.push(Validators.required);
        (<DynamicField>this.Form.TestThawMotileConcentration).validators.required = true;

        // Test Thaw Form Labels
        (<DynamicField>this.Form.TestThawMotileConcentration).label = 'Motile Concentration (x10<sup>6</sup>/ml)';
        (<DynamicField>this.Form.TestThawMotileConcentration).labelHtml = '<label>Motile Concentration (x10<sup>6</sup>/ml)</label>';
    }
}
