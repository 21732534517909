import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';

import { IBirthDetail } from '@model/interfaces/birth-detail';
import { IBirthOutcome } from '@model/interfaces/birth-outcome';

export const emptyBirthOutcome: IBirthOutcome = {
    Id: 0,
    NumberBorn: null,
    OutcomeSourceId: null,
    PregnancyOutcomeId: null,
    ThawingId: 0,
};

export const emptyBirthDetail: IBirthDetail = {
    BirthDefectsId: null,
    BirthOutcomeId: 0,
    Comments: null,
    GenderId: null,
    Height: null,
    HeightUnitId: null,
    Id: 0,
    Weight: null,
    WeightUnitId: null,
};

@Injectable({
    providedIn: 'root',
})
export class BirthOutcomeService extends BaseService<IBirthOutcome> {
    constructor(public http: HttpClient) {
        super('/birth-outcomes', http);
    }

    getEmptyBirthOutcome(thawingId: number): IBirthOutcome {
        const newBirthOutcome = { ...emptyBirthOutcome };
        newBirthOutcome.ThawingId = thawingId;
        return newBirthOutcome;
    }

    getEmptyBirthDetails(count: number, birthOutcomeId: number): IBirthDetail[] {
        const birthDetails = [];
        while (count > 0) {
            const newDetail = { ...emptyBirthDetail };
            newDetail.BirthOutcomeId = birthOutcomeId;
            birthDetails.push(newDetail);
            count--;
        }
        return birthDetails;
    }

    getBirthOutcomeByWarmingId(salesOrderId: number, warmingId: number): Observable<IBirthOutcome> {
        return this.http.get<IBirthOutcome>(`/recipient-sales-orders/${salesOrderId}/births/${warmingId}`);
    }
}
