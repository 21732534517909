import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize, map } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { INoteCategory } from '@model/interfaces/note-category';
import { INote } from '@model/interfaces/note';
import { NoteCategoryService } from './note-category.service';
import { NoteDynamicConfig } from './note.dynamic-config';
import { forkJoin } from 'rxjs';

import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { NoteCategories } from '@model/enums/note-categories.enum';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { NoteEntities } from '@model/enums/note-entities.enum';
import { NoteService } from './note.service';
import { IModalOptions } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
})
export class NotesComponent implements OnInit {
    @Input() entityId: number;
    @Input() entityType: NoteEntities;
    @Input() canEdit: boolean;
    // used for shipping notes, donor(s) will be linked on BE
    @Input() linkedEntityIds: number[];
    @Input() allowedNoteCategories: NoteCategories[];

    isEditing = false;
    isViewing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: NoteDynamicConfig<INote>;
    doubleClickIsDisabled = false;
    notes: INote[];
    allCategories: INoteCategory[];
    allCategorySearchFilterItems: MtSearchFilterItem[];
    selectedNote: INote;
    currentUser: ILoggedIn;
    currentPage = 1;
    itemsPerPage = 5;
    query = '';
    freshList = true;
    allFetched: boolean;

    showConfirm = false;
    confirmOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };

    constructor(
        private noteService: NoteService,
        private authService: AuthService,
        private noteCategoryService: NoteCategoryService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.noteCategoryService.getItems(), this.noteCategoryService.getSearchFilterItems()]).subscribe(
            ([categories, categoryFilterItems]) => {
                this.allCategories = categories;
                this.allCategorySearchFilterItems = categoryFilterItems;
                this.getNotes();
            },
        );
        this.authService.currentUser.subscribe((user) => (this.currentUser = user));
    }

    getNotes(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'DateCreated',
            orderDirection: 'desc',
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };
        if (this.allowedNoteCategories && this.allowedNoteCategories.length) {
            searchEntity.extraParams.push({ name: 'NoteCategoryIds', valueArray: this.allowedNoteCategories });
        }

        const searchparams = new SearchParams(searchEntity);

        this.noteService.getNotes(this.entityType, this.entityId, searchparams).subscribe((resp) => {
            if (resp.body.length === 0) {
                this.allFetched = true;
            }
            if (this.freshList) {
                this.notes = resp.body;
                this.freshList = false;
            } else {
                this.notes.push(...resp.body);
            }
        });
    }

    buildSearch(): ExtraSearchParams[] {
        const selectedCycleStatusIds: number[] = this.getSelectedFilters(this.allCategorySearchFilterItems);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'NoteCategoryIds',
                valueArray: selectedCycleStatusIds,
            }),
        );

        return _extraSearchParams;
    }

    filterSelectionChanged(): void {
        this.setListAsRefresh();
        this.getNotes();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    search(query: string): void {
        this.setListAsRefresh();
        this.query = query;
        this.getNotes();
    }

    setListAsRefresh(): void {
        this.currentPage = 1;
        this.allFetched = false;
        this.freshList = true;
    }

    setConfig(configControls?: string[]): void {
        const users = null;
        const filteredNoteCategories = this.allowedNoteCategories?.length ?
            this.allCategories.filter((cat) => this.allowedNoteCategories.includes(cat.Id)) :
            this.allCategories;
        this.formFactory = new NoteDynamicConfig<INote>(this.selectedNote, filteredNoteCategories, users, configControls);
        this.config = this.formFactory.getForUpdate();
    }

    createNote(): void {
        this.selectedNote = this.noteService.getEmptyNote();
        if (this.allowedNoteCategories && this.allowedNoteCategories?.length === 1) {
            this.selectedNote.CategoryId = this.allowedNoteCategories[0];
        }
        this.setConfig();
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
        this.isViewing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.selectedNote, form.value.Note);
            this.selectedNote.UserId = this.currentUser.Id;
            this.selectedNote.DateCreated = new Date();
            this.saveNote();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    showNote(note): void {
        this.selectedNote = note;
        if (this.canEdit && this.selectedNote.User.AuthUserId === this.currentUser.Id) {
            this.setConfig();
            this.isEditing = true;
        } else {
            this.setConfig(['Title', 'NoteText', 'CategoryId', 'UserId', 'DateCreated']);
            this.isViewing = true;
        }
    }

    onCheckInView(inView: boolean): void {
        // scroll-check will be in view before notes load, we don't want to pull on initial load
        // We also don't want to pull the list if coming back from editing since success will do that
        if (inView && !this.freshList && !this.allFetched) {
            this.currentPage++;
            this.getNotes();
        }
    }

    private saveNote(): void {
        this.noteService
            .saveNote(this.entityType, this.entityId, this.selectedNote, this.linkedEntityIds)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(() => {
                this.success();
            });
    }

    private success(): void {
        this.setListAsRefresh();
        this.getNotes();
        this.isEditing = false;
        this.setConfig();
        this.noteService.emitChange(this.selectedNote);
        this.notificationsService.success('Note saved successfully.');
    }

    openDeleteNote(): void {
        this.showConfirm = true;
    }

    cancelDeleteNote(): void {
        this.showConfirm = false;
    }

    deleteNote(): void {
        this.showConfirm = false;
        this.noteService.deleteNote(this.entityType, this.entityId, this.selectedNote.Id).subscribe(() => {
            this.setListAsRefresh();
            this.getNotes();
            this.cancelClick();
        });
    }
}
