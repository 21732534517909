import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { AppointmentService } from '../services/appointment.service';
import { IAppointment } from '@model/interfaces/appointment';
import { ClaimTypes } from '@model/ClaimTypes';
import { getIdFromRoute } from '@angular-common/libraries/common-functions.library';

@Component({
    templateUrl: './appointment-detail.component.html',
})
export class AppointmentDetailComponent implements OnInit {
    appointment: IAppointment;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private appointmentService: AppointmentService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Appointments, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = getIdFromRoute(this.route, 'appointmentId');
        if (id) {
            this.getAppointmentById(id);
        } else {
            this.router.navigate(['appointments']); // if no id found, go back to list
        }
    }

    getAppointmentById(id: number): void {
        this.appointmentService.getById(id).subscribe((appointment) => {
            if (appointment === null) {
                this.notificationsService.error('Appointment not found');
                this.router.navigate(['appointments']);
            }
            this.appointment = appointment;
        });
    }
}
