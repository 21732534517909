<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Recipients"></mt-page-title>
        </h2>
    </div>
</div>
<div *ngIf="metaItemsReady" class="row col-md-12">
    <div class="row">
        <mt-search-bar class="col-md-11" (onSearch)="search($event)" (ready)="searchbarApi = $event; setStickySearch()"></mt-search-bar>
        <div class="col-md-1">
            <button class="btn btn-flat btn-primary pull-right" (click)="exportRecipients($event)">
                Export &#10240; <i class="fa fa-download" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <br />
    <mt-search-filter-select [items]="recipientStatuses" entity="Recipient Status" (onSelectionChanged)="filterSelectionChanged()">
    </mt-search-filter-select>
    <mt-search-filter-select [items]="cycleStatuses" entity="Cycle Status" (onSelectionChanged)="filterSelectionChanged()"> </mt-search-filter-select>
    <mt-search-filter-select [items]="accountManagers" entity="Account Manager" (onSelectionChanged)="filterSelectionChanged()">
    </mt-search-filter-select>
    <results-per-page (onSizeChange)="onPageSizeChange($event)"></results-per-page>
    <br />
</div>

<div class="row col-md-12">
    <entity-list
        [entities]="recipientUsers"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        (onPageChanged)="getRecipientUsers()"
        (onItemSelected)="recipientUserSelected($event)"
        (onColumnSorted)="columnSorted($event)"
        [entityListConfig]="entityListConfig"
    >
    </entity-list>
</div>

<div *ngIf="canAddRecipientUser" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/recipient-users', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
