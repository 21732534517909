import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IBloodWorkDetail } from '../interfaces/blood-work-detail';
import { IScreeningResultOption } from '../interfaces/screening-result-option';
import { ISyphilisTest } from '../interfaces/syphilis-test';

export interface IBloodWorkDetailDynamicControlsParameters {
    formGroup?: string;
    hepBsAgOutcomes?: IScreeningResultOption[];
    hepBCoreOutcomes?: IScreeningResultOption[];
    hiv102AbOutcomes?: IScreeningResultOption[];
    hiv1NatOutcomes?: IScreeningResultOption[];
    hbvNatOutcomes?: IScreeningResultOption[];
    hcvNatOutcomes?: IScreeningResultOption[];
    antiHepCS?: IScreeningResultOption[];
    syphilisOutcomes?: IScreeningResultOption[];
    syphilisTests?: ISyphilisTest[];
    chlamydiaNatOutcomes?: IScreeningResultOption[];
    gonorrheaNatOutcomes?: IScreeningResultOption[];
    wnvNats?: IScreeningResultOption[];
    cmvOutcomes?: IScreeningResultOption[];
    cmvTotalAntibodyResults?: IScreeningResultOption[];
    cmvIggResults?: IScreeningResultOption[];
    cmvIgmResults?: IScreeningResultOption[];
    htlvIAndIiOutcomes?: IScreeningResultOption[];
}

export class BloodWorkDetailDynamicControls {

    formGroup: string;
    hepBsAgOutcomes: IScreeningResultOption[];
    hepBCoreOutcomes: IScreeningResultOption[];
    hiv102AbOutcomes: IScreeningResultOption[];
    hiv1NatOutcomes: IScreeningResultOption[];
    hbvNatOutcomes: IScreeningResultOption[];
    hcvNatOutcomes: IScreeningResultOption[];
    antiHepCS: IScreeningResultOption[];
    syphilisOutcomes: IScreeningResultOption[];
    syphilisTests: ISyphilisTest[];
    chlamydiaNatOutcomes: IScreeningResultOption[];
    gonorrheaNatOutcomes: IScreeningResultOption[];
    wnvNats: IScreeningResultOption[];
    cmvOutcomes: IScreeningResultOption[];
    cmvTotalAntibodyResults: IScreeningResultOption[];
    cmvIggResults: IScreeningResultOption[];
    cmvIgmResults: IScreeningResultOption[];
    htlvIAndIiOutcomes: IScreeningResultOption[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private bloodworkdetail?: IBloodWorkDetail, additionalParameters?: IBloodWorkDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'BloodWorkDetail';
        this.hepBsAgOutcomes = additionalParameters && additionalParameters.hepBsAgOutcomes || undefined;
        this.hepBCoreOutcomes = additionalParameters && additionalParameters.hepBCoreOutcomes || undefined;
        this.hiv102AbOutcomes = additionalParameters && additionalParameters.hiv102AbOutcomes || undefined;
        this.hiv1NatOutcomes = additionalParameters && additionalParameters.hiv1NatOutcomes || undefined;
        this.hbvNatOutcomes = additionalParameters && additionalParameters.hbvNatOutcomes || undefined;
        this.hcvNatOutcomes = additionalParameters && additionalParameters.hcvNatOutcomes || undefined;
        this.antiHepCS = additionalParameters && additionalParameters.antiHepCS || undefined;
        this.syphilisOutcomes = additionalParameters && additionalParameters.syphilisOutcomes || undefined;
        this.syphilisTests = additionalParameters && additionalParameters.syphilisTests || undefined;
        this.chlamydiaNatOutcomes = additionalParameters && additionalParameters.chlamydiaNatOutcomes || undefined;
        this.gonorrheaNatOutcomes = additionalParameters && additionalParameters.gonorrheaNatOutcomes || undefined;
        this.wnvNats = additionalParameters && additionalParameters.wnvNats || undefined;
        this.cmvOutcomes = additionalParameters && additionalParameters.cmvOutcomes || undefined;
        this.cmvTotalAntibodyResults = additionalParameters && additionalParameters.cmvTotalAntibodyResults || undefined;
        this.cmvIggResults = additionalParameters && additionalParameters.cmvIggResults || undefined;
        this.cmvIgmResults = additionalParameters && additionalParameters.cmvIgmResults || undefined;
        this.htlvIAndIiOutcomes = additionalParameters && additionalParameters.htlvIAndIiOutcomes || undefined;

        this.Form = {
            AdditionalLabs: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Labs',
                name: 'AdditionalLabs',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.AdditionalLabs || null,
            }),
            AntiHepCDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Anti Hep C Draw Date',
                name: 'AntiHepCDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.AntiHepCDrawDate || null,
            }),
            AntiHepCId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Anti Hep C',
                name: 'AntiHepCId',
                options: this.antiHepCS,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.AntiHepCId || null,
            }),
            ChlamydiaNatDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Chlamydia Nat Draw Date',
                name: 'ChlamydiaNatDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.ChlamydiaNatDrawDate || null,
            }),
            ChlamydiaNatOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Chlamydia Nat Outcome',
                name: 'ChlamydiaNatOutcomeId',
                options: this.chlamydiaNatOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.ChlamydiaNatOutcomeId || null,
            }),
            CmvDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cmv Draw Date',
                name: 'CmvDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.CmvDrawDate || null,
            }),
            CmvIggDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cmv Igg Draw Date',
                name: 'CmvIggDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.CmvIggDrawDate || null,
            }),
            CmvIggResultId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cmv Igg Result',
                name: 'CmvIggResultId',
                options: this.cmvIggResults,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.CmvIggResultId || null,
            }),
            CmvIgmDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cmv Igm Draw Date',
                name: 'CmvIgmDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.CmvIgmDrawDate || null,
            }),
            CmvIgmResultId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cmv Igm Result',
                name: 'CmvIgmResultId',
                options: this.cmvIgmResults,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.CmvIgmResultId || null,
            }),
            CmvOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cmv Outcome',
                name: 'CmvOutcomeId',
                options: this.cmvOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.CmvOutcomeId || null,
            }),
            CmvStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cmv Status',
                name: 'CmvStatusId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.CmvStatusId || null,
            }),
            CmvTotalAntibodyDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cmv Total Antibody Draw Date',
                name: 'CmvTotalAntibodyDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.CmvTotalAntibodyDrawDate || null,
            }),
            CmvTotalAntibodyResultId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cmv Total Antibody Result',
                name: 'CmvTotalAntibodyResultId',
                options: this.cmvTotalAntibodyResults,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.CmvTotalAntibodyResultId || null,
            }),
            FdaBookDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fda Book Date',
                name: 'FdaBookDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.FdaBookDate || null,
            }),
            FdaLabsDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fda Labs Date',
                name: 'FdaLabsDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.FdaLabsDate || null,
            }),
            GonorrheaNatDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gonorrhea Nat Draw Date',
                name: 'GonorrheaNatDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.GonorrheaNatDrawDate || null,
            }),
            GonorrheaNatOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gonorrhea Nat Outcome',
                name: 'GonorrheaNatOutcomeId',
                options: this.gonorrheaNatOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.GonorrheaNatOutcomeId || null,
            }),
            HbvNatDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hbv Nat Draw Date',
                name: 'HbvNatDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.HbvNatDrawDate || null,
            }),
            HbvNatOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hbv Nat Outcome',
                name: 'HbvNatOutcomeId',
                options: this.hbvNatOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.HbvNatOutcomeId || null,
            }),
            HcvNatDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hcv Nat Draw Date',
                name: 'HcvNatDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.HcvNatDrawDate || null,
            }),
            HcvNatOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hcv Nat Outcome',
                name: 'HcvNatOutcomeId',
                options: this.hcvNatOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.HcvNatOutcomeId || null,
            }),
            HepBCoreDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hep B Core Draw Date',
                name: 'HepBCoreDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.HepBCoreDrawDate || null,
            }),
            HepBCoreOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hep B Core Outcome',
                name: 'HepBCoreOutcomeId',
                options: this.hepBCoreOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.HepBCoreOutcomeId || null,
            }),
            HepBsAgDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hep Bs Ag Draw Date',
                name: 'HepBsAgDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.HepBsAgDrawDate || null,
            }),
            HepBsAgOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hep Bs Ag Outcome',
                name: 'HepBsAgOutcomeId',
                options: this.hepBsAgOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.HepBsAgOutcomeId || null,
            }),
            Hiv102AbDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hiv102 Ab Date',
                name: 'Hiv102AbDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.Hiv102AbDate || null,
            }),
            Hiv102AbOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hiv102 Ab Outcome',
                name: 'Hiv102AbOutcomeId',
                options: this.hiv102AbOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.Hiv102AbOutcomeId || null,
            }),
            Hiv1NatDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hiv1 Nat Date',
                name: 'Hiv1NatDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.Hiv1NatDate || null,
            }),
            Hiv1NatOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hiv1 Nat Outcome',
                name: 'Hiv1NatOutcomeId',
                options: this.hiv1NatOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.Hiv1NatOutcomeId || null,
            }),
            HtlvIAndIiDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Htlv I And Ii Draw Date',
                name: 'HtlvIAndIiDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.HtlvIAndIiDrawDate || null,
            }),
            HtlvIAndIiOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Htlv I And Ii Outcome',
                name: 'HtlvIAndIiOutcomeId',
                options: this.htlvIAndIiOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.HtlvIAndIiOutcomeId || null,
            }),
            SorReceivedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sor Received Date',
                name: 'SorReceivedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.SorReceivedDate || null,
            }),
            SyphilisDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Syphilis Draw Date',
                name: 'SyphilisDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.SyphilisDrawDate || null,
            }),
            SyphilisOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Syphilis Outcome',
                name: 'SyphilisOutcomeId',
                options: this.syphilisOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.SyphilisOutcomeId || null,
            }),
            SyphilisTestId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Syphilis Test',
                name: 'SyphilisTestId',
                options: this.syphilisTests,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.SyphilisTestId || null,
            }),
            WnvNatDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Wnv Nat Draw Date',
                name: 'WnvNatDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.WnvNatDrawDate || null,
            }),
            WnvNatId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Wnv Nat',
                name: 'WnvNatId',
                options: this.wnvNats,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.bloodworkdetail && this.bloodworkdetail.WnvNatId || null,
            }),
        };

        this.View = {
            AdditionalLabs: new DynamicLabel(
                'Additional Labs',
                this.bloodworkdetail && this.bloodworkdetail.AdditionalLabs || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            AntiHepCDrawDate: new DynamicLabel(
                'Anti Hep C Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.AntiHepCDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            AntiHepCId: new DynamicLabel(
                'Anti Hep C',
                getMetaItemValue(this.antiHepCS, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('AntiHepCId') && this.bloodworkdetail.AntiHepCId !== null ? this.bloodworkdetail.AntiHepCId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ChlamydiaNatDrawDate: new DynamicLabel(
                'Chlamydia Nat Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.ChlamydiaNatDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ChlamydiaNatOutcomeId: new DynamicLabel(
                'Chlamydia Nat Outcome',
                getMetaItemValue(this.chlamydiaNatOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('ChlamydiaNatOutcomeId') && this.bloodworkdetail.ChlamydiaNatOutcomeId !== null ? this.bloodworkdetail.ChlamydiaNatOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CmvDrawDate: new DynamicLabel(
                'Cmv Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.CmvDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            CmvIggDrawDate: new DynamicLabel(
                'Cmv Igg Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.CmvIggDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            CmvIggResultId: new DynamicLabel(
                'Cmv Igg Result',
                getMetaItemValue(this.cmvIggResults, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('CmvIggResultId') && this.bloodworkdetail.CmvIggResultId !== null ? this.bloodworkdetail.CmvIggResultId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CmvIgmDrawDate: new DynamicLabel(
                'Cmv Igm Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.CmvIgmDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            CmvIgmResultId: new DynamicLabel(
                'Cmv Igm Result',
                getMetaItemValue(this.cmvIgmResults, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('CmvIgmResultId') && this.bloodworkdetail.CmvIgmResultId !== null ? this.bloodworkdetail.CmvIgmResultId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CmvOutcomeId: new DynamicLabel(
                'Cmv Outcome',
                getMetaItemValue(this.cmvOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('CmvOutcomeId') && this.bloodworkdetail.CmvOutcomeId !== null ? this.bloodworkdetail.CmvOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CmvStatusId: new DynamicLabel(
                'Cmv Status',
                this.bloodworkdetail && this.bloodworkdetail.CmvStatusId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            CmvTotalAntibodyDrawDate: new DynamicLabel(
                'Cmv Total Antibody Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.CmvTotalAntibodyDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            CmvTotalAntibodyResultId: new DynamicLabel(
                'Cmv Total Antibody Result',
                getMetaItemValue(this.cmvTotalAntibodyResults, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('CmvTotalAntibodyResultId') && this.bloodworkdetail.CmvTotalAntibodyResultId !== null ? this.bloodworkdetail.CmvTotalAntibodyResultId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            FdaBookDate: new DynamicLabel(
                'Fda Book Date',
                this.bloodworkdetail && this.bloodworkdetail.FdaBookDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            FdaLabsDate: new DynamicLabel(
                'Fda Labs Date',
                this.bloodworkdetail && this.bloodworkdetail.FdaLabsDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GonorrheaNatDrawDate: new DynamicLabel(
                'Gonorrhea Nat Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.GonorrheaNatDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GonorrheaNatOutcomeId: new DynamicLabel(
                'Gonorrhea Nat Outcome',
                getMetaItemValue(this.gonorrheaNatOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('GonorrheaNatOutcomeId') && this.bloodworkdetail.GonorrheaNatOutcomeId !== null ? this.bloodworkdetail.GonorrheaNatOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HbvNatDrawDate: new DynamicLabel(
                'Hbv Nat Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.HbvNatDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HbvNatOutcomeId: new DynamicLabel(
                'Hbv Nat Outcome',
                getMetaItemValue(this.hbvNatOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('HbvNatOutcomeId') && this.bloodworkdetail.HbvNatOutcomeId !== null ? this.bloodworkdetail.HbvNatOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HcvNatDrawDate: new DynamicLabel(
                'Hcv Nat Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.HcvNatDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HcvNatOutcomeId: new DynamicLabel(
                'Hcv Nat Outcome',
                getMetaItemValue(this.hcvNatOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('HcvNatOutcomeId') && this.bloodworkdetail.HcvNatOutcomeId !== null ? this.bloodworkdetail.HcvNatOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HepBCoreDrawDate: new DynamicLabel(
                'Hep B Core Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.HepBCoreDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HepBCoreOutcomeId: new DynamicLabel(
                'Hep B Core Outcome',
                getMetaItemValue(this.hepBCoreOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('HepBCoreOutcomeId') && this.bloodworkdetail.HepBCoreOutcomeId !== null ? this.bloodworkdetail.HepBCoreOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HepBsAgDrawDate: new DynamicLabel(
                'Hep Bs Ag Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.HepBsAgDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HepBsAgOutcomeId: new DynamicLabel(
                'Hep Bs Ag Outcome',
                getMetaItemValue(this.hepBsAgOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('HepBsAgOutcomeId') && this.bloodworkdetail.HepBsAgOutcomeId !== null ? this.bloodworkdetail.HepBsAgOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Hiv102AbDate: new DynamicLabel(
                'Hiv102 Ab Date',
                this.bloodworkdetail && this.bloodworkdetail.Hiv102AbDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Hiv102AbOutcomeId: new DynamicLabel(
                'Hiv102 Ab Outcome',
                getMetaItemValue(this.hiv102AbOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('Hiv102AbOutcomeId') && this.bloodworkdetail.Hiv102AbOutcomeId !== null ? this.bloodworkdetail.Hiv102AbOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Hiv1NatDate: new DynamicLabel(
                'Hiv1 Nat Date',
                this.bloodworkdetail && this.bloodworkdetail.Hiv1NatDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Hiv1NatOutcomeId: new DynamicLabel(
                'Hiv1 Nat Outcome',
                getMetaItemValue(this.hiv1NatOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('Hiv1NatOutcomeId') && this.bloodworkdetail.Hiv1NatOutcomeId !== null ? this.bloodworkdetail.Hiv1NatOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HtlvIAndIiDrawDate: new DynamicLabel(
                'Htlv I And Ii Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.HtlvIAndIiDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HtlvIAndIiOutcomeId: new DynamicLabel(
                'Htlv I And Ii Outcome',
                getMetaItemValue(this.htlvIAndIiOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('HtlvIAndIiOutcomeId') && this.bloodworkdetail.HtlvIAndIiOutcomeId !== null ? this.bloodworkdetail.HtlvIAndIiOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SorReceivedDate: new DynamicLabel(
                'Sor Received Date',
                this.bloodworkdetail && this.bloodworkdetail.SorReceivedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            SyphilisDrawDate: new DynamicLabel(
                'Syphilis Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.SyphilisDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            SyphilisOutcomeId: new DynamicLabel(
                'Syphilis Outcome',
                getMetaItemValue(this.syphilisOutcomes, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('SyphilisOutcomeId') && this.bloodworkdetail.SyphilisOutcomeId !== null ? this.bloodworkdetail.SyphilisOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SyphilisTestId: new DynamicLabel(
                'Syphilis Test',
                getMetaItemValue(this.syphilisTests, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('SyphilisTestId') && this.bloodworkdetail.SyphilisTestId !== null ? this.bloodworkdetail.SyphilisTestId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            WnvNatDrawDate: new DynamicLabel(
                'Wnv Nat Draw Date',
                this.bloodworkdetail && this.bloodworkdetail.WnvNatDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            WnvNatId: new DynamicLabel(
                'Wnv Nat',
                getMetaItemValue(this.wnvNats, this.bloodworkdetail && this.bloodworkdetail.hasOwnProperty('WnvNatId') && this.bloodworkdetail.WnvNatId !== null ? this.bloodworkdetail.WnvNatId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
