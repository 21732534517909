import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalizeDatesByName } from '@angular-common/libraries/treat-date-as-local.library';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';

import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IDonor } from '@model/interfaces/donor';
import { IDonorBloodWork } from '@model/interfaces/donor-blood-work';
import { IDonorPsychologicalScreening } from '@model/interfaces/donor-psychological-screening';
import { IDonorUser } from '@model/interfaces/donor-user';
import { IVwDonorInventory } from '@model/interfaces/vw-donor-inventory';
import { IDonorAvailability } from '@model/interfaces/donor-availability';

@Injectable({
    providedIn: 'root',
})
export class DonorService extends BaseService<IDonor> {
    constructor(public http: HttpClient) {
        super('/donors', http);
    }

    getPotentialFamilies(donorId: number, selectedCountryCode: string): Observable<number> {
        return this.http.get<number>(`/donors/${donorId}/potential-families/${selectedCountryCode}`);
    }

    undenyDonor(donorId: number): Observable<IDonor> {
        return this.http.patch<IDonor>(`/donors/${donorId}/undeny-donor`, {});
    }

    getById(id: number): Observable<IDonor> {
        return super.getById(id).pipe(
            map((d) => {
                if (d.DonorPsychologicalScreening) {
                    this.localizePsychScreenDates(d.DonorPsychologicalScreening);
                }
                return d;
            }),
        );
    }

    postPsychScreen(donorId: number, data: IDonorPsychologicalScreening): Observable<IDonorPsychologicalScreening> {
        const resp = this.http.post<IDonorPsychologicalScreening>(`/donors/${donorId}/DonorPsychologicalScreening`, data);
        return this.localizePsychScreenResponse(resp);
    }

    patchPsychScreen(donorId: number, data: IDonorPsychologicalScreening): Observable<IDonorPsychologicalScreening> {
        const resp = this.http.patch<IDonorPsychologicalScreening>(`/donors/${donorId}/DonorPsychologicalScreening`, data);
        return this.localizePsychScreenResponse(resp);
    }

    postBloodWork(donorId: number, data: IDonorPsychologicalScreening): Observable<IDonorBloodWork> {
        return this.http.post<IDonorBloodWork>(`/donors/${donorId}/DonorBloodWork`, data);
    }

    patchBloodWork(donorId: number, data: IDonorBloodWork): Observable<IDonorBloodWork> {
        return this.http.patch<IDonorBloodWork>(`/donors/${donorId}/DonorBloodWork`, data);
    }

    getPersonalInformationMetaData(): Observable<IPersonalInformationMetaData> {
        return this.http.get<IPersonalInformationMetaData>('/options/personal-information');
    }

    updateDonorUser(donorUser: IDonorUser, donorId: number): Observable<void> {
        return this.http.put<void>(`/donors/${donorId}/donor-user`, donorUser);
    }

    getDonorInventorySummary(searchparameters: SearchParams): Observable<HttpResponse<IVwDonorInventory[]>> {
        let params = this.getHttpParams(searchparameters);
        return this.http
            .get<IVwDonorInventory[]>(`/donors/inventory-summary`, {
                observe: 'response',
                params: params,
            })
            .pipe(
                map((s) => {
                    s.body.forEach((inv) => LocalizeDatesByName(inv, ['DonationDate', 'ReleaseDate']));
                    return s;
                }),
                catchError(this.handleError),
            );
    }

    getDonorDetailsPdf(donorId: number): Observable<any> {
        return this.http.get(`/donors/${donorId}/details-pdf`, { responseType: 'blob' as 'blob' });
    }

    private localizePsychScreenResponse(screening: Observable<IDonorPsychologicalScreening>): Observable<IDonorPsychologicalScreening> {
        return screening.pipe(
            map((s) => {
                this.localizePsychScreenDates(s);
                return s;
            }),
        );
    }

    private localizePsychScreenDates(screening: IDonorPsychologicalScreening): void {
        LocalizeDatesByName(screening, [
            'EvalInstructionsSentDate',
            'MmpiCompleteDate',
            'ReportReceivedDate',
            'StatusChangedDate',
            'AnzicaSentDate',
            'AnzicaRecievedDate',
            'DateModified',
            'PsychCompletedDate',
        ]);
    }

    saveDonorAvailabilities(donorId: number, data: IDonorAvailability[]): Observable<boolean> {
        return this.http.post<boolean>(`/donors/${donorId}/availability`, data);
    }

}
