import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IRecipient } from '../interfaces/recipient';
import { IAddress } from '../interfaces/address';
import { IClinic } from '../interfaces/clinic';
import { IContactType } from '../interfaces/contact-type';
import { IReferralType } from '../interfaces/referral-type';
import { IRecipientStatus } from '../interfaces/recipient-status';

export interface IRecipientDynamicControlsParameters {
    formGroup?: string;
    addresses?: IAddress[];
    statuses?: IRecipientStatus[];
    preferredContactTypes?: IContactType[];
    referralTypes?: IReferralType[];
    clinics?: IClinic[];
}

export class RecipientDynamicControls {

    formGroup: string;
    addresses: IAddress[];
    statuses: IRecipientStatus[];
    preferredContactTypes: IContactType[];
    referralTypes: IReferralType[];
    clinics: IClinic[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private recipient?: IRecipient, additionalParameters?: IRecipientDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Recipient';
        this.addresses = additionalParameters && additionalParameters.addresses || undefined;
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;
        this.preferredContactTypes = additionalParameters && additionalParameters.preferredContactTypes || undefined;
        this.referralTypes = additionalParameters && additionalParameters.referralTypes || undefined;
        this.clinics = additionalParameters && additionalParameters.clinics || undefined;

        this.Form = {
            AccountManagerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Account Manager',
                name: 'AccountManagerId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipient && this.recipient.AccountManagerId || null,
            }),
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipient && this.recipient.AddressId || null,
            }),
            ClinicId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Clinic',
                name: 'ClinicId',
                options: this.clinics,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipient && this.recipient.ClinicId || null,
            }),
            Dob: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dob',
                name: 'Dob',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipient && this.recipient.Dob || null,
            }),
            MiddleInitial: new DynamicField({
                formGroup: this.formGroup,
                label: 'Middle Initial',
                name: 'MiddleInitial',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1) ],
                validators: { 'maxlength': 1 },
                value: this.recipient && this.recipient.hasOwnProperty('MiddleInitial') && this.recipient.MiddleInitial !== null ? this.recipient.MiddleInitial.toString() : '',
            }),
            PartnerDob: new DynamicField({
                formGroup: this.formGroup,
                label: 'Partner Dob',
                name: 'PartnerDob',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipient && this.recipient.PartnerDob || null,
            }),
            PartnerEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Partner Email',
                name: 'PartnerEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.recipient && this.recipient.hasOwnProperty('PartnerEmail') && this.recipient.PartnerEmail !== null ? this.recipient.PartnerEmail.toString() : '',
            }),
            PartnerName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Partner Name',
                name: 'PartnerName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.recipient && this.recipient.hasOwnProperty('PartnerName') && this.recipient.PartnerName !== null ? this.recipient.PartnerName.toString() : '',
            }),
            Phone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone',
                name: 'Phone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.recipient && this.recipient.hasOwnProperty('Phone') && this.recipient.Phone !== null ? this.recipient.Phone.toString() : '',
            }),
            Physician: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician',
                name: 'Physician',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.recipient && this.recipient.hasOwnProperty('Physician') && this.recipient.Physician !== null ? this.recipient.Physician.toString() : '',
            }),
            PreferredContactTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Preferred Contact Type',
                name: 'PreferredContactTypeId',
                options: this.preferredContactTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipient && this.recipient.PreferredContactTypeId || null,
            }),
            ReferralTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Referral Type',
                name: 'ReferralTypeId',
                options: this.referralTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipient && this.recipient.ReferralTypeId || null,
            }),
            RegistrationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Registration Date',
                name: 'RegistrationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipient && this.recipient.RegistrationDate || null,
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipient && this.recipient.StatusId || null,
            }),
            MayWeContactYou: new DynamicField({
                formGroup: this.formGroup,
                label: 'OK to Call/Text Them?',
                name: 'MayWeContactYou',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value:  this.recipient && this.recipient.hasOwnProperty('MayWeContactYou') && this.recipient.MayWeContactYou !== null ? this.recipient.MayWeContactYou : false,
            }),
        };

        this.View = {
            AccountManagerId: new DynamicLabel(
                'Account Manager',
                this.recipient && this.recipient.AccountManagerId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            AddressId: new DynamicLabel(
                'Address',
                getMetaItemValue(this.addresses, this.recipient && this.recipient.hasOwnProperty('AddressId') && this.recipient.AddressId !== null ? this.recipient.AddressId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ClinicId: new DynamicLabel(
                'Clinic',
                getMetaItemValue(this.clinics, this.recipient && this.recipient.hasOwnProperty('ClinicId') && this.recipient.ClinicId !== null ? this.recipient.ClinicId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Dob: new DynamicLabel(
                'Dob',
                this.recipient && this.recipient.Dob || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            MiddleInitial: new DynamicLabel(
                'Middle Initial',
                this.recipient && this.recipient.hasOwnProperty('MiddleInitial') && this.recipient.MiddleInitial !== null ? this.recipient.MiddleInitial.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PartnerDob: new DynamicLabel(
                'Partner Dob',
                this.recipient && this.recipient.PartnerDob || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            PartnerEmail: new DynamicLabel(
                'Partner Email',
                this.recipient && this.recipient.hasOwnProperty('PartnerEmail') && this.recipient.PartnerEmail !== null ? this.recipient.PartnerEmail.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PartnerName: new DynamicLabel(
                'Partner Name',
                this.recipient && this.recipient.hasOwnProperty('PartnerName') && this.recipient.PartnerName !== null ? this.recipient.PartnerName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Phone: new DynamicLabel(
                'Phone',
                this.recipient && this.recipient.hasOwnProperty('Phone') && this.recipient.Phone !== null ? this.recipient.Phone.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Physician: new DynamicLabel(
                'Physician',
                this.recipient && this.recipient.hasOwnProperty('Physician') && this.recipient.Physician !== null ? this.recipient.Physician.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PreferredContactTypeId: new DynamicLabel(
                'Preferred Contact Type',
                getMetaItemValue(this.preferredContactTypes, this.recipient && this.recipient.hasOwnProperty('PreferredContactTypeId') && this.recipient.PreferredContactTypeId !== null ? this.recipient.PreferredContactTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReferralTypeId: new DynamicLabel(
                'Referral Type',
                getMetaItemValue(this.referralTypes, this.recipient && this.recipient.hasOwnProperty('ReferralTypeId') && this.recipient.ReferralTypeId !== null ? this.recipient.ReferralTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            RegistrationDate: new DynamicLabel(
                'Registration Date',
                this.recipient && this.recipient.RegistrationDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            StatusId: new DynamicLabel(
                'Status',
                getMetaItemValue(this.statuses, this.recipient && this.recipient.hasOwnProperty('StatusId') && this.recipient.StatusId !== null ? this.recipient.StatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            MayWeContactYou: new DynamicLabel(
                'OK to Call/Text Them?',
                this.recipient && this.recipient.hasOwnProperty('MayWeContactYou') && this.recipient.MayWeContactYou !== null ? this.recipient.MayWeContactYou : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
