import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { FamilyHistoryDynamicControlsPartial } from '@model/partials/family-history-partial.form-controls';
import { IFamilyHistory } from '@model/interfaces/family-history';
import { UnitsOfMeasurement } from '@model/enums/units-of-measurement.enum';
import { IFamilyHistoryDynamicControlsParameters } from '@model/form-controls/family-history.form-controls';
import { SiblingTypes } from '@model/enums/custom/sibling-types.enum';

@Component({
    selector: 'individual-family-history',
    templateUrl: './individual-family-history.component.html',
})
export class IndividualFamilyHistoryComponent {
    // abstract controls
    @Input('individual') individual: IFamilyHistory;
    @Input('metaData') metaData: IFamilyHistoryDynamicControlsParameters;
    @Input() siblingIndex: number;
    @Output() onFormCreate = new EventEmitter<FormGroup>();

    abstractFamilyHistoryControls: any;
    familyHistoryForm: FormGroup;
    formCreated = false;
    selectedHeightUnitId: number;
    selectedWeightUnitId: number;

    subs = new Subscription();

    constructor(private fb: FormBuilder) {}

    get isSibling(): boolean {
        return this.siblingIndex !== null && this.siblingIndex !== undefined;
    }

    get isHalfSibling(): boolean {
        const isFullSibling = this.familyHistoryForm.get('FamilyHistory.IsFullSibling').value;
        return isFullSibling === SiblingTypes.HalfSibling;
    }

    ngOnInit(): void {
        this.selectedWeightUnitId = this.individual?.WeightUnitId;
        this.selectedHeightUnitId = this.individual?.HeightUnitId;
        this.createForm();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    usingMetric(): boolean {
        return this.selectedHeightUnitId === UnitsOfMeasurement.Meters;
    }

    optionIsSelected(controlName: string): boolean {
        const control = this.familyHistoryForm.get(`FamilyHistory.${controlName}`);
        return control.value;
    }

    controlHasValue(controlName: string): boolean {
        const control = this.familyHistoryForm.get(`FamilyHistory.${controlName}`);
        return control.value != null;
    }

    createForm(): void {
        this.getControls();
        this.familyHistoryForm = this.assignFormGroups();
        this.formCreated = true;
        this.onFormCreate.emit(this.familyHistoryForm);
    }

    getControls(): void {
        this.abstractFamilyHistoryControls = new FamilyHistoryDynamicControlsPartial(this.individual, this.metaData).Form;
    }

    assignFormGroups(): FormGroup {
        let form = this.fb.group({
            FamilyHistory: this.fb.group({}),
        });
        if (this.isSibling) {
            form.addControl('siblingIndex', new FormControl(this.siblingIndex));
        }
        return form;
    }
}
