import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IShippingStatus } from '@model/interfaces/shipping-status';

@Injectable({
    providedIn: 'root',
})
export class ShippingStatusService extends StaticMetaItemService<IShippingStatus> {
    constructor(public http: HttpClient) {
        super('ShippingStatusService', 'Status', 'StatusIds', '/meta-items/ShippingStatus', http);
    }
}
