import { Component, OnInit, Input } from '@angular/core';
import { DonorRelationshipTypeService } from '../services/donor-relationship-type.service';
import { MedicalIssueTypeService } from '../services/medical-issue-type.service';
import { IDonor } from '@model/interfaces/donor';
import { IDonorIssue } from '@model/interfaces/donor-issue';
import { IDonorMedicalHistory } from '@model/interfaces/donor-medical-history';
import { IDonorRelationshipType } from '@model/interfaces/donor-relationship-type';
import { IMedicalIssueType } from '@model/interfaces/medical-issue-type';
import { forkJoin, Subscriber, Subscription } from 'rxjs';
import { DonorIssueService } from '../services/donor-issue.service';
import { DonorService } from '../services/donor.service';
import { DonorStatuses } from '@model/enums/donor-statuses.enum';
import { DonorDenialTypes } from '@model/enums/donor-denial-types.enum';
import { timingSafeEqual } from 'crypto';

@Component({
    selector: 'app-donor-score',
    templateUrl: 'donor-score.component.html',
})
export class DonorScoreComponent implements OnInit {
    @Input() donor: IDonor;

    showDetails = false;
    medicalIssues: IDonorIssue;
    medicalIssueTypes: IMedicalIssueType[];
    donorMedicalHistories: IDonorMedicalHistory[];
    donorRelationshipTypes: IDonorRelationshipType[];

    subs = new Subscription();

    public get donorIsDenied(): boolean {
        return this.donor.DonorStatusId === DonorStatuses.Denied && this.donor.DenialReasonId !== null;
    }

    public get showDenialReason(): boolean {
        return this.donor.DenialReasonId !== DonorDenialTypes.FailingMedicalScore && this.donor.DenialReasonId !== null;
    }

    constructor(
        private donorService: DonorService,
        private medicalIssueTypesService: MedicalIssueTypeService,
        private donorRelationshipTypeService: DonorRelationshipTypeService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.donorRelationshipTypeService.getAll(), this.medicalIssueTypesService.getItems()]).subscribe((forkJoinResponses) => {
            const [relationshipTypes] = forkJoinResponses;
            this.donorRelationshipTypes = relationshipTypes;
            this.medicalIssueTypes = this.medicalIssueTypesService.items;
            this.filterRelaventIssues();
        });
        // if score or denial status has changed from update, refresh data
        this.subs.add(
            this.donorService.changeEmitted$.subscribe(() => {
                this.donorService.getById(this.donor.Id).subscribe((donor) => {
                    this.donor = donor;
                    this.filterRelaventIssues();
                });
            }),
        );
    }

    filterRelaventIssues(): void {
        this.donorMedicalHistories = this.donor.DonorIssue.DonorMedicalHistories.filter((issue) => {
            return issue.You || issue.Mother || issue.Father || issue.Sibling || issue.Grandparent || issue.Children;
        });
    }

    toggleDetailDisplay(): void {
        this.showDetails = !this.showDetails;
    }

    getIssuesByTypeId(typeId: number): IDonorMedicalHistory[] {
        return this.donorMedicalHistories.filter(
            (issue) => issue.MedicalIssue.MedicalIssueTypeId === typeId && !issue.MedicalIssue.ParentMedicalIssueId,
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
