import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IQcInvestigation } from '@model/interfaces/qc-investigation';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const emptyQcInvestigation: IQcInvestigation = {
    DiscontinueDonor: null,
    DraftsSentForApproval: null,
    EmailApproved: null,
    EmailSentToRecipient: null,
    HaltSalesOrder: null,
    Id: 0,
    Investigation: null,
    NothingOfferedReason: null,
    NumberFreeVials: null,
    QcComments: null,
    QcDecisionId: null,
    QcProblemId: null,
    QcReasonId: null,
    ResponseSentToClinic: null,
    ThawingId: 0,
    WatchlistDonor: null,
};

@Injectable({
    providedIn: 'root',
})
export class QcInvestigationService extends BaseService<IQcInvestigation> {
    constructor(public http: HttpClient) {
        super('/qc-investigations', http);
    }

    getEmptyQcInvestigation(thawingId: number): IQcInvestigation {
        const newQcInvestigation = { ...emptyQcInvestigation };
        newQcInvestigation.ThawingId = thawingId;
        return newQcInvestigation;
    }

    getQcInvestigationByThawingId(salesOrderId: number, thawingId: number): Observable<IQcInvestigation> {
        return this.http.get<IQcInvestigation>(`/recipient-sales-orders/${salesOrderId}/qc-investigation/${thawingId}`).pipe(
            tap((value) => {
                this.emitChange(value);
            }),
        );
    }
}
