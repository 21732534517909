import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { SalesOrderService } from '../../services/sales-order.service';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { entityListModuleConfig } from '@common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { SalesOrdersEntityListConfig } from './sales-orders.entity-list-config';

@Component({
    selector: 'app-sales-orders',
    templateUrl: './sales-orders.component.html',
})
export class SalesOrdersComponent implements OnInit {
    @Input() recipientId: number;
    salesOrders: ISalesOrder[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new SalesOrdersEntityListConfig();
    order = 'CreatedDate';
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddSalesOrder = false;

    constructor(
        private salesOrderService: SalesOrderService,
        private claimsService: ClaimsService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.canAddSalesOrder = this.claimsService.hasClaim(ClaimTypes.RecipientUsers, [ClaimValues.FullAccess]);
        this.getSalesOrders();
    }

    getSalesOrdersCall(): Observable<HttpResponse<ISalesOrder[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'RecipientId',
                valueArray: [this.recipientId],
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.salesOrderService.get(searchparams);
    }

    getSalesOrders(): void {
        this.getSalesOrdersCall().subscribe((answer) => {
            this.salesOrders = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getSalesOrders();
    }

    salesOrderSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['./sales-orders', event.entity.Id], { relativeTo: this.route });
    }
}
