import { NgModule } from '@angular/core';

import { AdminPortalSharedModule } from '@common/shared.module';
import { AdminPortalFeatureModule } from '@common/feature.module';
import { ThawingBasicInfoComponent } from './thawing-basic-info/thawing-basic-info.component';
import { ThawingsComponent } from './thawings/thawings.component';

@NgModule({
    declarations: [ThawingBasicInfoComponent, ThawingsComponent],
    exports: [ThawingsComponent],
    imports: [AdminPortalSharedModule, AdminPortalFeatureModule],
})
export class ThawingModule {}
