import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MeasurementTypeEnums } from '@model/enums/measurement-types.enum';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UnitsOfMeasurementService extends StaticMetaItemService<IUnitsOfMeasurement> {
    constructor(public http: HttpClient) {
        super('UnitsOfMeasurementService', 'Measurement', 'MeasurementIds', '/meta-items/UnitsOfMeasurement', http);
    }

    getAdultHeightItems(): Observable<IUnitsOfMeasurement[]> {
        return this.getItems().pipe(map((units) => units.filter((u) => u.UnitsOfMeasurementTypeId === MeasurementTypeEnums.HeightAdult)));
    }

    getAdultWeightItems(): Observable<IUnitsOfMeasurement[]> {
        return this.getItems().pipe(map((units) => units.filter((u) => u.UnitsOfMeasurementTypeId === MeasurementTypeEnums.WeightAdult)));
    }
}
