import { UnitsOfMeasurementService } from '@angular-common/donor-application-forms/services/basic-info-meta-items/units-of-measurement.service';
import { Component } from '@angular/core';
import { ApplicationSections } from '@model/enums/custom/application-sections.enum';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DonorService } from 'admin-portal/donors/services/donor.service';
import { forkJoin } from 'rxjs';
import { DonorApplicationDetailsService } from '../../services/donor-application-details.service';
import { DonorApplicationDetailsSectionBaseComponent } from '../donor-application-details-section.base.component';

@Component({
    selector: 'app-family-history-details',
    templateUrl: './family-history-details.component.html',
})
export class FamilyHistoryDetailsComponent extends DonorApplicationDetailsSectionBaseComponent {
    formId = ApplicationSections.FamilyHistory;

    metaData: IPersonalInformationMetaData;
    heightUnits: IUnitsOfMeasurement[];
    weightUnits: IUnitsOfMeasurement[];

    constructor(
        service: DonorApplicationDetailsService,
        notificationsService: NotificationsService,
        donorService: DonorService,
        private unitsOfMeasurementService: UnitsOfMeasurementService,
    ) {
        super(service, notificationsService, donorService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        forkJoin(
            this.donorService.getPersonalInformationMetaData(),
            this.unitsOfMeasurementService.getAdultHeightItems(),
            this.unitsOfMeasurementService.getAdultWeightItems()).subscribe(([metaData, heightUnits, weightUnits]) => {
            this.metaData = metaData;
            this.heightUnits = heightUnits;
            this.weightUnits = weightUnits;
        });
    }
}
