import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ISalesOrder } from '../interfaces/sales-order';
import { IRecipientUser } from '../interfaces/recipient-user';
import { IVialStatus } from '../interfaces/vial-status';

export interface ISalesOrderDynamicControlsParameters {
    formGroup?: string;
    recipients?: IRecipientUser[];
    vialStatuses?: IVialStatus[];
    statuses?: IVialStatus[];
}

export class SalesOrderDynamicControls {

    formGroup: string;
    recipients: IRecipientUser[];
    vialStatuses: IVialStatus[];
    statuses: IVialStatus[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private salesorder?: ISalesOrder, additionalParameters?: ISalesOrderDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'SalesOrder';
        this.recipients = additionalParameters && additionalParameters.recipients || undefined;
        this.vialStatuses = additionalParameters && additionalParameters.vialStatuses || undefined;
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;

        this.Form = {
            ContractReceived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contract Received',
                name: 'ContractReceived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.ContractReceived || null,
            }),
            ContractSent: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contract Sent',
                name: 'ContractSent',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.ContractSent || null,
            }),
            Cost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cost',
                name: 'Cost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.Cost || null,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.CreatedById || null,
            }),
            CreatedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created Date',
                name: 'CreatedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.salesorder && this.salesorder.CreatedDate || null,
            }),
            Deposit: new DynamicField({
                formGroup: this.formGroup,
                label: 'Deposit',
                name: 'Deposit',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.Deposit || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.salesorder && this.salesorder.hasOwnProperty('Notes') && this.salesorder.Notes !== null ? this.salesorder.Notes.toString() : '',
            }),
            ProductTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Product Type',
                name: 'ProductTypeId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.salesorder && this.salesorder.ProductTypeId || null,
            }),
            RecipientId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Recipient',
                name: 'RecipientId',
                options: this.recipients,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.salesorder && this.salesorder.RecipientId || null,
            }),
            ReplacementOrder: new DynamicField({
                formGroup: this.formGroup,
                label: 'Replacement Order',
                name: 'ReplacementOrder',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.hasOwnProperty('ReplacementOrder') && this.salesorder.ReplacementOrder !== null ? this.salesorder.ReplacementOrder : false,
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorder && this.salesorder.StatusId || 1,
            }),
            VialStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Vial Status',
                name: 'VialStatusId',
                options: this.vialStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.salesorder && this.salesorder.VialStatusId || null,
            }),
        };

        this.View = {
            ContractReceived: new DynamicLabel(
                'Contract Received',
                this.salesorder && this.salesorder.ContractReceived || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ContractSent: new DynamicLabel(
                'Contract Sent',
                this.salesorder && this.salesorder.ContractSent || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Cost: new DynamicLabel(
                'Cost',
                this.salesorder && this.salesorder.Cost || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            ),
            CreatedById: new DynamicLabel(
                'Created By',
                this.salesorder && this.salesorder.CreatedById || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            CreatedDate: new DynamicLabel(
                'Created Date',
                this.salesorder && this.salesorder.CreatedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Deposit: new DynamicLabel(
                'Deposit',
                this.salesorder && this.salesorder.Deposit || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            ),
            Notes: new DynamicLabel(
                'Notes',
                this.salesorder && this.salesorder.hasOwnProperty('Notes') && this.salesorder.Notes !== null ? this.salesorder.Notes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ProductTypeId: new DynamicLabel(
                'Product Type',
                this.salesorder && this.salesorder.ProductTypeId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            RecipientId: new DynamicLabel(
                'Recipient',
                getMetaItemValue(this.recipients, this.salesorder && this.salesorder.hasOwnProperty('RecipientId') && this.salesorder.RecipientId !== null ? this.salesorder.RecipientId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReplacementOrder: new DynamicLabel(
                'Replacement Order',
                this.salesorder && this.salesorder.hasOwnProperty('ReplacementOrder') && this.salesorder.ReplacementOrder !== null ? this.salesorder.ReplacementOrder : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            StatusId: new DynamicLabel(
                'Status',
                getMetaItemValue(this.statuses, this.salesorder && this.salesorder.hasOwnProperty('StatusId') && this.salesorder.StatusId !== null ? this.salesorder.StatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            VialStatusId: new DynamicLabel(
                'Vial Status',
                getMetaItemValue(this.vialStatuses, this.salesorder && this.salesorder.hasOwnProperty('VialStatusId') && this.salesorder.VialStatusId !== null ? this.salesorder.VialStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
