import { GeneticScreeningDynamicControls, IGeneticScreeningDynamicControlsParameters } from '../form-controls/genetic-screening.form-controls';
// import { IAcceptedRejectedOption } from '@model/interfaces/accepted-rejected-option';
// import { IAuditedDate } from '@model/interfaces/custom/audited-date';
import { IGeneticScreening } from '../interfaces/genetic-screening';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';
import { IAcceptedRejectedOption } from '@model/interfaces/accepted-rejected-option';

enum Option {
    Accepted = 'Accepted',
    Rejected = 'Rejected',
}

const Options = [
    {
        Id: true,
        Name: Option.Accepted,
    },
    {
        Id: false,
        Name: Option.Rejected,
    },
];

export class GeneticScreeningDynamicControlsPartial extends GeneticScreeningDynamicControls {
    constructor(private geneticScreening: IGeneticScreening) {
        super(geneticScreening);

        (<DynamicField>this.Form.GeneticScreeningAccepted).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
        });
        (<DynamicField>this.Form.GeneticScreeningAccepted).options = Options;
        this.Form.GeneticScreeningAccepted.value = this?.geneticScreening?.GeneticScreeningAccepted ? Options[0].Id : Options[1].Id;
        (<DynamicField>this.Form.GeneticScreeningAccepted).labelHtml = '<label>Genetic Screening Accepted / Rejected</label><br/>';

        (<DynamicField>this.Form.GeneticsLabsAccepted).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
        });
        (<DynamicField>this.Form.GeneticsLabsAccepted).options = Options;
        this.Form.GeneticsLabsAccepted.value = this?.geneticScreening?.GeneticsLabsAccepted ? Options[0].Id : Options[1].Id;

        (<DynamicField>this.Form.GeneticsLabsAccepted).labelHtml = '<label>Genetics Labs Accepted / Rejected</label><br/>';

        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedDate).labelHtml = '<label>Genetics Labs Accepted / Rejected Date</label><br/>';

        (<DynamicField>this.Form.GeneticsLabsApprovedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.GeneticsLabsApprovedBy).value = geneticScreening.GeneticsLabsApprovedBy;

        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedBy).value = geneticScreening.GeneticScreeningAcceptedRejectedBy;
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedBy).labelHtml = '<label>Genetics Labs Accepted / Rejected By</label><br/>';

        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedDate).labelHtml = '<label>Genetics Labs Accepted / Rejected Date</label><br/>';

        (<DynamicField>this.Form.GeneticsLabsApprovedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.GeneticsLabsApprovedBy).value = geneticScreening.GeneticsLabsApprovedBy;

        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedBy).value = geneticScreening.GeneticScreeningAcceptedRejectedBy;
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedBy).labelHtml = '<label>Genetics Labs Accepted / Rejected By</label><br/>';

        (<DynamicField>this.Form.GeneticConsultReportReceivedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.GeneticConsultReportReceivedBy).value = geneticScreening.GeneticConsultReportReceivedBy;
        (<DynamicField>this.Form.GeneticConsultReportReceivedBy).labelHtml = '<label>Genetic Consult Report Received By</label><br/>';

        (<DynamicField>this.Form.CarrierTestingResultsReceivedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.CarrierTestingResultsReceivedBy).value = geneticScreening.CarrierTestingResultsReceivedBy;
        (<DynamicField>this.Form.CarrierTestingResultsReceivedBy).labelHtml = '<label>Carrier Testing Results Received By</label><br/>';

        (<DynamicField>this.Form.CarrierTestingAcceptedRejectedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.CarrierTestingAcceptedRejectedBy).value = geneticScreening.CarrierTestingAcceptedRejectedBy;
        (<DynamicField>this.Form.CarrierTestingAcceptedRejectedBy).labelHtml = '<label>Carrier Testing Accepted/Rejected By</label><br/>';

        (<DynamicField>this.Form.GeneticConsultAcceptedRejectedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.GeneticConsultAcceptedRejectedBy).value = geneticScreening.GeneticConsultAcceptedRejectedBy;
        (<DynamicField>this.Form.GeneticConsultAcceptedRejectedBy).labelHtml = '<label>Genetic Consult Accepted/Rejected By</label><br/>';

        (<DynamicField>this.Form.GeneticConsultAcceptedRejectedDate).labelHtml = '<label>Genetic Consult Accepted/Rejected Date</label><br/>';

        (<DynamicField>this.Form.CarrierTestingAcceptedRejectedDate).labelHtml = '<label>Carrier Testing Accepted/Rejected Date</label><br/>';

        (<DynamicField>this.Form.GeneticConsultAcceptedRejected).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
        });
        (<DynamicField>this.Form.GeneticConsultAcceptedRejected).options = Options;

        (<DynamicField>this.Form.GeneticConsultAcceptedRejected).labelHtml = '<label>Genetic Consult Accepted/Rejected</label><br/>';

        (<DynamicField>this.Form.CarrierTestingAcceptedRejected).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
        });
        (<DynamicField>this.Form.CarrierTestingAcceptedRejected).options = Options;
        (<DynamicField>this.Form.CarrierTestingAcceptedRejected).labelHtml = '<label>Carrier Testing Accepted/Rejected</label><br/>';

        let dateFields = [
            'GeneticScreeningAcceptedRejectedDate',
            'GeneticsLabsApprovedDate',
            'GeneticsLabsPerformedByDate',
            'GeneticsLabsReceivedDate',
            'GeneticsLabsSentDate',
            'CarrierTestingDrawDate',
            'CarrierTestingResultsReceivedDate',
            'CarrierTestingAcceptedRejectedDate',
            'GeneticConsultSentToCounselorDate',
            'GeneticConsultPerformedDate',
            'GeneticConsultReportReceivedDate',
            'GeneticConsultAcceptedRejectedDate',
        ];

        dateFields.forEach((key) => {
            this.Form[key].type = new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.DateInput,
            });

            this.Form[key].value = geneticScreening[key] ? geneticScreening[key] : '';
        });
    }
}
