import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IOutcome } from '../interfaces/outcome';
import { IOutcomeOption } from '../interfaces/outcome-option';
import { IThawing } from '../interfaces/thawing';

export interface IOutcomeDynamicControlsParameters {
    formGroup?: string;
    thawings?: IThawing[];
    outcomeOptions?: IOutcomeOption[];
}

export class OutcomeDynamicControls {

    formGroup: string;
    thawings: IThawing[];
    outcomeOptions: IOutcomeOption[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private outcome?: IOutcome, additionalParameters?: IOutcomeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Outcome';
        this.thawings = additionalParameters && additionalParameters.thawings || undefined;
        this.outcomeOptions = additionalParameters && additionalParameters.outcomeOptions || undefined;

        this.Form = {
            Comment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comment',
                name: 'Comment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.outcome && this.outcome.hasOwnProperty('Comment') && this.outcome.Comment !== null ? this.outcome.Comment.toString() : '',
            }),
            DateUs: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Us',
                name: 'DateUs',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.outcome && this.outcome.DateUs || null,
            }),
            FetalSacCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fetal Sac Count',
                name: 'FetalSacCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.outcome && this.outcome.FetalSacCount || null,
            }),
            FhbCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fhb Count',
                name: 'FhbCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.outcome && this.outcome.FhbCount || null,
            }),
            FollowUpDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Follow Up Date',
                name: 'FollowUpDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.outcome && this.outcome.FollowUpDate || null,
            }),
            FollowUpReason: new DynamicField({
                formGroup: this.formGroup,
                label: 'Follow Up Reason',
                name: 'FollowUpReason',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.outcome && this.outcome.hasOwnProperty('FollowUpReason') && this.outcome.FollowUpReason !== null ? this.outcome.FollowUpReason.toString() : '',
            }),
            HcgDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hcg Date',
                name: 'HcgDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.outcome && this.outcome.HcgDate || null,
            }),
            HcgValue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hcg Value',
                name: 'HcgValue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.outcome && this.outcome.HcgValue || null,
            }),
            IsFinalized: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Finalized',
                name: 'IsFinalized',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.outcome && this.outcome.hasOwnProperty('IsFinalized') && this.outcome.IsFinalized !== null ? this.outcome.IsFinalized : false,
            }),
            NumberOfEmbryosTransferred: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Embryos Transferred',
                name: 'NumberOfEmbryosTransferred',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.outcome && this.outcome.hasOwnProperty('NumberOfEmbryosTransferred') && this.outcome.NumberOfEmbryosTransferred !== null ? this.outcome.NumberOfEmbryosTransferred.toString() : '',
            }),
            OutcomeOptionId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Outcome Option',
                name: 'OutcomeOptionId',
                options: this.outcomeOptions,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.outcome && this.outcome.OutcomeOptionId || null,
            }),
            ThawingId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Thawing',
                name: 'ThawingId',
                options: this.thawings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.outcome && this.outcome.ThawingId || null,
            }),
        };

        this.View = {
            Comment: new DynamicLabel(
                'Comment',
                this.outcome && this.outcome.hasOwnProperty('Comment') && this.outcome.Comment !== null ? this.outcome.Comment.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateUs: new DynamicLabel(
                'Date Us',
                this.outcome && this.outcome.DateUs || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            FetalSacCount: new DynamicLabel(
                'Fetal Sac Count',
                this.outcome && this.outcome.FetalSacCount || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            FhbCount: new DynamicLabel(
                'Fhb Count',
                this.outcome && this.outcome.FhbCount || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            FollowUpDate: new DynamicLabel(
                'Follow Up Date',
                this.outcome && this.outcome.FollowUpDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            FollowUpReason: new DynamicLabel(
                'Follow Up Reason',
                this.outcome && this.outcome.hasOwnProperty('FollowUpReason') && this.outcome.FollowUpReason !== null ? this.outcome.FollowUpReason.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            HcgDate: new DynamicLabel(
                'Hcg Date',
                this.outcome && this.outcome.HcgDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HcgValue: new DynamicLabel(
                'Hcg Value',
                this.outcome && this.outcome.HcgValue || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            IsFinalized: new DynamicLabel(
                'Is Finalized',
                this.outcome && this.outcome.hasOwnProperty('IsFinalized') && this.outcome.IsFinalized !== null ? this.outcome.IsFinalized : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            NumberOfEmbryosTransferred: new DynamicLabel(
                'Number Of Embryos Transferred',
                this.outcome && this.outcome.hasOwnProperty('NumberOfEmbryosTransferred') && this.outcome.NumberOfEmbryosTransferred !== null ? this.outcome.NumberOfEmbryosTransferred.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            OutcomeOptionId: new DynamicLabel(
                'Outcome Option',
                getMetaItemValue(this.outcomeOptions, this.outcome && this.outcome.hasOwnProperty('OutcomeOptionId') && this.outcome.OutcomeOptionId !== null ? this.outcome.OutcomeOptionId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ThawingId: new DynamicLabel(
                'Thawing',
                getMetaItemValue(this.thawings, this.outcome && this.outcome.hasOwnProperty('ThawingId') && this.outcome.ThawingId !== null ? this.outcome.ThawingId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
