import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { debounceTime } from 'rxjs/operators';

import { IDonorPreliminaryHistory } from '@model/interfaces/donor-preliminary-history';
import { DonorPreliminaryHistoryDynamicControlsPartial } from '@model/partials/donor-preliminary-history-partial.form-controls';
import { PreliminaryHistoryService } from '../services/prelim-history.service';
import { IDonor } from '@model/interfaces/donor';

@Component({
    selector: 'preliminary-history',
    styles: [
        `
            .adoption-tooltip .tooltiptext {
                color: red;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
            }
        `,
    ],
    templateUrl: './preliminary-history.component.html',
})
export class PreliminaryHistoryComponent implements OnInit, OnDestroy, OnChanges {
    @Input() donor: IDonor;
    @Output() onFormReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output('onFormValueChanges') onFormValueChanges: EventEmitter<IDonorPreliminaryHistory> = new EventEmitter<IDonorPreliminaryHistory>();

    denied = false;
    preliminaryHistory: IDonorPreliminaryHistory;
    abstractPreliminaryHistoryControls: any;

    showStdFollowUps = false;
    showIsAdoptedFollowUps = false;
    showHasAdoptedParentsFollowUps = false;
    showHasDonatedBeforeFollowUps = false;
    showListedOnAnotherRosterFollowUps = false;

    preliminaryHistoryForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    formSubscription: Subscription;

    subs = new Subscription();

    constructor(
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private prelimHistoryService: PreliminaryHistoryService,
    ) {}

    @HostListener('window:beforeunload', ['$event'])
    warnOfUnsavedChanges(e): any {
        if (this.preliminaryHistoryForm.dirty && !this.denied) {
            e.returnValue = true;
            return false;
        }
    }

    ngOnInit(): void {
        this.prelimHistoryService.getById(this.donor.Id).subscribe((prelimHistory) =>  {
            this.preliminaryHistory = prelimHistory;
            this.createForm();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.denied && !changes.denied.isFirstChange()) {
            setTimeout(() => this.preliminaryHistoryForm.disable());
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    createForm(): void {
        this.getControls();
        this.preliminaryHistoryForm = this.assignFormGroups();
        this.formCreated = true;
        this.setFormFields();
        if (this.denied) {
            this.disableForm();
        } else {
            this.subs.add(
                this.preliminaryHistoryForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                    if (this.preliminaryHistoryForm.dirty) {
                        const formvalues = Object.assign({}, this.preliminaryHistory, this.preliminaryHistoryForm.value.PreliminaryHistory);
                        this.onFormValueChanges.emit(formvalues);
                    }
                }),
            );
        }
        this.onFormReady.emit(this.preliminaryHistoryForm);
    }

    disableForm(): void {
        setTimeout(() => this.preliminaryHistoryForm.disable());
    }

    getControls(): void {
        this.abstractPreliminaryHistoryControls = new DonorPreliminaryHistoryDynamicControlsPartial(this.preliminaryHistory, {
            formGroup: 'PreliminaryHistory',
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            PreliminaryHistory: this.fb.group({}),
        });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    onHasStdValueChange(value: boolean): void {
        this.showStdFollowUps = value;
    }

    onIsAdoptedValueChanges(value: boolean): void {
        this.showIsAdoptedFollowUps = value;
    }

    onHasAdoptedParentsValueChanges(value: boolean): void {
        this.showHasAdoptedParentsFollowUps = value;
    }
    onHasDonatedBeforeValueChanges(value: boolean): void {
        this.showHasDonatedBeforeFollowUps = value;
    }
    onListedOnAnotherRosterValueChanges(value: boolean): void {
        this.showListedOnAnotherRosterFollowUps = value;
    }

    setFormFields(): void {
        this.onHasStdValueChange(this.preliminaryHistory.HasStd);
        this.onIsAdoptedValueChanges(this.preliminaryHistory.IsAdopted);
        this.onHasAdoptedParentsValueChanges(this.preliminaryHistory.HasAdoptedParents);
        this.onHasDonatedBeforeValueChanges(this.preliminaryHistory.HasDonatedBefore);
        this.onListedOnAnotherRosterValueChanges(this.preliminaryHistory.ListedOnAnotherRoster);
    }
}
