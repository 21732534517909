import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DonorSsnService {
    constructor(private httpClient: HttpClient) {}

    getDonorSsn(id: number): Observable<string> {
        return this.httpClient.get<string>(`/api/v1/donor-ssn/${id}`);
    }

    updateDonorSsn(id: number, data: string): Observable<string> {
        const headers = new HttpHeaders({'Content-Type': 'application/json' });
        return this.httpClient.post<string>(`/api/v1/donor-ssn/${id}`, JSON.stringify(data), { headers: headers }) ;
    }
}
