import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorPsychologicalScreening } from '../interfaces/donor-psychological-screening';
import { IPsychologicalScreeningStatus } from '../interfaces/psychological-screening-status';

export interface IDonorPsychologicalScreeningDynamicControlsParameters {
    formGroup?: string;
    psychologicalScreeningStatuses?: IPsychologicalScreeningStatus[];
}

export class DonorPsychologicalScreeningDynamicControls {

    formGroup: string;
    psychologicalScreeningStatuses: IPsychologicalScreeningStatus[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorpsychologicalscreening?: IDonorPsychologicalScreening, additionalParameters?: IDonorPsychologicalScreeningDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorPsychologicalScreening';
        this.psychologicalScreeningStatuses = additionalParameters && additionalParameters.psychologicalScreeningStatuses || undefined;

        this.Form = {
            AnzicaRecievedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Anzica Recieved Date',
                name: 'AnzicaRecievedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpsychologicalscreening && this.donorpsychologicalscreening.AnzicaRecievedDate || null,
            }),
            AnzicaSentDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Anzica Sent Date',
                name: 'AnzicaSentDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpsychologicalscreening && this.donorpsychologicalscreening.AnzicaSentDate || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpsychologicalscreening && this.donorpsychologicalscreening.DateModified || null,
            }),
            EvalInstructionsSentDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Eval Instructions Sent Date',
                name: 'EvalInstructionsSentDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpsychologicalscreening && this.donorpsychologicalscreening.EvalInstructionsSentDate || null,
            }),
            MmpiCompleteDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mmpi Complete Date',
                name: 'MmpiCompleteDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpsychologicalscreening && this.donorpsychologicalscreening.MmpiCompleteDate || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpsychologicalscreening && this.donorpsychologicalscreening.ModifiedById || null,
            }),
            PsychCompletedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Psych Completed Date',
                name: 'PsychCompletedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpsychologicalscreening && this.donorpsychologicalscreening.PsychCompletedDate || null,
            }),
            PsychologicalScreeningStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Psychological Screening Status',
                name: 'PsychologicalScreeningStatusId',
                options: this.psychologicalScreeningStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpsychologicalscreening && this.donorpsychologicalscreening.PsychologicalScreeningStatusId || null,
            }),
            ReportReceivedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Received Date',
                name: 'ReportReceivedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpsychologicalscreening && this.donorpsychologicalscreening.ReportReceivedDate || null,
            }),
            StatusChangedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status Changed Date',
                name: 'StatusChangedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpsychologicalscreening && this.donorpsychologicalscreening.StatusChangedDate || null,
            }),
        };

        this.View = {
            AnzicaRecievedDate: new DynamicLabel(
                'Anzica Recieved Date',
                this.donorpsychologicalscreening && this.donorpsychologicalscreening.AnzicaRecievedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            AnzicaSentDate: new DynamicLabel(
                'Anzica Sent Date',
                this.donorpsychologicalscreening && this.donorpsychologicalscreening.AnzicaSentDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.donorpsychologicalscreening && this.donorpsychologicalscreening.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EvalInstructionsSentDate: new DynamicLabel(
                'Eval Instructions Sent Date',
                this.donorpsychologicalscreening && this.donorpsychologicalscreening.EvalInstructionsSentDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            MmpiCompleteDate: new DynamicLabel(
                'Mmpi Complete Date',
                this.donorpsychologicalscreening && this.donorpsychologicalscreening.MmpiCompleteDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ModifiedById: new DynamicLabel(
                'Modified By',
                this.donorpsychologicalscreening && this.donorpsychologicalscreening.ModifiedById || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            PsychCompletedDate: new DynamicLabel(
                'Psych Completed Date',
                this.donorpsychologicalscreening && this.donorpsychologicalscreening.PsychCompletedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            PsychologicalScreeningStatusId: new DynamicLabel(
                'Psychological Screening Status',
                getMetaItemValue(this.psychologicalScreeningStatuses, this.donorpsychologicalscreening && this.donorpsychologicalscreening.hasOwnProperty('PsychologicalScreeningStatusId') && this.donorpsychologicalscreening.PsychologicalScreeningStatusId !== null ? this.donorpsychologicalscreening.PsychologicalScreeningStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReportReceivedDate: new DynamicLabel(
                'Report Received Date',
                this.donorpsychologicalscreening && this.donorpsychologicalscreening.ReportReceivedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            StatusChangedDate: new DynamicLabel(
                'Status Changed Date',
                this.donorpsychologicalscreening && this.donorpsychologicalscreening.StatusChangedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
        };

    }
}
