import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IBloodWorkStatus } from '@model/interfaces/blood-work-status';

@Injectable({
    providedIn: 'root',
})
export class BloodWorkStatusService extends StaticMetaItemService<IBloodWorkStatus> {
    constructor(public http: HttpClient) {
        super('BloodWorkStatusService', 'Status', 'StatusIds', '/meta-items/BloodWorkStatus', http);
    }
}
