import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yesNoPipe',
})
export class YesNoPipe implements PipeTransform {
    transform(value: string | boolean): string {
        return value === true || value === 'true' ?
            'Yes' :
            value === false || value === 'false' ?
            'No' :
            '';
    }
}
