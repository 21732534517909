<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && salesOrder.Id > 0"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Sales Order Info
        <mt-audit-logs-for-record *ngIf="salesOrder && viewAuditLog" [recordId]="salesOrder.Id" entity="SalesOrder"> </mt-audit-logs-for-record>
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
        <span class="pull-right mr-4" *ngIf="salesOrder.Donors?.length">
            <button
                class="btn btn-primary header-button"
                title="SHSBDF3 RDI - Domestic"
                (click)="downloadSHSBDF3(); $event.stopPropagation()"
            >
                RDI
            </button>
            <button
                class="btn btn-primary header-button"
                title="SHSBDF2 RDI - International"
                (click)="downloadSHSBDF2(); $event.stopPropagation()"
            >
                RDI (International)
            </button>
        </span>
    </h4>
    <div class="display-padded"><b>ID: </b>{{ salesOrder.Id }}</div>
    <div class="display-padded"><b>Created Date: </b>{{ salesOrder.CreatedDate | date: mediumDate }}</div>
    <div class="display-padded"><b>Donors</b></div>
    <div *ngFor="let donor of salesOrder.Donors; let i = index">
        <sales-order-linked-donor [donor]="donor" [index]="i" [isEditing]="isEditing"></sales-order-linked-donor>
    </div>
    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>{{ isNewSalesOrder ? 'Create Sales Order' : 'Sales Order Info' }}</h4>
    <div *ngIf="!isNewSalesOrder && vialStatusId && productTypeId">
        <button type="button" class="btn btn-primary pull-right" (click)="showDonorSearchModal = true">Link Donor</button>
    </div>
    <p *ngIf="!isNewSalesOrder"><b>ID: </b>{{ salesOrder.Id }}</p>
    <p><b>Created Date: </b>{{ salesOrder.CreatedDate | date: mediumDate }}</p>
    <p *ngIf="!isNewSalesOrder"><b>Donors</b></p>
    <div *ngFor="let donor of salesOrder.Donors; let i = index">
        <sales-order-linked-donor
            [donor]="donor"
            [index]="i"
            [isEditing]="isEditing"
            (onRemoveDonor)="removeDonorFromSalesOrder($event)"
        ></sales-order-linked-donor>
    </div>
    <app-dynamic-form
        [config]="config.formObject"
        ([isEditing])="(isEditing)"
        (submitted)="formSubmitted($event)"
        (formCreated)="onFormCreated($event)"
    >
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
    </app-dynamic-form>
</div>

<modal-wrapper *ngIf="showDonorSearchModal" [options]="searchModalOptions" (cancelClick)="showDonorSearchModal = false">
    <div class="form-modal">
        <link-donor-search
            (onDonorSelected)="onDonorSelected($event)"
            [productTypeId]="productTypeId"
            [vialStatusId]="vialStatusId"
        ></link-donor-search>
        <button type="button" class="btn btn-flat btn-default" (click)="showDonorSearchModal = false">Close</button>
    </div>
</modal-wrapper>

<modal-wrapper *ngIf="showDonorInventoryModal" [options]="searchDonorInventoryOptions" (cancelClick)="showDonorInventoryModal = false">
    <div class="form-modal">
        <donor-select-available-inventory
            [donorIds]="[selectedDonor.Id]"
            [productTypeId]="productTypeId"
            [vialStatusId]="vialStatusId"
            (onCancelSearch)="showDonorInventoryModal = false; showDonorSearchModal = true"
            (onSelectionComplete)="onInventorySelected($event)"
        ></donor-select-available-inventory>
    </div>
</modal-wrapper>
