import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IReviewType } from '@model/interfaces/review-type';

@Injectable({
    providedIn: 'root',
})
export class ReviewTypeService extends StaticMetaItemService<IReviewType> {
    constructor(public http: HttpClient) {
        super('ReviewTypeService', 'Review Types', 'ReviewTypeIds', '/meta-items/ReviewType', http);
    }
}
