import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { IDonorSample } from '@model/interfaces/donor-sample';
import { ClaimTypes } from '@model/ClaimTypes';
import { NoteEntities } from '@model/enums/note-entities.enum';
import { DocumentEntities } from '@model/enums/document-entities.enum';
import { DonorSampleService } from '../../../donors/services/donor-sample.service';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './donor-donation-detail.component.html',
})
export class DonorDonationDetailComponent implements OnInit {
    donorSample: IDonorSample;
    canEdit: boolean;

    noteEntity = NoteEntities.Donations;
    docEntity = DocumentEntities.DonorDonations;
    claimType = ClaimTypes.Donors;

    subs: Subscription = new Subscription();

    constructor(
        private donorSampleService: DonorSampleService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Donors_ProcessSamples, [ClaimValues.FullAccess]);
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'donationSampleId');
        if (id) {
            this.getDonorSampleById(id);
        } else {
            this.router.navigate(['../../'], { relativeTo: this.route }); // if no id found go back to details
        }
        this.subs.add(
            this.donorSampleService.changeEmitted$.subscribe((updatedSample) => {
                this.donorSample = null;
                this.cdr.detectChanges();
                this.donorSample = updatedSample;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    getDonorSampleById(id: number): void {
        this.donorSampleService.getById(id).subscribe((donorSample) => {
            if (donorSample === null) {
                this.notificationsService.error('Donation not found');
                this.router.navigate(['../../'], { relativeTo: this.route });
            }
            this.donorSample = donorSample;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        let id = route.snapshot.paramMap.get(param);
        return parseInt(id, 10) === NaN ? null : parseInt(id, 10);
    }
}
