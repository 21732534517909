<div *ngIf="formCreated">
    <form [formGroup]="newDonationForm" (ngSubmit)="formSubmitted()">
        <div>
            <dynamic-field [field]="abstractNewDonationControls.SampleDate" [form]="newDonationForm"></dynamic-field>
            <dynamic-field [field]="abstractNewDonationControls.SampleStatusId" [form]="newDonationForm"></dynamic-field>
            <dynamic-field [field]="abstractNewDonationControls.DonationStatusId" [form]="newDonationForm"></dynamic-field>
        </div>
        <div>
            <button type="submit" [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </div>
    </form>
</div>
