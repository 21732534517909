export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    Customers_CanChangeStatus = 5,
    UserRoles = 4,
    Audit = 6,
    AccessRequest = 7,
    DonorPortal = 8,
    Donors = 9,
    ManagedLists = 10,
    RecipientUsers = 12,
    Appointments = 13,
    NotificationTemplates = 14,
    Donors_ProcessSamples = 15,
    Clinics = 16,
    Reservations = 17,
    Donors_ConductInterviews = 18,
    Reports = 19,
    ViewEditSSN = 20,
}
