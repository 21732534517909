import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IDonorIssue } from '@model/interfaces/donor-issue';

export const emptyDonorIssue: IDonorIssue = {
    DateCreated: null,
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class DonorIssueService extends BaseService<IDonorIssue> {
    constructor(public http: HttpClient) {
        super('/donorissues', http);
    }

    getEmptyDonorIssue(): IDonorIssue {
        return { ...emptyDonorIssue };
    }
}
