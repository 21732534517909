import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, } from '@angular/forms';
import { forkJoin, Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { AddressDynamicControls, IAddressDynamicControlsParameters } from '@model/partials/address.form-controls';
import { IAddress } from '@model/interfaces/address';
import { ICountry } from '@model/interfaces/country';
import { IState } from '@model/interfaces/state';
import { CommonService } from '../../services/common.service';
import { NotificationsService } from '@mt-ng2/notifications-module';


@Component({
    selector: `address`,
    templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit, OnDestroy, OnChanges {
    @Input() address: IAddress;
    @Input() isEditing: boolean;
    @Input() Address: FormGroup;
    @Input() canEdit: boolean;
    @Input() showDelete: boolean;
    @Output() onAddressSubmitted: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output() onAddressValueChanges: EventEmitter<IAddress> = new EventEmitter<IAddress>();
    @Output() onCancelClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<IAddress> = new EventEmitter<IAddress>();
    @Output() onDelete: EventEmitter<IAddress> = new EventEmitter<IAddress>();
    @Output() formattedAddress: EventEmitter<string> = new EventEmitter<string>();

    denied = false;
    addressControls: any;

    formGroup = 'Address';

    doubleClickIsDisabled = false;
    formCreated = false;
    formSubscription: Subscription;
    countries: ICountry[];
    states: IState[];
    subs = new Subscription();
    showStatesCurrentAddress$: Observable<boolean>;

    constructor(
        private fb: FormBuilder,
        private optService: CommonService,
        private notificationService: NotificationsService,
    ) { }

    @HostListener('window:beforeunload', ['$event'])


    ngOnInit(): void {
        ///
        /// Subscribe to lists of countries and states then create the form
        /// 
        forkJoin(
            this.optService.getCountriesMetaItems(),
            this.optService.getStatesMetaItems(),
        ).subscribe(([countries, states]) => {
            this.countries = countries;
            this.states = states;
            this.createForm();
            this.formattedAddress.emit(this.formatAddressforView());
        });
    }

    ///
    /// Track external changes and update field properties.  E.g. for clinic addresses, the parent component has a "Same as Clinic" button to copy the primary clinic address
    /// to other clinic entities.
    ///
    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.address && changes.address.currentValue) {
            if (changes.address.currentValue.Id === null && this.addressReady()) {
                this.createForm();
            }
            this.setAddressFieldValue("Address1", changes.address.currentValue.Address1);
            this.setAddressFieldValue("Address2", changes.address.currentValue.Address2);
            this.setAddressFieldValue("City", changes.address.currentValue.City);
            this.setAddressFieldValue("Zip", changes.address.currentValue.Zip);
            this.setAddressFieldValue("CountryCode", changes.address.currentValue.CountryCode);
            if (changes.address.previousValue && changes.address.previousValue.CountryCode && changes.address.currentValue.CountryCode != changes.address.previousValue.CountryCode) {
                this.filterStatesForCounty(changes.address.currentValue.CountryCode);
            }
            this.setAddressFieldValue("StateCode", changes.address.currentValue.StateCode);
            this.setAddressFieldValue("Province", changes.address.currentValue.Province);
            if (this.addressReady()) {
                this.getAddress();
            }
        }
    }

    ///
    /// Confirm that initializtion of the address form group has completed.  This prevents errors during page initialization/refresh
    ///
    addressReady(): boolean {
        return this.Address !== undefined && this.Address.controls !== undefined && this.Address.controls.Address !== undefined;
    }

    ///
    /// Find a property-specific control and set its value
    ///
    setAddressFieldValue(fieldName: string, value: any): void {
        if (this.addressReady()) {
            const control = this.Address.controls.Address.get(fieldName);
            if (control) {
                control.setValue(value);
            }
        }
    }

    ///
    /// Destructor
    ///
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ///
    /// Setup form group controls and setup event emitter for changes
    ///
    createForm(): void {
        this.getControls();
        this.Address = this.assignFormGroups();
        this.formCreated = true;
        if (this.denied) {
            this.disableForm();
        } else {
            this.subs.add(
                this.Address.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                    if (this.Address.dirty) {
                        this.getAddress();
                        this.onAddressValueChanges.emit(this.address);
                    }
                }),
            );
        }
        this.onAddressSubmitted.emit(this.Address);
    }

    ///
    /// Disable the form group controls
    ///
    disableForm(): void {
        setTimeout(() => this.Address.disable());
    }

    ///
    /// Add dynamic controls for the address
    ///
    getControls(): void {

        const addressAdditionalParameters: IAddressDynamicControlsParameters = {
            addressTypeId: null,
            countries: this.countries,
            formGroup: this.formGroup,
            states: this.states
        };

        this.addressControls = new AddressDynamicControls(this.address, addressAdditionalParameters);
    }

    ///
    /// Single point to check whether or not the country code is one that we have a defined list of states for
    ///
    countryHasStatesList(value: any): boolean {
        return value === 0 || value === 'US' || value === 'CA' || value === 'AU';
    }


    ///
    /// When the country changes, refresh the list of states based on the country selection
    ///
    setStatesForCountry(data): void {
        const control = data.target as AbstractControl;
        const values = control.value.split(' ');
        let countryCode = '';
        if (values.length > 1) {
            countryCode = values[1];
        }

        this.filterStatesForCounty(countryCode);
    }

    ///
    /// Return a list of country-specific states
    ///
    filterStatesForCounty(countryCode: string): void {
        if (this.countryHasStatesList(countryCode)) {

            const stateCodeControl = this.addressControls.Form.StateCode;
            let statesForCountry: IState[] = [];
            let emptyState: IState = { Id: null, StateCode: "", Name: "", CountryCode: countryCode, Addresses: [], UserRegions: null };
            statesForCountry.push(emptyState);
            this.states.filter(s => s.CountryCode === countryCode).forEach(s => statesForCountry.push(s));
            stateCodeControl.options = statesForCountry;
        }
    }

    ///
    /// Setup event handler so that when the country changes, the State Code or Province is displayed based upon whether or not there are states available for  the selected country
    ///
    setShowStatesCurrentAddress(countryCodeControl: AbstractControl): void {
        this.showStatesCurrentAddress$ = countryCodeControl?.valueChanges.pipe(
            startWith(countryCodeControl.value ?? 0),
            map((value) => this.countryHasStatesList(value)),
            distinctUntilChanged(),
            shareReplay(1),
        );
    }

    ///
    /// Assign the form group to the Address Form Group
    assignFormGroups(): FormGroup {
        return this.fb.group({
            Address: this.fb.group({}),
        });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(): void {
        this.notificationService.error('Save failed.  Please check the form and try again.');
    }

    ///
    /// When the submit button is clicked, update the current address and trigger the address-submitted event emmitter
    ///
    submit(): void {

        this.getAddress();
        this.formattedAddress.emit(this.formatAddressforView());
        this.onAddressSubmitted.emit(this.Address);
        this.onSave.emit(this.address);
    }

    ///
    /// When the cancel button is clicked, trigger the cancel-clicked event emmitter
    ///
    cancel(): void {
        this.onCancelClicked.emit();
    }

    delete(): void {
        this.getAddress();
        this.onDelete.emit(this.address);
    }
    ///
    /// Update the current address from the form group.  Tried to use the  Object.assign() function, but it was adding a property 
    /// to this.address called Address - with everything inside that, instead of the individual property values.
    ///
    getAddress(): void {

        this.address.Address1 = this.Address.value.Address.Address1;
        this.address.Address2 = this.Address.value.Address.Address2;
        this.address.City = this.Address.value.Address.City;
        this.address.Province = this.Address.value.Address.Province;
        this.address.StateCode = this.Address.value.Address.StateCode;
        this.address.Zip = this.Address.value.Address.Zip;
        this.address.CountryCode = this.Address.value.Address.CountryCode;
    }

    formatAddressforView(): string {
        let result = '';

        if (this.address) {
            result += this.address.Address1 ?? '';

            if (this.address.Address2 && this.address.Address2.length > 0) {
                result += ', ' + this.address.Address2;
            }
            result += "," + this.address.City ?? '';

            if (this.address.CountryCode == "AU" || this.address.CountryCode == "CA" || this.address.CountryCode == "US") {
                result += ', ' + this.address.StateCode ?? ''
            }
            else {
                result += ', ' + this.address.Province ?? ''
            }

            result += ' ' + this.address.Zip ?? '';
            result += ',' + this.address.CountryCode;
        }
        return result;
    }
}
