import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { BirthOutcomeDynamicControlsPartial } from '@model/partials/birth-outcome-partial.form-controls';
import { IBirthDetail } from '@model/interfaces/birth-detail';
import { IBirthOutcome } from '@model/interfaces/birth-outcome';

import { OutcomeSourceService } from '../services/outcome-source.service';
import { PregnancyOutcomeService } from '../services/pregnancy-outcome.service';
import { BirthOutcomeService } from '../services/birth-outcome.service';
import { ThawingService } from '../../../../../recipient-users/sales-orders/thawings/services/thawing.service';
import { OutcomeStateService } from '../../outcome.state.service';

@Component({
    selector: 'birth-outcome',
    templateUrl: './birth-outcome.component.html',
})
export class BirthOutcomeComponent implements OnInit {
    @Input() salesOrderId: number;
    @Input() canEdit: boolean;
    @Input() thawingId: number;

    // abstract controls
    abstractBirthOutcomeControls: any;

    birthOutcomeForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    birthOutcome: IBirthOutcome;
    isEditing: boolean;
    birthDetails: IBirthDetail[];
    birthDetailsFormArray: FormArray;
    birthDetailsControl: any;

    showBirthOutcome = false;

    get isNewBirthOutcome(): boolean {
        return this.birthOutcome && this.birthOutcome.Id ? false : true;
    }

    subs = new Subscription();

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private pregnancyOutcomeService: PregnancyOutcomeService,
        private outcomeSourceService: OutcomeSourceService,
        private birthOutcomeService: BirthOutcomeService,
        private thawingService: ThawingService,
        private outcomeStateService: OutcomeStateService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.thawingService.getBirthOutcomeByThawingId(this.thawingId),
            this.pregnancyOutcomeService.getItems(),
            this.outcomeSourceService.getItems(),
        ]).subscribe(([births]) => {
            this.birthOutcome = births ? births : this.birthOutcomeService.getEmptyBirthOutcome(this.thawingId);
            this.createForm();
        });
        this.subs.add(
            this.outcomeStateService.isOutcomeFinalized$.subscribe((isFinalized) => {
                this.showBirthOutcome = isFinalized;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    createForm(): void {
        this.setBirthDetails();
        this.getControls();
        this.birthOutcomeForm = this.assignFormGroups();
        this.birthDetailsControl = (this.birthOutcomeForm.controls.BirthDetail as FormArray).controls;
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractBirthOutcomeControls = new BirthOutcomeDynamicControlsPartial(this.birthOutcome, {
            formGroup: 'BirthOutcome',
            outcomeSources: this.outcomeSourceService.items,
            pregnancyOutcomes: this.pregnancyOutcomeService.items,
        });
    }

    assignFormGroups(): FormGroup {
        let formGroup = this.birthDetails.map((itm) => this.fb.group(itm));
        this.birthDetailsFormArray = this.fb.array(formGroup);
        return this.fb.group({
            BirthDetail: this.birthDetailsFormArray,
            BirthOutcome: this.fb.group({}),
        });
    }

    setBirthDetails(): void {
        if (this.birthOutcome && this.birthOutcome.Id) {
            if (this.birthOutcome.BirthDetails && this.birthOutcome.BirthDetails.length) {
                this.birthDetails = this.birthOutcome.BirthDetails;
            } else {
                this.birthDetails = this.birthOutcome.NumberBorn
                    ? this.birthOutcomeService.getEmptyBirthDetails(this.birthOutcome.NumberBorn, this.birthOutcome.Id)
                    : [];
            }
        } else {
            this.birthDetails = [];
        }
    }

    numberBornChanges(value: any): void {
        // Only need to reset if the value already exists
        if (this.birthOutcome.BirthDetails) {
            this.birthOutcome.BirthDetails = null;
            this.birthDetails = null;
            this.birthOutcome.NumberBorn = value;
            this.createForm();
        }
    }

    formSubmitted(): void {
        if (this.birthOutcomeForm.valid) {
            Object.assign(this.birthOutcome, this.birthOutcomeForm.value.BirthOutcome);
            this.birthOutcome.BirthDetails = this.birthOutcomeForm.value.BirthDetail;
            this.saveBirthOutcome();
        } else {
            markAllFormFieldsAsTouched(this.birthOutcomeForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    saveBirthOutcome(): void {
        if (this.isNewBirthOutcome) {
            this.birthOutcomeService
                .create(this.birthOutcome)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.birthOutcome.Id = answer;
                    this.success();
                });
        } else {
            this.birthOutcomeService
                .updateWithFks(this.birthOutcome)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((success) => {
                    this.success();
                });
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Birth outcome saved successfully.');
        this.createForm();
        this.isEditing = false;
    }
}
