<div>
    <div *ngIf="isReady">
        <div class="row">
            <div class="col-md-8">
                <label>Tank</label>
                <select
                    [ngModel]="selectedTank"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    (ngModelChange)="tankSelectionChanged($event)"
                    [compareWith]="vesselEquals"
                >
                    <option></option>
                    <option *ngFor="let tank of tanks" [ngValue]="tank">{{ tank.Name }} - {{ tank.Number }}</option>
                </select>
            </div>
            <div class="pad-buttons" *ngIf="canCreate">
                <button type="button" class="btn btn-primary btn-fab-sm" (click)="editVessel(vesselTypes.Tank)">
                    <span class="fa fa-pencil"></span>
                </button>
                <button type="button" class="btn btn-primary btn-fab-sm" (click)="createVessel(null, vesselTypes.Tank)">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
        </div>
        <br />
        <div class="row" *ngIf="selectedTank">
            <div class="col-md-8">
                <label>Can</label>
                <select
                    [ngModel]="selectedCan"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    (ngModelChange)="canSelectionChanged($event, selectedTank.Id)"
                    [compareWith]="vesselEquals"
                >
                    <option></option>
                    <option *ngFor="let can of cans" [ngValue]="can">
                        {{ can.Number }}
                    </option>
                </select>
            </div>
            <div class="pad-buttons" *ngIf="canCreate">
                <button type="button" class="btn btn-primary btn-fab-sm" (click)="editVessel(vesselTypes.Can)">
                    <span class="fa fa-pencil"></span>
                </button>
                <button type="button" class="btn btn-primary btn-fab-sm" (click)="createVessel(selectedTank.Id, vesselTypes.Can)">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
        </div>
        <br />
        <div class="row" *ngIf="selectedCan">
            <div class="col-md-8">
                <label>Cane</label>
                <select
                    [ngModel]="selectedCane"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    (ngModelChange)="caneSelectionChanged($event, selectedCan.Id)"
                    [compareWith]="vesselEquals"
                >
                    <option></option>
                    <option *ngFor="let cane of canes" [ngValue]="cane">
                        {{ cane.Number }}
                    </option>
                </select>
            </div>
            <div class="pad-buttons" *ngIf="canCreate">
                <button type="button" class="btn btn-primary btn-fab-sm" (click)="editVessel(vesselTypes.Cane)">
                    <span class="fa fa-pencil"></span>
                </button>
                <button type="button" class="btn btn-primary btn-fab-sm" (click)="createVessel(selectedCan.Id, vesselTypes.Cane)">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
        </div>
        <br />
    </div>
</div>
<!-- create/edit vessel modal -->
<modal-wrapper *ngIf="showModal" [options]="modalOptions">
    <inventory-vessel-form (onCancel)="closeModal()" (onSuccess)="onSuccess($event)" [options]="formOptions"></inventory-vessel-form>
</modal-wrapper>
