import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IContactType } from '@model/interfaces/contact-type';

@Injectable({
    providedIn: 'root',
})
export class ContactTypeService extends StaticMetaItemService<IContactType> {
    constructor(public http: HttpClient) {
        super('ContactTypeService', 'Type', 'TypeIds', '/meta-items/ContactType', http);
    }
}
