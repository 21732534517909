<div class="miles-card padded">
    <h4>Donor Inventory</h4>

    <div *ngIf="donorInventory?.length">
        <p>
            <b>Available Quarantined: </b> {{ quarantinedInventory | vialTypeCounts }}
            <span class="pull-right"><b>Total Reserved: </b> {{ reservedInventory | vialTypeCounts }}</span>
        </p>
        <p><b>Available Released: </b> {{ approvedInventory | vialTypeCounts }}</p>
    </div>
    <entity-list
        *ngIf="donorInventory"
        [entities]="donorInventory"
        [total]="donorInventory.length"
        [entityListConfig]="entityListConfig"
        (onColumnSorted)="onColumnSorted($event)"
        [emptyMessage]="noResultText"
        [showPagination]="false"
    ></entity-list>
</div>
