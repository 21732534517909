import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';

import { IDonorWhoAreYou } from '@model/interfaces/donor-who-are-you';

import { AncestryService } from './who-are-you-meta-items/ancestry.service';
import { BloodTypeService } from './who-are-you-meta-items/blood-type.service';
import { BodyStructureService} from './who-are-you-meta-items/body-structure.service';
import { GpaService} from './who-are-you-meta-items/gpa.service';
import { EducationCompletedTypeService} from './who-are-you-meta-items/education-completed-type.service';
import { EyeColorService} from './who-are-you-meta-items/eye-color.service';
import { HairTextureService} from './who-are-you-meta-items/hair-texture.service';
import { NaturalHairColorService} from './who-are-you-meta-items/natural-hair-color.service';
import { RaceService} from './who-are-you-meta-items/race.service';
import { RhFactorService} from './who-are-you-meta-items/rh-factor.service';
import { SkinTypeService} from './who-are-you-meta-items/skin-type.service';
import { TraumaticEventService} from './who-are-you-meta-items/traumatic-event.service';
import { IDonorWhoAreYouDynamicControlsParameters } from '@model/form-controls/donor-who-are-you.form-controls';
import { IAncestry } from '@model/interfaces/ancestry';
import { IApplicationPartTwoValidationDTO } from '@model/interfaces/custom/application-part-two-validation-dto';

export interface IWhoAreYouMetaItems extends IDonorWhoAreYouDynamicControlsParameters {
    ancestries: IAncestry[];
}

@Injectable({
    providedIn: 'root',
})
export class DonorWhoAreYouService extends BaseService<IDonorWhoAreYou> {

    constructor(public http: HttpClient,
                private ancestryService: AncestryService,
                private bloodTypeService: BloodTypeService,
                private bodyStructureService: BodyStructureService,
                private gpaService: GpaService,
                private educationService: EducationCompletedTypeService,
                private eyeColorService: EyeColorService,
                private hairTextureService: HairTextureService,
                private hairColorService: NaturalHairColorService,
                private raceService: RaceService,
                private rhFactorService: RhFactorService,
                private skinService: SkinTypeService,
                private truamaticEventService: TraumaticEventService) {
        super('/application-part-two/who-are-you', http);
    }

    whoAreYouAdditionalParameters():  Observable<IWhoAreYouMetaItems> {
        return forkJoin({
            ancestries: this.ancestryService.getItems(),
            bloodTypes: this.bloodTypeService.getItems(),
            bodyStructures: this.bodyStructureService.getItems(),
            collegeGpas: this.gpaService.getItems(),
            educationCompletedTypes: this.educationService.getItems(),
            eyeColors: this.eyeColorService.getItems(),
            hairTextures: this.hairTextureService.getItems(),
            highSchoolGpas: this.gpaService.getItems(),
            naturalHairColors: this.hairColorService.getItems(),
            races: this.raceService.getItems(),
            rhFactors: this.rhFactorService.getItems(),
            skinTypes: this.skinService.getItems(),
            traumaticEvents: this.truamaticEventService.getItems(),
        });
    }

    update(data: IDonorWhoAreYou): Observable<IApplicationPartTwoValidationDTO> {
        return super.update(data);
    }

    updateWithFks(data: IDonorWhoAreYou): Observable<IApplicationPartTwoValidationDTO> {
        return super.updateWithFks(data);
    }

}
