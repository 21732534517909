<div>
    <div>
        <form [formGroup]="form" *ngIf="formCreated">
            <div *ngIf="!isBulkUpdate">
                <label>Donation Date: </label><span> {{ donation.SampleDate | date: medium }}</span>
                <br />
                <label>Donation Status: </label><span> {{ donation.DonationStatus.Name }}</span>
                <dynamic-field [field]="controls.SamplePrepMethodId" [form]="form" [disabled]="isBulkUpdate"></dynamic-field>
                <dynamic-field [field]="quantityControl" [form]="form" (valueChanges)="setQuantity($event)" [disabled]="isBulkUpdate"></dynamic-field>
            </div>
            <dynamic-field [field]="controls.Comments" [form]="form"></dynamic-field>
            <dynamic-field *ngIf="!isBulkUpdate" [field]="controls.ReserveForResearch" [form]="form"></dynamic-field>
            <app-inventory-vessel
                [position]="inventoryPosition"
                (onCaneSelected)="inventoryPosition = $event"
                [canCreate]="!isBulkUpdate"
            ></app-inventory-vessel>
            <button *ngIf="showDiscardInventoryButton" type="button" class="btn btn-flat btn-danger" (click)="discardInventory()">
                Discard Inventory
            </button>
            <button *ngIf="showKeepLocationButton" type="button" class="btn btn-flat btn-warning" (click)="keepInventoryLocation()">
                Keep Location
            </button>
            <button
                type="submit"
                mt-doubleClickDisabled
                [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                Class="btn btn-flat btn-success"
                (click)="formSubmitted()"
            >
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </form>
    </div>
</div>
<!-- Confirm Modal -->
<modal-wrapper *ngIf="showConfirm" [options]="confirmOptions">
    <div>
        <h2>Inventory Summary</h2>
        <p><b>Vial Type:</b> {{ getPrepMethodName(form.value.Inventory.SamplePrepMethodId) }}</p>
        <p><b>Tank:</b> {{ inventoryPosition.Tank.Name }} - {{ inventoryPosition.Tank.Number }}</p>
        <p><b>Can:</b> {{ inventoryPosition.Can.Number }}</p>
        <p><b>Cane:</b> {{ inventoryPosition.Cane.Number }}</p>
        <p><b>Quanity of Vials to Add:</b> {{ quantity }}</p>
    </div>
    <div class="row col-md-12">
        <button (click)="saveEggInventory()" class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelSave()">Cancel</button>
    </div>
</modal-wrapper>
