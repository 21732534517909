import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FeatureModule } from '../feature.module';
import { SharedModule } from '../shared.module';
import { FormYesNoButtonComponent } from './form-yes-no-button.component';
import { NotesComponent } from './notes/notes.component';
import { DyanmicLabelComponent } from './dynamic-label.component';
import { DonorAddressesComponent } from './donor-addresses/donor-addresses.component';
import { UploadDocumentsDetailComponent } from './upload-documents-detail/upload-documents-detail.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { CheckboxWithTooltipComponent } from './checkbox-with-tooltip.component';
import {  AddressComponent } from './../../angular-common/components/address/address.component'
import {  AddressContainerComponent } from './../../angular-common/components/address/address-container.component'

@NgModule({
    declarations: [
        FormYesNoButtonComponent,
        NotesComponent,
        DyanmicLabelComponent,
        DonorAddressesComponent,
        UploadDocumentsDetailComponent,
        UploadDocumentsComponent,
        CheckboxWithTooltipComponent,
        AddressComponent,
        AddressContainerComponent,
    ],
    exports: [
        FormYesNoButtonComponent,
        NotesComponent,
        DyanmicLabelComponent,
        DonorAddressesComponent,
        UploadDocumentsDetailComponent,
        UploadDocumentsComponent,
        CheckboxWithTooltipComponent,
        AddressComponent,
        AddressContainerComponent,
    ],
    imports: [CommonModule, SharedModule, FeatureModule],
})
export class CommonComponentsModule {}
