import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { AuthService, ClaimsService, ClaimValues, ILoggedIn } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { RecipientUserService } from '../services/recipient-user.service';
import { IRecipientUser } from '@model/interfaces/recipient-user';
import { entityListModuleConfig } from '@common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { RecipientUsersEntityListConfig } from './recipient-users.entity-list-config';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { RecipientStatusService } from '../services/recipient-status.service';
import { UserService } from 'admin-portal/users/user.service';
import { ISearchbarControlAPI } from '@mt-ng2/searchbar-control';
import { IMetaItem } from '@mt-ng2/base-service';
import { StickySearchEntity, StickySearchStateService } from '@common/services/sticky-search.state.service';
import { SalesOrderStatusService } from '../services/recipient-cycle-status.service';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { SalesOrderStatuses } from '@model/enums/sales-order-statuses.enum';
import { saveAs } from 'file-saver';
import { UserRoles } from '@model/enums/user-roles.enum';

@Component({
    selector: 'app-recipient-users',
    templateUrl: './recipient-users.component.html',
})
export class RecipientUsersComponent implements OnInit {
    recipientUsers: IRecipientUser[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new RecipientUsersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddRecipientUser = false;

    metaItemsReady: boolean;
    accountManagers: MtSearchFilterItem[];
    recipientStatuses: MtSearchFilterItem[];
    cycleStatuses: MtSearchFilterItem[];

    currentUser: ILoggedIn;
    searchbarApi: ISearchbarControlAPI;

    constructor(
        private recipientCycleStatusService: SalesOrderStatusService,
        private recipientUserService: RecipientUserService,
        private claimsService: ClaimsService,
        private router: Router,
        private recipientStatusService: RecipientStatusService,
        private userService: UserService,
        private authService: AuthService,
        private stickySearchService: StickySearchStateService,
    ) {}

    ngOnInit(): void {
        this.canAddRecipientUser = this.claimsService.hasClaim(ClaimTypes.RecipientUsers, [ClaimValues.FullAccess]);
        forkJoin([
            this.userService.getUsersByRoleAsMetaItem(UserRoles.AccountManager),
            this.recipientStatusService.getSearchFilterItems(),
            this.recipientCycleStatusService.getSearchFilterItems(),
        ]).subscribe(([accountManagers, recipientStatuses, cycleStatuses]) => {
            this.currentUser = this.authService.currentUser.getValue();
            this.accountManagers = this.metaItemToSearchItem(accountManagers);
            this.recipientStatuses = recipientStatuses;
            this.cycleStatuses = cycleStatuses;
            this.metaItemsReady = true;
         });
    }

    private createSearchParam(lastSearch?: IEntitySearchParams): SearchParams {
        let searchparams: SearchParams;
        if (lastSearch) {
            searchparams = new SearchParams(lastSearch);
        } else {
            const search = this.query;
            const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
            const searchEntity: IEntitySearchParams = {
                extraParams: _extraSearchParams,
                order: this.order,
                orderDirection: this.orderDirection,
                query: search && search.length > 0 ? search : '',
                skip: (this.currentPage - 1) * this.itemsPerPage,
                take: this.itemsPerPage,
            };
            this.stickySearchService.setSearch(searchEntity, StickySearchEntity.Recipients);
            searchparams = new SearchParams(searchEntity);
        }
        return searchparams;
    }

    getRecipientUsersCall(lastSearch?: IEntitySearchParams): Observable<HttpResponse<IRecipientUser[]>> {
        let searchparams = this.createSearchParam(lastSearch);
        return this.recipientUserService.get(searchparams);
    }

    getRecipientUsers(lastSearch?: IEntitySearchParams): void {
        this.getRecipientUsersCall(lastSearch).subscribe((answer) => {
            let recipientUsers = answer.body;
            for (const recipientUser of recipientUsers) {
                recipientUser.SalesOrders = this.getRecipientLatestCycleStatus(recipientUser.SalesOrders);
            }
            this.recipientUsers = recipientUsers;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    private getRecipientLatestCycleStatus(listOfCycles: ISalesOrder[]): ISalesOrder[] {
        if (listOfCycles.length > 1) {
            let allStatusesAreCancelled: boolean = this.checkAllStatusesAreCancelled(listOfCycles);
            if (allStatusesAreCancelled) {
                return listOfCycles;
            }
            return this.getStatusNotCancelled(listOfCycles);
        }
        return listOfCycles;
    }

    private getStatusNotCancelled(listOfCycles: ISalesOrder[]): ISalesOrder[] {
        return listOfCycles.filter((i) => i.StatusId !== SalesOrderStatuses.Cancelled);
    }

    private checkAllStatusesAreCancelled(listOfCycles: ISalesOrder[]): boolean {
        return listOfCycles.every((item) => item.StatusId === SalesOrderStatuses.Cancelled);
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getRecipientUsers();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const accountManagerIds: number[] = this.getSelectedFilters(this.accountManagers);
        const recipientStatusIds: number[] = this.getSelectedFilters(this.recipientStatuses);
        const cycleStatusIds: number[] = this.getSelectedFilters(this.cycleStatuses);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'StatusIds',
                valueArray: recipientStatusIds,
            }),
            new ExtraSearchParams({
                name: 'AccountManagerId',
                valueArray: accountManagerIds,
            }),
            new ExtraSearchParams({
                name: 'CycleStatusIds',
                valueArray: cycleStatusIds,
            }),
        );

        return _extraSearchParams;
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getRecipientUsers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getRecipientUsers();
    }

    recipientUserSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['/recipient-users', event.entity.Id]);
    }

    metaItemToSearchItem(list: IMetaItem[]): MtSearchFilterItem[] {
        return list.map((itm) => new MtSearchFilterItem(itm, false));
    }

    setStickySearch(): void {
        const lastSearch = this.stickySearchService.getSearch(StickySearchEntity.Recipients);
        if (lastSearch) {
            if (lastSearch.query && this.searchbarApi) {
                this.searchbarApi.getSearchControl().patchValue(lastSearch.query);
            }
            if (lastSearch.extraParams.length) {
                let statusIds = lastSearch.extraParams.find((ep) => ep.name === 'StatusIds');
                if (statusIds) {
                    this.recipientStatuses.forEach((rs) => {
                        if (statusIds.valueArray.includes(rs.Item.Id)) {
                            rs.Selected = true;
                        }
                    });
                }
                let accountManagerIds = lastSearch.extraParams.find((ep) => ep.name === 'AccountManagerId');
                if (accountManagerIds) {
                    this.accountManagers.forEach((rs) => {
                        if (accountManagerIds.valueArray.includes(rs.Item.Id)) {
                            rs.Selected = true;
                        }
                    });
                }
                let cycleStatusIds = lastSearch.extraParams.find((ep) => ep.name === 'CycleStatusIds');
                if (cycleStatusIds) {
                    this.cycleStatuses.forEach((rs) => {
                        if (cycleStatusIds.valueArray.includes(rs.Item.Id)) {
                            rs.Selected = true;
                        }
                    });
                }
            }

            this.getRecipientUsers(lastSearch);
        } else {
            this.getRecipientUsers();
        }
    }

    onPageSizeChange(event: number): void {
        this.itemsPerPage = event;
        this.getRecipientUsers();
    }

    exportRecipients(event): void {
        let searchParams = this.createSearchParam();
        let date = new Date();
        let stringDate = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`;
        this.recipientUserService.exportRecipients(searchParams).subscribe((data: any) => {
            const exportFile = new Blob([data as ArrayBuffer], {
                type: 'application/octet-stream',
            });
            saveAs(exportFile, `Recipients_Export_${stringDate}.csv`);
        });
    }
}
