import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IReferralType } from '@model/interfaces/referral-type';

@Injectable({
    providedIn: 'root',
})
export class ReferralTypeService extends MetaItemService<IReferralType> {
    constructor(public http: HttpClient) {
        super('ReferralTypeService', 'Type', 'TypeIds', '/meta-items/ReferralType', http);
    }
}
