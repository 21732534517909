import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DonorOngoingScreeningDynamicControlsPartial } from '@model/partials/donor-ongoing-screening-partial.form-controls';
import { IDonor } from '@model/interfaces/donor';
import { DonorOngoingScreeningsEntityListConfig } from './donor-ongoing-screenings.entity-list-config';
import { entityListModuleConfig } from '@common/shared.module';
import { IDonorOngoingScreening } from '@model/interfaces/donor-ongoing-screening';
import { HttpResponse } from '@angular/common/http';
import { ExtraParams } from '@common/extra-params/extra-params.library';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, SortDirection, IItemSelectedEvent } from '@mt-ng2/entity-list-module';
import { DonorOngoingScreeningService } from '../services/donor-ongoing-screening.service';
import { BloodWorkStatusService } from '../services/blood-work-status.service';
import { RegulatoryQuestionnaireStatusService } from '../services/regulatory-questionnaire-status.service';
import { PhysicalStatusService } from '../services/physical-status.service';
import { DeleteDonorOngoingScreeningComponent } from '../donor-ongoing-screening-basic-info/delete-donor-ongoing-screening.component';

@Component({
    selector: 'app-donor-ongoing-screenings',
    templateUrl: './donor-ongoing-screenings.component.html',
})
export class DonorOngoingScreeningsComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;

    screenings: IDonorOngoingScreening[];

    // abstract controls
    abstractDonorOngoingScreeningControls: any;

    donorOngoingScreeningsForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;

    entityListConfig = new DonorOngoingScreeningsEntityListConfig();
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    total: number;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private bloodWorkStatusService: BloodWorkStatusService,
        private regulatoryQuestionnaireStatusService: RegulatoryQuestionnaireStatusService,
        private physicalStatusService: PhysicalStatusService,
        private donorOngoingScreeningService: DonorOngoingScreeningService,
    ) {}

    ngOnInit(): void {
        forkJoin(
            this.bloodWorkStatusService.getItems(),
            this.regulatoryQuestionnaireStatusService.getItems(),
            this.physicalStatusService.getItems(),
        ).subscribe((answer) => {
            this.createForm();
            this.getScreenings();
        });
    }

    createForm(): void {
        this.getControls();
        this.donorOngoingScreeningsForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractDonorOngoingScreeningControls = new DonorOngoingScreeningDynamicControlsPartial(null, {
            bloodWorkStatuses: this.bloodWorkStatusService.items,
            formGroup: 'DonorOngoingScreening',
            physicalStatuses: this.physicalStatusService.items,
            regulatoryQuestionnaireStatuses: this.regulatoryQuestionnaireStatusService.items,
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            DonationDate: this.fb.group({}),
            DonorOngoingScreening: this.fb.group({}),
        });
    }

    getScreenings(): void {
        this.getScreeningsCall().subscribe((answer) => {
            this.screenings = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getScreeningsCall(): Observable<HttpResponse<IDonorOngoingScreening[]>> {
        const _extraSearchParams: ExtraSearchParams[] = ExtraParams.build({
            DonorId: this.donor.Id,
        });
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.donorOngoingScreeningService.get(searchparams);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getScreenings();
    }

    screeningSelected(event: IItemSelectedEvent): void {
        this.router.navigate([`./ongoing-screenings`, event.entity.Id], { relativeTo: this.route });
    }

    formSubmitted(): void {
        if (this.donorOngoingScreeningsForm.valid) {
            const emptyScreening = this.donorOngoingScreeningService.getEmptyDonorOngoingScreening(this.donor.Id);
            const screening = Object.assign({}, emptyScreening, this.donorOngoingScreeningsForm.value.DonorOngoingScreening);
            this.donorOngoingScreeningService.create(screening).subscribe(() => {
                this.getScreenings();
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.donorOngoingScreeningsForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    createOngoingScreening(): void {
        this.getControls();
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.isEditing = false;
        this.notificationsService.success('Donor Ongoing Screening saved successfully.');
    }

    get canDelete(): boolean {
        let okToDelete = true;

        return okToDelete;
    }
}
