import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IThawing } from '@model/interfaces/thawing';
import { IOutcome } from '@model/interfaces/outcome';
import { Observable } from 'rxjs';
import { IQcInvestigation } from '@model/interfaces/qc-investigation';
import { IBirthOutcome } from '@model/interfaces/birth-outcome';

export const emptyThawing: IThawing = {
    ActualThawingDate: null,
    ClinicId: null,
    Comments: null,
    Concentration: null,
    FollowUpDate: null,
    FollowUpReasonId: null,
    GuaranteeMet: null,
    Id: 0,
    Motility: null,
    ProcedureDate: null,
    ProcedureTypeId: null,
    ReasonNotToCountId: null,
    SalesOrderId: 0,
    Technician: null,
    TreatingPhysician: null,
    VialsThawed: null,
    VialsUtilized: null,
    VialTypeId: null,
    Volume: null,
};

@Injectable({
    providedIn: 'root',
})
export class ThawingService extends BaseService<IThawing> {
    constructor(public http: HttpClient) {
        super('/thawings', http);
    }

    getEmptyThawing(salesOrderId: number): IThawing {
        return { ...emptyThawing, SalesOrderId: salesOrderId };
    }

    getOutcomeByThawingId(thawingId: number): Observable<IOutcome> {
        return this.http.get<IOutcome>(`/thawings/${thawingId}/outcomes`);
    }

    getQcInvestigationByThawingId(thawingId: number): Observable<IQcInvestigation> {
        return this.http.get<IQcInvestigation>(`/thawings/${thawingId}/qc-investigation`);
    }

    getBirthOutcomeByThawingId(thawingId: number): Observable<IBirthOutcome> {
        return this.http.get<IBirthOutcome>(`/thawings/${thawingId}/birth-outcome`);
    }
}
