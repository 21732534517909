import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';

import { MtAlertsModule } from '@mt-ng2/alerts-module';
import { MtPreventDoubleClickButtonModule } from '@mt-ng2/disable-double-click';
import { BackButtonModule } from '@mt-ng2/back-button-module';

import { PageTitlesModule } from '@mt-ng2/page-titles';
import { IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module-config';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { MultiselectControlModule } from '@mt-ng2/multiselect-control';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

/**
 * Shared Module contains all imports and exports that are commonly used in all other modules.
 * Since this is imported into the AppModule, only things that are needed on startup should be
 * included.
 *
 * This should only be imported to the portal specific SharedModule.
 *
 * Tip: If the import/export should only be included in feature modules, than consider putting
 * it into "FeatureModule" instead.  If the import/export is only needed at the AppModule level
 * and rarely/never needs to be imported into any other modules, than consider putting it into
 * "AppModule" instead.
 */
@NgModule({
    exports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        MultiselectControlModule,
        MtAlertsModule,
        MtPreventDoubleClickButtonModule,
        BackButtonModule,
        PageTitlesModule,
        NgxSkeletonLoaderModule,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        MtAlertsModule,
        MultiselectControlModule,
        MtPreventDoubleClickButtonModule,
        BackButtonModule,
        PageTitlesModule,
        NgxSkeletonLoaderModule,
    ],
})
export class SharedModule {
    static forRoot(): any {
        return {
            ngModule: SharedModule,
            providers: [
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
            ],
        };
    }
}
