<div class="miles-card padded" *ngIf="donor.Id > 0">
    <h4>Donor Score {{ donor.DonorScore }}</h4>
    <div>
        <div class="row">
            <div class="padded">
                <div class="text-denied">
                    <span *ngIf="donorIsDenied">Denied</span>
                    <span *ngIf="showDenialReason">: {{ donor.DonorDenialType.Name }}</span>
                </div>
                <div *ngIf="!showDetails" (click)="toggleDetailDisplay()">
                    <i class="fa fa-angle-right fa-2x" aria-hidden="true"></i><span style="margin: 7px 0"> Expand to view details</span>
                </div>
                <div *ngIf="showDetails" (click)="toggleDetailDisplay()" style="padding-bottom: 20px">
                    <i class="fa fa-angle-down fa-2x" aria-hidden="true"></i><span> Hide details</span>
                </div>
                <div *ngIf="showDetails">
                    <div *ngFor="let type of medicalIssueTypes">
                        <div *ngFor="let issue of getIssuesByTypeId(type.Id)">
                            <donor-score-medical-issue [donorMedicalHistory]="issue" [donorRelationshipTypes]="donorRelationshipTypes">
                            </donor-score-medical-issue>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
