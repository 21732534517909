<div class="miles-card padded"
(mouseenter)="isHovered = true"
(mouseleave)="isHovered = false">
    <h4>
        Donor Ongoing Screening
        <span *ngIf="canEdit && isHovered">
            <i class="pull-right fa fa-lg fa-edit" (click)="edit()"></i>
        </span>
    </h4>
    <div *ngIf="formCreated">
        <div *ngIf="!isEditing">
            <div class="display-padded" *ngIf="donorOngoingScreening.DateModified">
                <span
                    ><em>Last Modified {{ donorOngoingScreening.DateModified | date: 'medium' }}</em></span
                >
                <span *ngIf="donorOngoingScreening.User"
                    ><em>by {{ donorOngoingScreening.User.FirstName + ' ' + donorOngoingScreening.User.LastName }}</em></span
                >
                <br />
            </div>
            <div>
                <dynamic-label [field]="abstractDonorOngoingScreeningControls.BloodDrawDate"></dynamic-label>
                <div class="display-padded">
                    <span><b>Blood Work Status:</b></span>
                    <span>{{ donorOngoingScreening.BloodWorkStatus?.Name }}</span>
                </div>
                <div class="display-padded">
                    <b>Regulatory Questionnaire Status:</b>
                    <span>{{ donorOngoingScreening.RegulatoryQuestionnaireStatus?.Name }}</span>
                </div>
                <dynamic-label [field]="abstractDonorOngoingScreeningControls.RegulatoryQuestionnaireResultDate"></dynamic-label>
                <div class="display-padded">
                    <b>Physical Status</b>
                    <span>{{ donorOngoingScreening.PhysicalStatus?.Name }}</span>
                </div>
                <dynamic-label [field]="abstractDonorOngoingScreeningControls.PhysicalResultDate"></dynamic-label>
            </div>
        </div>
        <div *ngIf="isEditing">
            <div>
                <form [formGroup]="donorOngoingScreeningsForm" (ngSubmit)="formSubmitted()">
                    <div class="row">
                        <div class="col-md-6">
                            <dynamic-field
                                [field]="abstractDonorOngoingScreeningControls.BloodDrawDate"
                                [form]="donorOngoingScreeningsForm"
                            ></dynamic-field>
                            <dynamic-field
                                [field]="abstractDonorOngoingScreeningControls.BloodWorkStatusId"
                                [form]="donorOngoingScreeningsForm"
                            ></dynamic-field>
                            <dynamic-field
                                [field]="abstractDonorOngoingScreeningControls.RegulatoryQuestionnaireStatusId"
                                [form]="donorOngoingScreeningsForm"
                            ></dynamic-field>
                            <dynamic-field
                                [field]="abstractDonorOngoingScreeningControls.RegulatoryQuestionnaireResultDate"
                                [form]="donorOngoingScreeningsForm"
                            ></dynamic-field>
                            <dynamic-field
                                [field]="abstractDonorOngoingScreeningControls.PhysicalStatusId"
                                [form]="donorOngoingScreeningsForm"
                            ></dynamic-field>
                            <dynamic-field
                                [field]="abstractDonorOngoingScreeningControls.PhysicalResultDate"
                                [form]="donorOngoingScreeningsForm"
                            ></dynamic-field>
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <button
                            type="submit"
                            mt-doubleClickDisabled
                            [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                            Class="btn btn-flat btn-success"
                        >
                            Save
                        </button>
                        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
                    </div>
                </form>
                <br />
                <br />
            </div>
        </div>
    </div>
</div>
