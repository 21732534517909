import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, DynamicLabel, NumericInputTypes } from '@mt-ng2/dynamic-form';
import {
    DonorPsychologicalScreeningDynamicControls as Base,
    IDonorPsychologicalScreeningDynamicControlsParameters,
} from '../form-controls/donor-psychological-screening.form-controls';
import { IDonorPsychologicalScreening } from '../interfaces/donor-psychological-screening';

export class DonorPsychologicalScreeningDynamicControls extends Base {
    constructor(
        private donorPsychologicalScreening?: IDonorPsychologicalScreening,
        additionalParameters?: IDonorPsychologicalScreeningDynamicControlsParameters,
    ) {
        super(donorPsychologicalScreening, additionalParameters);

        this.View.ModifiedById = new DynamicLabel(
            'Modified By',
            this?.donorPsychologicalScreening?.ModifiedById
                ? `${this?.donorPsychologicalScreening?.User.FirstName} ${this?.donorPsychologicalScreening?.User.LastName}`
                : null,
            new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
                scale: null,
            }),
        );

        this.View.MmpiCompleteDate.label = 'MMPI Complete Date';
        this.Form.MmpiCompleteDate = new DynamicField({
            formGroup: this.formGroup,
            label: 'MMPI Complete Date',
            name: 'MmpiCompleteDate',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: (this.donorPsychologicalScreening && this.donorPsychologicalScreening.MmpiCompleteDate) || null,
        });

        this.View.AnzicaSentDate.label = 'ANZICA Sent Date';
        this.Form.AnzicaRecievedDate = new DynamicField({
            formGroup: this.formGroup,
            label: 'ANZICA Recieved Date',
            name: 'AnzicaRecievedDate',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: (this.donorPsychologicalScreening && this.donorPsychologicalScreening.AnzicaRecievedDate) || null,
        });

        this.View.AnzicaRecievedDate.label = 'ANZICA Recieved Date';
        this.Form.AnzicaSentDate = new DynamicField({
            formGroup: this.formGroup,
            label: 'ANZICA Sent Date',
            name: 'AnzicaSentDate',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: (this.donorPsychologicalScreening && this.donorPsychologicalScreening.AnzicaSentDate) || null,
        });
    }
}
