import { IDonorSample } from '@model/interfaces/donor-sample';
import {
    DonorTestSampleDynamicControlsPartial,
    IDonorTestSampleDynamicControlsPartialParameters,
} from '@model/partials/donor-test-sample-partial.form-controls';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

export class DonorTestSampleDynamicConfig<T extends IDonorSample> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private donorTestSample: T, additionalParams: IDonorTestSampleDynamicControlsPartialParameters, private configControls?: string[]) {
        super();

        const dynamicControls = new DonorTestSampleDynamicControlsPartial(this.donorTestSample, additionalParams);
        if (!configControls) {
            this.configControls = [
                'SampleDate',
                'TestSampleTypeId',
                'SampleStatusId',
                'TimeProduced',
                'TimeProcessed',
                'HoursOfAbstinence',
                'PrepMethodId',
                'SampleProcessorId',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
