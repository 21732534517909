import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IModalOptions, ModalService } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { ClinicsToMetaItem } from '@common/libraries/meta-item.library';
import { ShippingDynamicConfig } from '../shipping.dynamic-config';

import { IMetaItem } from '@model/interfaces/base';
import { IShipper } from '@model/interfaces/shipper';
import { IShipping } from '@model/interfaces/shipping';
import { IRecipient } from '@model/interfaces/recipient';
import { ISalesOrder } from '@model/interfaces/sales-order';

import { ClinicService } from '../../../../clinics/services/clinic.service';
import { ShipperService } from '../../../../managed-lists/services/shipper.service';
import { SalesOrderService } from '../../../services/sales-order.service';
import { ShipmentTypeService } from '../services/shipment-type.service';
import { ShippingMethodService } from '../services/shipping-method.service';
import { ShippingStatusService } from '../services/shipping-status.service';
import { ShippingService } from '../services/shipping.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-shipping-basic-info',
    styles: [
        `
            .header-button {
                font-size: 11px;
                margin-top: -10px;
            }
            .mr-28 {
                margin-right: 28px;
            }
            .mr-4 {
                margin-right: 4px;
            }
        `,
    ],
    templateUrl: './shipping-basic-info.component.html',
})
export class ShippingBasicInfoComponent implements OnInit {
    @Input() salesOrder: ISalesOrder;
    @Input() canEdit: boolean;
    @Input() shippingDetails: IShipping;

    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: ShippingDynamicConfig<IShipping>;
    doubleClickIsDisabled = false;
    formCreated = false;

    shipToClinics: IMetaItem[];
    shippers: IShipper[];

    vialsPurchased: number;

    internationalPriorityDays = 5;
    priorityOvernightDays = 1;

    get isNewShipping(): boolean {
        return this.shippingDetails && this.shippingDetails.Id ? false : true;
    }

    constructor(
        private shippingService: ShippingService,
        private clinicService: ClinicService,
        private shipmentTypeService: ShipmentTypeService,
        private shipperService: ShipperService,
        private shippingStatusService: ShippingStatusService,
        private shippingMethodService: ShippingMethodService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private salesOrderService: SalesOrderService,
    ) {}

    ngOnInit(): void {
        if (this.salesOrder) {
            this.vialsPurchased = this.salesOrder.Inventories?.length;
            forkJoin([
                this.clinicService.getAll(),
                this.shipperService.getItems(),
                this.shipmentTypeService.getItems(),
                this.shippingStatusService.getItems(),
                this.shippingMethodService.getItems(),
            ]).subscribe(([shipToClinics, shippers]) => {
                this.shipToClinics = ClinicsToMetaItem(shipToClinics);
                this.shippers = shippers;
                this.setConfig();
            });
        }
    }

    setConfig(): void {
        const additionalParams = {
            shipmentTypes: this.shipmentTypeService.items,
            shippers: this.shippers,
            shippingMethods: this.shippingMethodService.items,
            shippingStatuses: this.shippingStatusService.items,
            shipToClinics: this.shipToClinics,
            vialsPurchased: this.vialsPurchased,
        };
        this.formFactory = new ShippingDynamicConfig<IShipping>(this.shippingDetails, additionalParams);
        if (this.isNewShipping) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    onFormCreated(form: FormGroup): void {
        if (this.isNewShipping) {
            form.get('Shipping.ShippingMethodId').setValue(this.priorityOvernightDays);
        }
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.shippingDetails, form.value.Shipping);
            this.validateVialsShipped(this.shippingDetails.NumberOfVialsShipped).subscribe((resp) => {
                if (resp) {
                    this.saveShipping();
                } else {
                    this.abortSubmit(form);
                }
            });
        } else {
            this.abortSubmit(form, true);
        }
    }

    private abortSubmit(form: FormGroup, showError?: boolean): void {
        markAllFormFieldsAsTouched(form);
        if (showError) {
            this.notificationsService.error('Save failed. Please check the form and try again.');
        }
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    private saveShipping(): void {
        if (this.isNewShipping) {
            this.shippingService
                .create(this.shippingDetails)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.shippingDetails.Id = answer;
                    this.success();
                });
        } else {
            this.shippingService
                .update(this.shippingDetails)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe(() => {
                    this.success();
                });
        }
    }

    private success(): void {
        this.setConfig();
        this.isEditing = false;
        this.shippingService.emitChange(this.shippingDetails);
        this.notificationsService.success('Shipping saved successfully.');
    }

    private validateVialsShipped(countShipped: number): Observable<boolean> {
        let modalOpt: IModalOptions = {
            cancelButtonClass: 'btn btn-swal-override',
            confirmButtonClass: 'btn btn-swal-override',
            confirmButtonText: 'Continue',
            showCancelButton: true,
            text: '',
            title: 'Are you sure?',
            type: 'warning',
        };

        // Not validating if value hasn't been entered yet
        if (!countShipped || countShipped === 0) {
            return of(true);
        } else {
            if (countShipped < this.vialsPurchased) {
                modalOpt.text = 'Number of vials shipped is less than vials purchased.';
                return this.modalService.showModal(modalOpt).pipe(map((result) => result.isConfirmed));
            } else if (countShipped > this.vialsPurchased) {
                modalOpt.text = 'Number of vials shipped is more than vials purchased.';
                return this.modalService.showModal(modalOpt).pipe(map((result) => result.isConfirmed));
            } else {
                return of(true);
            }
        }
    }

    downloadSHSBDF6(): void {
        this.salesOrderService.getSHSBDF6Pdf(this.salesOrder.Id).subscribe((bytes: any) => {
            const thefile = new Blob([bytes as ArrayBuffer], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, `SHSBDF6 Summary of Records - External - File.pdf`);
        });
    }

    downloadSHSBDF8(): void {
        this.salesOrderService.getSHSBDF8Pdf(this.salesOrder.Id).subscribe((bytes: any) => {
            const thefile = new Blob([bytes as ArrayBuffer], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, `SHSBDF8 Summary of Records - Canada - File.pdf`);
        });
    }

    downloadSHSBDF9(): void {
        this.salesOrderService.getSHSBDF9Pdf(this.salesOrder.Id).subscribe((bytes: any) => {
            const thefile = new Blob([bytes as ArrayBuffer], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, `SHSBDF9 Summary of Records - Australia - File.pdf`);
        });
    }
}
