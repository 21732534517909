import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';

@Component({
    selector: 'view-donation-details',
    template: `<div style="width:100%;height:100%;text-align: center;" (click)="navigateToDetails($event)">
        <i class="fa fa-pencil-square-o fa-2x" style="color:#095093;"></i>
    </div> `,
})
export class ViewDonationDetailsComponent implements IEntityListDynamicCellComponentGeneric<IDonorSample> {
    entity: IDonorSample;
    entityListComponentMembers: IEntityListComponentMembers;

    constructor(private router: Router, private route: ActivatedRoute) {}

    navigateToDetails(e: any): void {
        e.stopPropagation();
        this.router.navigate(['donation-samples', this.entity.Id], { relativeTo: this.route });
    }
}
