<div class="miles-card padded">
    <h4>
        Cycles
        <span class="pull-right" *ngIf="canAddSalesOrder">
            <a [routerLink]="['sales-orders', 'add']">
                <i class="fa fa-lg fa-plus"></i>
            </a>
        </span>
    </h4>
    <entity-list
        [entities]="salesOrders"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        (onPageChanged)="getSalesOrders()"
        (onItemSelected)="salesOrderSelected($event)"
        (onColumnSorted)="columnSorted($event)"
        [entityListConfig]="entityListConfig"
    >
    </entity-list>
</div>
