import { Component, Input} from '@angular/core';

import { format } from '@mt-ng2/format-functions';

import { IDonorAddress } from '@model/interfaces/donor-address';
import { AddressTypes } from '@model/enums/address-types.enum';

@Component({
    selector: 'app-donor-addresses',
    templateUrl: './donor-addresses.component.html' ,
})
export class DonorAddressesComponent {
    @Input() donorAddresses: IDonorAddress[];

    addressTypes = AddressTypes;

    constructor() {}

    getIndividualAddressTitle(address: any): string {
        return format.address(address);
    }

}
