import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { ITestSampleType } from '@model/interfaces/test-sample-type';

@Injectable({
    providedIn: 'root',
})
export class TestSampleTypeService extends StaticMetaItemService<ITestSampleType> {
    constructor(public http: HttpClient) {
        super('TestSampleTypeService', 'Type', 'TypeIds', '/meta-items/TestSampleType', http);
    }
}
