import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { DonorSampleDynamicConfig } from '../donor-sample.dynamic-config';
import { DonorSampleStatusService } from '../../initial-screening/donor-test-samples/services/donor-sample-status.service';

import { IDonorSample } from '@model/interfaces/donor-sample';
import { IDonor } from '@model/interfaces/donor';
import { DonorSampleService } from '../../services/donor-sample.service';
import { SamplePrepMethodService } from '../../services/sample-prep-method.service';
import { IMetaItem } from '@mt-ng2/base-service';
import { DonationStatusService } from '../services/donation-status.service';
import { IInventory } from '@model/interfaces/inventory';
import { DonationStatuses } from '@model/enums/donation-statuses.enum';
import { InventoryVesselService } from 'admin-portal/donors/inventory/services/inventory-vessel.service';
import { IModalOptions } from '@mt-ng2/modal-module';
import { BulkUpdateTypes } from '@model/enums/custom/bulk-update-types.enum';
import { DonorOngoingScreeningService } from 'admin-portal/donors/donor-ongoing-screenings/services/donor-ongoing-screening.service';
import { saveAs } from 'file-saver';
import { UserService } from 'admin-portal/users/user.service';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-donor-donation-basic-info',
    styles: [
        `
            .header-button {
                font-size: 11px;
                margin-top: -10px;
            }
            .mr-4 {
                margin-right: 4px;
            }
        `,
    ],
    templateUrl: './donor-donation-basic-info.component.html',
})
export class DonorDonationBasicInfoComponent implements OnInit {
    @Input() donorSample: IDonorSample;
    @Input() canEdit: boolean;

    isEditing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: DonorSampleDynamicConfig<IDonorSample>;
    doubleClickIsDisabled = false;

    donors: IDonor[];
    sampleProcessors: IMetaItem[];
    ongoingScreenings: IMetaItem[];
    nullOption: IMetaItem = { Id: null, Name: null };
    bulkUpdateTypes = BulkUpdateTypes;

    bulkUpdateDonation = false;
    updateType: BulkUpdateTypes;
    updatedInventory: IInventory[];
    updateSaveReady = false;
    modalOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        padding: '35px',
        showCloseButton: true,
        showConfirmButton: false,
        width: '50%',
    };

    get isNewDonorSample(): boolean {
        return this.donorSample && this.donorSample.Id ? false : true;
    }

    get eligibleForRelease(): boolean {
        return this.donorSample.DonationStatusId === DonationStatuses.EligibleforRelease;
    }

    constructor(
        private donorSampleService: DonorSampleService,
        private donorSampleStatusService: DonorSampleStatusService,
        private notificationsService: NotificationsService,
        private SamplePrepMethodService: SamplePrepMethodService,
        private donationStatusService: DonationStatusService,
        private inventoryService: InventoryVesselService,
        private ongoingScreeningService: DonorOngoingScreeningService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.donorSampleStatusService.getItems(),
            this.SamplePrepMethodService.getItems(),
            this.userService.getUsersByClaimAsMetaItem(ClaimTypes.Donors_ProcessSamples),
            this.donationStatusService.getItems(),
            this.ongoingScreeningService.getDonorScreeningsAsMetaItems(this.donorSample.DonorId),
        ]).subscribe(([, , sampleProcessors, , ongoingScreenings]) => {
            this.sampleProcessors = sampleProcessors;
            this.ongoingScreenings = ongoingScreenings;
            this.ongoingScreenings.unshift(this.nullOption);
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new DonorSampleDynamicConfig<IDonorSample>(this.donorSample, {
            donationStatuses: this.donationStatusService.items,
            followUpScreeningTestingsMetaItem: this.ongoingScreenings,
            initialScreeningTestingsMetaItem: this.ongoingScreenings,
            prepMethods: this.SamplePrepMethodService.items,
            preQuarantineTestingsMetaItem: this.ongoingScreenings,
            quarantineReleaseTestingsMetaItem: this.ongoingScreenings,
            sampleProcessorMetaItems: this.sampleProcessors,
            sampleStatuses: this.donorSampleStatusService.items,
        });
        if (this.isNewDonorSample) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.donorSample, form.value.DonorSample);
            this.saveDonorSample();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveDonorSample(): void {
        const saveData = { ...this.donorSample };
        delete saveData.DonorSampleLabData;
        delete saveData.DonorSampleRemainderLabData;
        this.donorSampleService
            .update(saveData)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((updatedData) => {
                this.success(updatedData);
            });
    }

    private success(updatedData: IDonorSample): void {
        this.donorSample = updatedData;
        this.setConfig();
        this.bulkUpdateDonation = false;
        this.isEditing = false;
        this.donorSampleService.emitChange(this.donorSample);
        this.notificationsService.success('Donation saved successfully.');
    }

    saveBulkUpdate(): void {
        this.inventoryService.updateMultipleInventoryLocations(this.updatedInventory).subscribe((answer) => {
            switch (this.updateType) {
                case BulkUpdateTypes.Approved:
                    this.donorSample.DonationStatusId = DonationStatuses.ApprovedforRelease;
                    break;
                case BulkUpdateTypes.Discard:
                    this.donorSample.DonationStatusId = DonationStatuses.Discard;
                    break;
                default:
                    break;
            }
            this.saveDonorSample();
        });
    }

    cancelBulkUpdate(): void {
        this.bulkUpdateDonation = false;
        this.updatedInventory = null;
    }

    onInventoryLocationEditFinished(updates: IInventory[]): void {
        this.updatedInventory = updates;
    }

    downloadSHSBDF7(): void {
        this.donorSampleService.getSHSBDF7Pdf(this.donorSample.Id).subscribe((bytes: any) => {
            const thefile = new Blob([bytes as ArrayBuffer], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, `SHSBDF7 Summary of Records - File.pdf`);
        });
    }

    downloadSHSBDF8(): void {
        this.donorSampleService.getSHSBDF8Pdf(this.donorSample.Id).subscribe((bytes: any) => {
            const thefile = new Blob([bytes as ArrayBuffer], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, `SHSBDF8 Summary of Records - Canada - File.pdf`);
        });
    }

    downloadSHSBDF9(): void {
        this.donorSampleService.getSHSBDF9Pdf(this.donorSample.Id).subscribe((bytes: any) => {
            const thefile = new Blob([bytes as ArrayBuffer], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, `SHSBDF9 Summary of Records - Australia - File.pdf`);
        });
    }
}
