import { Component } from '@angular/core';
import { ApplicationSections } from '@model/enums/custom/application-sections.enum';
import { DonorRelationshipTypes } from '@model/enums/donor-relationship-types.enum';
import { TraumaticEvents } from '@model/enums/traumatic-events.enum';
import { basename } from 'path';
import { DonorApplicationDetailsSectionBaseComponent } from '../donor-application-details-section.base.component';

@Component({
    selector: 'app-who-are-you-details',
    templateUrl: './who-are-you-details.component.html',
})
export class WhoAreYouDetailsComponent extends DonorApplicationDetailsSectionBaseComponent {
    formId = ApplicationSections.WhoAreYou;

    get fatherAncestries(): string {
        let fatherAncestries = this.donor.DonorWhoAreYou.DonorAncestries.filter((da) => da.RelationshipTypeId === DonorRelationshipTypes.Father)
            .map((da) => da.Ancestry.Name)
            .join(', ');
        if (this.donor.DonorWhoAreYou.OtherAncestryFather) {
            fatherAncestries += ', ' + this.donor.DonorWhoAreYou.OtherAncestryFather;
        }
        return fatherAncestries;
    }

    get motherAncestries(): string {
        let motherAncestries = this.donor.DonorWhoAreYou.DonorAncestries.filter((da) => da.RelationshipTypeId === DonorRelationshipTypes.Mother)
            .map((da) => da.Ancestry.Name)
            .join(', ');
        if (this.donor.DonorWhoAreYou.OtherAncestryMother) {
            motherAncestries += ', ' + this.donor.DonorWhoAreYou.OtherAncestryMother;
        }
        return motherAncestries;
    }

    showOtherTraumaticEvent(): boolean {
        return (
            this.donor.DonorWhoAreYou.ExperiencedTraumaticEvent &&
            this.donor.DonorWhoAreYou.TraumaticEventId &&
            this.donor.DonorWhoAreYou.TraumaticEventId === TraumaticEvents.Other
        );
    }
}
