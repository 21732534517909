import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DonorBasicInfoDynamicControls as base, IDonorBasicInfoDynamicControlsParameters } from '@model/form-controls/donor-basic-info.form-controls';
import { IDonorBasicInfo } from '@model/interfaces/donor-basic-info';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, NumericInputTypes, LabelPositions, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { FormYesNoButtonComponent } from '../../../angular-common/components/form-yes-no-button.component';
import { BuildDonorFormLabel } from '@angular-common/libraries/build-donor-form-label.library';
import { DateParts } from '@mt-ng2/date-module';

export class DonorBasicInfoDynamicControls extends base {
    constructor(public donorBasicInfo?: IDonorBasicInfo, additionalParameters?: IDonorBasicInfoDynamicControlsParameters) {
        super(donorBasicInfo, additionalParameters);

        this.Form.EmergencyContactName = new DynamicField({
            formGroup: this.formGroup,
            label: 'Emergency Contact Name',
            labelPosition: {
                colsForLabel: 1,
                position:null,
            },
            name: 'EmergencyContactName',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(125)],
            validators: { required: true, max: 125 },
            value: this?.donorBasicInfo?.EmergencyContactName || null,
        });
        (<DynamicField>this.Form.EmergencyContactName).label = 'Emergency Contact';
        this.Form.EmergencyContactNumber = new DynamicField({
            formGroup: this.formGroup,
            label: 'Emergency Contact Number',
            labelPosition: {
                colsForLabel: 1,
                position: null,
            },
            name: 'EmergencyContactNumber',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(20)],
            validators: { required: true, max: 20 },
            value: this?.donorBasicInfo?.EmergencyContactNumber || null,
        });

        this.Form.Weight = new DynamicField({
            formGroup: this.formGroup,
            label: 'Weight',
            labelPosition: {
                colsForLabel: 1,
                position: LabelPositions.Hidden,
            },
            name: 'Weight',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(300)],
            validators: { required: true, max: 300 },
            value: this?.donorBasicInfo?.Weight || null,
        });

        this.Form.WeightUnitId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Weight Units',
            labelPosition: {
                colsForLabel: 1,
                position: LabelPositions.Hidden,
            },
            name: 'WeightUnitId',
            options: additionalParameters.weights,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: this?.donorBasicInfo?.WeightUnitId || 1,
        });

        this.Form.HeightUnitId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Height Units',
            labelPosition: {
                colsForLabel: 1,
                position: LabelPositions.Hidden,
            },
            name: 'HeightUnitId',
            options: additionalParameters.heights,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: this?.donorBasicInfo?.HeightUnitId || 3,
        });

        (<DynamicField>this.Form.Feet) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Feet',
            name: 'Feet',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(10)],
            validators: { required: true, max: 10 },
            value: this?.donorBasicInfo?.Height ? Math.floor(this?.donorBasicInfo?.Height / 12) : null,
        });

        (<DynamicField>this.Form.Inches) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Inches',
            name: 'Inches',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(12)],
            validators: { required: true, max: 12 },
            value: this?.donorBasicInfo?.Height ? this?.donorBasicInfo?.Height % 12 : null,
        });

        (<DynamicField>this.Form.Meters) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Meters',
            name: 'Meters',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Float,
                scale: 2,
            }),
            validation: [Validators.required, Validators.max(10)],
            validators: { required: true, max: 10 },
            value: this?.donorBasicInfo?.Height ? this?.donorBasicInfo?.Height : null,
        });

        (<DynamicField>this.Form.ApplicantPhoneNumber).validators = { minlength: 10, required: true };
        (<DynamicField>this.Form.ApplicantPhoneNumber).validation = [Validators.minLength(10), Validators.required];
        // (<DynamicField>this.Form.ApplicantPhoneNumber).labelPosition.position = LabelPositions.Hidden;

        (<DynamicField>this.Form.HasTextCommunication).labelHtml = BuildDonorFormLabel('Is it OK to text you?');
        (<DynamicField>this.Form.HasTextCommunication).component = FormYesNoButtonComponent;

        (<DynamicField>this.Form.IsUsCitizen).labelHtml = BuildDonorFormLabel('Are you a US citizen?');
        (<DynamicField>this.Form.IsUsCitizen).component = FormYesNoButtonComponent;

        (<DynamicField>this.Form.GenderId).label = 'Sex';
        (<DynamicField>this.Form.GenderId).validation = [Validators.required];
        (<DynamicField>this.Form.GenderId).validators = { required: true };
        (<DynamicField>this.Form.GenderId).type.inputType = SelectInputTypes.RadioButtonList;

        (<DynamicField>this.Form.ReferralTypeId).validation = [Validators.required];
        (<DynamicField>this.Form.ReferralTypeId).validators = { required: true };

        (<DynamicField>this.Form.Comment).validation = [Validators.required];
        (<DynamicField>this.Form.Comment).validators = { required: true };

        let minYear = new Date().mtDate.subtract(80, DateParts.years).toMoment().year();
        (<DynamicField>this.Form.Dob).type.datepickerOptions.minDate = { year: minYear, day: 1, month: 1 };
        (<DynamicField>this.Form.Dob).validation = [Validators.required, this.dobValidator()];
        (<DynamicField>this.Form.Dob).validators = { required: true };
        (<DynamicField>this.Form.Dob).insideBoxValidation = true;
    }

    dobValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const enteredDOB = new Date(control.value);
            const adultDOB = new Date().mtDate.subtract(18, DateParts.years);
            return adultDOB.date < enteredDOB ? { DOB: 'Must be 18 or older to apply' } : null;
        };
    }
}
