import { BaseService } from '@mt-ng2/base-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IDocument } from '@model/interfaces/base';
import { IDocumentType } from '@model/interfaces/document-type';
import { IDonorDocument } from '@model/interfaces/donor-document';
import { DocumentEntities } from '@model/enums/document-entities.enum';

const ENDPOINT_MAPPING = {
    [DocumentEntities.Donors]: 'donor',
    [DocumentEntities.Clinics]: 'clinic',
    [DocumentEntities.Recipients]: 'recipient',
    [DocumentEntities.Users]: 'user',
    [DocumentEntities.GeneticScreening]: 'genetic-screening',
    [DocumentEntities.DonorTestSamples]: 'donor-test-samples',
    [DocumentEntities.DonorDonations]: 'donation-samples',
    [DocumentEntities.DonorOngoingScreenings]: 'donor-ongoing-screenings',
};

@Injectable({
    providedIn: 'root',
})
export class UploadDocumentsService extends BaseService<IDocument> {
    constructor(public http: HttpClient) {
        super('/documents', http);
    }

    private getUrlPrefix(entityType: number): string {
        return ENDPOINT_MAPPING[entityType];
    }

    getDocuments<t = IDonorDocument[]>(entityType: number, entityId: number): Observable<t> {
        return this.http.get<t>(`/${this.getUrlPrefix(entityType)}/documents/${entityId}`, {});
    }

    getDocumentById(entityType: number, entityId: number, documentId: number): Observable<Blob> {
        return this.http.get(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/${documentId}`, { responseType: 'blob' as 'blob' });
    }

    saveDocument(entityType: number, entityId: number, documentTypeId: number, file: File, fileName: string): any {
        let formData: FormData = new FormData();
        formData.append('file', file, fileName);
        return this.http
            .post(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/uploads/${documentTypeId}`, formData)
            .pipe(catchError(this.handleError));
    }

    getDocumentTypes(entityType: number, entityId: number): Observable<IDocumentType[]> {
        return this.http.get<IDocumentType[]>(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/types`, {});
    }

    deleteDocument(entityType: number, entityId: number, documentId: number): Observable<any> {
        return this.http.delete<any>(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/${documentId}`, {});
    }

    editDocumentDetails(entityType: number, entityId: number, doc: IDonorDocument): Observable<any> {
        return this.http.post<any>(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/${doc.DocumentId}`, doc);
    }

    createWithoutUpload(entityType: number, entityId: number, documentTypeId: number): any {
        return this.http
            .post(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/create-without-upload/`, documentTypeId)
            .pipe(catchError(this.handleError));
    }

    updateDocumentEntity(entityType: number, entityId: number, documentEntityId: number, file: File): any {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http
            .post(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/update-document-entity/${documentEntityId}`, formData)
            .pipe(catchError(this.handleError));
    }

    deleteWithoutDocument(entityType: number, entityId: number, documentEntityId: number): Observable<any> {
        return this.http.delete<any>(`/${this.getUrlPrefix(entityType)}/documents/${entityId}/delete-without-document/${documentEntityId}`, {});
    }
}
