<div *ngIf="!isEditing && donor.DonorIssue">
    <h4>
        Medical Issues
    </h4>
    <div>
        <div class="display-padded">
            <b>Is Taking Medication:</b>
            <span>{{ donor.DonorIssue.IsTakingMedication | yesNoPipe }}</span>
        </div>
        <div class="display-padded">
            <b>Current Medications:</b>
            <span>{{ donor.DonorIssue.CurrentMedications }}</span>
        </div>
        <div class="display-padded">
            <b>Has Surgeries:</b>
            <span>{{ donor.DonorIssue.HasSurgeries | yesNoPipe }}</span>
        </div>
        <div *ngIf="donor.DonorIssue.HasSurgeries" class="display-padded">
            <b>Surgeries:</b>
            <span>{{ donor.DonorIssue.Surgeries }}</span>
        </div>
        <div class="display-padded">
            <b>Has Taken Hgh:</b>
            <span>{{ donor.DonorIssue.HasTakenHgh | yesNoPipe }}</span>
        </div><div class="display-padded">
            <b>Has Had Major Radiation Exposure:</b>
            <span>{{ donor.DonorIssue.HasHadMajorRadiationExposure | yesNoPipe }}</span>
        </div><div class="display-padded">
            <b>Other Issues:</b>
            <span>{{ donor.DonorIssue.OtherIssues }}</span>
        </div><div class="display-padded">
            <b>Date Created:</b>
            <span>{{ donor.DonorIssue.DateCreated | date }}</span>
        </div><div class="display-padded">
            <b>Date Modified:</b>
            <span>{{ donor.DonorIssue.DateModified | date }}</span>
        </div>
        <div class="display-padded">
            <b>Completed:</b>
            <span>{{ donor.DonorIssue.Completed | date }}</span>
        </div>
        <div *ngFor="let type of medicalIssueTypes">
            <div class="form-section-border">
                <h3>{{ type.Name }}</h3>
            </div>
            <div *ngFor="let issue of getIssuesByTypeId(type.Id)">
                <donor-medical-issue [donorMedicalHistory]="issue" [donorRelationshipTypes]="donorRelationshipTypes">
                </donor-medical-issue>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isEditing">
    <h4>Medical Issues</h4>
    <medical-issues
    [donor]="donor"
    (onFormValueChanges)="onFormValueChanges($event)"
    ></medical-issues>
    <div>
        <button [disabled]="!formChanged" class="btn btn-success btn-flat" (click)="onSubmit()">
            Save
        </button>
        <button class="btn btn-default btn-flat" (click)="cancelClick()">
            Cancel
        </button>
    </div>
</div>
