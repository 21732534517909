<div class="row">
    <div class="col-md-6">
        <div
            class="miles-editable miles-card padded"
            *ngIf="!isEditing && donorSample.Id > 0"
            (mouseenter)="isHovered = true"
            (mouseleave)="isHovered = false"
            (click)="edit()"
            [style.cursor]="canEdit ? 'pointer' : 'default'"
        >
            <h4>
                Remainder Data
                <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
                    <i class="fa fa-lg fa-edit"></i>
                </span>
            </h4>
            <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
        </div>
        <div *ngIf="isEditing" class="miles-form padded">
            <h4>Remainder Data</h4>
            <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </app-dynamic-form>
        </div>
        <br />
        <app-donor-remainder-vial-details
            *ngIf="hasRemainder"
            [testSample]="donorSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="labDataService"
            [formControls]="dynamicControls"
        ></app-donor-remainder-vial-details>
    </div>
    <div class="col-md-6">
        <app-donor-remainder-vials
            *ngIf="hasRemainder"
            [testSample]="donorSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="labDataService"
            [formControls]="dynamicControls"
        ></app-donor-remainder-vials>
        <br />
        <app-donor-remainder-test-thaw
            *ngIf="hasRemainder"
            [testSample]="donorSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="labDataService"
            [formControls]="dynamicControls"
        ></app-donor-remainder-test-thaw>
        <br />
    </div>
</div>
