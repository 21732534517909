import { SampleTypes } from '@model/enums/sample-types.enum';
import { TestSampleTypes } from '@model/enums/test-sample-types.enum';
import { IMetaItem } from '@mt-ng2/base-service';
import { DynamicField, DynamicFieldTypes, DynamicLabel, InputTypes, noZeroRequiredValidator, SelectInputTypes } from '@mt-ng2/dynamic-form';

import { DonorSampleDynamicControls, IDonorSampleDynamicControlsParameters } from '../form-controls/donor-sample.form-controls';
import { IDonorSample } from '../interfaces/donor-sample';
import { DonorSampleStatuses } from '@model/enums/donor-sample-statuses.enum';

export interface IDonorTestSampleDynamicControlsPartialParameters extends IDonorSampleDynamicControlsParameters {
    sampleProcessorMetaItems?: IMetaItem[];
}
export class DonorTestSampleDynamicControlsPartial extends DonorSampleDynamicControls {
    constructor(donorSamplePartial?: IDonorSample, additionalParameters?: IDonorTestSampleDynamicControlsPartialParameters) {
        super(donorSamplePartial, additionalParameters);

        (<DynamicField>this.Form.SampleStatusId).value = donorSamplePartial?.SampleStatusId
            ? donorSamplePartial.SampleStatusId
            : DonorSampleStatuses.Pending;

        (<DynamicField>this.Form.TestSampleTypeId).validation = [noZeroRequiredValidator];
        (<DynamicField>this.Form.TestSampleTypeId).validators = { required: true };
        (<DynamicField>this.Form.TestSampleTypeId).labelHtml = '<label>Sample Type</label>';
        (<DynamicField>this.Form.TestSampleTypeId).value = donorSamplePartial?.TestSampleTypeId
            ? donorSamplePartial.TestSampleTypeId
            : TestSampleTypes.First;

        (<DynamicField>this.Form.TimeProcessed).type.inputType = InputTypes.Timepicker;
        (<DynamicField>this.Form.TimeProcessed).type.timepickerOptions = { spinners: false, seconds: false, meridian: true };
        (<DynamicField>this.Form.TimeProduced).type.inputType = InputTypes.Timepicker;
        (<DynamicField>this.Form.TimeProduced).type.timepickerOptions = { spinners: false, seconds: false, meridian: true };

        (<DynamicField>this.Form.SampleProcessorId).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.SampleProcessorId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.SampleProcessorId).options = additionalParameters.sampleProcessorMetaItems;

        (<DynamicLabel>this.View.SampleProcessorId).value = donorSamplePartial?.SampleProcessorId
            ? additionalParameters.sampleProcessorMetaItems?.find((s) => s.Id === donorSamplePartial.SampleProcessorId).Name
            : '';
        (<DynamicLabel>this.View.TestSampleTypeId).label = 'Sample Type';
    }
}
