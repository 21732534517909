import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { RecipientUserAddComponent } from './recipient-user-add/recipient-user-add.component';
import { RecipientUserDetailComponent } from './recipient-user-detail/recipient-user-detail.component';
import { RecipientUsersComponent } from './recipient-user-list/recipient-users.component';
import { RecipientUserService } from './services/recipient-user.service';
import { RecipientUserHeaderComponent } from './recipient-user-header/recipient-user-header.component';

import { ClaimTypes } from '@model/ClaimTypes';
import { SalesOrderDetailComponent } from './sales-orders/sales-order-detail/sales-order-detail.component';
import { SalesOrderService } from './services/sales-order.service';
import { SalesOrderAddComponent } from './sales-orders/sales-order-add/sales-order-add.component';

const recipientUserEntityConfig = {
    claimType: ClaimTypes.RecipientUsers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'recipientUserId',
    service: RecipientUserService,
    title: 'RecipientUser Detail',
};

const salesOrderEntityConfig = {
    claimType: ClaimTypes.RecipientUsers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'salesOrderId',
    service: SalesOrderService,
    title: 'Sales Order Detail',
};

const recipientUserListRoleGuard: any = {
    claimType: ClaimTypes.RecipientUsers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'RecipientUsers',
};

const recipientUserAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.RecipientUsers,
    claimValues: [ClaimValues.FullAccess],
};

export const recipientUserPathSlugs = {
    add: 'add',
    root: 'recipient-users',
    salesOrderDetails: 'sales-orders',
};

export const recipientUserPaths = {
    recipientUsers: recipientUserPathSlugs.root,
    recipientUsersAdd: `${recipientUserPathSlugs.root}/${recipientUserPathSlugs.add}`,
};

const recipientUserRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: RecipientUsersComponent,
        data: recipientUserListRoleGuard,
        path: '',
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: RecipientUserAddComponent, path: '', pathMatch: 'full' }],
        component: RecipientUserHeaderComponent,
        data: recipientUserAddRoleGuard,
        path: recipientUserPathSlugs.add,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: SalesOrderAddComponent, path: '', pathMatch: 'full' }],
        component: RecipientUserHeaderComponent,
        data: recipientUserAddRoleGuard,
        path: `:${recipientUserEntityConfig.entityIdParam}/${recipientUserPathSlugs.salesOrderDetails}/add`,
    },
    {
        canActivate: [AuthGuard],
        children: [
            { component: RecipientUserDetailComponent, path: '', pathMatch: 'full' },
            {
                component: SalesOrderDetailComponent,
                path: `${recipientUserPathSlugs.salesOrderDetails}/:${salesOrderEntityConfig.entityIdParam}`,
                pathMatch: 'full',
            },
        ],
        component: RecipientUserHeaderComponent,
        data: recipientUserEntityConfig,
        path: `:${recipientUserEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(recipientUserRoutes)],
})
export class RecipientUserRoutingModule {}
