import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IClinic } from '@model/interfaces/clinic';
import { IUser } from '@model/interfaces/user';
import { IRecipient } from '@model/interfaces/recipient';
import { IAddress } from '@model/interfaces/address';
import { IAddressResponse } from '@model/interfaces/custom/address-response';

export const emptyRecipient = {
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class RecipientService {
    constructor(public http: HttpClient) {
    }

    getClinics(): Observable<IClinic[]> {
        return this.http.get<IClinic[]>(`/recipients/clinics`);
    }

    getAccountManagers(): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/recipients/account-managers`);
    }

    getEmptyRecipient(id = 0): IRecipient {
        return { ...emptyRecipient, ...{ Id: id } };
    }

    saveAddress(recipientId: number, address: IAddress): Observable<IAddressResponse> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<IAddressResponse>(`/recipients/${recipientId}/address`, address);
        } else {
            return this.http.put<IAddressResponse>(`/recipients/${recipientId}/address`, address);
        }
    }

    deleteAddress(recipientId: number): Observable<object> {
        return this.http.delete('/recipients/' + recipientId + '/address', {
            responseType: 'text' as 'json',
        });
    }
}
