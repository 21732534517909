import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ISalesOrder } from '@model/interfaces/sales-order';
import { SalesOrderStatuses } from '@model/enums/sales-order-statuses.enum';
import { IVwDonorInventory } from '@model/interfaces/vw-donor-inventory';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IThawing } from '@model/interfaces/thawing';
import { IShipping } from '@model/interfaces/shipping';
import { LocalizeDatesByName } from '@angular-common/libraries/treat-date-as-local.library';
import { MetaItem } from '@mt-ng2/base-service';

export const emptySalesOrder: ISalesOrder = {
    CreatedDate: null,
    Id: 0,
    ProductTypeId: 0,
    RecipientId: 0,
    ReplacementOrder: null,
    StatusId: 0,
    VialStatusId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class SalesOrderService extends BaseService<ISalesOrder> {
    constructor(public http: HttpClient) {
        super('/sales-orders', http);
    }

    getSHSBDF3Pdf(salesOrderId: number): Observable<any> {
        return this.http.get(`/sales-orders/${salesOrderId}/SHSBDF3-pdf`, {
            responseType: 'blob' as 'blob',
        });
    }

    getSHSBDF2Pdf(salesOrderId: number): Observable<any> {
        return this.http.get(`/sales-orders/${salesOrderId}/SHSBDF2-pdf`, {
            responseType: 'blob' as 'blob',
        });
    }

    getSHSBDF9Pdf(salesOrderId: number): Observable<any> {
        return this.http.get(`/sales-orders/${salesOrderId}/SHSBDF9-pdf`, {
            responseType: 'blob' as 'blob',
        });
    }

    getSHSBDF8Pdf(salesOrderId: number): Observable<any> {
        return this.http.get(`/sales-orders/${salesOrderId}/SHSBDF8-pdf`, {
            responseType: 'blob' as 'blob',
        });
    }

    getSHSBDF6Pdf(salesOrderId: number): Observable<any> {
        return this.http.get(`/sales-orders/${salesOrderId}/SHSBDF6-pdf`, {
            responseType: 'blob' as 'blob',
        });
    }

    getEmptySalesOrder(recipientId: number): ISalesOrder {
        return { ...emptySalesOrder, RecipientId: recipientId, CreatedDate: new Date(), StatusId: SalesOrderStatuses.ContractedOut };
    }

    AddDonorAndInventory(salesOrderId: number, donorId: number, inventory: IVwDonorInventory[]): Observable<ISalesOrder> {
        return this.http.post<ISalesOrder>(`/sales-orders/${salesOrderId}/donors/${donorId}`, inventory).pipe(
            tap((so) => {
                this.emitChange(so);
            }),
        );
    }

    RemoveDonorAndInventory(salesOrderId: number, donorId: number): Observable<ISalesOrder> {
        return this.http.delete<ISalesOrder>(`/sales-orders/${salesOrderId}/donors/${donorId}`).pipe(
            tap((so) => {
                this.emitChange(so);
            }),
        );
    }

    UpdateSalesOrderInventory(salesOrderId: number, inventory: IVwDonorInventory[]): Observable<IVwDonorInventory[]> {
        return this.http.post<IVwDonorInventory[]>(`/sales-orders/${salesOrderId}/inventory`, inventory).pipe(
            map((invs) => {
                return invs.map((i) => LocalizeDatesByName(i, ['DonationDate', 'ReleaseDate']));
            }),
        );
    }

    GetSalesOrderInventory(salesOrderId: number): Observable<IVwDonorInventory[]> {
        return this.http.get<IVwDonorInventory[]>(`/sales-orders/${salesOrderId}/inventory`).pipe(
            map((invs) => {
                return invs.map((i) => LocalizeDatesByName(i, ['DonationDate', 'ReleaseDate']));
            }),
        );
    }

    GetThawingsBySalesOrderId(salesOrderId: number): Observable<IThawing[]> {
        return this.http.get<IThawing[]>(`/sales-orders/${salesOrderId}/thawings`);
    }

    GetSalesOrderDonorIds(salesOrderId: number): Observable<MetaItem[]> {
        return this.http.get<MetaItem[]>(`/sales-orders/${salesOrderId}/sales-order-donors`);
    }

    getShippingDetailsBySalesOrderId(salesOrderId: number): Observable<IShipping> {
        return this.http.get<IShipping>(`/sales-orders/${salesOrderId}/shipping`);
    }
}
