import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { RecipientUserService } from '../services/recipient-user.service';
import { IRecipientUser } from '@model/interfaces/recipient-user';
import { ClaimTypes } from '@model/ClaimTypes';
import { ISendEmailTemplateModalApi } from '@common/components/send-email/send-email-template.component';
import { IEmail, ISendEmailModalResult } from '@mt-ng2/email-modal-module';
import { TemplateTypes } from '@model/enums/custom/template-types.enum';
import { NoteEntities } from '@model/enums/note-entities.enum';
import { DocumentEntities } from '@model/enums/document-entities.enum';
import { RecipientService } from '../services/recipient.service';

@Component({
    templateUrl: './recipient-user-detail.component.html',
})
export class RecipientUserDetailComponent implements OnInit {
    recipientUser: IRecipientUser;
    canEdit = false;
    canAdd: boolean;

    emailModalApi: ISendEmailTemplateModalApi;
    welcomeEmailTemplateId = TemplateTypes.RecipientsWelcomeEmail;
    noteEntity = NoteEntities.Recipient;
    claimType = ClaimTypes.RecipientUsers;
    docEntityType = DocumentEntities.Recipients;

    constructor(
        private recipientUserService: RecipientUserService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        private recipientService: RecipientService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.RecipientUsers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'recipientUserId');
        if (id) {
            this.getRecipientUserById(id);
        } else {
            this.router.navigate(['recipientUsers']); // if no id found, go back to list
        }
    }

    getRecipientUserById(id: number): void {
        this.recipientUserService.getById(id).subscribe((recipientUser) => {
            if (recipientUser === null) {
                this.notificationsService.error('RecipientUser not found');
                this.router.navigate(['recipientUsers']);
            }
            this.recipientUser = recipientUser;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        let id = route.snapshot.paramMap.get(param);
        return parseInt(id, 10) === NaN ? null : parseInt(id, 10);
    }

    showEmailModal(): void {
        const notificationOptions = { RecipientId: this.recipientUser.Id };
        this.emailModalApi.show(this.recipientUser.Email, this.welcomeEmailTemplateId, notificationOptions).subscribe((response: ISendEmailModalResult) => {
            if (!response.cancelled) {
                this.sendWelcomeEmail(response.email);
            }
        });
    }

    sendWelcomeEmail(emailData: IEmail): void {
        const emailDto = { ...emailData, TemplateId: this.welcomeEmailTemplateId };
        this.recipientUserService.sendWelcomeEmail(this.recipientUser.Id, emailDto).subscribe((answer) => {
            this.notificationsService.success('Email sent successfully');
            this.recipientUser.Recipient = answer;
        });
    }

    saveAddress(address): void {
        this.recipientService.saveAddress(this.recipientUser.Recipient.Id, address).subscribe((answer) => {
            if (answer?.AddressId) {
                address.Id = answer.AddressId;
            }
            this.notificationsService.success('Address Saved Successfully');
            this.recipientUser.Recipient.Address = address;
        });
    }

    deleteAddress(address): void {
        this.recipientService.deleteAddress(this.recipientUser.Recipient.Id).subscribe((answer) => {
            this.notificationsService.success('Address Deleted Successfully');
            this.recipientUser.Recipient.Address = null;
        });
    }
}
