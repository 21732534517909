import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorSample } from '../interfaces/donor-sample';
import { IUser } from '../interfaces/user';
import { IDonationStatus } from '../interfaces/donation-status';
import { IDonor } from '../interfaces/donor';
import { IDonorOngoingScreening } from '../interfaces/donor-ongoing-screening';
import { ISamplePrepMethod } from '../interfaces/sample-prep-method';
import { IDonorSampleStatus } from '../interfaces/donor-sample-status';
import { ISampleType } from '../interfaces/sample-type';
import { ITestSampleType } from '../interfaces/test-sample-type';

export interface IDonorSampleDynamicControlsParameters {
    formGroup?: string;
    donors?: IDonor[];
    sampleTypes?: ISampleType[];
    testSampleTypes?: ITestSampleType[];
    donationStatuses?: IDonationStatus[];
    sampleStatuses?: IDonorSampleStatus[];
    prepMethods?: ISamplePrepMethod[];
    sampleProcessors?: IUser[];
    sampleStatusModifiedBies?: IUser[];
    remainderPrepMethods?: ISamplePrepMethod[];
    remainderLabStatuses?: IDonorSampleStatus[];
    abbreviatedScreeningModifiedBies?: IUser[];
    initialScreeningTestings?: IDonorOngoingScreening[];
    followUpScreeningTestings?: IDonorOngoingScreening[];
    preQuarantineTestings?: IDonorOngoingScreening[];
    quarantineReleaseTestings?: IDonorOngoingScreening[];
}

export class DonorSampleDynamicControls {

    formGroup: string;
    donors: IDonor[];
    sampleTypes: ISampleType[];
    testSampleTypes: ITestSampleType[];
    donationStatuses: IDonationStatus[];
    sampleStatuses: IDonorSampleStatus[];
    prepMethods: ISamplePrepMethod[];
    sampleProcessors: IUser[];
    sampleStatusModifiedBies: IUser[];
    remainderPrepMethods: ISamplePrepMethod[];
    remainderLabStatuses: IDonorSampleStatus[];
    abbreviatedScreeningModifiedBies: IUser[];
    initialScreeningTestings: IDonorOngoingScreening[];
    followUpScreeningTestings: IDonorOngoingScreening[];
    preQuarantineTestings: IDonorOngoingScreening[];
    quarantineReleaseTestings: IDonorOngoingScreening[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorsample?: IDonorSample, additionalParameters?: IDonorSampleDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorSample';
        this.donors = additionalParameters && additionalParameters.donors || undefined;
        this.sampleTypes = additionalParameters && additionalParameters.sampleTypes || undefined;
        this.testSampleTypes = additionalParameters && additionalParameters.testSampleTypes || undefined;
        this.donationStatuses = additionalParameters && additionalParameters.donationStatuses || undefined;
        this.sampleStatuses = additionalParameters && additionalParameters.sampleStatuses || undefined;
        this.prepMethods = additionalParameters && additionalParameters.prepMethods || undefined;
        this.sampleProcessors = additionalParameters && additionalParameters.sampleProcessors || undefined;
        this.sampleStatusModifiedBies = additionalParameters && additionalParameters.sampleStatusModifiedBies || undefined;
        this.remainderPrepMethods = additionalParameters && additionalParameters.remainderPrepMethods || undefined;
        this.remainderLabStatuses = additionalParameters && additionalParameters.remainderLabStatuses || undefined;
        this.abbreviatedScreeningModifiedBies = additionalParameters && additionalParameters.abbreviatedScreeningModifiedBies || undefined;
        this.initialScreeningTestings = additionalParameters && additionalParameters.initialScreeningTestings || undefined;
        this.followUpScreeningTestings = additionalParameters && additionalParameters.followUpScreeningTestings || undefined;
        this.preQuarantineTestings = additionalParameters && additionalParameters.preQuarantineTestings || undefined;
        this.quarantineReleaseTestings = additionalParameters && additionalParameters.quarantineReleaseTestings || undefined;

        this.Form = {
            AbbreviatedScreeningComplete: new DynamicField({
                formGroup: this.formGroup,
                label: 'Abbreviated Screening Complete',
                name: 'AbbreviatedScreeningComplete',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.hasOwnProperty('AbbreviatedScreeningComplete') && this.donorsample.AbbreviatedScreeningComplete !== null ? this.donorsample.AbbreviatedScreeningComplete : false,
            }),
            AbbreviatedScreeningModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Abbreviated Screening Modified By',
                name: 'AbbreviatedScreeningModifiedById',
                options: this.abbreviatedScreeningModifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.AbbreviatedScreeningModifiedById || null,
            }),
            AbbreviatedScreeningModifiedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Abbreviated Screening Modified Date',
                name: 'AbbreviatedScreeningModifiedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.AbbreviatedScreeningModifiedDate || null,
            }),
            DonationStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donation Status',
                name: 'DonationStatusId',
                options: this.donationStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.DonationStatusId || null,
            }),
            DonorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor',
                name: 'DonorId',
                options: this.donors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorsample && this.donorsample.DonorId || null,
            }),
            EligibilityDeterminationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Eligibility Determination Date',
                name: 'EligibilityDeterminationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.EligibilityDeterminationDate || null,
            }),
            FollowUpScreeningTestingId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Follow Up Screening Testing',
                name: 'FollowUpScreeningTestingId',
                options: this.followUpScreeningTestings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.FollowUpScreeningTestingId || null,
            }),
            HasRemainder: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Remainder',
                name: 'HasRemainder',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.hasOwnProperty('HasRemainder') && this.donorsample.HasRemainder !== null ? this.donorsample.HasRemainder : false,
            }),
            HoursOfAbstinence: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hours Of Abstinence',
                name: 'HoursOfAbstinence',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.HoursOfAbstinence || null,
            }),
            InitialScreeningTestingId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Initial Screening Testing',
                name: 'InitialScreeningTestingId',
                options: this.initialScreeningTestings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.InitialScreeningTestingId || null,
            }),
            InitialsOfReleasingPerson: new DynamicField({
                formGroup: this.formGroup,
                label: 'Initials Of Releasing Person',
                name: 'InitialsOfReleasingPerson',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3) ],
                validators: { 'maxlength': 3 },
                value: this.donorsample && this.donorsample.hasOwnProperty('InitialsOfReleasingPerson') && this.donorsample.InitialsOfReleasingPerson !== null ? this.donorsample.InitialsOfReleasingPerson.toString() : '',
            }),
            PrepMethodId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Prep Method',
                name: 'PrepMethodId',
                options: this.prepMethods,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.PrepMethodId || null,
            }),
            PreQuarantineTestingId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pre Quarantine Testing',
                name: 'PreQuarantineTestingId',
                options: this.preQuarantineTestings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.PreQuarantineTestingId || null,
            }),
            QuarantineReleaseTestingId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Quarantine Release Testing',
                name: 'QuarantineReleaseTestingId',
                options: this.quarantineReleaseTestings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.QuarantineReleaseTestingId || null,
            }),
            RemainderLabStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Remainder Lab Status',
                name: 'RemainderLabStatusId',
                options: this.remainderLabStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.RemainderLabStatusId || null,
            }),
            RemainderPrepMethodId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Remainder Prep Method',
                name: 'RemainderPrepMethodId',
                options: this.remainderPrepMethods,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.RemainderPrepMethodId || null,
            }),
            SampleDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sample Date',
                name: 'SampleDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorsample && this.donorsample.SampleDate || null,
            }),
            SampleProcessorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sample Processor',
                name: 'SampleProcessorId',
                options: this.sampleProcessors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.SampleProcessorId || null,
            }),
            SampleStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sample Status',
                name: 'SampleStatusId',
                options: this.sampleStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorsample && this.donorsample.SampleStatusId || null,
            }),
            SampleStatusModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sample Status Modified By',
                name: 'SampleStatusModifiedById',
                options: this.sampleStatusModifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.SampleStatusModifiedById || null,
            }),
            SampleStatusModifiedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sample Status Modified Date',
                name: 'SampleStatusModifiedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.SampleStatusModifiedDate || null,
            }),
            SampleTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sample Type',
                name: 'SampleTypeId',
                options: this.sampleTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.SampleTypeId || 1,
            }),
            TestSampleTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Test Sample Type',
                name: 'TestSampleTypeId',
                options: this.testSampleTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.TestSampleTypeId || null,
            }),
            TimeProcessed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Time Processed',
                name: 'TimeProcessed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.hasOwnProperty('TimeProcessed') && this.donorsample.TimeProcessed !== null ? this.donorsample.TimeProcessed.toString() : '',
            }),
            TimeProduced: new DynamicField({
                formGroup: this.formGroup,
                label: 'Time Produced',
                name: 'TimeProduced',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.hasOwnProperty('TimeProduced') && this.donorsample.TimeProduced !== null ? this.donorsample.TimeProduced.toString() : '',
            }),
            TissueEligibleForTransfer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tissue Eligible For Transfer',
                name: 'TissueEligibleForTransfer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.hasOwnProperty('TissueEligibleForTransfer') && this.donorsample.TissueEligibleForTransfer !== null ? this.donorsample.TissueEligibleForTransfer : false,
            }),
            Volume: new DynamicField({
                formGroup: this.formGroup,
                label: 'Volume',
                name: 'Volume',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsample && this.donorsample.Volume || null,
            }),
        };

        this.View = {
            AbbreviatedScreeningComplete: new DynamicLabel(
                'Abbreviated Screening Complete',
                this.donorsample && this.donorsample.hasOwnProperty('AbbreviatedScreeningComplete') && this.donorsample.AbbreviatedScreeningComplete !== null ? this.donorsample.AbbreviatedScreeningComplete : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            AbbreviatedScreeningModifiedById: new DynamicLabel(
                'Abbreviated Screening Modified By',
                getMetaItemValue(this.abbreviatedScreeningModifiedBies, this.donorsample && this.donorsample.hasOwnProperty('AbbreviatedScreeningModifiedById') && this.donorsample.AbbreviatedScreeningModifiedById !== null ? this.donorsample.AbbreviatedScreeningModifiedById : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            AbbreviatedScreeningModifiedDate: new DynamicLabel(
                'Abbreviated Screening Modified Date',
                this.donorsample && this.donorsample.AbbreviatedScreeningModifiedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DonationStatusId: new DynamicLabel(
                'Donation Status',
                getMetaItemValue(this.donationStatuses, this.donorsample && this.donorsample.hasOwnProperty('DonationStatusId') && this.donorsample.DonationStatusId !== null ? this.donorsample.DonationStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            DonorId: new DynamicLabel(
                'Donor',
                getMetaItemValue(this.donors, this.donorsample && this.donorsample.hasOwnProperty('DonorId') && this.donorsample.DonorId !== null ? this.donorsample.DonorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            EligibilityDeterminationDate: new DynamicLabel(
                'Eligibility Determination Date',
                this.donorsample && this.donorsample.EligibilityDeterminationDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            FollowUpScreeningTestingId: new DynamicLabel(
                'Follow Up Screening Testing',
                getMetaItemValue(this.followUpScreeningTestings, this.donorsample && this.donorsample.hasOwnProperty('FollowUpScreeningTestingId') && this.donorsample.FollowUpScreeningTestingId !== null ? this.donorsample.FollowUpScreeningTestingId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasRemainder: new DynamicLabel(
                'Has Remainder',
                this.donorsample && this.donorsample.hasOwnProperty('HasRemainder') && this.donorsample.HasRemainder !== null ? this.donorsample.HasRemainder : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HoursOfAbstinence: new DynamicLabel(
                'Hours Of Abstinence',
                this.donorsample && this.donorsample.HoursOfAbstinence || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            InitialScreeningTestingId: new DynamicLabel(
                'Initial Screening Testing',
                getMetaItemValue(this.initialScreeningTestings, this.donorsample && this.donorsample.hasOwnProperty('InitialScreeningTestingId') && this.donorsample.InitialScreeningTestingId !== null ? this.donorsample.InitialScreeningTestingId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            InitialsOfReleasingPerson: new DynamicLabel(
                'Initials Of Releasing Person',
                this.donorsample && this.donorsample.hasOwnProperty('InitialsOfReleasingPerson') && this.donorsample.InitialsOfReleasingPerson !== null ? this.donorsample.InitialsOfReleasingPerson.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            PrepMethodId: new DynamicLabel(
                'Prep Method',
                getMetaItemValue(this.prepMethods, this.donorsample && this.donorsample.hasOwnProperty('PrepMethodId') && this.donorsample.PrepMethodId !== null ? this.donorsample.PrepMethodId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            PreQuarantineTestingId: new DynamicLabel(
                'Pre Quarantine Testing',
                getMetaItemValue(this.preQuarantineTestings, this.donorsample && this.donorsample.hasOwnProperty('PreQuarantineTestingId') && this.donorsample.PreQuarantineTestingId !== null ? this.donorsample.PreQuarantineTestingId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            QuarantineReleaseTestingId: new DynamicLabel(
                'Quarantine Release Testing',
                getMetaItemValue(this.quarantineReleaseTestings, this.donorsample && this.donorsample.hasOwnProperty('QuarantineReleaseTestingId') && this.donorsample.QuarantineReleaseTestingId !== null ? this.donorsample.QuarantineReleaseTestingId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            RemainderLabStatusId: new DynamicLabel(
                'Remainder Lab Status',
                getMetaItemValue(this.remainderLabStatuses, this.donorsample && this.donorsample.hasOwnProperty('RemainderLabStatusId') && this.donorsample.RemainderLabStatusId !== null ? this.donorsample.RemainderLabStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            RemainderPrepMethodId: new DynamicLabel(
                'Remainder Prep Method',
                getMetaItemValue(this.remainderPrepMethods, this.donorsample && this.donorsample.hasOwnProperty('RemainderPrepMethodId') && this.donorsample.RemainderPrepMethodId !== null ? this.donorsample.RemainderPrepMethodId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SampleDate: new DynamicLabel(
                'Sample Date',
                this.donorsample && this.donorsample.SampleDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            SampleProcessorId: new DynamicLabel(
                'Sample Processor',
                getMetaItemValue(this.sampleProcessors, this.donorsample && this.donorsample.hasOwnProperty('SampleProcessorId') && this.donorsample.SampleProcessorId !== null ? this.donorsample.SampleProcessorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SampleStatusId: new DynamicLabel(
                'Sample Status',
                getMetaItemValue(this.sampleStatuses, this.donorsample && this.donorsample.hasOwnProperty('SampleStatusId') && this.donorsample.SampleStatusId !== null ? this.donorsample.SampleStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SampleStatusModifiedById: new DynamicLabel(
                'Sample Status Modified By',
                getMetaItemValue(this.sampleStatusModifiedBies, this.donorsample && this.donorsample.hasOwnProperty('SampleStatusModifiedById') && this.donorsample.SampleStatusModifiedById !== null ? this.donorsample.SampleStatusModifiedById : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SampleStatusModifiedDate: new DynamicLabel(
                'Sample Status Modified Date',
                this.donorsample && this.donorsample.SampleStatusModifiedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            SampleTypeId: new DynamicLabel(
                'Sample Type',
                getMetaItemValue(this.sampleTypes, this.donorsample && this.donorsample.hasOwnProperty('SampleTypeId') && this.donorsample.SampleTypeId !== null ? this.donorsample.SampleTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TestSampleTypeId: new DynamicLabel(
                'Test Sample Type',
                getMetaItemValue(this.testSampleTypes, this.donorsample && this.donorsample.hasOwnProperty('TestSampleTypeId') && this.donorsample.TestSampleTypeId !== null ? this.donorsample.TestSampleTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TimeProcessed: new DynamicLabel(
                'Time Processed',
                this.donorsample && this.donorsample.hasOwnProperty('TimeProcessed') && this.donorsample.TimeProcessed !== null ? this.donorsample.TimeProcessed.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TimeProduced: new DynamicLabel(
                'Time Produced',
                this.donorsample && this.donorsample.hasOwnProperty('TimeProduced') && this.donorsample.TimeProduced !== null ? this.donorsample.TimeProduced.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TissueEligibleForTransfer: new DynamicLabel(
                'Tissue Eligible For Transfer',
                this.donorsample && this.donorsample.hasOwnProperty('TissueEligibleForTransfer') && this.donorsample.TissueEligibleForTransfer !== null ? this.donorsample.TissueEligibleForTransfer : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Volume: new DynamicLabel(
                'Volume',
                this.donorsample && this.donorsample.Volume || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
        };

    }
}
