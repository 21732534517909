import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IModalOptions } from '@mt-ng2/modal-module';

import { IOutcome } from '@model/interfaces/outcome';
import { OutcomeDynamicControlsPartial } from '@model/partials/outcome-partial.form-controls';

import { SalesOrderOutcomeService } from './services/sales-order-outcome.service';
import { OutcomeOptionService } from './services/outcome-option.service';
import { ThawingService } from '../../thawings/services/thawing.service';
import { OutcomeStateService } from '../outcome.state.service';

@Component({
    selector: 'app-sales-order-outcome',
    templateUrl: './outcome.component.html',
})
export class SalesOrderOutcomeComponent implements OnInit {
    @Input() salesOrderId: number;
    @Input() canEdit: boolean;
    @Input() thawingId: number;

    // abstract controls
    abstractOutcomeControls: any;

    isEditing = false;

    numberOfEggsTransferred: number;
    outcomeDetails: IOutcome;
    outcomeForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    subs = new Subscription();

    finalizeConfirmOptions: IModalOptions = {
        confirmButtonText: 'Finalize',
        text: 'Details cannot be edited after finalizing',
        type: 'question',
    };
    canFinalize: boolean;

    isReadOnly: boolean;

    get isNewOutcome(): boolean {
        return this.outcomeDetails && this.outcomeDetails.Id ? false : true;
    }

    get isFinalized(): boolean {
        return this.outcomeDetails?.IsFinalized;
    }

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private salesOrderOutcomeService: SalesOrderOutcomeService,
        private outcomesService: OutcomeOptionService,
        private notificationsService: NotificationsService,
        private thawingService: ThawingService,
        private outcomeStateService: OutcomeStateService,
    ) {}

    ngOnInit(): void {
        if (this.salesOrderId) {
            forkJoin([this.thawingService.getOutcomeByThawingId(this.thawingId), this.outcomesService.getItems()]).subscribe(([soOutcome, _]) => {
                this.outcomeDetails = soOutcome ? soOutcome : this.salesOrderOutcomeService.getEmptySalesOrderOutcome(this.thawingId);
                this.setIsReadOnly();
                this.outcomeStateService.outcome$.next(this.outcomeDetails);
                this.createForm();
            });
        }
        this.subs.add(
            this.outcomeStateService.isInvestigationComplete$.subscribe((complete) => {
                this.canFinalize = complete;
            }),
        );
    }

    setIsReadOnly(): void {
        this.isReadOnly = !this.canEdit || this.isFinalized;
    }

    createForm(): void {
        this.getControls();
        this.outcomeForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Outcome: this.fb.group({}),
        });
    }

    getControls(): void {
        this.abstractOutcomeControls = new OutcomeDynamicControlsPartial(this.outcomeDetails, {
            formGroup: 'Outcome',
            outcomeOptions: this.outcomesService.items,
        });
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    formSubmitted(): void {
        if (this.outcomeForm.valid) {
            // setValue (above) is not included when using .value
            Object.assign(this.outcomeDetails, this.outcomeForm.getRawValue().Outcome);
            this.saveOutcome();
        } else {
            markAllFormFieldsAsTouched(this.outcomeForm);
            this.notificationsService.error('Save failed. Please check the form and try again.');
            this.enableDoubleClick();
        }
    }

    private saveOutcome(): void {
        if (this.isNewOutcome) {
            this.salesOrderOutcomeService
                .create(this.outcomeDetails)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.outcomeDetails.Id = answer;
                    this.success();
                });
        } else {
            this.salesOrderOutcomeService
                .update(this.outcomeDetails)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((success) => {
                    this.success();
                });
        }
    }

    finalizeOutcome(value: any): void {
        this.outcomeDetails.IsFinalized = true;
        this.formSubmitted();
    }

    private success(): void {
        this.notificationsService.success('Outcome saved successfully.');
        this.setIsReadOnly();
        this.salesOrderOutcomeService.emitChange(this.outcomeDetails);
        this.outcomeStateService.outcome$.next(this.outcomeDetails);
        this.getControls();
        this.isEditing = false;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
    }
}
