import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IVwDonorInventory } from '@model/interfaces/vw-donor-inventory';

import { IEntity, IEntityListComponentMembers, IEntityListDynamicCellComponent } from '@mt-ng2/entity-list-module';

@Component({
    template: `
        <a
            ><span (click)="navigateToSalesOrder()">{{ donorInventory.SalesOrderId }}</span></a
        >
    `,
})
export class SalesOrderCellComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;

    set entity(value: IEntity) {
        this.donorInventory = value as any;
    }

    donorInventory: IVwDonorInventory;

    constructor(private router: Router) {}

    navigateToSalesOrder(): void {
        this.router.navigate([`/recipient-users/${this.donorInventory.SalesOrderRecipientId}/sales-orders/${this.donorInventory.SalesOrderId}`]);
    }
}
