import { Component } from '@angular/core';
import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';

import { AbstractLabDataFormComponent } from '../base-components/abstract-lab-data-form.component';

@Component({
    selector: 'app-donor-remainder-vials',
    templateUrl: './remainder-vials.component.html',
})
export class RemainderVialsComponent extends AbstractLabDataFormComponent {
    sectionId = DonorTestSampleLabDataSections.RemainderVials;
}
