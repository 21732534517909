import { DynamicField, LabelPosition, LabelPositions } from '@mt-ng2/dynamic-form';
import { FormYesNoButtonComponent } from '@angular-common/components/form-yes-no-button.component';
import { BuildDonorFormLabel } from '@angular-common/libraries/build-donor-form-label.library';
import { DonorIssueDynamicControls, IDonorIssueDynamicControlsParameters } from '../form-controls/donor-issue.form-controls';
import { IDonorIssue } from '../interfaces/donor-issue';
import { Validators } from '@angular/forms';

export class DonorIssueDynamicControlsPartial extends DonorIssueDynamicControls {

    constructor(donorissuePartial?: IDonorIssue, additionalParameters?: IDonorIssueDynamicControlsParameters) {
        super(donorissuePartial, additionalParameters);

        (<DynamicField>this.Form.IsTakingMedication).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.IsTakingMedication).labelHtml = BuildDonorFormLabel(`Are you taking any medications?`);
        (<DynamicField>this.Form.IsTakingMedication).value = donorissuePartial.IsTakingMedication;
        (<DynamicField>this.Form.IsTakingMedication).validation = [Validators.required];
        (<DynamicField>this.Form.IsTakingMedication).validators = { required: true};
        (<DynamicField>this.Form.CurrentMedications).placeholder = 'List all medications';
        (<DynamicField>this.Form.CurrentMedications).validation = [Validators.required];
        (<DynamicField>this.Form.CurrentMedications).validators = { required: true};

        (<DynamicField>this.Form.HasSurgeries).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasSurgeries).labelHtml = BuildDonorFormLabel(`Have you had any surgeries in the past?`);
        (<DynamicField>this.Form.HasSurgeries).value = donorissuePartial.HasSurgeries;
        (<DynamicField>this.Form.HasSurgeries).validation = [Validators.required];
        (<DynamicField>this.Form.HasSurgeries).validators = { required: true};
        (<DynamicField>this.Form.Surgeries).placeholder = 'List surgery and year';
        (<DynamicField>this.Form.Surgeries).validation = [Validators.required];
        (<DynamicField>this.Form.Surgeries).validators = { required: true};

        (<DynamicField>this.Form.HasTakenHgh).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasTakenHgh).labelHtml = BuildDonorFormLabel(`Have you ever taken Human Growth Hormone?`);
        (<DynamicField>this.Form.HasTakenHgh).value = donorissuePartial.HasTakenHgh;
        (<DynamicField>this.Form.HasTakenHgh).validation = [Validators.required];
        (<DynamicField>this.Form.HasTakenHgh).validators = { required: true};

        (<DynamicField>this.Form.HasHadMajorRadiationExposure).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasHadMajorRadiationExposure).labelHtml = BuildDonorFormLabel(`Have you ever had major radiation exposure or X-ray exposure?`);
        (<DynamicField>this.Form.HasHadMajorRadiationExposure).value = donorissuePartial.HasHadMajorRadiationExposure;
        (<DynamicField>this.Form.HasHadMajorRadiationExposure).validation = [Validators.required];
        (<DynamicField>this.Form.HasHadMajorRadiationExposure).validators = { required: true};

        (<DynamicField>this.Form.OtherIssues).placeholder = 'List any other conditions not mentioned above';

    }
}
