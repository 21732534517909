import { SalesOrderDynamicControls, ISalesOrderDynamicControlsParameters } from '../form-controls/sales-order.form-controls';
import { ISalesOrder } from '../interfaces/sales-order';
import { ISalesOrderStatus } from '@model/interfaces/sales-order-status';
import { ISamplePrepMethod } from '@model/interfaces/sample-prep-method';
import { Validators } from '@angular/forms';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

export interface ISalesOrderDynamicControlsParametersPartial extends ISalesOrderDynamicControlsParameters {
    productTypes?: ISamplePrepMethod[];
    salesOrderStatuses?: ISalesOrderStatus[];
}

export class SalesOrderDynamicControlsPartial extends SalesOrderDynamicControls {
    constructor(salesorderPartial?: ISalesOrder, additionalParameters?: ISalesOrderDynamicControlsParametersPartial, isNew?: boolean) {
        super(salesorderPartial, additionalParameters);

        // Form

        (<DynamicField>this.Form.Id) = new DynamicField({
            formGroup: this.formGroup,
            label: 'ID',
            name: 'Id',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: (salesorderPartial && salesorderPartial.Id) || null,
        });
        (<DynamicField>this.Form.Id).doNotCreateControl = true;

        (<DynamicField>this.Form.CreatedDate).doNotCreateControl = true;

        (<DynamicField>this.Form.StatusId).options = additionalParameters.salesOrderStatuses;

        (<DynamicField>this.Form.ProductTypeId) = new DynamicField({
            disabled: isNew ? false : true,
            formGroup: this.formGroup,
            label: 'Product Type',
            name: 'ProductTypeId',
            options: additionalParameters.productTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.RadioButtonList,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: (salesorderPartial && salesorderPartial.ProductTypeId) || null,
        });

        (<DynamicField>this.Form.VialStatusId).disabled = isNew ? false : true;

        (<DynamicField>this.Form.Cost).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicField>this.Form.Deposit).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });

        // View
        (<DynamicLabel>this.View.Id) = new DynamicLabel(
            'ID',
            salesorderPartial.Id,
            new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textarea,
            }),
        );

        (<DynamicLabel>this.View.StatusId).value = getMetaItemValue(
            additionalParameters.salesOrderStatuses,
            salesorderPartial && salesorderPartial.hasOwnProperty('StatusId') && salesorderPartial.StatusId !== null
                ? salesorderPartial.StatusId
                : null,
        );

        (<DynamicLabel>this.View.ProductTypeId) = new DynamicLabel(
            'Product Type',
            getMetaItemValue(
                additionalParameters.productTypes,
                salesorderPartial && salesorderPartial.hasOwnProperty('ProductTypeId') && salesorderPartial.ProductTypeId !== null
                    ? salesorderPartial.ProductTypeId
                    : null,
            ),
            new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.RadioButtonList,
            }),
        );

        (<DynamicLabel>this.View.Cost).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
        (<DynamicLabel>this.View.Deposit).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Currency,
            scale: 2,
        });
    }
}
