import { NgModule } from '@angular/core';
import { AdminPortalFeatureModule } from '@common/feature.module';
import { AdminPortalSharedModule } from '@common/shared.module';

import { OutcomesComponent } from './outcomes.component';
import { OutcomeDetailsComponent } from './outcome-details.component';
import { QcInvestigationComponent } from './qc-investigation/qc-investigation.component';
import { SalesOrderOutcomeComponent } from './outcome/outcome.component';
import { BirthOutcomeComponent } from './births/birth-outcome/birth-outcome.component';
import { BirthDetailComponent } from './births/birth-detail/birth-detail.component';
import { AngularCommonModule } from '@angular-common/angular-common.module';

@NgModule({
    declarations: [
        OutcomesComponent,
        OutcomeDetailsComponent,
        QcInvestigationComponent,
        SalesOrderOutcomeComponent,
        BirthOutcomeComponent,
        BirthDetailComponent,
    ],
    exports: [OutcomesComponent],
    imports: [AdminPortalSharedModule, AdminPortalFeatureModule, AngularCommonModule],
})
export class OutcomesModule {}
