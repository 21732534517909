import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IAppointment } from '@model/interfaces/appointment';
import { AppointmentService } from '../services/appointment.service';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './appointment-header.component.html',
})
export class AppointmentHeaderComponent implements OnInit, OnDestroy {
    appointment: IAppointment;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private appointmentService: AppointmentService, private route: ActivatedRoute, private datePipe: DatePipe) { }

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('appointmentId');
        // set the header based on the id
        if (id > 0) {
            this.appointmentService.getById(id).subscribe((appointment) => {
                this.setHeader(appointment);
            });
        } else {
            this.setHeader(this.appointmentService.getEmptyAppointment());
        }
        // subscribe to any changes in the appointment service
        // which should update the header accordingly
        this.subscriptions.add(
            this.appointmentService.changeEmitted$.subscribe((appointment) => {
                this.setHeader(appointment);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(appointment: IAppointment): void {
        this.appointment = appointment;
        this.header = `Appointment: ${this.datePipe.transform(appointment.StartTimeUtc, 'short')} Donor: ${appointment.Donors[0].DonorUser.FirstName} ${appointment.Donors[0].DonorUser.LastName}`;
    }
}
