import { NgModule } from '@angular/core';

import { AdminPortalSharedModule } from '@common/shared.module';
import { AppointmentRoutingModule } from './appointment-routing.module';
import { AppointmentBasicInfoComponent } from './appointment-basic-info/appointment-basic-info.component';
import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import { AppointmentsComponent } from './appointment-list/appointments.component';
import { AppointmentHeaderComponent } from './appointment-header/appointment-header.component';
import { MtDocumentControlModule } from '@mt-ng2/document-control';
import { AdminPortalFeatureModule } from '@common/feature.module';

@NgModule({
    declarations: [AppointmentsComponent, AppointmentHeaderComponent, AppointmentDetailComponent, AppointmentBasicInfoComponent],
    exports: [],
    imports: [AdminPortalSharedModule, AppointmentRoutingModule, MtDocumentControlModule, AdminPortalFeatureModule, AdminPortalSharedModule],
})
export class AppointmentModule {}
