import { Component, OnInit, Input } from '@angular/core';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IDocumentType } from '@model/interfaces/document-type';
import { ClaimTypes } from '@model/ClaimTypes';
import { UploadDocumentsService } from '../../services/upload-documents.service';
import { DocumentEntities } from '@model/enums/document-entities.enum';

// This is a wrapper around UploadDocumentsComponent

@Component({
    selector: 'app-upload-documents-detail',
    templateUrl: './upload-documents-detail.component.html',
})
export class UploadDocumentsDetailComponent implements OnInit {
    @Input() entityId: number;
    @Input() entityType: DocumentEntities;
    @Input() claimType: ClaimTypes;
    // Only need to provide document types if we're limited the types available
    @Input() documentTypes?: IDocumentType[];

    showDocumentsCard = false;
    canUpload = false;

    constructor(public uploadDocumentsService: UploadDocumentsService, private claimsService: ClaimsService) {}

    ngOnInit(): void {
        this.canUpload = this.claimsService.hasClaim(this.claimType, [ClaimValues.FullAccess]);
        this.showDocumentsCard = this.claimsService.hasClaim(this.claimType, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        if (!this.documentTypes || !this.documentTypes.length) {
            this.uploadDocumentsService
                .getDocumentTypes(this.entityType, this.entityId)
                .subscribe((documentTypes) => (this.documentTypes = [...documentTypes]));
        }
    }
}
