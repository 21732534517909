import { IAppointment } from '@model/interfaces/appointment';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';

export class AppointmentsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['DonorId'],
                    name: 'Donor Id',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (appt: IAppointment) => {
                        const donor = appt.Donors[0].DonorUser;
                        const donorName = donor ? `${donor.FirstName} ${donor.LastName}` : '';
                        return donorName;
                    },
                    name: 'Donor',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessors: ['StartTimeUtc'],
                    name: 'Start Time',
                    pipes: ['date:short'],
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessors: ['PhoneNumber'],
                    name: 'Phone Number',
                    pipes: ['phone'],
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (appt: IAppointment) => (appt.User ? `${appt.User.FirstName} ${appt.User.LastName}` : ''),
                    name: 'Assigned User',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (appt: IAppointment) => (appt.Cancelled ? 'Yes' : 'No'),
                    name: 'Cancelled',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessors: ['AppointmentType', 'Name'],
                    name: 'Type',
                    sort: { disableSort: true },
                }),

                new EntityListColumn({
                    accessorFunction: (appt: IAppointment) => (appt.InterviewPassed == null ? null : appt.InterviewPassed ? 'Yes' : 'No'),
                    name: 'Interview Passed',
                    sort: { disableSort: true },
                }),
            ],
        };
        super(listConfig);
    }
}
