import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocalizedDateBaseService } from '@angular-common/services/localized-date-base.service';
import { IDonorOngoingScreening } from '@model/interfaces/donor-ongoing-screening';
import { Observable } from 'rxjs';
import { IMetaItem, MetaItem } from '@mt-ng2/base-service';
import { ExtraParams } from '@common/extra-params/extra-params.library';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { map } from 'rxjs/operators';

export const emptyDonorOngoingScreening: IDonorOngoingScreening = {
    BloodDrawDate: null,
    BloodWorkStatusId: 0,
    DonorId: 0,
    Id: 0,
};

export const datesToLocalize = ['BloodDrawDate', 'PhysicalResultDate', 'RegulatoryQuestionnaireResultDate'];

@Injectable({
    providedIn: 'root',
})
export class DonorOngoingScreeningService extends LocalizedDateBaseService<IDonorOngoingScreening> {
    constructor(public http: HttpClient) {
        super('/donor-ongoing-screenings', http, datesToLocalize);
    }

    getEmptyDonorOngoingScreening(donorId: number): IDonorOngoingScreening {
        return { ...emptyDonorOngoingScreening, DonorId: donorId };
    }

    getDonorScreeningsAsMetaItems(donorId: number): Observable<IMetaItem[]> {
        const _extraSearchParams: ExtraSearchParams[] = ExtraParams.build({
            DonorId: donorId,
        });
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'BloodDrawDate',
            orderDirection: 'desc',
            query: '',
        };

        const searchparams = new SearchParams(searchEntity);
        return this.get(searchparams).pipe(
            map((resp) => resp.body.map((os) => new MetaItem(os.Id, new Date(os.BloodDrawDate).mtDate.format('MMM D, yyyy')))),
        );
    }

    delete(donorScreeningId: number): Observable<any> {
        return this.http.delete(`/donor-ongoing-screenings/delete/${donorScreeningId}`);
    }
}
