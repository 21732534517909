import { Component, Input, OnInit } from '@angular/core';

import { IThawing } from '@model/interfaces/thawing';
import { SalesOrderService } from '../../../services/sales-order.service';
import { ThawingService } from '../services/thawing.service';
import { forkJoin } from 'rxjs';
import { MetaItem } from '@mt-ng2/base-service';

@Component({
    selector: 'sales-order-thawings',
    templateUrl: './thawings.component.html',
})
export class ThawingsComponent implements OnInit {
    @Input() salesOrderId: number;
    @Input() canEdit: boolean;
    newThawing: IThawing;
    salesOrderThawings: IThawing[] = [];
    salesOrderDonorIds: MetaItem[];

    constructor(private thawingService: ThawingService, private salesOrderService: SalesOrderService) { }

    ngOnInit(): void {
        this.newThawing = {
            Id: null,
            SalesOrderId: null,
            DonorId: null,
            ClinicId: null,
            Technician: null,
            ProcedureTypeId: null,
            TreatingPhysician: null,
            ProcedureDate: null,
            ActualThawingDate: null,
            VialTypeId: null,
            VialsThawed: null,
            VialsUtilized: null,
            Volume: null,
            Concentration: null,
            Motility: null,
            ReasonNotToCountId: null,
            Comments: null,
            FollowUpDate: null,
            FollowUpReasonId: null,
            GuaranteeMet: null,
        };
        forkJoin(
            this.salesOrderService.GetSalesOrderDonorIds(this.salesOrderId),
            this.salesOrderService.GetThawingsBySalesOrderId(this.salesOrderId)
        ).subscribe(([salesOrderDonorIds, thawings]) => {
            this.salesOrderDonorIds = salesOrderDonorIds;
            this.salesOrderThawings = thawings;
        });
    }

    addNewThawing(): void {
        this.newThawing = this.thawingService.getEmptyThawing(this.salesOrderId);
        this.salesOrderThawings.unshift(this.newThawing);
    }

    discardNewThaw(): void {
        this.salesOrderThawings.shift();
    }
}
