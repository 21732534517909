import { ShippingDynamicControls, IShippingDynamicControlsParameters } from '../form-controls/shipping.form-controls';
import { IShipping } from '../interfaces/shipping';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { DynamicField, SelectInputTypes, DynamicLabel } from '@mt-ng2/dynamic-form';

export interface IShippingDynamicControlsParametersPartial extends IShippingDynamicControlsParameters {
    vialsPurchased?: number;
}

export class ShippingDynamicControlsPartial extends ShippingDynamicControls {
    constructor(shippingPartial?: IShipping, additionalParameters?: IShippingDynamicControlsParametersPartial) {
        super(shippingPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        (<DynamicField>this.Form.ClinicalBoundAirwayBillNumber).labelHtml = '<label>Clinical Bound Airway Bill #</label>';
        (<DynamicField>this.Form.DataLoggerNumber).labelHtml = '<label>Data Logger #</label>';
        (<DynamicField>this.Form.NumberOfVialsShipped).labelHtml = '<label># of Vials Shipped</label>';
        (<DynamicField>this.Form.PrintSummary).labelHtml = '<label>Summary of Records, Protocols, Reports, and Declarations</label>';
        (<DynamicField>this.Form.ShippingMethodId).labelHtml = '<label>Shipping Method</label>';
        (<DynamicField>this.Form.ShippingInvoiceNumber).labelHtml = '<label>Shipping Invoice #</label>';
        (<DynamicField>this.Form.TankNumber).labelHtml = '<label>Tank #</label>';
        (<DynamicField>this.Form.TwebBoundAirwayBillNumber).labelHtml = '<label>TWEB Bound Airway Bill #</label>';
        (<DynamicField>this.Form.ShipToClinicId).type.inputType = SelectInputTypes.TypeAhead;

        (<DynamicField>this.Form.ProposedShipDate).type.datepickerOptions = { showClearButton: true };

        if (additionalParameters.shipToClinics) {
            (<DynamicLabel>this.View.ShipToClinicId).value = getMetaItemValue(
                additionalParameters.shipToClinics,
                shippingPartial && shippingPartial.hasOwnProperty('ShipToClinicId') && shippingPartial.ShipToClinicId !== null
                    ? shippingPartial.ShipToClinicId
                    : null,
            );
        }

        (<DynamicField>this.Form.NumberOfVialsShipped).value = shippingPartial?.Id
            ? shippingPartial.NumberOfVialsShipped
            : additionalParameters.vialsPurchased ?? null;
    }
}
