import { Component } from '@angular/core';
import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';

import { AbstractLabDataComponent } from '../base-components/abstract-lab-data.component';

@Component({
    selector: 'app-donor-cryo-prep',
    templateUrl: './cryo-prep.component.html',
})
export class CryoPrepComponent extends AbstractLabDataComponent {
    sectionId = DonorTestSampleLabDataSections.CryoPrep;
}
