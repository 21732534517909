<div class="mile-editable miles-card padded">
    <div *ngIf="!isEditing">
        <h4>
            Lab Data: Remainder Vials
            <span class="pull-right" *ngIf="canEdit" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h4>
        <div *ngIf="formCreated">
            <div class="display-padded">
                <b><label>Number of Vials</label>:</b>
                <span>{{ calculatedFields.NumberOfVials }}</span>
            </div>
            <div class="display-padded">
                <b><label>Vial Add Volume</label>:</b>
                <span>550 ul</span>
            </div>
            <div class="display-padded">
                <b><label>Aprox Vol Left</label>:</b>
                <span>{{ calculatedFields.AproxVolLeft }}</span>
            </div>
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.LabelVerifiedBy"></dynamic-label>
        </div>
    </div>
    <div *ngIf="isEditing">
        <form *ngIf="formCreated" [formGroup]="donorTestSampleLabDataForm" (ngSubmit)="formSubmitted()">
            <div>
                <h4>Lab Data: Remainder Vials</h4>

                <div class="calculated-field">
                    <b>Number of Vials</b>
                    <input class="form-control" disabled value="{{ calculatedFields.NumberOfVials }}" />
                </div>
                <div class="calculated-field">
                    <b>Vial Add Volume</b>
                    <input class="form-control" disabled value="550" />
                </div>
                <div class="calculated-field">
                    <b>Aprox Vol Left</b>
                    <input class="form-control" disabled value="{{ calculatedFields.AproxVolLeft }}" />
                </div>
                <dynamic-field [field]="abstractDonorTestSampleLabDataControls.LabelVerifiedBy" [form]="donorTestSampleLabDataForm"></dynamic-field>
            </div>
            <div>
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
        </form>
    </div>
</div>
