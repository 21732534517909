import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocalizedDateBaseService } from '@angular-common/services/localized-date-base.service';

import { IRecipientUser } from '@model/interfaces/recipient-user';
import { Observable } from 'rxjs';
import { nullOutFks } from '@common/libraries/null-out-fks.library';
import { IEmailDto } from '@model/interfaces/custom/email-dto';
import { parseEmailAttachment } from '@common/libraries/parse-email-attachment.library';
import { AuthService } from '@mt-ng2/auth-module';
import { SearchParams } from '@mt-ng2/common-classes';

export const emptyRecipientUser: IRecipientUser = {
    AuthUserId: 0,
    DateCreated: null,
    Email: null,
    FirstName: null,
    Id: 0,
    LastName: null,
    Version: null,
};

export const fksToIgnore = ['Recipient'];
export const datesToLocalize = ['Recipient.Dob', 'Recipient.PartnerDob'];

@Injectable({
    providedIn: 'root',
})
export class RecipientUserService extends LocalizedDateBaseService<IRecipientUser> {
    constructor(public http: HttpClient, private authService: AuthService) {
        super('/recipient-users', http);
        this.datesToLocalize = datesToLocalize;
    }

    getEmptyRecipientUser(): IRecipientUser {
        return { ...emptyRecipientUser };
    }

    createRecipientUserWithRecipient(recipientUser: IRecipientUser): Observable<number> {
        let clone = this.nullOutFks(recipientUser);
        clone.DateCreated = new Date();
        return this.http.post<number>(`/recipient-users`, clone);
    }

    updateRecipientUserWithRecipient(recipientUser: IRecipientUser): Observable<any> {
        let clone = this.nullOutFks(recipientUser);
        return this.http.put(`/recipient-users/${recipientUser.Id}`, clone);
    }

    private nullOutFks(recipientUser: IRecipientUser): IRecipientUser {
        let clone = JSON.parse(JSON.stringify(recipientUser));
        nullOutFks(clone.Recipient);
        return nullOutFks(clone, fksToIgnore);
    }

    sendWelcomeEmail(recipientId: number, email: IEmailDto): Observable<IRecipientUser> {
        email.CurrentUserId = this.authService.currentUser.getValue().Id;
        if (email.Attachments) {
            const formData = parseEmailAttachment(email);
            return this.http.post<IRecipientUser>(`/recipients/${recipientId}/welcome-email-with-attachments`, formData);
        } else {
            return this.http.post<IRecipientUser>(`/recipients/${recipientId}/welcome-email`, email);
        }
    }

    exportRecipients(searchParams: SearchParams): Observable<Blob> {
        let params = this.getHttpParams(searchParams);
        return this.http.get(`/recipient-users/export`, {
            params: params,
            responseType: 'blob' as 'blob',
        });
    }
}
