import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { INotificationTemplate } from '@model/interfaces/notification-template';
import { Observable } from 'rxjs';
import { INotificationOptionsDto } from '@model/interfaces/custom/notification-options-dto';
import { NotificationTypes } from '@model/enums/notification-types.enum';

const newNotificationTemplate: INotificationTemplate = {
    Custom: true,
    Editable: 1,
    Id: 0,
    Name: '',
    NotificationTypeId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class NotificationTemplateService extends BaseService<INotificationTemplate> {
    constructor(public http: HttpClient) {
        super('/notification-templates', http);
    }

    getNewNotificationTemplate(): INotificationTemplate {
        return { ...newNotificationTemplate };
    }

    getPopulatedNotificationTemplate(templateId: number, options: INotificationOptionsDto): Observable<INotificationTemplate> {
        return this.http.post<INotificationTemplate>(`/notification-templates/templates/${templateId}`, options);
    }

    // Used to populate email dropdowns
    getNotificationsTemplatesByType(notificationTypeId: NotificationTypes): Observable<INotificationTemplate[]> {
        return this.http.get<INotificationTemplate[]>(`/notification-templates/type/${notificationTypeId}`);
    }
}
