import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IEmail, ISendEmailModalResult } from '@mt-ng2/email-modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { ClinicEntityTypes } from '@model/enums/clinic-entity-types.enum';
import { IShipping } from '@model/interfaces/shipping';
import { INotificationOptionsDto } from '@model/interfaces/custom/notification-options-dto';
import { INotificationTemplate } from '@model/interfaces/notification-template';
import { NotificationTypes } from '@model/enums/notification-types.enum';
import { NotificationTemplates } from '@model/enums/notification-templates.enum';

import { EmailService } from '@common/services/email.service';
import { ISendEmailTemplateModalApi } from '@common/components/send-email/send-email-template.component';
import { SelectTemplateDynamicControls } from './select-template.form-controls';

import { NotificationTemplateService } from '../../../../notification-templates/services/notification-template.service';
import { ClinicEntityService } from '../../../../clinics/services/clinic-entity.service';

@Component({
    selector: 'app-shipping-emails',
    templateUrl: './shipping-email.component.html',
})
export class ShippingEmailComponent {
    @Input() recipientId: number;
    @Input() shippingDetails: IShipping;

    templatesToInclude = [
        NotificationTemplates.ReportsRequestFirstNotice,
        NotificationTemplates.ReportsRequestSecondNotice,
        NotificationTemplates.ReportsRequestThirdNotice,
        NotificationTemplates.ConfirmationEmailVerifiedClinic,
    ];

    selectedTemplateId: number;
    donorNotificationTemplates: INotificationTemplate[];

    abstractSelectTemplateControls: any;

    emailTemplateForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    emailModalApi: ISendEmailTemplateModalApi;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private donorNotificationsService: NotificationTemplateService,
        private notificationsService: NotificationsService,
        private emailService: EmailService,
        private clinicEntityService: ClinicEntityService,
    ) {}

    ngOnInit(): void {
        this.donorNotificationsService.getNotificationsTemplatesByType(NotificationTypes.ClinicEmail).subscribe((templates) => {
            this.donorNotificationTemplates = templates.filter((t) => this.templatesToInclude.includes(t.Id));
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.emailTemplateForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractSelectTemplateControls = new SelectTemplateDynamicControls(
            { Id: null, Name: null, Editable: null, NotificationTypeId: null },
            {
                emailTemplates: this.donorNotificationTemplates,
                formGroup: 'Template',
            },
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Template: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.emailTemplateForm.valid) {
            this.selectedTemplateId = this.emailTemplateForm.value.Template.TemplateId;
            this.showEmailModal();
        } else {
            markAllFormFieldsAsTouched(this.emailTemplateForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(): void {
        this.notificationsService.error('Select an email template to send.');
    }

    success(): void {
        this.notificationsService.success('Email successfully sent.');
    }

    showEmailModal(): void {
        forkJoin([this.clinicEntityService.getByClinicId(this.shippingDetails.ShipToClinicId, ClinicEntityTypes.LabShippingAddress)]).subscribe(
            ([answer]) => {
                this.getPopulatedEmailTemplate(
                    {
                        ClinicId: this.shippingDetails.ShipToClinicId,
                        RecipientId: this.recipientId,
                        SalesOrderId: this.shippingDetails.SalesOrderId,
                        ShippingId: this.shippingDetails.Id,
                    },
                    answer?.ClinicContact.Email,
                );
            },
        );
    }

    getPopulatedEmailTemplate(options: INotificationOptionsDto, email?: string): void {
        this.emailModalApi.show(email ? email : '', this.selectedTemplateId, options).subscribe((response: ISendEmailModalResult) => {
            if (!response.cancelled) {
                this.sendEmail(response.email);
            }
            this.enableDoubleClick();
        });
    }

    sendEmail(emailData: IEmail): void {
        const emailDto = { ...emailData, TemplateId: this.selectedTemplateId };
        this.emailService.sendEmail(emailDto).subscribe((answer) => {
            this.success();
            this.enableDoubleClick();
        });
    }
}
