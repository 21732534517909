import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IVwDonorInventorySearchEntity } from '@model/interfaces/vw-donor-inventory-search-entity';
import { DonorInventorySearchEntityService } from '@common/components/user-entity-search/services/donor-inventory-search-entity.service';
import { ExtraSearchParams } from '@mt-ng2/common-classes';
import { ExtraParams } from '@common/extra-params/extra-params.library';
import { DonorInventorySearchEntityListConfig } from './donor-inventory-search.entity-list-config';
import { IItemSelectedEvent } from '@mt-ng2/entity-list-module';

@Component({
    selector: 'link-donor-search',
    template: ` <app-user-entity-search
        [service]="donorInvSearchService"
        [entityListConfig]="entityListConfig"
        [extraParams]="extraSearchParams"
        [searchOnInit]="false"
        (itemSelected)="donorSelected($event)"
        emptyMessage="No donor with available inventory of matching product type and vial status found"
    >
    </app-user-entity-search>`,
})
export class LinkDonorSearchComponent {
    @Input() productTypeId: number;
    @Input() vialStatusId: number;
    @Output() onDonorSelected: EventEmitter<IVwDonorInventorySearchEntity> = new EventEmitter<IVwDonorInventorySearchEntity>();

    extraSearchParams: ExtraSearchParams[];
    entityListConfig = new DonorInventorySearchEntityListConfig();

    constructor(public donorInvSearchService: DonorInventorySearchEntityService) {}

    ngOnInit(): void {
        this.extraSearchParams = ExtraParams.build({
            ProductTypeIds: this.productTypeId,
            VialStatusIds: this.vialStatusId,
        });
    }

    donorSelected(selectEvent: IItemSelectedEvent): void {
        this.onDonorSelected.emit(selectEvent.entity);
    }
}
