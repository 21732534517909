<div class="miles-card padded">
    <h4>Hello Sign Documents</h4>
    <div *ngFor="let template of helloSignTemplates">
        <div class="row">
            <div class="col-md-12">
                <span
                    ><strong>{{ template.Name }}</strong></span
                >
                <span class="pull-right">
                    <span *ngIf="!donorHasSigned(template.Id)">
                        <i>{{ getLastRequestDate(template.Id) }}</i>
                        <button (click)="sendReminderEmail(template.Id)">Request Signature</button>
                    </span>

                    <div *ngIf="donorHasSigned(template.Id)">
                        <span *ngIf="documentHasCompletionDate(template.Id)">
                            <a title="Click to edit the signature date" (click)="setCompletionDate(template.Id, $event)" class="small-text">
                                <i>Signed on: {{ getCompletionDate(template.Id) ? (getCompletionDate(template.Id) | date: 'medium') : '' }}</i>
                            </a>
                        </span>
                        <button *ngIf="!docDateAddedManually(template.Id)" (click)="downloadPDF(template)">View</button>
                        <button *ngIf="docDateAddedManually(template.Id)" (click)="clearSignatureDate(template)">Clear Signature Date</button>
                    </div>

                    <span *ngIf="!documentHasCompletionDate(template.Id)">
                        <a (click)="setCompletionDate(template.Id, $event)" class="small-text"><i>Add signature date</i></a>
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
