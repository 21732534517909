import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorBloodWork } from '../interfaces/donor-blood-work';
import { IBloodType } from '../interfaces/blood-type';
import { IRhFactor } from '../interfaces/rh-factor';

export interface IDonorBloodWorkDynamicControlsParameters {
    formGroup?: string;
    bloodTypes?: IBloodType[];
    rhFactors?: IRhFactor[];
}

export class DonorBloodWorkDynamicControls {

    formGroup: string;
    bloodTypes: IBloodType[];
    rhFactors: IRhFactor[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorbloodwork?: IDonorBloodWork, additionalParameters?: IDonorBloodWorkDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorBloodWork';
        this.bloodTypes = additionalParameters && additionalParameters.bloodTypes || undefined;
        this.rhFactors = additionalParameters && additionalParameters.rhFactors || undefined;

        this.Form = {
            BloodTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Blood Type',
                name: 'BloodTypeId',
                options: this.bloodTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorbloodwork && this.donorbloodwork.BloodTypeId || null,
            }),
            KaryotypeAcceptedRejectedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Karyotype Accepted Rejected By',
                name: 'KaryotypeAcceptedRejectedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.donorbloodwork && this.donorbloodwork.hasOwnProperty('KaryotypeAcceptedRejectedBy') && this.donorbloodwork.KaryotypeAcceptedRejectedBy !== null ? this.donorbloodwork.KaryotypeAcceptedRejectedBy.toString() : '',
            }),
            KaryotypeAcceptedRejectedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Karyotype Accepted Rejected Date',
                name: 'KaryotypeAcceptedRejectedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbloodwork && this.donorbloodwork.KaryotypeAcceptedRejectedDate || null,
            }),
            KaryotypeDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Karyotype Draw Date',
                name: 'KaryotypeDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbloodwork && this.donorbloodwork.KaryotypeDrawDate || null,
            }),
            KaryotypeModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Karyotype Modified By',
                name: 'KaryotypeModifiedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbloodwork && this.donorbloodwork.KaryotypeModifiedById || null,
            }),
            KaryotypeModifiedOn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Karyotype Modified On',
                name: 'KaryotypeModifiedOn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbloodwork && this.donorbloodwork.KaryotypeModifiedOn || null,
            }),
            KaryotypeResultId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Karyotype Result',
                name: 'KaryotypeResultId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbloodwork && this.donorbloodwork.KaryotypeResultId || null,
            }),
            RhFactorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rh Factor',
                name: 'RhFactorId',
                options: this.rhFactors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorbloodwork && this.donorbloodwork.RhFactorId || null,
            }),
        };

        this.View = {
            BloodTypeId: new DynamicLabel(
                'Blood Type',
                getMetaItemValue(this.bloodTypes, this.donorbloodwork && this.donorbloodwork.hasOwnProperty('BloodTypeId') && this.donorbloodwork.BloodTypeId !== null ? this.donorbloodwork.BloodTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            KaryotypeAcceptedRejectedBy: new DynamicLabel(
                'Karyotype Accepted Rejected By',
                this.donorbloodwork && this.donorbloodwork.hasOwnProperty('KaryotypeAcceptedRejectedBy') && this.donorbloodwork.KaryotypeAcceptedRejectedBy !== null ? this.donorbloodwork.KaryotypeAcceptedRejectedBy.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            KaryotypeAcceptedRejectedDate: new DynamicLabel(
                'Karyotype Accepted Rejected Date',
                this.donorbloodwork && this.donorbloodwork.KaryotypeAcceptedRejectedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            KaryotypeDrawDate: new DynamicLabel(
                'Karyotype Draw Date',
                this.donorbloodwork && this.donorbloodwork.KaryotypeDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            KaryotypeModifiedById: new DynamicLabel(
                'Karyotype Modified By',
                this.donorbloodwork && this.donorbloodwork.KaryotypeModifiedById || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            KaryotypeModifiedOn: new DynamicLabel(
                'Karyotype Modified On',
                this.donorbloodwork && this.donorbloodwork.KaryotypeModifiedOn || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            KaryotypeResultId: new DynamicLabel(
                'Karyotype Result',
                this.donorbloodwork && this.donorbloodwork.KaryotypeResultId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            RhFactorId: new DynamicLabel(
                'Rh Factor',
                getMetaItemValue(this.rhFactors, this.donorbloodwork && this.donorbloodwork.hasOwnProperty('RhFactorId') && this.donorbloodwork.RhFactorId !== null ? this.donorbloodwork.RhFactorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
