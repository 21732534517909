<div *ngIf="!isEditing && donor.DonorPreliminaryHistory">
    <h4>
        Preliminary History
    </h4>
    <div>
        <div class="display-padded">
            <b>Has Std:</b>
            <span>{{ donor.DonorPreliminaryHistory.HasStd | yesNoPipe }}</span>
        </div>
        <div *ngIf="donor.DonorPreliminaryHistory.HasStd">
            <div class="display-padded">
                <b>Std Name:</b>
                <span>{{ donor.DonorPreliminaryHistory.StdName }}</span>
            </div>
            <div class="display-padded">
                <b>Date of Std Treatment:</b>
                <span>{{ donor.DonorPreliminaryHistory.DateOfStdTreatment }}</span>
            </div>
        </div>
        <div class="display-padded">
            <b>Had Sex With Man:</b>
            <span>{{ donor.DonorPreliminaryHistory.HadSexWithMan | yesNoPipe }}</span>
        </div>
        <div class="display-padded">
            <b>Had Vasectomy:</b>
            <span>{{ donor.DonorPreliminaryHistory.HadVasectomy | yesNoPipe }}</span>
        </div>
        <div class="display-padded">
            <b>Has Donated Before:</b>
            <span>{{ donor.DonorPreliminaryHistory.HasDonatedBefore | yesNoPipe }}</span>
        </div>
        <div *ngIf="donor.DonorPreliminaryHistory.HasDonatedBefore" class="display-padded">
            <b>Times Donated:</b>
            <span>{{ donor.DonorPreliminaryHistory.TimesDonated }}</span>
        </div>
        <div class="display-padded">
            <b>Listed on Another Roster:</b>
            <span>{{ donor.DonorPreliminaryHistory.ListedOnAnotherRoster | yesNoPipe }}</span>
        </div>
        <div *ngIf="donor.DonorPreliminaryHistory.ListedOnAnotherRoster" class="display-padded">
            <b>Other Rosters:</b>
            <span>{{ donor.DonorPreliminaryHistory.OtherRosters }}</span>
        </div>
        <div class="display-padded">
            <b>Is Adopted:</b>
            <span>{{ donor.DonorPreliminaryHistory.IsAdopted | yesNoPipe }}</span>
        </div>
        <div class="display-padded">
            <b>Knows Family History:</b>
            <span>{{ donor.DonorPreliminaryHistory.KnowsFamilyHistory | yesNoPipe }}</span>
        </div>
        <div class="display-padded">
            <b>Has Adopted Parents:</b>
            <span>{{ donor.DonorPreliminaryHistory.HasAdoptedParents | yesNoPipe }}</span>
        </div>
        <div class="display-padded">
            <b>Knows Parents Family History:</b>
            <span>{{ donor.DonorPreliminaryHistory.KnowsParentsFamilyHistory | yesNoPipe }}</span>
        </div>
        <div class="display-padded">
            <b>Date Created:</b>
            <span>{{ donor.DonorPreliminaryHistory.DateCreated | date }}</span>
        </div>
        <div class="display-padded">
            <b>Date Modified:</b>
            <span>{{ donor.DonorPreliminaryHistory.DateModified | date }}</span>
        </div>
    </div>
</div>
<div *ngIf="isEditing">
    <h4>Preliminary History</h4>
    <preliminary-history
    [donor]="donor"
    (onFormValueChanges)="onFormValueChanges($event)"
    ></preliminary-history>
    <div>
        <button [disabled]="!formChanged" class="btn btn-success btn-flat" (click)="onSubmit()">
            Save
        </button>
        <button class="btn btn-default btn-flat" (click)="cancelClick()">
            Cancel
        </button>
    </div>
</div>
