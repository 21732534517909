<div class="miles-editable miles-card padded" *ngIf="!isEditing" [style.cursor]="!isReadOnly ? 'pointer' : 'default'">
    <h4>
        Outcome <span *ngIf="isFinalized"><em style="color: red">Outcome is finalized</em></span>
        <span class="pull-right" *ngIf="!isReadOnly" (click)="edit()">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div *ngIf="formCreated && outcomeDetails">
        <div class="row">
            <div class="col-md-12">
                <dynamic-label [field]="abstractOutcomeControls.View.OutcomeOptionId"></dynamic-label>
                <dynamic-label [field]="abstractOutcomeControls.View.FollowUpDate"></dynamic-label>
                <dynamic-label [field]="abstractOutcomeControls.View.FollowUpReason"></dynamic-label>
                <dynamic-label [field]="abstractOutcomeControls.View.HcgValue"></dynamic-label>
                <dynamic-label [field]="abstractOutcomeControls.View.HcgDate"></dynamic-label>
                <dynamic-label [field]="abstractOutcomeControls.View.DateUs"></dynamic-label>
                <dynamic-label [field]="abstractOutcomeControls.View.FetalSacCount"></dynamic-label>
                <dynamic-label [field]="abstractOutcomeControls.View.FhbCount"></dynamic-label>
                <dynamic-label [field]="abstractOutcomeControls.View.NumberOfEmbryosTransferred"></dynamic-label>
                <dynamic-label [field]="abstractOutcomeControls.View.Comment"></dynamic-label>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Outcome</h4>
    <div *ngIf="formCreated">
        <form [formGroup]="outcomeForm" (ngSubmit)="formSubmitted()">
            <div class="row">
                <div class="col-md-12">
                    <dynamic-field [field]="abstractOutcomeControls.Form.OutcomeOptionId" [form]="outcomeForm"></dynamic-field>
                    <dynamic-field [field]="abstractOutcomeControls.Form.FollowUpDate" [form]="outcomeForm"></dynamic-field>
                    <dynamic-field [field]="abstractOutcomeControls.Form.FollowUpReason" [form]="outcomeForm"></dynamic-field>
                    <dynamic-field [field]="abstractOutcomeControls.Form.HcgValue" [form]="outcomeForm"></dynamic-field>
                    <dynamic-field [field]="abstractOutcomeControls.Form.HcgDate" [form]="outcomeForm"></dynamic-field>
                    <dynamic-field [field]="abstractOutcomeControls.Form.DateUs" [form]="outcomeForm"></dynamic-field>
                    <dynamic-field [field]="abstractOutcomeControls.Form.FetalSacCount" [form]="outcomeForm"></dynamic-field>
                    <dynamic-field [field]="abstractOutcomeControls.Form.FhbCount" [form]="outcomeForm"></dynamic-field>
                    <dynamic-field [field]="abstractOutcomeControls.Form.NumberOfEmbryosTransferred" [form]="outcomeForm"></dynamic-field>
                    <dynamic-field [field]="abstractOutcomeControls.Form.Comment" [form]="outcomeForm"></dynamic-field>
                </div>
                <div class="col-md-12">
                    <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                        Save
                    </button>
                    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
                    <button
                        type="button"
                        class="btn btn-flat btn-primary"
                        (mtConfirm)="finalizeOutcome($event)"
                        [mtConfirmOptions]="finalizeConfirmOptions"
                        [disabled]="!canFinalize"
                    >
                        Finalize Outcome
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
