import { BloodWorkDetailDynamicControls, IBloodWorkDetailDynamicControlsParameters } from '../form-controls/blood-work-detail.form-controls';
import { IBloodWorkDetail } from '../interfaces/blood-work-detail';
import { ScreeningResultOptions } from '../enums/screening-result-options.enum';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, InputTypes } from '@mt-ng2/dynamic-form';
import { IPassFailOption } from '@model/interfaces/pass-fail-option';

export class BloodWorkDetailDynamicControlsPartial extends BloodWorkDetailDynamicControls {
    constructor(
        bloodworkdetailPartial?: IBloodWorkDetail,
        additionalParameters?: IBloodWorkDetailDynamicControlsParameters,
        passFailOptions?: IPassFailOption[],
    ) {
        super(bloodworkdetailPartial, additionalParameters);

        // Add clearing option to all dates in form
        for (let field in this.Form) {
            if (this.Form[field].type.inputType === InputTypes.Datepicker) {
                this.Form[field].type.datepickerOptions = {
                    showClearButton: true,
                };
            }
        }

        // Default values - Default values removed 8/24/22 B.C.
        // (<DynamicField>this.Form.HepBsAgOutcomeId).value = bloodworkdetailPartial?.HepBsAgOutcomeId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.HepBCoreOutcomeId).value = bloodworkdetailPartial?.HepBCoreOutcomeId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.Hiv102AbOutcomeId).value = bloodworkdetailPartial?.Hiv102AbOutcomeId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.Hiv1NatOutcomeId).value = bloodworkdetailPartial?.Hiv1NatOutcomeId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.HbvNatOutcomeId).value = bloodworkdetailPartial?.HbvNatOutcomeId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.HcvNatOutcomeId).value = bloodworkdetailPartial?.HcvNatOutcomeId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.AntiHepCId).value = bloodworkdetailPartial?.AntiHepCId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.SyphilisOutcomeId).value = bloodworkdetailPartial?.SyphilisOutcomeId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.ChlamydiaNatOutcomeId).value = bloodworkdetailPartial?.ChlamydiaNatOutcomeId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.GonorrheaNatOutcomeId).value = bloodworkdetailPartial?.GonorrheaNatOutcomeId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.WnvNatId).value = bloodworkdetailPartial?.WnvNatId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.FdaLabsDate).value =
        //     bloodworkdetailPartial?.FdaLabsDate || bloodworkdetailPartial?.DonorOngoingScreening?.BloodDrawDate || null;
        // (<DynamicField>this.Form.CmvTotalAntibodyResultId).value =
        //     bloodworkdetailPartial?.CmvTotalAntibodyResultId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.CmvIggResultId).value = bloodworkdetailPartial?.CmvIggResultId ?? ScreeningResultOptions.NoResult;
        // (<DynamicField>this.Form.CmvIgmResultId).value = bloodworkdetailPartial?.CmvIgmResultId ?? ScreeningResultOptions.NoResult;

        // Labels
        (<DynamicField>this.Form.FdaLabsDate).label = 'FDA Labs Date';
        (<DynamicField>this.Form.FdaLabsDate).labelHtml = '<b>FDA Labs Date</b>';
        (<DynamicLabel>this.View.FdaLabsDate).label = 'FDA Labs Date';

        (<DynamicField>this.Form.SorReceivedDate).label = 'SOR Received Date';
        (<DynamicField>this.Form.SorReceivedDate).labelHtml = '<b>SOR Received Date</b>';
        (<DynamicLabel>this.View.SorReceivedDate).label = 'SOR Received Date';

        (<DynamicField>this.Form.FdaBookDate).label = 'FDA Book Date';
        (<DynamicField>this.Form.FdaBookDate).labelHtml = '<b>FDA Book Date</b>';
        (<DynamicLabel>this.View.FdaBookDate).label = 'FDA Book Date';

        (<DynamicField>this.Form.HepBsAgDrawDate).label = 'Hep BsAg Draw Date';
        (<DynamicField>this.Form.HepBsAgDrawDate).labelHtml = '<b>Hep BsAg Draw Date</b>';
        (<DynamicLabel>this.View.HepBsAgDrawDate).label = 'Hep BsAg Draw Date';

        (<DynamicField>this.Form.HepBsAgOutcomeId).label = 'Hep BsAG Outcome';
        (<DynamicField>this.Form.HepBsAgOutcomeId).labelHtml = '<b>Hep BsAG Outcome</b>';
        (<DynamicLabel>this.View.HepBsAgOutcomeId).label = 'Hep BsAG Outcome';

        (<DynamicField>this.Form.Hiv102AbOutcomeId).label = 'HIV 1/0/2 Ab Outcome';
        (<DynamicField>this.Form.Hiv102AbOutcomeId).labelHtml = '<b>HIV 1/0/2 Ab Outcome</b>';
        (<DynamicLabel>this.View.Hiv102AbOutcomeId).label = 'HIV 1/0/2 Ab Outcome';

        (<DynamicField>this.Form.Hiv102AbDate).label = 'HIV 1/0/2 Ab Date';
        (<DynamicField>this.Form.Hiv102AbDate).labelHtml = '<b>HIV 1/0/2 Ab Date</b>';
        (<DynamicLabel>this.View.Hiv102AbDate).label = 'HIV 1/0/2 Ab Date';

        (<DynamicField>this.Form.Hiv1NatOutcomeId).label = 'HIV 1 NAT Outcome';
        (<DynamicField>this.Form.Hiv1NatOutcomeId).labelHtml = '<b>HIV 1 NAT Outcome</b>';
        (<DynamicLabel>this.View.Hiv1NatOutcomeId).label = 'HIV 1 NAT Outcome';

        (<DynamicField>this.Form.Hiv1NatDate).label = 'HIV 1 NAT Date';
        (<DynamicField>this.Form.Hiv1NatDate).labelHtml = '<b>HIV 1 NAT Date</b>';
        (<DynamicLabel>this.View.Hiv1NatDate).label = 'HIV 1 NAT Date';

        (<DynamicField>this.Form.HcvNatOutcomeId).label = 'HCV NAT Outcome';
        (<DynamicField>this.Form.HcvNatOutcomeId).labelHtml = '<b>HCV NAT Outcome</b>';
        (<DynamicLabel>this.View.HcvNatOutcomeId).label = 'HCV NAT Outcome';

        (<DynamicField>this.Form.HcvNatDrawDate).label = 'HCV NAT Draw Date';
        (<DynamicField>this.Form.HcvNatDrawDate).labelHtml = '<b>HCV NAT Draw Date</b>';
        (<DynamicLabel>this.View.HcvNatDrawDate).label = 'HCV NAT Draw Date';

        (<DynamicField>this.Form.HbvNatOutcomeId).label = 'HBV NAT Outcome';
        (<DynamicField>this.Form.HbvNatOutcomeId).labelHtml = '<b>HBV NAT Outcome</b>';
        (<DynamicLabel>this.View.HbvNatOutcomeId).label = 'HBV NAT Outcome';

        (<DynamicField>this.Form.HbvNatDrawDate).label = 'HBV NAT Draw Date';
        (<DynamicField>this.Form.HbvNatDrawDate).labelHtml = '<b>HBV NAT Draw Date</b>';
        (<DynamicLabel>this.View.HbvNatDrawDate).label = 'HBV NAT Draw Date';

        (<DynamicField>this.Form.ChlamydiaNatOutcomeId).label = 'Chlamydia NAT Outcome';
        (<DynamicField>this.Form.ChlamydiaNatOutcomeId).labelHtml = '<b>Chlamydia NAT Outcome</b>';
        (<DynamicLabel>this.View.ChlamydiaNatOutcomeId).label = 'Chlamydia NAT Outcome';

        (<DynamicField>this.Form.ChlamydiaNatDrawDate).label = 'Chlamydia NAT Draw Date';
        (<DynamicField>this.Form.ChlamydiaNatDrawDate).labelHtml = '<b>Chlamydia NAT Draw Date</b>';
        (<DynamicLabel>this.View.ChlamydiaNatDrawDate).label = 'Chlamydia NAT Draw Date';

        (<DynamicField>this.Form.GonorrheaNatOutcomeId).label = 'Gonorrhea NAT Outcome';
        (<DynamicField>this.Form.GonorrheaNatOutcomeId).labelHtml = '<b>Gonorrhea NAT Outcome</b>';
        (<DynamicLabel>this.View.GonorrheaNatOutcomeId).label = 'Gonorrhea NAT Outcome';

        (<DynamicField>this.Form.GonorrheaNatDrawDate).label = 'Gonorrhea NAT Draw Date';
        (<DynamicField>this.Form.GonorrheaNatDrawDate).labelHtml = '<b>Gonorrhea NAT Draw Date</b>';
        (<DynamicLabel>this.View.GonorrheaNatDrawDate).label = 'Gonorrhea NAT Draw Date';

        (<DynamicField>this.Form.WnvNatId).label = 'WNV NAT';
        (<DynamicField>this.Form.WnvNatId).labelHtml = '<b>WNV NAT</b>';
        (<DynamicLabel>this.View.WnvNatId).label = 'WNV NAT';

        (<DynamicField>this.Form.WnvNatDrawDate).label = 'WNV NAT Draw Date';
        (<DynamicField>this.Form.WnvNatDrawDate).labelHtml = '<b>WNV NAT Draw Date</b>';
        (<DynamicLabel>this.View.WnvNatDrawDate).label = 'WNV NAT Draw Date';

        (<DynamicField>this.Form.CmvOutcomeId).label = 'CMV Outcome';
        (<DynamicField>this.Form.CmvOutcomeId).labelHtml = '<b>CMV Outcome</b>';
        (<DynamicLabel>this.View.CmvOutcomeId).label = 'CMV Outcome';

        (<DynamicField>this.Form.CmvStatusId).label = 'CMV Status';
        (<DynamicField>this.Form.CmvStatusId).labelHtml = '<b>CMV Status</b>';
        (<DynamicLabel>this.View.CmvStatusId).label = 'CMV Status';

        (<DynamicField>this.Form.CmvDrawDate).label = 'CMV Draw Date';
        (<DynamicField>this.Form.CmvDrawDate).labelHtml = '<b>CMV Draw Date</b>';
        (<DynamicLabel>this.View.CmvDrawDate).label = 'CMV Draw Date';

        (<DynamicField>this.Form.HtlvIAndIiOutcomeId).label = 'HTLV I And II Outcome';
        (<DynamicField>this.Form.HtlvIAndIiOutcomeId).labelHtml = '<b>HTLV I And II Outcome</b>';
        (<DynamicLabel>this.View.HtlvIAndIiOutcomeId).label = 'HTLV I And II Outcome';

        (<DynamicField>this.Form.HtlvIAndIiDrawDate).label = 'HTLV I And II Draw Date';
        (<DynamicField>this.Form.HtlvIAndIiDrawDate).labelHtml = '<b>HTLV I And II Draw Date</b>';
        (<DynamicLabel>this.View.HtlvIAndIiDrawDate).label = 'HTLV I And II Draw Date';

        (<DynamicField>this.Form.CmvTotalAntibodyResultId).labelHtml = '<b>CMV Total Antibody Result</b>';
        (<DynamicLabel>this.View.CmvTotalAntibodyResultId).label = 'CMV Total Antibody Result';

        (<DynamicField>this.Form.CmvTotalAntibodyDrawDate).labelHtml = '<b>CMV Total Antibody Draw Date</b>';
        (<DynamicLabel>this.View.CmvTotalAntibodyDrawDate).label = 'CMV Total Antibody Draw Date';

        (<DynamicField>this.Form.CmvIggResultId).labelHtml = '<b>CMV IgG Result</b>';
        (<DynamicLabel>this.View.CmvIggResultId).label = 'CMV IgG Result';

        (<DynamicField>this.Form.CmvIggDrawDate).labelHtml = '<b>CMV IgG Draw Date</b>';
        (<DynamicLabel>this.View.CmvIggDrawDate).label = 'CMV IgG Draw Date';

        (<DynamicField>this.Form.CmvIgmResultId).labelHtml = '<b>CMV IgM Result</b>';
        (<DynamicLabel>this.View.CmvIgmResultId).label = 'CMV IgM Result';

        (<DynamicField>this.Form.CmvIgmDrawDate).labelHtml = '<b>CMV IgM Draw Date</b>';
        (<DynamicLabel>this.View.CmvIgmDrawDate).label = 'CMV IgM Draw Date';

        (<DynamicField>this.Form.CmvStatusId).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.CmvStatusId).options = passFailOptions || null;
    }
}
