import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { SalesOrderService } from '../../services/sales-order.service';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { ClaimTypes } from '@model/ClaimTypes';
import { getIdFromRoute } from '@angular-common/libraries/common-functions.library';

@Component({
    templateUrl: './sales-order-detail.component.html',
})
export class SalesOrderDetailComponent implements OnInit {
    salesOrder: ISalesOrder;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private salesOrderService: SalesOrderService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.RecipientUsers, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = getIdFromRoute(this.route, 'salesOrderId');
        if (id) {
            this.getSalesOrderById(id);
        } else {
            this.router.navigate(['../', { relativeTo: this.route }]); // if no id found, try to navigate to recipient details
        }
        // emit changes to sales order to child components
        this.salesOrderService.changeEmitted$.subscribe((so) => {
            this.salesOrder = null;
            this.cdr.detectChanges();
            this.salesOrder = so;
        });
    }

    getSalesOrderById(id: number): void {
        this.salesOrderService.getById(id).subscribe((salesOrder) => {
            if (salesOrder === null) {
                this.notificationsService.error('Sales order not found');
                this.router.navigate(['../', { relativeTo: this.route }]);
            }
            this.salesOrder = salesOrder;
        });
    }
}
