import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IDonorBloodWork } from '@model/interfaces/donor-blood-work';
import { DonorBloodWorkDynamicControls, IDonorBloodWorkDynamicControlsParameters } from '@model/partials/donor-blood-work.form-controls';

export class DonorBloodWorkDynamicConfig<T extends IDonorBloodWork> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private donor: T, additionalParams: IDonorBloodWorkDynamicControlsParameters, private configControls?: string[]) {
        super();

        const dynamicControls = new DonorBloodWorkDynamicControls(this.donor, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'BloodTypeId',
                'RhFactorId',
                'KaryotypeDrawDate',
                'KaryotypeResultId',
                'KaryotypeAcceptedRejectedBy',
                'KaryotypeAcceptedRejectedDate',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
