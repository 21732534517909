import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDonationStatus } from '@model/interfaces/donation-status';

import { IDonorSample } from '@model/interfaces/donor-sample';
import { IDonorSampleStatus } from '@model/interfaces/donor-sample-status';
import { DonorDonationSampleDynamicControlsPartial } from '@model/partials/donor-donation-sample-partial.form-controls';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { DonorSampleStatusService } from '../../initial-screening/donor-test-samples/services/donor-sample-status.service';
import { DonorSampleService } from '../../services/donor-sample.service';
import { DonationStatusService } from '../services/donation-status.service';

@Component({
    selector: 'app-donation-add',
    templateUrl: './donor-donation-add.component.html',
})
export class DonorDonationAddComponent implements OnInit {
    @Input() donorId: number;
    @Output() onFinished: EventEmitter<IDonorSample> = new EventEmitter<IDonorSample>();
    donationSample: IDonorSample;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    newDonationSample: IDonorSample;
    newDonationForm: FormGroup;
    formCreated = false;
    isEditing = false;
    doubleClickIsDisabled = false;
    // abstract controls
    abstractNewDonationControls: any;

    sampleStatuses: IDonorSampleStatus[];
    donationStatuses: IDonationStatus[];

    constructor(
        private donorSampleService: DonorSampleService,
        private sampleStatusService: DonorSampleStatusService,
        private donationStatusService: DonationStatusService,
        private cdr: ChangeDetectorRef,
        private fb: FormBuilder,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.sampleStatusService.getItems(), this.donationStatusService.getItems()]).subscribe(([sampleStatuses, donationStatuses]) => {
            this.sampleStatuses = sampleStatuses;
            this.donationStatuses = donationStatuses;
            this.donationSample = this.donorSampleService.getEmptyDonorSample(this.donorId);
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.newDonationForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractNewDonationControls = new DonorDonationSampleDynamicControlsPartial(this.donationSample, {
            donationStatuses: this.donationStatuses,
            formGroup: 'DonorDonation',
            sampleStatuses: this.sampleStatuses,
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            DonorDonation: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.newDonationForm.valid) {
            this.donationSample = Object.assign({}, this.donationSample, this.newDonationForm.value.DonorDonation);
            this.donorSampleService.create(this.donationSample).subscribe((answer) => {
                this.donationSample.Id = answer;
                this.addDonorTestSampleFks();
                this.onFinished.emit(this.donationSample);
            });
        } else {
            markAllFormFieldsAsTouched(this.newDonationForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    addDonorTestSampleFks(): void {
        this.donationSample.SampleStatus = this.sampleStatuses.find((i) => i.Id === this.donationSample.SampleStatusId);
        this.donationSample.DonationStatus = this.donationStatuses.find((i) => i.Id === this.donationSample.DonationStatusId);
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.onFinished.emit(null);
    }

    error(): void {
        this.notificationService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationService.success('Donation saved successfully.');
    }
}
