import { NgModule } from '@angular/core';
import { AdminPortalFeatureModule } from '@common/feature.module';
import { AdminPortalSharedModule } from '@common/shared.module';

import { ShippingDetailsComponent } from './shipping-details.component';
import { ShippingBasicInfoComponent } from './shipping-basic-info/shipping-basic-info.component';
import { ShippingEmailComponent } from './shipping-emails/shipping-email.component';

@NgModule({
    declarations: [ShippingDetailsComponent, ShippingBasicInfoComponent, ShippingEmailComponent],
    exports: [ShippingDetailsComponent],
    imports: [AdminPortalFeatureModule, AdminPortalSharedModule],
})
export class ShippingModule {}
