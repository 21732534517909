import { Component, Input, OnInit } from '@angular/core';
import { IDonor } from '@model/interfaces/donor';

@Component({
    selector: 'app-donor-initial-screening',
    template: `
        <div class="miles-card padded">
            <h4>Initial Screening</h4>

            <div class="container-fluid">
                <app-donor-psychological-screening
                    [donor]="donor"
                    [canEdit]="canEditChild"
                    (emitIsEditing)="onEdit($event)"
                ></app-donor-psychological-screening>
                <br />

                <app-genetic-screening [donor]="donor" [canEdit]="canEditChild" (emitIsEditing)="onEdit($event)"></app-genetic-screening>
                <br />

                <app-blood-work [donor]="donor" [canEdit]="canEditChild" (emitIsEditing)="onEdit($event)"></app-blood-work>
                <br />

                <app-donor-test-samples [donor]="donor" [canEdit]="canEditChild" (emitIsEditing)="onEdit($event)"></app-donor-test-samples>
            </div>
        </div>
    `,
})
export class DonorInitialScreeningComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;
    isEditing = false;

    get canEditChild(): boolean {
        return this.canEdit && !this.isEditing;
    }

    constructor() {}

    ngOnInit(): void {}

    onEdit(event: boolean): void {
        this.isEditing = event;
    }
}
