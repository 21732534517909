import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IBirthDefect } from '@model/interfaces/birth-defect';

@Injectable({
    providedIn: 'root',
})
export class BirthDefectService extends StaticMetaItemService<IBirthDefect> {
    constructor(public http: HttpClient) {
        super('BirthDefectService', 'Defect', 'DefectIds', '/meta-items/BirthDefect', http);
    }
}
