import { Validators } from '@angular/forms';
import { AddressDynamicControls as base, IAddressDynamicControlsParameters as ibase } from '../form-controls/address.form-controls';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, LabelPositions, SelectInputTypes } from '@mt-ng2/dynamic-form';

import { ICountry } from '../interfaces/country';
import { IState } from '../interfaces/state';
import { FormYesNoButtonComponent } from '@angular-common/components/form-yes-no-button.component';
import { BuildDonorFormLabel } from '@angular-common/libraries/build-donor-form-label.library';
import { IAddress } from '../interfaces/address';

export interface IAddressDynamicControlsParameters extends ibase {
    addressTypeId?: number;
    countries: ICountry[];
    formGroup?: string;
    states: IState[];
}

export class AddressDynamicControls extends base {
    constructor(public value?: IAddress, public additionalParameters?: IAddressDynamicControlsParameters) {
        super(value, additionalParameters);

        (<DynamicField>this.Form.StateCode) = new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            name: 'StateCode',
            labelPosition: { position: LabelPositions.Top, colsForLabel: 1},
            options: additionalParameters.states.filter( s => s.CountryCode == value?.CountryCode),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: value?.StateCode,
        });

        (<DynamicField>this.Form.CountryCode) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Country',
            name: 'CountryCode',
            options: additionalParameters.countries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: value?.CountryCode,
        });

        (<DynamicField>this.Form.IsCurrentAddress) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: 'Is This Your Current Address?',
            labelHtml: BuildDonorFormLabel(`Is this your current address?`),
            name: 'IsCurrentAddress',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: additionalParameters.addressTypeId ? additionalParameters.addressTypeId === 2 : true,
        });

        (<DynamicField>this.Form.City).validation = [Validators.required];
        (<DynamicField>this.Form.City).validators = { required: true};

        (<DynamicField>this.Form.Zip).validation = [Validators.required];
        (<DynamicField>this.Form.Zip).validators = { required: true};

        (<DynamicField>this.Form.Address1).validation = [Validators.required];
        (<DynamicField>this.Form.Address1).validators = { required: true};
    }
}
