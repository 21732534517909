import { NgModule } from '@angular/core';

import { AdminPortalSharedModule } from '@common/shared.module';
import { AdminPortalFeatureModule } from '@common/feature.module';

import { SalesOrderAddComponent } from './sales-order-add/sales-order-add.component';
import { SalesOrderBasicInfoComponent } from './sales-order-basic-info/sales-order-basic-info.component';
import { SalesOrderDetailComponent } from './sales-order-detail/sales-order-detail.component';
import { SalesOrdersComponent } from './sales-order-list/sales-orders.component';
import { LinkDonorSearchComponent } from './link-donor/link-donor-search.component';
import { SalesOrderLinkedDonorComponent } from './link-donor/linked-donor-display.component';
import { SalesOrderInventoryComponent } from './inventory/sales-order-inventory.component';
import { ThawingModule } from './thawings/thawing.module';
import { ShippingModule } from './shipping/shipping.module';
import { OutcomesModule } from './outcomes/outcomes.module';

@NgModule({
    declarations: [
        SalesOrdersComponent,
        SalesOrderAddComponent,
        SalesOrderDetailComponent,
        SalesOrderBasicInfoComponent,
        LinkDonorSearchComponent,
        SalesOrderLinkedDonorComponent,
        SalesOrderInventoryComponent,
    ],
    exports: [SalesOrdersComponent, SalesOrderAddComponent, SalesOrderDetailComponent],
    imports: [AdminPortalSharedModule, AdminPortalFeatureModule, ThawingModule, ShippingModule, OutcomesModule],
})
export class SalesOrderModule {}
