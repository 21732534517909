import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { LocalizedDateBaseService } from '../../services/localized-date-base.service';

import { IDonorBasicInfo } from '@model/interfaces/donor-basic-info';
import { IUpdateBasicInfoDTO } from '@model/interfaces/custom/update-basic-info-dto';
import { CommonService } from '../../services/common.service';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReferralTypeService } from '../../services/referral-type.service';
import { GenderService } from './basic-info-meta-items/gender.service';
import { UnitsOfMeasurementService } from './basic-info-meta-items/units-of-measurement.service';
import { AddressTypeService } from './basic-info-meta-items/address-type.service';

export const datesToLocalize = [
    'Dob',
];

@Injectable({
    providedIn: 'root',
})
export class BasicInfoService extends LocalizedDateBaseService<IDonorBasicInfo> {

    addressAdditionalParameters$ = forkJoin({
        countries: this.optService.getCountriesMetaItems(),
        states: this.optService.getStatesMetaItems(),
    });

    donorAddressAddtionalParameters$ = forkJoin({ addressTypes: this.addressTypeService.getItems() });

    getBasicAdditionalParameters(): Observable<any> {
        return forkJoin({
            genders: this.genderService.getItems(),
            heights: this.unitsOfMesaurementService.getAdultHeightItems(),
            referralTypes: this.referralTypesService.getItems().pipe(map((types) => types.filter((rt) => rt.Active))),
            weights: this.unitsOfMesaurementService.getAdultWeightItems(),
        });
    }
    constructor(
        public http: HttpClient,
        private optService: CommonService,
        private referralTypesService: ReferralTypeService,
        private genderService: GenderService,
        private addressTypeService: AddressTypeService,
        private unitsOfMesaurementService: UnitsOfMeasurementService) {
        super('/applicaton-part-one/basic-info', http, datesToLocalize);
    }

    update(data: IDonorBasicInfo): Observable<IUpdateBasicInfoDTO> {
        return super.update(data);
    }

    updateWithFks(data: IDonorBasicInfo): Observable<IUpdateBasicInfoDTO> {
        return super.updateWithFks(data);
    }
}
