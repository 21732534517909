<div class="miles-card padded" *ngIf="donor">
    <h4>
        Donor Application Details
        <span (click)="edit()" class="pull-right" *ngIf="canEdit && !isEditing">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div>
        <div class="row">
            <div class="col-md-12">
                <button type="button" class="btn btn-flat btn-primary pull-right" (click)="downloadApplicationDetails()">Details PDF</button>
            </div>
        </div>
        <div class="row">
            <div class="padded">
                <ngx-skeleton-loader count="25" *ngIf="!donor"></ngx-skeleton-loader>
                <div *ngIf="donor">
                    <ngb-tabset #formTabs type="pills">
                        <ngb-tab id="tab-basicsComplete" title="Basics Complete">
                            <ng-template ngbTabContent>
                                <app-basic-info-details
                                    *ngIf="donor"
                                    [donor]="donor"
                                    [isEditing]="isEditing"
                                    (outputCancelClick)="cancelClick()"
                                ></app-basic-info-details>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="tab-preliminaryHistory" title="Preliminary History">
                            <ng-template ngbTabContent>
                                <app-preliminary-history-details
                                    *ngIf="donor"
                                    [isEditing]="isEditing"
                                    [donor]="donor"
                                    (outputCancelClick)="cancelClick()"
                                ></app-preliminary-history-details>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="tab-medicalIssues" title="Medical Issues">
                            <ng-template ngbTabContent>
                                <app-medical-issues-details
                                    *ngIf="donor"
                                    [donor]="donor"
                                    [isEditing]="isEditing"
                                    (outputCancelClick)="cancelClick()"
                                ></app-medical-issues-details>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="tab-whoAreYou" title="Who Are You?">
                            <ng-template ngbTabContent>
                                <app-who-are-you-details
                                    *ngIf="donor"
                                    [donor]="donor"
                                    [isEditing]="isEditing"
                                    (outputCancelClick)="cancelClick()"
                                ></app-who-are-you-details>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab id="tab-familyHistory" title="Family History">
                            <ng-template ngbTabContent>
                                <app-family-history-details
                                    *ngIf="donor"
                                    [donor]="donor"
                                    [isEditing]="isEditing"
                                    (outputCancelClick)="cancelClick()"
                                ></app-family-history-details>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>
