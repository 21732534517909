import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BulkUpdateTypes } from '@model/enums/custom/bulk-update-types.enum';
import { IInventoryPositionDto } from '@model/interfaces/custom/inventory-position-dto';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { IInventory } from '@model/interfaces/inventory';
import { InventoryVesselService } from 'admin-portal/donors/inventory/services/inventory-vessel.service';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

interface IGroupedInventory {
    Title: string;
    Inventories: IInventory[];
}

@Component({
    selector: 'app-bulk-inventory-update',
    styles: [
        `
            h4 {
                color: #095093;
                font-weight: bold;
                border-bottom: solid 1px #ccc;
                padding-bottom: 5px;
            }
        `,
        `
            h4.confirmed {
                color: #344f68;
                font-weight: bold;
                border-bottom: solid 1px #ccc;
                padding-bottom: 5px;
            }
        `,
    ],
    template: `
        <div class="row" *ngIf="ready">
            <ngb-accordion [closeOthers]="true" #acc="ngbAccordion">
                <ngb-panel *ngFor="let group of groupedInventory | keyvalue" [id]="group.key">
                    <ng-template ngbPanelHeader let-opened="opened">
                        <h4>
                            Vial Type: {{ group.value.Inventories[0].SamplePrepMethod.Name }} <br />
                            {{ group.value.Title }} {{ opened ? '- Editing' : '' }}
                            <span class="pull-right" (click)="acc.toggle(group.key)" *ngIf="!opened">
                                <i class="fa fa-lg fa-edit"></i>
                            </span>
                        </h4>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <donor-multiple-inventory-locations-form
                            [inventories]="group.value.Inventories"
                            [donation]="donation"
                            [updateType]="updateType"
                            (cancel)="acc.toggle(group.key)"
                            (onEditFinished)="onInventoryLocationEditFinished($event)"
                        ></donor-multiple-inventory-locations-form>
                    </ng-template>
                </ngb-panel>
                <ngb-panel *ngFor="let group of updatedInventory" [id]="group.key">
                    <ng-template ngbPanelHeader>
                        <h4 class="confirmed" *ngIf="!isGroupDiscarded(group)">
                            Vial Type: {{ group.Inventories[0].SamplePrepMethod.Name }} <br />
                            {{ group.Title }}
                        </h4>
                        <h4 class="confirmed" *ngIf="isGroupDiscarded(group)">
                            Vial Type: {{ group.Inventories[0].SamplePrepMethod.Name }} <br />
                            DISCARDED
                        </h4>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    `,
})
export class BulkInventoryUpdateComponent implements OnInit {
    @Input() donation: IDonorSample;
    @Input() updateType: BulkUpdateTypes;
    @Output() onEditFinished = new EventEmitter<IInventory[]>();
    @Output() saveReady = new EventEmitter<boolean>();
    @ViewChild('acc') acc;

    donationInventory: IInventory[];
    updatedDonationInventory: IInventory[] = [];
    groupedInventory: any;
    groupedInventoryCount: number;
    updatedInventory: IGroupedInventory[] = [];
    ready: boolean;

    constructor(private inventorySerivce: InventoryVesselService) {}

    ngOnInit(): void {
        this.donationInventory = this.donation.Inventories;
        this.groupByInventoryLocation();
    }

    groupByInventoryLocation(): void {
        this.groupedInventory = this.groupBy(this.donationInventory, 'CaneId');
        const allObservables = Object.entries(this.groupedInventory).map(([k, v]: [string, IGroupedInventory]) => {
            return this.setInventoryTitle(+k, v);
        });
        forkJoin(allObservables).subscribe(() => {
            this.groupedInventoryCount = Object.keys(this.groupedInventory).length;
            this.ready = true;
        });
    }

    groupBy(xs: any[], key: string): any {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || { Inventories: [], Title: '' }).Inventories.push(x);
            return rv;
        }, {});
    }

    setInventoryTitle(caneId: number, groupedInventory: IGroupedInventory): Observable<IInventoryPositionDto> {
        return this.inventorySerivce.getInventoryPositionByCane(caneId).pipe(
            tap((resp) => {
                groupedInventory.Title = `${groupedInventory.Inventories.length} Vials - Tank: ${resp.Tank.Name} ${resp.Tank.Number} Can: ${resp.Can.Number} Cane: ${resp.Cane.Number}`;
            }),
        );
    }

    onInventoryLocationEditFinished(updates: IInventory[]): void {
        this.updatedDonationInventory.push(...updates);
        const [openId] = this.acc.activeIds;
        const updatedInventory = { ...this.groupedInventory[openId] } as IGroupedInventory;
        updatedInventory.Inventories = updates;
        this.setInventoryTitle(updates[0].CaneId, updatedInventory).subscribe(() => {
            this.updatedInventory.push(updatedInventory);
            delete this.groupedInventory[openId];
            this.onEditFinished.emit(this.updatedDonationInventory);
            if (this.updatedInventory.length === this.groupedInventoryCount) {
                this.saveReady.emit(true);
            }
        });
    }

    isGroupDiscarded(invGroup: IGroupedInventory): boolean {
        return invGroup.Inventories.some((i) => i.Discarded);
    }
}
