<div>
    <h4>
        Donor Test Samples
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-plus" (click)="createTestSample()"></i>
        </span>
    </h4>
    <div *ngIf="!isEditing">
        <entity-list
            [entities]="samples"
            [total]="total"
            [(currentPage)]="currentPage"
            [(itemsPerPage)]="itemsPerPage"
            (onPageChanged)="getSamples()"
            (onItemSelected)="sampleSelected($event)"
            (onColumnSorted)="columnSorted($event)"
            [entityListConfig]="entityListConfig"
        >
        </entity-list>
    </div>
    <div *ngIf="isEditing">
        <div *ngIf="formCreated">
            <form [formGroup]="donorTestSamplesForm" (ngSubmit)="formSubmitted()">
                <div>
                    <dynamic-field [field]="abstractDonorTestSampleControls.SampleDate" [form]="donorTestSamplesForm"></dynamic-field>
                    <dynamic-field [field]="abstractDonorTestSampleControls.TestSampleTypeId" [form]="donorTestSamplesForm"></dynamic-field>
                    <dynamic-field [field]="abstractDonorTestSampleControls.SampleStatusId" [form]="donorTestSamplesForm"></dynamic-field>
                </div>
                <div>
                    <button type="submit" [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
                    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
