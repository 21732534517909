import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IReasonsNotToCount } from '@model/interfaces/reasons-not-to-count';

@Injectable({
    providedIn: 'root',
})
export class ReasonsNotToCountService extends StaticMetaItemService<IReasonsNotToCount> {
    constructor(public http: HttpClient) {
        super('ReasonsNotToCountService', 'Count', 'CountIds', '/meta-items/ReasonsNotToCount', http);
    }
}
