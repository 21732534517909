import { IReservation } from '@model/interfaces/reservation';
import { Component, Input, OnInit } from '@angular/core';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ExtraParams } from '@common/extra-params/extra-params.library';
import { Observable } from 'rxjs';
import { entityListModuleConfig } from '@common/shared.module';
import { HttpResponse } from '@angular/common/http';
import { ReservationService } from '../../reservations/services/reservation.service';
import { RecipientReservationsEntityListConfig } from './recipient-reservations.entity-list-config';
import { IReservationDto } from '@model/interfaces/custom/reservation-dto';

@Component({
    selector: 'app-recipient-reservations',
    template: `
        <div class="miles-card padded">
            <h4>Recipient Reservations</h4>
            <entity-list
                *ngIf="reservations"
                [entities]="reservations"
                [entityListConfig]="entityListConfig"
                [emptyMessage]="noResultText"
                [itemsPerPage]="itemsPerPage"
                [total]="total"
                (onColumnSorted)="columnSorted($event)"
            ></entity-list>
        </div>
    `,
})
export class RecipientReservationsComponent implements OnInit {
    @Input() recipientId: number;
    reservations: IReservationDto[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new RecipientReservationsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddReservation = false;

    constructor(
        private reservationService: ReservationService,
    ) {}

    ngOnInit(): void {
        this.getReservations();
    }

    getReservationsCall(): Observable<HttpResponse<IReservationDto[]>> {
        const search = this.query;
        const _extraSearchParams = ExtraParams.build({
            RecipientIds: this.recipientId,
        });

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.reservationService.getReservations(searchparams);
    }

    getReservations(): void {
        this.getReservationsCall().subscribe((answer) => {
            this.reservations = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getReservations();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getReservations();
    }
}
