import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserEntitySearchService } from './user-entity-search.service';
import { IVwDonorInventorySearchEntity } from '@model/interfaces/vw-donor-inventory-search-entity';

@Injectable({
    providedIn: 'root',
})
export class DonorInventorySearchEntityService extends UserEntitySearchService<IVwDonorInventorySearchEntity> {
    constructor(public http: HttpClient) {
        super('/donor-inventory-search-entities', http);
    }
}
