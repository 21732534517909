import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatureModule } from '../feature.module';
import { SharedModule } from '../shared.module';

import { DonorApplicationBasicFormComponent } from './donor-application-basic-form/donor-application-basic-form.component';
import { DonorApplicationDonorUserFormComponent } from './donor-application-donor-user-form/donor-application-donor-user-form.component';
import { PreliminaryHistoryComponent } from './preliminary-history/preliminary-history.component';
import { MedicalIssuesComponent } from './medical-history/medical-issues.component';
import { IssueSelectorComponent } from './medical-history/issue-selector/issue-selector.component';
import { WhoAreYouComponent } from './who-are-you/who-are-you.component';
import { FamilyHistoryComponent } from './family-history/family-history.component';
import { IndividualFamilyHistoryComponent } from './family-history/individual-family-history-component/individual-family-history.component';

@NgModule({
    declarations: [
        DonorApplicationBasicFormComponent,
        PreliminaryHistoryComponent,
        DonorApplicationDonorUserFormComponent,
        MedicalIssuesComponent,
        IssueSelectorComponent,
        WhoAreYouComponent,
        FamilyHistoryComponent,
        IndividualFamilyHistoryComponent,
    ],
    exports: [
        DonorApplicationBasicFormComponent,
        PreliminaryHistoryComponent,
        DonorApplicationDonorUserFormComponent,
        MedicalIssuesComponent,
        IssueSelectorComponent,
        WhoAreYouComponent,
        FamilyHistoryComponent,
        IndividualFamilyHistoryComponent,
    ],
    imports: [CommonModule, FeatureModule, SharedModule],
})
export class DonorApplicationFormsModule {}
