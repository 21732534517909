import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { IDonor } from '@model/interfaces/donor';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DonorService } from '../services/donor.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-donor-application-details',
    styles: [''],
    templateUrl: 'donor-application-details.component.html',
})
export class DonorApplicationDetailsComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;

    isEditing = false;

    tabNames = {
        basicsComplete: 'tab-basics',
        familyHistory: 'tab-familyHistory',
        medicalIssues: 'tab-medicalIssues',
        preliminaryHistory: 'tab-preliminaryHistory',
        whoAreYou: 'tab-whoAreYou',
    };
    tabsLoaded: boolean;
    tabNameSectionNameMap = {
        'tab-basicsComplete': 'Basics Complete',
        'tab-familyHistory': 'Family History',
        'tab-medicalIssues': 'Medical Issues',
        'tab-preliminaryHistory': 'Preliminary History',
        'tab-whoAreYou': 'Who Are You?',
    };

    activeTab = 'tab-basicsComplete';
    constructor(private notificationService: NotificationsService, private cdr: ChangeDetectorRef, private donorService: DonorService) {}

    ngOnInit(): void {
        this.tabsLoaded = false;
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    downloadApplicationDetails(): void {
        this.donorService.getDonorDetailsPdf(this.donor.Id).subscribe((bytes: any) => {
            const thefile = new Blob([bytes as ArrayBuffer], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, `Donor# ${this.donor.Id} Application Details.pdf.pdf`);
        });
    }
}
