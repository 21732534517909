import { IEntityListConfig, EntityListConfig, EntityListColumn, SelectTypes } from '@mt-ng2/entity-list-module';

export class DonorInventorySelectEntityListConfig extends EntityListConfig {
    constructor(select = true) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['DonorId'],
                    name: 'Donor Id',
                }),
                new EntityListColumn({
                    accessors: ['DonationDate'],
                    name: 'Donation Date',
                    pipes: ['date:mediumDate'],
                }),
                new EntityListColumn({
                    accessors: ['ReleaseDate'],
                    name: 'Release Date',
                    pipes: ['date:mediumDate'],
                }),
                new EntityListColumn({
                    accessors: ['InventoryStatus'],
                    name: 'Product Type',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['VialType'],
                    name: 'Vial Status',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Location'],
                    name: 'Location',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['VialNumber'],
                    name: 'Vial Number',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        if (select) {
            listConfig.select = {
                type: SelectTypes.Multi,
                uniqueId: 'InventoryId',
            };
        }
        super(listConfig);
    }
}
