function RemoveFileExtension(filename: string): string {
    return filename.slice(0, filename.lastIndexOf('.')) || filename;
}

function GetExtensionFromFilename(filename: string): string {
    return filename.slice(filename.lastIndexOf('.') + 1); // leave out .
}

function AddFileExtension(filename: string, extension: string): string {
    return filename.concat('.', extension);
}

export { RemoveFileExtension, GetExtensionFromFilename, AddFileExtension };
