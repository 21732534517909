import { getIdFromRoute } from '@angular-common/libraries/common-functions.library';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ISalesOrder } from '@model/interfaces/sales-order';
import { SalesOrderService } from '../../services/sales-order.service';

@Component({
    templateUrl: './sales-order-add.component.html',
})
export class SalesOrderAddComponent implements OnInit {
    salesOrder: ISalesOrder;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private salesorderService: SalesOrderService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        const recipientId = getIdFromRoute(this.route, 'recipientUserId');
        this.salesOrder = this.salesorderService.getEmptySalesOrder(recipientId);
    }
}
