import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IReviewOutcome } from '@model/interfaces/review-outcome';

@Injectable({
    providedIn: 'root',
})
export class ReviewOutcomeService extends StaticMetaItemService<IReviewOutcome> {
    constructor(public http: HttpClient) {
        super('ReviewOutcomeService', 'Outcome', 'OutcomeIds', '/meta-items/ReviewOutcome', http);
    }
}
