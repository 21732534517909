import { DynamicField, DynamicFieldType, DynamicFieldTypes, LabelPosition, LabelPositions } from '@mt-ng2/dynamic-form';
import { DonorPreliminaryHistoryDynamicControls, IDonorPreliminaryHistoryDynamicControlsParameters } from '../form-controls/donor-preliminary-history.form-controls';
import { IDonorPreliminaryHistory } from '../interfaces/donor-preliminary-history';
import { FormYesNoButtonComponent } from '../../../angular-common/components/form-yes-no-button.component';
import { BuildDonorFormLabel } from '../../../angular-common/libraries/build-donor-form-label.library';
import { Validators } from '@angular/forms';

export class DonorPreliminaryHistoryDynamicControlsPartial extends DonorPreliminaryHistoryDynamicControls {

    constructor(donorpreliminaryhistoryPartial?: IDonorPreliminaryHistory, additionalParameters?: IDonorPreliminaryHistoryDynamicControlsParameters) {
        super(donorpreliminaryhistoryPartial, additionalParameters);

        (<DynamicField>this.Form.HasStd).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasStd).labelHtml = BuildDonorFormLabel(`Have you tested POSITIVE for any STD’s in the past year?`);
        (<DynamicField>this.Form.HasStd).value = donorpreliminaryhistoryPartial.HasStd;
        (<DynamicField>this.Form.HasStd).validation = [Validators.required];
        (<DynamicField>this.Form.HasStd).validators = { required: true};
        (<DynamicField>this.Form.StdName).label = `What Disease?`;
        (<DynamicField>this.Form.StdName).validation = [Validators.required];
        (<DynamicField>this.Form.StdName).validators = { required: true};
        (<DynamicField>this.Form.DateOfStdTreatment).label = `Date of Treatment?`;
        (<DynamicField>this.Form.DateOfStdTreatment).placeholder = `MM/DD/YYYY`;
        (<DynamicField>this.Form.DateOfStdTreatment).validation = [Validators.required];
        (<DynamicField>this.Form.DateOfStdTreatment).validators = { required: true};

        (<DynamicField>this.Form.HadSexWithMan).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HadSexWithMan).labelHtml = BuildDonorFormLabel(`Have you had sex with another man in the preceding five years?`);
        (<DynamicField>this.Form.HadSexWithMan).value = donorpreliminaryhistoryPartial.HadSexWithMan;
        (<DynamicField>this.Form.HadSexWithMan).validation = [Validators.required];
        (<DynamicField>this.Form.HadSexWithMan).validators = { required: true};

        (<DynamicField>this.Form.HasHiv).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasHiv).labelHtml = BuildDonorFormLabel(`Have you tested positive for HIV?`);
        (<DynamicField>this.Form.HasHiv).value = donorpreliminaryhistoryPartial.HasHiv;
        (<DynamicField>this.Form.HasHiv).validation = [Validators.required];
        (<DynamicField>this.Form.HasHiv).validators = { required: true};

        (<DynamicField>this.Form.HadVasectomy).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HadVasectomy).labelHtml = BuildDonorFormLabel('Have you had a vasectomy (including reversal)?');
        (<DynamicField>this.Form.HadVasectomy).value = donorpreliminaryhistoryPartial.HadVasectomy;
        (<DynamicField>this.Form.HadVasectomy).validation = [Validators.required];
        (<DynamicField>this.Form.HadVasectomy).validators = { required: true};

        (<DynamicField>this.Form.HasDonatedBefore).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasDonatedBefore).labelHtml = BuildDonorFormLabel('Have you ever donated your sperm before?');
        (<DynamicField>this.Form.HasDonatedBefore).value = donorpreliminaryhistoryPartial.HasDonatedBefore;
        (<DynamicField>this.Form.HasDonatedBefore).validation = [Validators.required];
        (<DynamicField>this.Form.HasDonatedBefore).validators = { required: true};
        (<DynamicField>this.Form.TimesDonated).label = 'How many times?';
        (<DynamicField>this.Form.TimesDonated).validation = [Validators.required];
        (<DynamicField>this.Form.TimesDonated).validators = { required: true};

        (<DynamicField>this.Form.ListedOnAnotherRoster).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.ListedOnAnotherRoster).labelHtml = BuildDonorFormLabel('Are you currently listed on another sperm donor roster?');
        (<DynamicField>this.Form.ListedOnAnotherRoster).value = donorpreliminaryhistoryPartial.ListedOnAnotherRoster;
        (<DynamicField>this.Form.ListedOnAnotherRoster).validation = [Validators.required];
        (<DynamicField>this.Form.ListedOnAnotherRoster).validators = { required: true};
        (<DynamicField>this.Form.OtherRosters).label = 'Where?';
        (<DynamicField>this.Form.OtherRosters).validation = [Validators.required];
        (<DynamicField>this.Form.OtherRosters).validators = { required: true};

        (<DynamicField>this.Form.IsAdopted).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.IsAdopted).labelHtml = BuildDonorFormLabel('Are you adopted? <small>(You must know your family medical history in order to move forward with the donation process)</small>');
        (<DynamicField>this.Form.IsAdopted).value = donorpreliminaryhistoryPartial.IsAdopted;
        (<DynamicField>this.Form.IsAdopted).validation = [Validators.required];
        (<DynamicField>this.Form.IsAdopted).validators = { required: true};
        (<DynamicField>this.Form.KnowsFamilyHistory).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.KnowsFamilyHistory).labelHtml = BuildDonorFormLabel('Do you know your family history or can you obtain this information?');
        (<DynamicField>this.Form.KnowsFamilyHistory).value = donorpreliminaryhistoryPartial.KnowsFamilyHistory;
        (<DynamicField>this.Form.KnowsFamilyHistory).validation = [Validators.required];
        (<DynamicField>this.Form.KnowsFamilyHistory).validators = { required: true};

        (<DynamicField>this.Form.HasAdoptedParents).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasAdoptedParents).labelHtml = BuildDonorFormLabel('Are either of your parents adopted?');
        (<DynamicField>this.Form.HasAdoptedParents).value = donorpreliminaryhistoryPartial.HasAdoptedParents;
        (<DynamicField>this.Form.HasAdoptedParents).validation = [Validators.required];
        (<DynamicField>this.Form.HasAdoptedParents).validators = { required: true};
        (<DynamicField>this.Form.KnowsParentsFamilyHistory).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.KnowsParentsFamilyHistory).labelHtml = BuildDonorFormLabel('Do you know your parents\' family history or can you obtain this information?');
        (<DynamicField>this.Form.KnowsParentsFamilyHistory).value = donorpreliminaryhistoryPartial.KnowsParentsFamilyHistory;
        (<DynamicField>this.Form.KnowsParentsFamilyHistory).validation = [Validators.required];
        (<DynamicField>this.Form.KnowsParentsFamilyHistory).validators = { required: true};

    }
}
