<ngx-skeleton-loader count="25" *ngIf="!formCreated"></ngx-skeleton-loader>
<div *ngIf="formCreated">
    <form class="miles-form padded row application-section" [formGroup]="medicalIssuesForm">
        <div class="input-scaffold" style="margin-top: 8px">
            <label class="input-label">
                <span class="input-scaffold__label"
                    >For each of the following questions please select any options for individuals in your family that have been diagnosed with the
                    preceding medical condition.</span
                >
            </label>
        </div>

        <!-- For each ApplicationMedicalIssue Create Component and pass in ApplicationMedicalIssue -->
        <div *ngFor="let type of medicalIssueTypes">
            <div class="form-section-border">
                <h3>{{ type.Name }}</h3>
            </div>
            <div *ngFor="let issue of getIssuesByTypeId(type.Id)">
                <issue-selector
                    [denied]="denied"
                    [donorMedicalHistory]="issue"
                    [donorRelationshipTypes]="donorRelationshipType"
                    [isSaved]="isSaved"
                    (valueChange)="medicalIssueChanged()"
                    (subissueChange)="setSubissues($event)"
                >
                </issue-selector>
                <br />
            </div>
        </div>

        <div class="input-scaffold">
            <dynamic-field
                [field]="abstractApplicationIssueControls.IsTakingMedication"
                [form]="medicalIssuesForm"
                (valueChanges)="selectedIsTakingMedication = $event"
            ></dynamic-field>
        </div>
        <div *ngIf="selectedIsTakingMedication" class="input-scaffold">
            <dynamic-field [field]="abstractApplicationIssueControls.CurrentMedications" [form]="medicalIssuesForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field
                [field]="abstractApplicationIssueControls.HasSurgeries"
                [form]="medicalIssuesForm"
                (valueChanges)="selectedHasSurgeries = $event"
            ></dynamic-field>
        </div>
        <div *ngIf="selectedHasSurgeries" class="input-scaffold">
            <dynamic-field [field]="abstractApplicationIssueControls.Surgeries" [form]="medicalIssuesForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field [field]="abstractApplicationIssueControls.HasTakenHgh" [form]="medicalIssuesForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field [field]="abstractApplicationIssueControls.HasHadMajorRadiationExposure" [form]="medicalIssuesForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field [field]="abstractApplicationIssueControls.OtherIssues" [form]="medicalIssuesForm"></dynamic-field>
        </div>
    </form>
</div>
