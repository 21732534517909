import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IGpa } from '@model/interfaces/gpa';

@Injectable({
    providedIn: 'root',
})
export class GpaService extends StaticMetaItemService<IGpa> {
    constructor(public http: HttpClient) {
        super('GpaService', 'Gpa', 'GpaIds', '/options/gpas', http);
    }
}
