import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { IDonorSample } from '@model/interfaces/donor-sample';
import { ILabDataBase } from '@model/interfaces/custom/lab-data-base';
import { SampleTypes } from '@model/enums/sample-types.enum';
import { IDonorSampleLabData } from '@model/interfaces/donor-sample-lab-data';
import { IDonorSampleRemainderLabData } from '@model/interfaces/donor-sample-remainder-lab-data';

import { DonorSampleService } from '../../services/donor-sample.service';
import { LabDataLibrary } from '../services/lab-data-library.service';
import { LabDataBaseService } from '../services/abstract-lab-data.service';
import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';

@Component({
    template: '',
})
export class AbstractLabDataComponent implements OnInit, OnDestroy {
    @Input() testSample: IDonorSample;
    @Input() dataService: LabDataBaseService<IDonorSampleLabData | IDonorSampleRemainderLabData>;

    labData: ILabDataBase;
    sectionId: number;

    calculatedFields: any;

    subs = new Subscription();

    get isTestSample(): boolean {
        return this.testSample.SampleTypeId === SampleTypes.TestSample;
    }

    get isDonation(): boolean {
        return this.testSample.SampleTypeId === SampleTypes.Donation;
    }

    get isRemainderData(): boolean {
        return (
            this.sectionId === DonorTestSampleLabDataSections.RemainderVials ||
            this.sectionId === DonorTestSampleLabDataSections.RemainderVialDetails ||
            this.sectionId === DonorTestSampleLabDataSections.RemainderTestThawInfo
        );
    }

    get sectionLabData(): ILabDataBase {
        return this.isRemainderData ? this.testSample.DonorSampleRemainderLabData : this.testSample.DonorSampleLabData;
    }

    constructor(protected testSampleService: DonorSampleService) {}

    ngOnInit(): void {
        this.calculatedFields = LabDataLibrary.getEmptyCalculatedFields(this.sectionId);
        this.getLabData();
        this.setCalculatedFields();
        this.subs.add(
            this.dataService.changeEmitted$.subscribe(() => {
                this.refreshData();
            }),
        );
        this.subs.add(
            this.testSampleService.changeEmitted$.subscribe(() => {
                this.refreshData();
            }),
        );
    }

    refreshData(): void {
        this.getLabData();
        this.calculateFields(this.labData);
    }

    getLabData(): void {
        this.labData = this.sectionLabData ? { ...this.sectionLabData } : this.dataService.getEmptyLabData();
        this.labData.DonorTestSample = Object.assign({}, this.testSample);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    setCalculatedFields(event?: any): void {
        if (!event) {
            event = this.labData;
        }
        this.calculateFields(event);
    }

    calculateFields(currentValues: ILabDataBase): void {
        this.calculatedFields = LabDataLibrary.getCalculatedFields(this.sectionId, currentValues);
    }
}
