import { Router } from '@angular/router';
import { IVwDonorInventory } from '@model/interfaces/vw-donor-inventory';
import { EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class DonorInventoryEntityListConfig extends EntityListConfig {
    constructor(private router: Router) {
        super({
            columns: [
                new EntityListColumn({
                    accessors: ['DonationDate'],
                    name: 'Donation Date',
                    pipes: ['date:mediumDate'],
                }),
                new EntityListColumn({
                    accessors: ['InventoryStatus'],
                    name: 'Inventory Type',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Location'],
                    name: 'Location',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['VialNumber'],
                    name: 'Vial Number',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['VialType'],
                    name: 'Vial Type',
                }),
                new EntityListColumn({
                    accessors: ['SalesOrderId'],
                    linkFunction: (value: IVwDonorInventory) => {
                        this.router.navigate([`/recipient-users/${value.SalesOrderRecipientId}/sales-orders/${value.SalesOrderId}`]);
                    },
                    name: 'Sales Order',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (value: IVwDonorInventory) => {
                        return value.SalesOrderId ? value.SalesOrderRecipientId : value.ReservationRecipientId;
                    },
                    linkFunction: (value: IVwDonorInventory) => {
                        this.router.navigate([`/reservations`], {
                            queryParams: { recipientId: value.SalesOrderId ? value.SalesOrderRecipientId : value.ReservationRecipientId },
                        });
                    },
                    name: 'Recipient',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (value: IVwDonorInventory) => {
                        return value.ReservationId ? 'Yes' : 'No';
                    },
                    name: 'Reserved',
                    sort: { disableSort: true },
                }),
            ],
        });
    }
}
