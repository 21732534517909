import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class DonorInventorySearchEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Id'],
                    name: 'Donor Id',
                }),
                new EntityListColumn({
                    accessors: ['FirstName'],
                    name: 'First Name',
                }),
                new EntityListColumn({
                    accessors: ['LastName'],
                    name: 'Last Name',
                }),
                new EntityListColumn({
                    accessors: ['Email'],
                    name: 'Email',
                }),
                new EntityListColumn({
                    accessors: ['IuiQuarantine'],
                    name: 'IUI/Quarantine',
                }),
                new EntityListColumn({
                    accessors: ['IuiReleased'],
                    name: 'IUI/Released',
                }),
                new EntityListColumn({
                    accessors: ['IvfQuarantine'],
                    name: 'IVF/Quarantine',
                }),
                new EntityListColumn({
                    accessors: ['IvfReleased'],
                    name: 'IVF/Released',
                }),
                new EntityListColumn({
                    accessors: ['IcsiQuarantine'],
                    name: 'ICSI/Quarantine',
                }),
                new EntityListColumn({
                    accessors: ['IcsiReleased'],
                    name: 'ICSI/Released',
                }),
            ],
        };
        super(listConfig);
    }
}
