import { getIdFromRoute } from '@angular-common/libraries/common-functions.library';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimTypes } from '@model/ClaimTypes';
import { DocumentEntities } from '@model/enums/document-entities.enum';
import { NoteEntities } from '@model/enums/note-entities.enum';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { Subscription } from 'rxjs';

import { DonorSampleService } from '../../../services/donor-sample.service';
import { DonorSampleLabDataService } from '../../../donor-sample-lab-data/services/donor-sample-lab-data.service';
import { DonorSampleLabDataDynamicControlsPartial } from '@model/partials/donor-test-sample-lab-data-partial.form-controls';

@Component({
    selector: 'app-donor-test-sample-detail',
    templateUrl: './donor-test-sample-detail.component.html',
})
export class DonorTestSampleDetailComponent implements OnInit {
    @Input() canEdit: boolean;
    donorTestSample: IDonorSample;

    claimType = ClaimTypes.Donors;
    docEntityType = DocumentEntities.DonorTestSamples;
    noteEntity = NoteEntities.DonorTestSample;

    dynamicControls = DonorSampleLabDataDynamicControlsPartial;

    subs = new Subscription();

    constructor(
        private donorTestSampleService: DonorSampleService,
        private router: Router,
        private route: ActivatedRoute,
        private claimsService: ClaimsService,
        private donorSampleLabData: DonorSampleLabDataService,
    ) {}

    ngOnInit(): void {
        const id = getIdFromRoute(this.route, 'testSampleId');
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Donors, [ClaimValues.FullAccess]);
        if (id) {
            this.donorTestSampleService.getById(id).subscribe((testSample) => {
                this.donorTestSample = testSample;
            });
        } else {
            this.router.navigate(['donors']);
        }
        this.subs.add(
            this.donorTestSampleService.changeEmitted$.subscribe((testSample) => {
                this.donorTestSample = testSample;
            }),
        );
    }

    setCanEdit(event: boolean): void {
        this.canEdit = !event;
    }
}
