<div class="miles-card padded">
    <h4>Potential Families</h4>
    <div>
        <div class="row">
            <div class="col-md-3">
                <select (ngModelChange)="countrySelected($event)" class="form-control" [(ngModel)]="selectedCountry">
                    <option *ngFor="let country of countries" [ngValue]="country">{{ country.Name }}</option>
                    <option value="">Worldwide</option>
                </select>
            </div>
            <div class="col-md-3 col-centered">
                <p class="potential-families-label"><b>Potential Families: </b>{{ potentialFamilies }}</p>
            </div>
        </div>
    </div>
</div>
