import { Component, Input, OnInit } from '@angular/core';
import { FileItem } from 'ng2-file-upload';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IDocumentType } from '@model/interfaces/document-type';
import { UploadDocumentsService } from '../../services/upload-documents.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDonorDocument } from '@model/interfaces/donor-document';
import { saveAs } from 'file-saver';
import { DocumentEntities } from '@model/enums/document-entities.enum';
import { RemoveFileExtension, AddFileExtension, GetExtensionFromFilename } from '../../libraries/parse-extension.library';

@Component({
    selector: 'app-upload-documents',
    templateUrl: './upload-documents.component.html',
})
export class UploadDocumentsComponent implements OnInit {
    @Input() entityId: number;
    @Input() entityType: DocumentEntities;
    @Input('documentTypes') documentTypes: IDocumentType[];
    @Input('canEdit') canUpload = false;

    showUploadArea = false;
    isUploading = false;
    isEditing: boolean;

    headerTitle = 'Uploaded Documents';
    maxFileSize = 10000000;
    allowedDocumentTypes: string[] = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    documentTypeSelected: number;

    tempFile: FileItem;
    fileName: string;
    extension: string;
    modalOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };
    showModal = false;

    documentArray: IDonorDocument[] = [];

    mustSelectType = false;

    editedDocument: IDonorDocument;

    constructor(public uploadDocumentsService: UploadDocumentsService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.getDocuments();
        if (this.documentTypes && this.documentTypes.length > 1) {
            this.mustSelectType = true;
        } else {
            this.documentTypeSelected = this.documentTypes[0].Id;
        }
    }

    confirm(file: FileItem): void {
        this.tempFile = file;
        this.fileName = RemoveFileExtension(file.file.name);
        this.extension = GetExtensionFromFilename(file.file.name);
        this.showModal = true;
    }

    error(errorMessage: string): void {
        this.notificationsService.error(errorMessage);
    }

    save(): void {
        const filename = AddFileExtension(this.fileName, this.extension);
        if (this.tempFile == null && this.editedDocument != null) {
            this.editedDocument.DocumentTypeId = this.documentTypeSelected;
            this.editedDocument.Document.Name = filename;
            this.uploadDocumentsService.editDocumentDetails(this.entityType, this.entityId, this.editedDocument).subscribe(() => {
                this.getDocuments();
                this.closePreview();
            });
        } else {
            this.isUploading = true;
            this.uploadDocumentsService
                .saveDocument(this.entityType, this.entityId, this.documentTypeSelected, this.tempFile._file, filename)
                .subscribe(() => {
                    this.getDocuments();
                    this.closePreview();
                });
        }
    }

    closePreview(): void {
        this.showModal = false;
        this.showUploadArea = false;
        this.isUploading = false;
        this.isEditing = false;
        this.documentTypeSelected = null;
        this.editedDocument = null;
        this.fileName = null;
        this.extension = null;
    }

    cancel(): void {
        this.showUploadArea = false;
    }

    getDocuments(): void {
        this.uploadDocumentsService.getDocuments(this.entityType, this.entityId).subscribe((documents) => {
            const typeIds = this.documentTypes.map((x) => x.Id);
            this.documentArray = documents.filter((doc) => typeIds.includes(doc.DocumentTypeId));
        });
    }

    deleteDocument(documentId: number): void {
        this.uploadDocumentsService.deleteDocument(this.entityType, this.entityId, documentId).subscribe(() => {
            this.getDocuments();
        });
    }

    downloadDocument(index: number, documentId: number): void {
        this.uploadDocumentsService.getDocumentById(this.entityType, this.entityId, documentId).subscribe((doc: any) => {
            const thefile = new Blob([<ArrayBuffer>doc], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, this.documentArray[index].Document.Name);
        });
    }

    editDocument(doc: IDonorDocument): void {
        this.fileName = RemoveFileExtension(doc.Document.Name);
        this.extension = GetExtensionFromFilename(doc.Document.Name);
        this.documentTypeSelected = doc.DocumentTypeId;
        this.editedDocument = Object.assign({}, doc);
        this.showModal = true;
    }
}
