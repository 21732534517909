import { Component, Input } from '@angular/core';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { DonorSampleLabDataDynamicControlsPartial } from '@model/partials/donor-test-sample-lab-data-partial.form-controls';
import { DonorSampleLabDataService } from 'admin-portal/donors/donor-sample-lab-data/services/donor-sample-lab-data.service';

@Component({
    selector: 'app-donation-lab-data',
    templateUrl: './donor-donation-lab-data.component.html',
})
export class DonationLabDataComponent {
    @Input() donorSample: IDonorSample;
    @Input() canEdit: boolean;

    dynamicControls = DonorSampleLabDataDynamicControlsPartial;
    labDataService: DonorSampleLabDataService;

    constructor(private sampleLabDataService: DonorSampleLabDataService) {
        this.labDataService = this.sampleLabDataService;
    }

    setCanEdit(event: boolean): void {
        this.canEdit = !event;
    }
}
