import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IQcDecision } from '@model/interfaces/qc-decision';

@Injectable({
    providedIn: 'root',
})
export class QcDecisionService extends StaticMetaItemService<IQcDecision> {
    constructor(public http: HttpClient) {
        super('QcDecisionService', 'Decision', 'DecisionIds', '/meta-items/QcDecision', http);
    }
}
