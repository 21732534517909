import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IEmail, ISendEmailModalResult } from '@mt-ng2/email-modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { IDonorUser } from '@model/interfaces/donor-user';
import { INotificationTemplate } from '@model/interfaces/notification-template';
import { NotificationTypes } from '@model/enums/notification-types.enum';
import { ISendEmailTemplateModalApi } from '@common/components/send-email/send-email-template.component';
import { EmailService } from '@common/services/email.service';

import { DonorService } from '../services/donor.service';
import { SelectTemplateDynamicControls } from './select-template.form-controls';
import { NotificationTemplateService } from '../../notification-templates/services/notification-template.service';

@Component({
    selector: 'app-donor-emails',
    templateUrl: './donor-email.component.html',
})
export class DonorEmailComponent {
    @Input() user: IDonorUser;
    @Input() donorId: number;

    selectedTemplateId: number;

    donorNotificationTemplates: INotificationTemplate[];

    abstractSelectTemplateControls: any;

    emailTemplateForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    emailModalApi: ISendEmailTemplateModalApi;

    subscriptions = new Subscription();

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private templateService: NotificationTemplateService,
        private notificationsService: NotificationsService,
        private emailService: EmailService,
        private donorService: DonorService,
    ) {}

    ngOnInit(): void {
        this.templateService.getNotificationsTemplatesByType(NotificationTypes.DonorEmail).subscribe((templates) => {
            this.donorNotificationTemplates = templates;
            this.createForm();
        });
        // This ensures donor email is up to date in the event it was edited
        this.subscriptions.add(
            this.donorService.changeEmitted$.subscribe((donor) => {
                this.user = donor.DonorUser;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    createForm(): void {
        this.getControls();
        this.emailTemplateForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractSelectTemplateControls = new SelectTemplateDynamicControls(
            { Id: null, Name: null, Editable: null, NotificationTypeId: null },
            {
                emailTemplates: this.donorNotificationTemplates,
                formGroup: 'Template',
            },
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Template: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.emailTemplateForm.valid) {
            this.selectedTemplateId = this.emailTemplateForm.value.Template.TemplateId;
            this.showEmailModal();
        } else {
            markAllFormFieldsAsTouched(this.emailTemplateForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(): void {
        this.notificationsService.error('Select an email template to send.');
    }

    success(): void {
        this.notificationsService.success('Email successfully sent.');
    }

    showEmailModal(): void {
        this.emailModalApi.show(this.user.Email, this.selectedTemplateId, {DonorId: this.donorId }).subscribe((response: ISendEmailModalResult) => {
            if (!response.cancelled) {
                this.sendEmail(response.email);
            }
            this.enableDoubleClick();
        });
    }

    sendEmail(emailData: IEmail): void {
        const emailDto = { ...emailData, TemplateId: this.selectedTemplateId };
        this.emailService.sendEmail(emailDto).subscribe((answer) => {
            this.success();
            this.enableDoubleClick();
        });
    }
}
