import { UnitsOfMeasurement } from '@model/enums/units-of-measurement.enum';

function convertInchesToFeetAndInches(height: number): string {
    const feet = Math.trunc(height / 12);
    const inches = Math.trunc(height - feet * 12);
    return `${feet}' ${inches}"`;
}

// Calculates numeric height in inches if not using metric
function calculateHeight(info: any): void {
    info.Height = info.HeightUnitId !== UnitsOfMeasurement.FeetInches ? info.Meters : Number(info.Feet * 12) + Number(info.Inches);
    delete info.Meters;
    delete info.Feet;
    delete info.Inches;
}

export {convertInchesToFeetAndInches, calculateHeight};
