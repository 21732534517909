import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { DonorSampleRemainderLabDataDynamicControlsPartial } from '@model/partials/donor-sample-remainder-lab-data-partial.form-controls';
import { DonorSampleService } from '../../services/donor-sample.service';
import { SamplePrepMethodService } from '../../services/sample-prep-method.service';
import { finalize } from 'rxjs/operators';
import { DonorSampleDynamicConfig } from '../donor-sample.dynamic-config';
import { DonorSampleRemainderLabDataService } from '../../donor-sample-lab-data/services/donor-sample-remainder-lab-data.service';
import { forkJoin } from 'rxjs';
import { DonorSampleStatusService } from 'admin-portal/donors/initial-screening/donor-test-samples/services/donor-sample-status.service';

@Component({
    selector: 'app-remainder-lab-data',
    templateUrl: './remainder-lab-data.component.html',
})
export class RemainderLabDataComponent {
    @Input() donorSample: IDonorSample;
    @Input() canEdit: boolean;

    dynamicControls = DonorSampleRemainderLabDataDynamicControlsPartial;
    labDataService: DonorSampleRemainderLabDataService;

    testThawSectiondId = DonorTestSampleLabDataSections.RemainderTestThawInfo;
    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: DonorSampleDynamicConfig<IDonorSample>;
    doubleClickIsDisabled = false;

    get hasRemainder(): boolean {
        return this.donorSample.HasRemainder;
    }

    constructor(
        private donorSampleService: DonorSampleService,
        private notificationsService: NotificationsService,
        private samplePrepMethodService: SamplePrepMethodService,
        private remainderLabDataService: DonorSampleRemainderLabDataService,
        private labDataStatusService: DonorSampleStatusService,
    ) {
        this.labDataService = this.remainderLabDataService;
    }

    setCanEdit(event: boolean): void {
        this.canEdit = !event;
    }

    ngOnInit(): void {
        forkJoin([this.samplePrepMethodService.getItems(), this.labDataStatusService.getItems()]).subscribe(() => {
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new DonorSampleDynamicConfig<IDonorSample>(
            this.donorSample,
            {
                remainderLabStatuses: this.labDataStatusService.items,
                remainderPrepMethods: this.samplePrepMethodService.items,
            },
            ['HasRemainder', 'RemainderPrepMethodId', 'RemainderLabStatusId'],
        );
        this.config = this.formFactory.getForUpdate();
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.donorSample, form.value.DonorSample);
            if (!this.donorSample.HasRemainder) {
                this.donorSample.RemainderPrepMethodId = null;
                this.donorSample.RemainderLabStatusId = null;
            }
            this.saveDonorSample();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveDonorSample(): void {
        const saveValue = { ...this.donorSample };
        delete saveValue.DonorSampleLabData;
        delete saveValue.DonorSampleRemainderLabData;
        this.donorSampleService
            .update(saveValue)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(() => {
                this.success();
            });
    }

    private success(): void {
        this.setConfig();
        this.isEditing = false;
        this.donorSampleService.emitChange(this.donorSample);
        this.notificationsService.success('Donation saved successfully.');
    }
}
