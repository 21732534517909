import { NgModule } from '@angular/core';

import { AdminPortalSharedModule } from '@common/shared.module';
import { AdminPortalFeatureModule } from '@common/feature.module';

import { RecipientUserRoutingModule } from './recipient-user-routing.module';
import { RecipientUserAddComponent } from './recipient-user-add/recipient-user-add.component';
import { RecipientUserBasicInfoComponent } from './recipient-user-basic-info/recipient-user-basic-info.component';
import { RecipientUserDetailComponent } from './recipient-user-detail/recipient-user-detail.component';
import { RecipientUsersComponent } from './recipient-user-list/recipient-users.component';
import { RecipientUserHeaderComponent } from './recipient-user-header/recipient-user-header.component';
import { CommonComponentsModule } from '@angular-common/components/components.module';
import { SalesOrderModule } from './sales-orders/sales-order.module';
import { RecipientReservationsComponent } from './recipient-reservations/recipient-reservations.component';

@NgModule({
    declarations: [
        RecipientUsersComponent,
        RecipientUserHeaderComponent,
        RecipientUserAddComponent,
        RecipientUserDetailComponent,
        RecipientUserBasicInfoComponent,
        RecipientReservationsComponent,
    ],
    imports: [AdminPortalSharedModule, AdminPortalFeatureModule, RecipientUserRoutingModule, CommonComponentsModule, SalesOrderModule],
})
export class RecipientUserModule {}
