<div class="miles-card padded">
    <h4>Send Email</h4>
    <form *ngIf="formCreated" class="padded row" [formGroup]="emailTemplateForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-12">
                <dynamic-field [field]="abstractSelectTemplateControls.TemplateId" [form]="emailTemplateForm"></dynamic-field>
            </div>
        </div>
        <div class="row col-md-12">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Email Donor
            </button>
        </div>
    </form>
</div>

<send-email-template (ready)="emailModalApi = $event"></send-email-template>
