import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPregnancyOutcomeOption } from '@model/interfaces/pregnancy-outcome-option';

import { StaticMetaItemService } from '@mt-ng2/base-service';

@Injectable({
    providedIn: 'root',
})
export class PregnancyOutcomeService extends StaticMetaItemService<IPregnancyOutcomeOption> {
    constructor(public http: HttpClient) {
        super('PregnancyOutcomeService', 'Outcome', 'OutcomeIds', '/meta-items/PregnancyOutcomeOption', http);
    }
}
