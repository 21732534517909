<div class="miles-card padded">
    <h4>Addresses</h4>
    <ul *ngIf="donorAddresses.length" class="list-group">
        <li *ngFor="let address of donorAddresses" (click)="selectedAddress = address" class="list-group-item">
            {{ getIndividualAddressTitle(address.Address) }}
            <span class="pull-right"
                >{{ address.AddressType.Name
                }}<i
                    *ngIf="address.AddressTypeId === addressTypes.Current"
                    class="fa fa-fw fa-star"
                    [style.marginTop.px]="3"
                    title="Current Address"
                ></i
            ></span>
        </li>
    </ul>
</div>
