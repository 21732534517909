import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { IDonor } from '@model/interfaces/donor';
import { DonorService } from '../services/donor.service';
import { getDonorFullName } from '@angular-common/libraries/common-functions.library';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    templateUrl: './donor-header.component.html',
})
export class DonorHeaderComponent implements OnInit, OnDestroy {
    donor: IDonor;
    header: string;
    subscriptions: Subscription = new Subscription();

    urlSegmentsToIgnore = ['test-samples', 'donation-samples'];

    constructor(
        private donorService: DonorService,
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('donorId');
        // set the header based on the id
        if (id > 0) {
            this.donorService.getById(id).subscribe(
                (donor) => {
                    this.setHeader(donor);
                },
                () => {
                    this.notificationService.info('Donor not found');
                    this.router.navigate(['donors']);
                },
            );
        } else {
            this.notificationService.info('Donor not found');
            this.router.navigate(['donors']);
        }
        // subscribe to any changes in the donor service
        // which should update the header accordingly
        this.subscriptions.add(
            this.donorService.changeEmitted$.subscribe((donor) => {
                this.setHeader(donor);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(donor: IDonor): void {
        this.donor = donor;
        this.header = `Donor: ${getDonorFullName(this.donor.DonorUser)} #${this.donor.Id}`;
    }
}
