import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRhFactor } from '@model/interfaces/rh-factor';
import { StaticMetaItemService } from '@mt-ng2/base-service';

@Injectable({
    providedIn: 'root',
})
export class RhFactorService extends StaticMetaItemService<IRhFactor> {
    constructor(public http: HttpClient) {
        super('BloodTypeService', 'Type', 'TypeIds', '/meta-items/RhFactor', http);
    }
}
