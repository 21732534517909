import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import { AppointmentsComponent } from './appointment-list/appointments.component';
import { AppointmentService } from './services/appointment.service';
import { AppointmentHeaderComponent } from './appointment-header/appointment-header.component';

import { ClaimTypes } from '@model/ClaimTypes';

const appointmentEntityConfig = {
    claimType: ClaimTypes.Appointments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'appointmentId',
    service: AppointmentService,
    title: 'Appointment Detail',
};

const appointmentListRoleGuard: any = {
    claimType: ClaimTypes.Appointments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Appointments',
};

export const appointmentPaths = {
    appointments: 'appointments',
};

const appointmentRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: AppointmentsComponent,
        data: appointmentListRoleGuard,
        path: '',
    },
    {
        canActivate: [AuthGuard],
        children: [
            { component: AppointmentDetailComponent, path: '', pathMatch: 'full' },

        ],
        component: AppointmentHeaderComponent,
        data: appointmentEntityConfig,
        path: `:${appointmentEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(appointmentRoutes)],
})
export class AppointmentRoutingModule { }
