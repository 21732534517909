// tslint:disable: object-literal-sort-keys

export const userPathSlugs = {
    root: 'users',
    add: 'add',
};

export const userPaths = {
    users: userPathSlugs.root,
    usersAdd: `${userPathSlugs.root}/${userPathSlugs.add}`,
};
