import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import {
    DonorBloodWorkDynamicControls as base,
    IDonorBloodWorkDynamicControlsParameters as baseInterface,
} from '../form-controls/donor-blood-work.form-controls';
import { IDonorBloodWork } from '../interfaces/donor-blood-work';
import { IScreeningResultOption } from '@model/interfaces/screening-result-option';
import { getUserFullName } from '@angular-common/libraries/common-functions.library';
import { DatePipe } from '@angular/common';

export interface IDonorBloodWorkDynamicControlsParameters extends baseInterface {
    karyotypeOptions?: IScreeningResultOption[];
}

export class DonorBloodWorkDynamicControls extends base {
    datePipe = new DatePipe('en-US');

    constructor(private donorBloodWork?: IDonorBloodWork, additionalParameters?: IDonorBloodWorkDynamicControlsParameters) {
        super(donorBloodWork, additionalParameters);

        // View
        (<DynamicLabel>this.View.BloodTypeId).value = this.donorBloodWork
            ? getMetaItemValue(
                  this.bloodTypes,
                  this.donorBloodWork && this.donorBloodWork.hasOwnProperty('BloodTypeId') && this.donorBloodWork.BloodTypeId !== null
                      ? this.donorBloodWork.BloodTypeId
                      : null,
              )
            : 'Not Available';

        (<DynamicLabel>this.View.RhFactorId).value = this.donorBloodWork
            ? getMetaItemValue(
                  this.rhFactors,
                  this.donorBloodWork && this.donorBloodWork.hasOwnProperty('RhFactorId') && this.donorBloodWork.RhFactorId !== null
                      ? this.donorBloodWork.RhFactorId
                      : null,
              )
            : 'Not Available';

        (<DynamicLabel>this.View.KaryotypeResultId).valueHtml =
            donorBloodWork && donorBloodWork.KaryotypeResult
                ? `${donorBloodWork.KaryotypeResult?.Name}<br/>${
                      donorBloodWork.KaryotypeModifiedBy
                          ? `<p style="margin:0"><em>Modified By ${getUserFullName(donorBloodWork.KaryotypeModifiedBy)} ${this.datePipe.transform(
                                donorBloodWork.KaryotypeModifiedOn,
                                'short',
                            )}</em></p>`
                          : ''
                  }`
                : '';

        // Form

        (<DynamicField>this.Form.KaryotypeResultId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Karyotype Result',
            name: 'KaryotypeResultId',
            options: additionalParameters.karyotypeOptions,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: (this.donorBloodWork && this.donorBloodWork.KaryotypeResultId) || null,
        });

        (<DynamicField>this.Form.KaryotypeModifiedById).doNotCreateControl = true;
        (<DynamicField>this.Form.KaryotypeModifiedOn).doNotCreateControl = true;
    }
}
