import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { AppointmentService } from '../services/appointment.service';
import { IAppointment } from '@model/interfaces/appointment';
import { entityListModuleConfig } from '@common/shared.module';
import { AppointmentsEntityListConfig } from './appointments.entity-list-config';
import { UserRoles } from '@model/enums/user-roles.enum';
import { UserService } from '../../users/user.service';
import { AppointmentTypeService } from '../services/appointment-type.service';

@Component({
    selector: 'app-appointments',
    templateUrl: './appointments.component.html',
})
export class AppointmentsComponent implements OnInit {
    appointments: IAppointment[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    assignedUsers: MtSearchFilterItem[] = [];
    appointmentTypes: MtSearchFilterItem[] = [];
    entityListConfig = new AppointmentsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection = 'asc';

    canAddAppointment = false;
    userId: number;

    constructor(
        private appointmentService: AppointmentService,
        private userService: UserService,
        private claimsService: ClaimsService,
        private appointmentTypeService: AppointmentTypeService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        if (this.activatedRoute.snapshot.queryParamMap.has('userId')) {
            this.userId = +this.activatedRoute.snapshot.queryParamMap.get('userId');
        }
        forkJoin(this.userService.getUsersByRoleAsMetaItem(),
            this.appointmentTypeService.getSearchFilterItems())
            .subscribe((answer) => {
                const [users, types] = answer;
                this.assignedUsers = users.map((u) => new MtSearchFilterItem(u, u.Id === this.userId));
                this.appointmentTypes = types;
            });
        this.getAppointments();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedAssignedUserIds: number[] = this.getSelectedFilters(this.assignedUsers);
        const selectedAppointmentTypeIds: number[] = this.getSelectedFilters(this.appointmentTypes);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AssignedUserIds',
                valueArray: selectedAssignedUserIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AppointmentTypeIds',
                valueArray: selectedAppointmentTypeIds,
            }),
        );

        return _extraSearchParams;
    }

    getAppointmentsCall(): Observable<HttpResponse<IAppointment[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.appointmentService.get(searchparams);
    }

    getAppointments(): void {
        this.getAppointmentsCall().subscribe((answer) => {
            this.appointments = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getAppointments();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getAppointments();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getAppointments();
    }

    appointmentSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['/appointments', event.entity.Id]);
    }
}
