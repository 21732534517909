import { DatePipe } from '@angular/common';
import { IMetaItem } from '@model/interfaces/base';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { PhonePipe } from '@mt-ng2/phone-control';
import { AppointmentDynamicControls, IAppointmentDynamicControlsParameters } from '../form-controls/appointment.form-controls';
import { IAppointment } from '../interfaces/appointment';

export class AppointmentDynamicControlsPartial extends AppointmentDynamicControls {
    constructor(appointmentPartial?: IAppointment, additionalParameters?: IAppointmentDynamicControlsParameters) {
        super(appointmentPartial, additionalParameters);
        const datePipe = new DatePipe('en-US');

        (<DynamicField>this.Form.InterviewPassed).labelHtml = undefined;
        (<DynamicField>this.Form.InterviewPassed).label = 'Interview Outcome';
        (<DynamicField>this.Form.InterviewPassed).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.InterviewPassed).value = appointmentPartial.InterviewPassed;
        (<DynamicField>this.Form.InterviewPassed).options = [
            { Id: 1, Name: 'Pass' },
            { Id: 0, Name: 'Fail' },
        ];

        // Only create Assigned User control and Interview Passed control
        for (const prop of Object.keys(this.Form)) {
            if (prop !== 'AssignedUserId' && prop !== 'InterviewPassed') {
                let control = this.Form[prop] as DynamicField;
                control.doNotCreateControl = true;
            }
        }

        this.Form.InterviewPassed = new DynamicField(this.Form.InterviewPassed);

        (<DynamicLabel>this.View.EndTimeUtc).label = 'End Time';
        (<DynamicLabel>this.View.EndTimeUtc).valueHtml = datePipe.transform(appointmentPartial.EndTimeUtc, 'h:mm a');
        (<DynamicLabel>this.View.StartTimeUtc).valueHtml = datePipe.transform(appointmentPartial.StartTimeUtc, 'h:mm a');
        (<DynamicLabel>this.View.StartTimeUtc).label = 'Start Time';
        (<DynamicLabel>this.View.EndTimeDonor).valueHtml = datePipe.transform(appointmentPartial.EndTimeUtc, 'h:mm a');
        (<DynamicLabel>this.View.StartTimeDonor).valueHtml = datePipe.transform(appointmentPartial.StartTimeUtc, 'h:mm a');
        (<DynamicLabel>this.View.PhoneNumber).valueHtml = new PhonePipe().transform(appointmentPartial.PhoneNumber);
        (<DynamicLabel>this.View.Timezone).label = 'Donor Timezone';
        (<DynamicLabel>this.View.DonorId).label = 'Donor Id';

        (<DynamicLabel>this.View.Cancelled).valueHtml = appointmentPartial.Cancelled ? 'Yes' : 'No';
        (<DynamicLabel>this.View.InterviewPassed).label = 'Interview Outcome';
        (<DynamicLabel>this.View.InterviewPassed).valueHtml =
            appointmentPartial.InterviewPassed === null ? 'N/A' : appointmentPartial.InterviewPassed ? 'Pass' : 'Fail';

        (<DynamicLabel>this.View.TypeId).value = appointmentPartial.AppointmentType?.Name || '';

        const donor = appointmentPartial.Donors[0].DonorUser;
        const donorName = donor ? `${donor.FirstName} ${donor.LastName}` : '';
        this.View.Donor = new DynamicLabel('Donor', donorName, new DynamicFieldType({ fieldType: DynamicFieldTypes.Input }));
    }
}
