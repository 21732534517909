import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorOngoingScreening } from '../interfaces/donor-ongoing-screening';
import { IBloodWorkStatus } from '../interfaces/blood-work-status';
import { IDonor } from '../interfaces/donor';
import { IPhysicalStatus } from '../interfaces/physical-status';
import { IRegulatoryQuestionnaireStatus } from '../interfaces/regulatory-questionnaire-status';

export interface IDonorOngoingScreeningDynamicControlsParameters {
    formGroup?: string;
    donors?: IDonor[];
    bloodWorkStatuses?: IBloodWorkStatus[];
    regulatoryQuestionnaireStatuses?: IRegulatoryQuestionnaireStatus[];
    physicalStatuses?: IPhysicalStatus[];
}

export class DonorOngoingScreeningDynamicControls {

    formGroup: string;
    donors: IDonor[];
    bloodWorkStatuses: IBloodWorkStatus[];
    regulatoryQuestionnaireStatuses: IRegulatoryQuestionnaireStatus[];
    physicalStatuses: IPhysicalStatus[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorongoingscreening?: IDonorOngoingScreening, additionalParameters?: IDonorOngoingScreeningDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorOngoingScreening';
        this.donors = additionalParameters && additionalParameters.donors || undefined;
        this.bloodWorkStatuses = additionalParameters && additionalParameters.bloodWorkStatuses || undefined;
        this.regulatoryQuestionnaireStatuses = additionalParameters && additionalParameters.regulatoryQuestionnaireStatuses || undefined;
        this.physicalStatuses = additionalParameters && additionalParameters.physicalStatuses || undefined;

        this.Form = {
            BloodDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Blood Draw Date',
                name: 'BloodDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.DateInput,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorongoingscreening && this.donorongoingscreening.BloodDrawDate || null,
            }),
            BloodWorkStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Blood Work Status',
                name: 'BloodWorkStatusId',
                options: this.bloodWorkStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorongoingscreening && this.donorongoingscreening.BloodWorkStatusId || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.DateInput,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorongoingscreening && this.donorongoingscreening.DateModified || null,
            }),
            DonorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor',
                name: 'DonorId',
                options: this.donors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorongoingscreening && this.donorongoingscreening.DonorId || null,
            }),
            LastModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Modified By',
                name: 'LastModifiedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorongoingscreening && this.donorongoingscreening.LastModifiedById || null,
            }),
            PhysicalResultDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Result Date',
                name: 'PhysicalResultDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.DateInput,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorongoingscreening && this.donorongoingscreening.PhysicalResultDate || null,
            }),
            PhysicalStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Status',
                name: 'PhysicalStatusId',
                options: this.physicalStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorongoingscreening && this.donorongoingscreening.PhysicalStatusId || null,
            }),
            RegulatoryQuestionnaireResultDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Regulatory Questionnaire Result Date',
                name: 'RegulatoryQuestionnaireResultDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.DateInput,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorongoingscreening && this.donorongoingscreening.RegulatoryQuestionnaireResultDate || null,
            }),
            RegulatoryQuestionnaireStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Regulatory Questionnaire Status',
                name: 'RegulatoryQuestionnaireStatusId',
                options: this.regulatoryQuestionnaireStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorongoingscreening && this.donorongoingscreening.RegulatoryQuestionnaireStatusId || null,
            }),
        };

        this.View = {
            BloodDrawDate: new DynamicLabel(
                'Blood Draw Date',
                this.donorongoingscreening && this.donorongoingscreening.BloodDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            BloodWorkStatusId: new DynamicLabel(
                'Blood Work Status',
                getMetaItemValue(this.bloodWorkStatuses, this.donorongoingscreening && this.donorongoingscreening.hasOwnProperty('BloodWorkStatusId') && this.donorongoingscreening.BloodWorkStatusId !== null ? this.donorongoingscreening.BloodWorkStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.donorongoingscreening && this.donorongoingscreening.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DonorId: new DynamicLabel(
                'Donor',
                getMetaItemValue(this.donors, this.donorongoingscreening && this.donorongoingscreening.hasOwnProperty('DonorId') && this.donorongoingscreening.DonorId !== null ? this.donorongoingscreening.DonorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            LastModifiedById: new DynamicLabel(
                'Last Modified By',
                this.donorongoingscreening && this.donorongoingscreening.LastModifiedById || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            PhysicalResultDate: new DynamicLabel(
                'Physical Result Date',
                this.donorongoingscreening && this.donorongoingscreening.PhysicalResultDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            PhysicalStatusId: new DynamicLabel(
                'Physical Status',
                getMetaItemValue(this.physicalStatuses, this.donorongoingscreening && this.donorongoingscreening.hasOwnProperty('PhysicalStatusId') && this.donorongoingscreening.PhysicalStatusId !== null ? this.donorongoingscreening.PhysicalStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            RegulatoryQuestionnaireResultDate: new DynamicLabel(
                'Regulatory Questionnaire Result Date',
                this.donorongoingscreening && this.donorongoingscreening.RegulatoryQuestionnaireResultDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            RegulatoryQuestionnaireStatusId: new DynamicLabel(
                'Regulatory Questionnaire Status',
                getMetaItemValue(this.regulatoryQuestionnaireStatuses, this.donorongoingscreening && this.donorongoingscreening.hasOwnProperty('RegulatoryQuestionnaireStatusId') && this.donorongoingscreening.RegulatoryQuestionnaireStatusId !== null ? this.donorongoingscreening.RegulatoryQuestionnaireStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
