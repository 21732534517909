import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentTemplateService } from '@angular-common/services/document-template.service';
import { DonorHelloSignService } from '../services/donor-hello-sign.service';
import { IDocumentTemplate } from '@model/interfaces/document-template';

import { IDonor } from '@model/interfaces/donor';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-hello-sign-documents',
    templateUrl: './hello-sign-docs.component.html',
})
export class HelloSignDocumentsComponent implements OnInit {
    @Input() donor: IDonor;
    @Output() onRequestSent = new EventEmitter<void>();

    doubleClickIsDisabled = false;
    helloSignTemplates: IDocumentTemplate[];

    constructor(
        private templateService: DocumentTemplateService,
        private helloSignService: DonorHelloSignService,
        private notificationService: NotificationsService,
        private datePipe: DatePipe,
    ) {}

    ngOnInit(): void {
        this.templateService.getAll().subscribe((templates) => {
            this.helloSignTemplates = templates;
        });
    }

    sendReminderEmail(templateId: number): void {
        // Remove after all templates implemented
        let template = this.helloSignTemplates.find((t) => t.Id === templateId);
        if (!template.TemplateId) {
            return alert('Template not yet implemented!');
        }
        this.helloSignService.sendHelloSignSignatureRequestEmail(this.donor.Id, templateId).subscribe(() => {
            this.notificationService.success('Email sent successfully.');
            this.onRequestSent.emit();
        });
    }

    downloadPDF(template: IDocumentTemplate): void {
        this.helloSignService.getDonorHelloSignDocument(this.donor.Id, template.Id).subscribe((doc: any) => {
            const thefile = new Blob([<ArrayBuffer>doc], {
                type: 'application/pdf',
            });
            saveAs(thefile, `${this.donor.DonorUser.LastName}${this.donor.DonorUser.FirstName}-${template.Name}`);
        });
    }

    clearSignatureDate(template: IDocumentTemplate): void {
        this.helloSignService.clearDocumentSignature(this.donor.Id, template.Id).subscribe((answer) => {
            this.donor.DonorHelloSignDocuments = answer;
        });
    }

    setCompletionDate(templateId: number, event: Event): void {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        let doc = this.donor.DonorHelloSignDocuments.find((x) => x.DocumentTemplateId === templateId);
        let tzoffset;
        let localISOTime;
        if (doc?.SignatureDate) {
            tzoffset = new Date(doc.SignatureDate).getTimezoneOffset() * 60000;
            localISOTime = new Date(new Date(doc.SignatureDate).getTime() - tzoffset).toISOString().split('.')[0];
        }
        let currentISOTime = new Date(Date.now() - new Date().getTimezoneOffset() / 60).toISOString().split('.')[0];
        Swal.fire({
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Update',
            html: doc?.SignatureDate
                ? `Please enter document completion date <br/><br/> <input value="${localISOTime}" type="datetime-local" max="${currentISOTime}" id="datepicker">`
                : `Please enter document completion date <br/><br/> <input type="datetime-local" max="${currentISOTime}" id="datepicker">`,
            preConfirm: () => {
                return (<HTMLInputElement>document.getElementById('datepicker')).value;
            },
            showCancelButton: true,
            title: 'Enter new date',
            width: '400px',
        }).then((answer) => {
            if (!answer.isConfirmed) {
                return;
            }
            this.helloSignService.updateDocumentSignature(this.donor.Id, templateId, new Date(answer.value)).subscribe((answer) => {
                this.donor.DonorHelloSignDocuments = answer;
            });
        });
    }

    // UI Methods

    docDateAddedManually(templateId: number): boolean {
        return this.donor.DonorHelloSignDocuments.some((doc) => {
            return doc.DocumentTemplateId === templateId && doc.DateAddedManually;
        });
    }

    donorHasSigned(templateId: number): boolean {
        return this.donor.DonorHelloSignDocuments.some((doc) => {
            return doc.DocumentTemplateId === templateId && doc.Completed;
        });
    }

    getLastRequestDate(templateId: number): string {
        let doc = this.donor.DonorHelloSignDocuments.find((x) => x.DocumentTemplateId === templateId);
        if (doc) {
            return `Last Request Sent ${
                doc.LastRequestedDate ? this.datePipe.transform(doc.LastRequestedDate, 'short') : this.datePipe.transform(doc.RequestedDate, 'short')
            }`;
        } else {
            return '';
        }
    }

    documentHasCompletionDate(templateId: number): boolean {
        let doc = this.donor.DonorHelloSignDocuments.find((x) => x.DocumentTemplateId === templateId);
        return doc?.SignatureDate != null;
    }

    getCompletionDate(templateId: number): Date {
        let doc = this.donor.DonorHelloSignDocuments.find((x) => x.DocumentTemplateId === templateId);
        return doc.SignatureDate;
    }
}
