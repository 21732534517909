import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IInventoryDynamicControlsParameters } from '@model/form-controls/inventory.form-controls';
import { IInventory } from '@model/interfaces/inventory';
import { InventoryDynamicControlsPartial } from '@model/partials/inventory-partial.form-controls';

export class InventoryDynamicConfig<T extends IInventory> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private inventory: T, private additionalparams: IInventoryDynamicControlsParameters, private configControls?: string[]) {
        super();
        const additionalParams: IInventoryDynamicControlsParameters = this.additionalparams || {};
        const dynamicControls = new InventoryDynamicControlsPartial(this.inventory, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['SamplePrepMethodId', 'VialNumber', 'Comments', 'ReserveForResearch'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
