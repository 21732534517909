import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { InventoryVesselDynamicControls } from '@model/form-controls/inventory-vessel.form-controls';
import { IInventoryVessel } from '@model/interfaces/inventory-vessel';
import { VesselTypes } from '../inventory-vessel.component';
import { InventoryVesselService } from '../../services/inventory-vessel.service';

export interface IVesselFormOptions {
    vessel: IInventoryVessel;
    type: VesselTypes;
    parentNode?: number;
}

@Component({
    selector: 'inventory-vessel-form',
    templateUrl: './inventory-vessel-form.component.html',
})
export class InventoryVesselFormComponent implements OnInit {
    @Input() options: IVesselFormOptions;
    @Output() onCancel = new EventEmitter();
    @Output() onSuccess = new EventEmitter<IVesselFormOptions>();

    // abstract controls
    abstractInventoryVesselControls: any;

    inventoryVesselForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private inventoryVesselService: InventoryVesselService,
    ) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.inventoryVesselForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractInventoryVesselControls = new InventoryVesselDynamicControls(this.options.vessel, {
            formGroup: 'InventoryVessel',
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            InventoryVessel: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.inventoryVesselForm.valid) {
            Object.assign(this.options.vessel, this.inventoryVesselForm.value.InventoryVessel);
            if (this.options.vessel.Id === 0) {
                this.inventoryVesselService.create(this.options.vessel, this.options.parentNode).subscribe((id) => {
                    this.options.vessel.Id = id;
                    this.success(this.options.type, this.options.vessel);
                });
            } else {
                this.inventoryVesselService.update(this.options.vessel).subscribe((id) => {
                    this.success(this.options.type, this.options.vessel);
                });
            }
        } else {
            markAllFormFieldsAsTouched(this.inventoryVesselForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.onCancel.emit();
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(type, vessel): void {
        this.onSuccess.emit({ type: type, vessel: vessel });
        this.notificationsService.success('Inventory vessel saved successfully.');
    }
}
