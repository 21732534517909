import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IDonorIssue } from '../interfaces/donor-issue';

export interface IDonorIssueDynamicControlsParameters {
    formGroup?: string;
}

export class DonorIssueDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorissue?: IDonorIssue, additionalParameters?: IDonorIssueDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorIssue';

        this.Form = {
            Completed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Completed',
                name: 'Completed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorissue && this.donorissue.Completed || null,
            }),
            CurrentMedications: new DynamicField({
                formGroup: this.formGroup,
                label: 'Current Medications',
                name: 'CurrentMedications',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.donorissue && this.donorissue.hasOwnProperty('CurrentMedications') && this.donorissue.CurrentMedications !== null ? this.donorissue.CurrentMedications.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorissue && this.donorissue.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorissue && this.donorissue.DateModified || null,
            }),
            HasHadMajorRadiationExposure: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Had Major Radiation Exposure',
                name: 'HasHadMajorRadiationExposure',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorissue && this.donorissue.hasOwnProperty('HasHadMajorRadiationExposure') && this.donorissue.HasHadMajorRadiationExposure !== null ? this.donorissue.HasHadMajorRadiationExposure : false,
            }),
            HasSurgeries: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Surgeries',
                name: 'HasSurgeries',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorissue && this.donorissue.hasOwnProperty('HasSurgeries') && this.donorissue.HasSurgeries !== null ? this.donorissue.HasSurgeries : false,
            }),
            HasTakenHgh: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Taken Hgh',
                name: 'HasTakenHgh',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorissue && this.donorissue.hasOwnProperty('HasTakenHgh') && this.donorissue.HasTakenHgh !== null ? this.donorissue.HasTakenHgh : false,
            }),
            IsTakingMedication: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Taking Medication',
                name: 'IsTakingMedication',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorissue && this.donorissue.hasOwnProperty('IsTakingMedication') && this.donorissue.IsTakingMedication !== null ? this.donorissue.IsTakingMedication : false,
            }),
            OtherIssues: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Issues',
                name: 'OtherIssues',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.donorissue && this.donorissue.hasOwnProperty('OtherIssues') && this.donorissue.OtherIssues !== null ? this.donorissue.OtherIssues.toString() : '',
            }),
            Surgeries: new DynamicField({
                formGroup: this.formGroup,
                label: 'Surgeries',
                name: 'Surgeries',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.donorissue && this.donorissue.hasOwnProperty('Surgeries') && this.donorissue.Surgeries !== null ? this.donorissue.Surgeries.toString() : '',
            }),
        };

        this.View = {
            Completed: new DynamicLabel(
                'Completed',
                this.donorissue && this.donorissue.Completed || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            CurrentMedications: new DynamicLabel(
                'Current Medications',
                this.donorissue && this.donorissue.hasOwnProperty('CurrentMedications') && this.donorissue.CurrentMedications !== null ? this.donorissue.CurrentMedications.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.donorissue && this.donorissue.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.donorissue && this.donorissue.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HasHadMajorRadiationExposure: new DynamicLabel(
                'Has Had Major Radiation Exposure',
                this.donorissue && this.donorissue.hasOwnProperty('HasHadMajorRadiationExposure') && this.donorissue.HasHadMajorRadiationExposure !== null ? this.donorissue.HasHadMajorRadiationExposure : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasSurgeries: new DynamicLabel(
                'Has Surgeries',
                this.donorissue && this.donorissue.hasOwnProperty('HasSurgeries') && this.donorissue.HasSurgeries !== null ? this.donorissue.HasSurgeries : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasTakenHgh: new DynamicLabel(
                'Has Taken Hgh',
                this.donorissue && this.donorissue.hasOwnProperty('HasTakenHgh') && this.donorissue.HasTakenHgh !== null ? this.donorissue.HasTakenHgh : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsTakingMedication: new DynamicLabel(
                'Is Taking Medication',
                this.donorissue && this.donorissue.hasOwnProperty('IsTakingMedication') && this.donorissue.IsTakingMedication !== null ? this.donorissue.IsTakingMedication : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherIssues: new DynamicLabel(
                'Other Issues',
                this.donorissue && this.donorissue.hasOwnProperty('OtherIssues') && this.donorissue.OtherIssues !== null ? this.donorissue.OtherIssues.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Surgeries: new DynamicLabel(
                'Surgeries',
                this.donorissue && this.donorissue.hasOwnProperty('Surgeries') && this.donorissue.Surgeries !== null ? this.donorissue.Surgeries.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
