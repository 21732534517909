<div *ngIf="donorOngoingScreening">
    <div class="row">
        <div class="col-md-6">
            <app-donor-ongoing-screening-basic-info [donorOngoingScreening]="donorOngoingScreening" [canEdit]="canEdit"></app-donor-ongoing-screening-basic-info>
            <br />
            <app-upload-documents-detail [entityId]="donorOngoingScreening.Id" [claimType]="claimType" [entityType]="docEntityType"> </app-upload-documents-detail>
            <br />
        </div>
        <div class="col-md-6">
            <app-blood-work-detail [donorOngoingScreening]="donorOngoingScreening" [canEdit]="canEdit"></app-blood-work-detail>
            <br />
        </div>
    </div>
    <a routerLink="{{ '/donors/' + donorId }}" class="btn btn-default">Close</a>
</div>
