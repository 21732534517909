<ngx-skeleton-loader count="25" *ngIf="!formCreated"></ngx-skeleton-loader>
<div *ngIf="formCreated" [hidden]="!allFormsReady">
    <form *ngIf="metaData" class="miles-form padded row application-section" [formGroup]="familyHistoryForm">
        <h2>Parents & Grandparents</h2>
        <div class="form-section-border">
            <h3>Mother</h3>
        </div>
        <individual-family-history
            (onFormCreate)="setFormGroup($event, 'Mother')"
            [individual]="familyHistory.Mother"
            [metaData]="metaData"
        ></individual-family-history>
        <div class="form-section-border">
            <h3>Father</h3>
        </div>
        <individual-family-history
            (onFormCreate)="setFormGroup($event, 'Father')"
            [individual]="familyHistory.Father"
            [metaData]="metaData"
        ></individual-family-history>
        <div class="form-section-border">
            <h3>Maternal Grandmother</h3>
        </div>
        <individual-family-history
            (onFormCreate)="setFormGroup($event, 'MaternalGrandmother')"
            [individual]="familyHistory.MaternalGrandmother"
            [metaData]="metaData"
        >
        </individual-family-history>
        <div class="form-section-border">
            <h3>Maternal Grandfather</h3>
        </div>
        <individual-family-history
            (onFormCreate)="setFormGroup($event, 'MaternalGrandfather')"
            [individual]="familyHistory.MaternalGrandfather"
            [metaData]="metaData"
        >
        </individual-family-history>
        <div class="form-section-border">
            <h3>Paternal Grandmother</h3>
        </div>
        <individual-family-history
            (onFormCreate)="setFormGroup($event, 'PaternalGrandmother')"
            [individual]="familyHistory.PaternalGrandmother"
            [metaData]="metaData"
        >
        </individual-family-history>
        <div class="form-section-border">
            <h3>Paternal Grandfather</h3>
        </div>
        <individual-family-history
            (onFormCreate)="setFormGroup($event, 'PaternalGrandfather')"
            [individual]="familyHistory.PaternalGrandfather"
            [metaData]="metaData"
        >
        </individual-family-history>
        <h2>Siblings</h2>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field
                    [field]="getSiblingsControl()"
                    (valueChanges)="showSiblingsControls($event)"
                    [form]="familyHistoryForm"
                ></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field
                    *ngIf="hasSiblings"
                    [field]="getHowManySiblingsControl()"
                    [form]="familyHistoryForm"
                    (valueChanges)="siblingsToAddNumber = $event"
                >
                </dynamic-field>
            </div>
            <div class="input-scaffold">
                <button *ngIf="hasSiblings" type="button" class="btn tab-selector btn-outline-primary next" (click)="addSiblingsToFamilyHistory()">
                    Add <span *ngIf="familyHistory.DonorSiblingFamilyHistories.length">More </span>Siblings
                </button>
            </div>
        </div>
        <div *ngFor="let sibling of familyHistory.DonorSiblingFamilyHistories; let i = index">
            <div class="form-section-border">
                <h4 class="heading-with-button">
                    Sibling {{ i + 1 }} <button type="button" class="btn btn-sm" (click)="removeSibling(i)"><i class="fa fa-fw fa-times"></i></button>
                </h4>
            </div>
            <individual-family-history
                (onFormCreate)="addSiblingFormToFormArray($event)"
                [siblingIndex]="i"
                [individual]="sibling"
                [metaData]="metaData"
            ></individual-family-history>
        </div>
    </form>
</div>
