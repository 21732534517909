import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IEntity } from '@mt-ng2/entity-list-module';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export abstract class UserEntitySearchService<T extends IEntity> extends BaseService<T> {
    baseUrl: string;
    constructor(baseUrl: string, public http: HttpClient) {
        super(baseUrl, http);
        this.baseUrl = baseUrl;
    }

    getUserEntities(searchparams: SearchParams): Observable<HttpResponse<T[]>> {
        let params = this.getHttpParams(searchparams);
        return this.http
            .get<HttpResponse<T[]>>(this.baseUrl + '/search-user-entities', {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }
}
