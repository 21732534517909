<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing"
    [style.cursor]="!isReadOnly ? 'pointer' : 'default'"
>
    <h4>
        Births
        <span class="pull-right" *ngIf="!isReadOnly && showBirthOutcome" (click)="edit()">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div *ngIf="!showBirthOutcome">
        <span><em>Finalize outcome to enter birth details</em></span>
    </div>
    <div *ngIf="formCreated && showBirthOutcome">
        <div class="row">
            <div class="col-md-12">
                <dynamic-label [field]="abstractBirthOutcomeControls.View.PregnancyOutcomeId"></dynamic-label>
                <dynamic-label [field]="abstractBirthOutcomeControls.View.OutcomeSourceId"></dynamic-label>
                <dynamic-label [field]="abstractBirthOutcomeControls.View.NumberBorn"></dynamic-label>
                <div *ngFor="let formGroup of birthDetailsControl">
                    <hr />
                    <birth-detail [birthDetail]="formGroup.value" [isEditing]="isEditing" [form]="formGroup"></birth-detail>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isEditing">
    <form *ngIf="formCreated && showBirthOutcome" class="miles-form padded row" [formGroup]="birthOutcomeForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-12">
                <h4>Births</h4>
                <dynamic-field [field]="abstractBirthOutcomeControls.Form.PregnancyOutcomeId" [form]="birthOutcomeForm"></dynamic-field>
                <dynamic-field [field]="abstractBirthOutcomeControls.Form.OutcomeSourceId" [form]="birthOutcomeForm"></dynamic-field>
                <dynamic-field
                    [field]="abstractBirthOutcomeControls.Form.NumberBorn"
                    [form]="birthOutcomeForm"
                    (valueChanges)="numberBornChanges($event)"
                ></dynamic-field>
                <div *ngFor="let formGroup of birthDetailsControl">
                    <hr />
                    <birth-detail [birthDetail]="formGroup.value" [isEditing]="isEditing" [form]="formGroup"></birth-detail>
                </div>
            </div>
        </div>
        <div class="row col-md-12">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </div>
    </form>
</div>
