import { ThawingDynamicControls, IThawingDynamicControlsParameters } from '../form-controls/thawing.form-controls';
import { IThawing } from '../interfaces/thawing';
import { IReasonsNotToCount } from '../interfaces/reasons-not-to-count';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, InputTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';

export interface IThawingDynamicControlsParametersPartial extends IThawingDynamicControlsParameters {
    reasonsNotToCount?: IReasonsNotToCount[];
}

export class ThawingDynamicControlsPartial extends ThawingDynamicControls {
    constructor(thawingPartial?: IThawing, additionalParameters?: IThawingDynamicControlsParametersPartial) {
        super(thawingPartial, additionalParameters);

        // FIELD
        (<DynamicField>this.Form.ClinicId).type.inputType = SelectInputTypes.Dropdown;

        (<DynamicField>this.Form.TreatingPhysician).type.inputType = InputTypes.Textbox;

        (<DynamicField>this.Form.FollowUpReasonId).type.inputType = SelectInputTypes.Dropdown;

        (<DynamicField>this.Form.ReasonNotToCountId).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.Dropdown,
        });
        (<DynamicField>this.Form.ReasonNotToCountId).options = additionalParameters.reasonsNotToCount;

        (<DynamicField>this.Form.Concentration).labelHtml = '<label>Concentration (million per ml)</label>';
        (<DynamicField>this.Form.Motility).type.numericAddOn = {
            html: '%',
            position: 'right',
        };

        (<DynamicField>this.Form.TotalMotalCount) = new DynamicField({
            disabled: true,
            formGroup: this.formGroup,
            label: 'Total Motile Count',
            name: 'TotalMotalCount',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: null,
                scale: 1,
            }),
            validation: [],
            validators: {},
            value:
                (thawingPartial?.Volume ? thawingPartial.Volume : 0) *
                (thawingPartial?.Concentration ? thawingPartial.Concentration : 0) *
                (thawingPartial?.Motility ? thawingPartial.Motility / 100 : 0),
        });

        // VIEW
        (<DynamicLabel>this.View.Concentration).label = 'Concentration (million per ml)';
        (<DynamicLabel>this.View.Motility).value = thawingPartial?.Motility ? thawingPartial.Motility + '%' : null;

        (<DynamicLabel>this.View.TotalMotalCount) = new DynamicLabel(
            'Total Motile Count',
            (
                (thawingPartial?.Volume ? thawingPartial.Volume : 0) *
                (thawingPartial?.Concentration ? thawingPartial.Concentration : 0) *
                (thawingPartial?.Motility ? thawingPartial.Motility / 100 : 0)
            ).toFixed(1),
            new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: null,
                scale: null,
            }),
        );
    }
}
