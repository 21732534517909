import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IRecipientUser } from '@model/interfaces/recipient-user';
import { RecipientUserService } from '../services/recipient-user.service';

@Component({
    templateUrl: './recipient-user-header.component.html',
})
export class RecipientUserHeaderComponent implements OnInit, OnDestroy {
    recipientUser: IRecipientUser;
    header: string;
    subscriptions: Subscription = new Subscription();

    urlSegmentsToIgnore = ['sales-orders'];

    constructor(private recipientUserService: RecipientUserService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('recipientUserId');
        // set the header based on the id
        if (id > 0) {
            this.recipientUserService.getById(id).subscribe((recipientUser) => {
                this.setHeader(recipientUser);
            });
        } else {
            this.setHeader(this.recipientUserService.getEmptyRecipientUser());
        }
        // subscribe to any changes in the recipientUser service
        // which should update the header accordingly
        this.subscriptions.add(
            this.recipientUserService.changeEmitted$.subscribe((recipientUser) => {
                this.setHeader(recipientUser);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(recipientUser: IRecipientUser): void {
        this.recipientUser = recipientUser;
        this.header = recipientUser && recipientUser.Id ? `Recipient: ${recipientUser.FirstName} ${recipientUser.LastName}` : 'Add Recipient';
    }
}
