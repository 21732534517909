import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DonorApplicationDetailsService } from '../services/donor-application-details.service';
import { FormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDonor } from '@model/interfaces/donor';
import { DonorService } from 'admin-portal/donors/services/donor.service';

@Component({
    template: '',
})
export class DonorApplicationDetailsSectionBaseComponent implements OnInit, OnChanges {
    @Input() donor: IDonor;
    @Input() isEditing: boolean;
    @Output() outputCancelClick = new EventEmitter();

    public get formChanged(): boolean {
        return this.currentFormValues !== undefined;
    }

    formId: number;
    currentFormValues: any;

    constructor(
        private service: DonorApplicationDetailsService,
        private notificationsService: NotificationsService,
        protected donorService: DonorService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        // Re-attach FKs if donor is updated
        if (changes?.donor?.previousValue) {
            this.service.getDonorFks(this.donor, this.formId);
        }
    }

    ngOnInit(): void {
        this.service.getDonorFks(this.donor, this.formId);
    }

    onFormValueChanges(value: FormGroup): void {
        this.currentFormValues = value;
    }

    onSubmit(): void {
        this.service.update(this.currentFormValues, this.formId).subscribe((resp) => {
            this.success();
            this.updateValues();
        });
    }

    updateValues(): void {
        this.service.getDonorFks(this.donor, this.formId);
    }

    success(): void {
        this.notificationsService.success('Application saved successfully!');
        this.donorService.emitChange(this.donor);
        this.cancelClick();
    }

    cancelClick(): void {
        this.outputCancelClick.emit();
    }
}
