<div class="display-padded ng-star-inserted">
    <ng-container *ngIf="!isEditing">
        <label for="ssn"><b>Social Security Number:</b></label>
    </ng-container>
    <ng-container *ngIf="!isEditing && !showSsn">
        <label for="ssn"><b>***-***-****</b></label>
    </ng-container>
    <ng-container *ngIf="!isEditing && showSsn">
        <span>{{ formattedSsn }}</span>
    </ng-container>
    <ng-container *ngIf="canViewEdit && isEditing" class="col-md-12">
        <div class="row">
            <div class="col-md-5">
                <label for="ssn"><b>Social Security Number:</b></label>
            </div>
            <div class="col-md-5">
                <input id="ssn" type="text" [formControl]="ssn">
            </div>
            <div class="col-md-2">
                <span (click)="save()">
                    <i class="fa fa-lg fa-floppy-o"></i>
                </span>
                <span (click)="cancel()">
                    <i class="fa fa-lg fa-times"></i>
                </span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="canViewEdit && !isEditing">
        <span *ngIf="showSsn" (click)="hideSsn()">
            <i class="fa fa-lg fa-eye-slash"></i>
        </span>
        <span *ngIf="!showSsn" (click)="getSsn()">
            <i class="fa fa-lg fa-eye"></i>
        </span>
        <span *ngIf="showEdit" (click)="edit()">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </ng-container>
</div>
