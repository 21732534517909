import { getMetaItemValue } from '@mt-ng2/common-functions';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, SelectInputTypes } from '@mt-ng2/dynamic-form';

import { INotificationTemplate } from '@model/interfaces/notification-template';
import { IExpandableObject } from '@model/expandable-object';

export interface ISelectTemplateDynamicControlsParameters {
    formGroup?: string;
    emailTemplates: INotificationTemplate[];
}
export class SelectTemplateDynamicControls {
    formGroup: string;
    emailTemplates: INotificationTemplate[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private emailTemplate?: INotificationTemplate, additionalParameters?: ISelectTemplateDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'Template';
        this.emailTemplates = additionalParameters && additionalParameters.emailTemplates;

        this.Form = {
            TemplateId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email Template',
                name: 'TemplateId',
                options: this.emailTemplates,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.Dropdown,
                }),
                validation: [],
                validators: {},
                value: emailTemplate.Id ? this.emailTemplates.find((temp) => temp.Id === emailTemplate.Id).Id : null,
            }),
        };

        this.View = {
            TemplateId: new DynamicLabel(
                'Email Template',
                getMetaItemValue(
                    this.emailTemplates,
                    this.emailTemplate && this.emailTemplate.hasOwnProperty('Id') && this.emailTemplate.Id !== null ? this.emailTemplate.Id : null,
                ),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };
    }
}
