import { Component } from '@angular/core';
import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';

import { AbstractLabDataFormComponent } from '../base-components/abstract-lab-data-form.component';

@Component({
    selector: 'app-donor-lab-data-location',
    templateUrl: './lab-data-location.component.html',
})
export class LabDataLocationComponent extends AbstractLabDataFormComponent {
    sectionId = DonorTestSampleLabDataSections.Location;
}
