import { RecipientDynamicControls, IRecipientDynamicControlsParameters } from '../form-controls/recipient.form-controls';
import { IRecipient } from '../interfaces/recipient';
import { IAddress } from '../interfaces/address';
import { IClinic } from '../interfaces/clinic';
import { IContactType } from '../interfaces/contact-type';
import { IReferralType } from '../interfaces/referral-type';
import { IRecipientStatus } from '../interfaces/recipient-status';
import { IUser } from '@model/interfaces/user';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';

export class RecipientDynamicControlsPartial extends RecipientDynamicControls {
    constructor(recipientPartial?: IRecipient, additionalParameters?: IRecipientDynamicControlsParameters, accountManagers?: IUser[]) {
        super(recipientPartial, additionalParameters);

        (<DynamicField>this.Form.Dob).type.datepickerOptions.minDate = { year: 1900, day: 1, month: 1 };
        (<DynamicField>this.Form.PartnerDob).type.datepickerOptions.minDate = { year: 1900, day: 1, month: 1 };
        (<DynamicField>this.Form.AccountManagerId).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.Dropdown,
            scale: null,
        });
        (<DynamicField>this.Form.AccountManagerId).options = accountManagers ? accountManagers : [];
        (<DynamicField>this.Form.Phone).value =
            recipientPartial && recipientPartial.hasOwnProperty('Phone') && recipientPartial.Phone !== null
                ? recipientPartial.Phone.toString()
                : null;
    }
}
