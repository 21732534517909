import { Component, Input, OnInit } from '@angular/core';
import { DonorInventorySelectEntityListConfig } from '@common/components/donor-select-inventory/donor-inventory-select.entity-list-config';
import { IDonor } from '@model/interfaces/donor';
import { IVwDonorInventory } from '@model/interfaces/vw-donor-inventory';
import { SalesOrderService } from 'admin-portal/recipient-users/services/sales-order.service';

@Component({
    selector: 'sales-order-inventory',
    templateUrl: 'sales-order-inventory.component.html',
})
export class SalesOrderInventoryComponent implements OnInit {
    @Input() salesOrderId: number;
    @Input() donors: IDonor[];
    @Input() productTypeId: number;
    @Input() vialStatusId: number;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    entityListConfig = new DonorInventorySelectEntityListConfig(false);

    inventory: IVwDonorInventory[];
    total: number;

    get donorIds(): number[] {
        return this.donors.map((d) => d.Id);
    }

    constructor(private salesOrderService: SalesOrderService) {}

    ngOnInit(): void {
        this.salesOrderService.GetSalesOrderInventory(this.salesOrderId).subscribe((inv) => ((this.inventory = inv), (this.total = inv.length)));
    }

    onInventorySelected(selectedInv: IVwDonorInventory[]): void {
        this.salesOrderService.UpdateSalesOrderInventory(this.salesOrderId, selectedInv).subscribe((inv) => {
            this.inventory = inv;
            this.isEditing = false;
        });
    }

    edit(): void {
        this.isEditing = true;
    }
}
