<div *ngIf="salesOrder">
    <div class="row">
        <div class="col-md-6">
            <app-sales-order-basic-info [salesOrder]="salesOrder" [canEdit]="canEdit"></app-sales-order-basic-info>
            <br />
        </div>
        <div class="col-md-6">
            <sales-order-inventory
                [salesOrderId]="salesOrder.Id"
                [donors]="salesOrder.Donors"
                [productTypeId]="salesOrder.ProductTypeId"
                [vialStatusId]="salesOrder.VialStatusId"
                [canEdit]="canEdit"
            ></sales-order-inventory>
            <br />
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ngb-tabset type="pills">
                <ngb-tab id="tab-shipping">
                    <ng-template ngbTabTitle><h4 style="color: white">Shipping</h4></ng-template>
                    <ng-template ngbTabContent>
                        <br />
                        <sales-order-shipping-details [salesOrder]="salesOrder" [canEdit]="canEdit"></sales-order-shipping-details>
                        <br />
                    </ng-template>
                </ngb-tab>
                <ngb-tab id="tab-thawing">
                    <ng-template ngbTabTitle><h4 style="color: white">Thawing</h4></ng-template>
                    <ng-template ngbTabContent>
                        <br />
                        <sales-order-thawings [salesOrderId]="salesOrder.Id" [canEdit]="canEdit"></sales-order-thawings>
                    </ng-template>
                </ngb-tab>
                <ngb-tab id="tab-outcomes">
                    <ng-template ngbTabTitle><h4 style="color: white">Outcomes</h4></ng-template>
                    <ng-template ngbTabContent>
                        <br />
                        <app-outcomes [salesOrderId]="salesOrder.Id" [canEdit]="canEdit"></app-outcomes>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
    <a [routerLink]="['../../']" class="btn btn-default">Close</a>
</div>
