import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';

import { DonorDetailComponent } from './donor-detail/donor-detail.component';
import { DonorsComponent } from './donor-list/donors.component';
import { DonorService } from './services/donor.service';
import { DonorHeaderComponent } from './donor-header/donor-header.component';

import { ClaimTypes } from '@model/ClaimTypes';
import { DonorTestSampleDetailComponent } from './initial-screening/donor-test-samples/donor-test-sample-detail/donor-test-sample-detail.component';
import { DonorDonationDetailComponent } from './donor-donations/donor-donation-detail/donor-donation-detail.component';
import { DonorOngoingScreeningService } from './donor-ongoing-screenings/services/donor-ongoing-screening.service';
import { DonorOngoingScreeningDetailComponent } from './donor-ongoing-screenings/donor-ongoing-screening-detail/donor-ongoing-screening-detail.component';
import { DonorOngoingScreeningHeaderComponent } from './donor-ongoing-screenings/donor-ongoing-screening-header/donor-ongoing-screening-header.component';
import { DonorSampleService } from './services/donor-sample.service';

const donorEntityConfig = {
    claimType: ClaimTypes.Donors,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'donorId',
    service: DonorService,
    title: 'Donor Detail',
};

const donorTestSampleEntityConfig = {
    claimType: ClaimTypes.Donors,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'testSampleId',
    service: DonorSampleService,
    title: 'Donor Test Sample Detail',
};

const donorDonationSampleEntityConfig = {
    claimType: ClaimTypes.Donors,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'donationSampleId',
    service: DonorSampleService,
    title: 'DonorSample Detail',
};

const donorOngoingScreeningEntityConfig = {
    claimType: ClaimTypes.Donors,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'donorOngoingScreeningId',
    service: DonorOngoingScreeningService,
    title: 'Donor Ongoing Screening Detail',
};

const donorListRoleGuard: any = {
    claimType: ClaimTypes.Donors,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Donors',
};

export const donorPaths = {
    donors: 'donors',
};

const donorRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: DonorsComponent,
        data: donorListRoleGuard,
        path: '',
    },
    {
        canActivate: [AuthGuard],
        children: [
            { component: DonorDetailComponent, path: '', pathMatch: 'full' },
            { component: DonorTestSampleDetailComponent, path: `test-samples/:${donorTestSampleEntityConfig.entityIdParam}`, pathMatch: 'full' },
            {
                component: DonorDonationDetailComponent,
                path: `donation-samples/:${donorDonationSampleEntityConfig.entityIdParam}`,
                pathMatch: 'full',
            },
        ],
        component: DonorHeaderComponent,
        data: donorEntityConfig,
        path: `:${donorEntityConfig.entityIdParam}`,
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: DonorOngoingScreeningDetailComponent,
                path: '',
                pathMatch: 'full',
            },
        ],
        component: DonorOngoingScreeningHeaderComponent,
        data: donorOngoingScreeningEntityConfig,
        path: `:${donorEntityConfig.entityIdParam}/ongoing-screenings/:${donorOngoingScreeningEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(donorRoutes)],
})
export class DonorRoutingModule {}
