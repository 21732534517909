<div class="miles-editable miles-card padded" *ngIf="!isEditing && donor.Id > 0" (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'">
    <h4>
        Donor Availability
        <span class="pull-right">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div class="row">
        <div class="col-md-12">
            <span *ngIf="!donorAvailabilities || !donorAvailabilities.length"><i>No Countries Assigned</i></span>
            <div *ngIf="donorAvailabilities && donorAvailabilities.length">
                <div *ngFor="let country of displayRegionNames(donorAvailabilities)">
                    <span>{{ country }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div [hidden]="!isEditing" class="miles-form padded">
    <form *ngIf="formCreated" [formGroup]="donorAvailabilitiesForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-12">
                <h4>Donor Country</h4>
                <dynamic-field [field]="abstractDonorAvailabilityControls.CountryCode" [form]="donorAvailabilitiesForm"
                    (valueChanges)="onCountryChanged($event)"></dynamic-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <dynamic-field *ngIf="showSelectAuStates" [field]="abstractDonorAvailabilityControls.AuStateCodes"
                    [form]="donorAvailabilitiesForm">
                </dynamic-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                    Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
        </div>
    </form>
</div>