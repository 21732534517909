<div class="row">
    <div class="col-md-6">
        <app-donor-raw-semen
            [testSample]="donorSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="labDataService"
            [formControls]="dynamicControls"
        ></app-donor-raw-semen>
        <br />
        <app-donor-cryo-prep [testSample]="donorSample" [dataService]="labDataService"></app-donor-cryo-prep>
        <br />
    </div>
    <div class="col-md-6">
        <app-donor-post-gradient
            [testSample]="donorSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="labDataService"
            [formControls]="dynamicControls"
        ></app-donor-post-gradient>
        <br />
        <app-donor-ivf-vials
            [testSample]="donorSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="labDataService"
            [formControls]="dynamicControls"
        ></app-donor-ivf-vials>
        <br />
        <app-donor-test-thaw-info
            [testSample]="donorSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="labDataService"
            [formControls]="dynamicControls"
        ></app-donor-test-thaw-info>
        <br />
    </div>
</div>
