import { IEmail } from '@mt-ng2/email-modal-module';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IShipping } from '@model/interfaces/shipping';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const emptyShipping: IShipping = {
    ActualShipDate: null,
    ClinicalBoundAirwayBillNumber: null,
    DataLoggerNumber: null,
    DeliveryDate: null,
    ExpectedShipDate: null,
    Id: 0,
    NumberOfVialsShipped: 0,
    PrintSummary: null,
    ProposedShipDate: new Date(),
    ReturnDate: null,
    SalesOrderId: null,
    ShipmentTypeId: null,
    ShipperId: null,
    ShippingInvoiceNumber: null,
    ShippingMethodId: null,
    ShippingStatusId: null,
    ShipToClinicId: null,
    TankNumber: null,
    TwebBoundAirwayBillNumber: null,
};

@Injectable({
    providedIn: 'root',
})
export class ShippingService extends BaseService<IShipping> {
    constructor(public http: HttpClient) {
        super('/shippings', http);
    }

    getEmptyShipping(salesOrderId: number): IShipping {
        const newEmptyShipping = { ...emptyShipping };
        newEmptyShipping.SalesOrderId = salesOrderId;
        return newEmptyShipping;
    }

    sendConfirmationEmail(shippingId: number, email: IEmail): Observable<IShipping> {
        return this.http.post<IShipping>(`/shippings/${shippingId}/confirmation-email`, email);
    }
}
