import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, AdminAccessComponent } from '@mt-ng2/login-module';

import { appPaths } from './admin-portal/default-routes/app-paths.library';
import { MtCanLoadAuthGuard } from '@mt-ng2/auth-module';

const appRoutes: Routes = [
    { path: appPaths.login, component: LoginComponent },
    { path: appPaths.forgotPassword, component: ForgotPasswordComponent },
    { path: appPaths.resetPassword, component: ResetPasswordComponent },
    { path: appPaths.adminAccess, component: AdminAccessComponent },
    { canLoad: [MtCanLoadAuthGuard], path: '', loadChildren: () => import('./admin-portal/admin-portal.module').then((m) => m.AdminPortalModule) },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
