import { NgModule } from '@angular/core';

import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { MtSearchFilterDaterangeModule } from '@mt-ng2/search-filter-daterange-control';
import { MtSearchFilterCheckboxModule } from '@mt-ng2/search-filter-checkbox-control';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { LocalizeDatePipe } from './pipes/localize-date.pipe';
import { SendEmailTemplateComponent } from './components/send-email/send-email-template.component';
import { UserEntitySearchComponent } from './components/user-entity-search/user-entity-search.component';
import { ResultsPerPageComponent } from './components/results-per-page.component';
import { DonorSelectInventoryComponent } from './components/donor-select-inventory/donor-select-inventory.component';

import { FeatureModule } from '@angular-common/feature.module';
import { EmailModalModule } from '@mt-ng2/email-modal-module';
import { FileUploadModule } from 'ng2-file-upload';
import { CommonModule } from '@angular/common';

/**
 * Feature Module contains all imports and exports that are specific to Features.  This makes
 * importing the standard grouping of mt-ng2 and angular modules easy and also helps with
 * bundling by having a consistent feature module grouping.
 *
 * Would only be imported into Features such as "CustomerModule" and "UserModule" and would
 * not be imported into root modules such as "AppModule" or "AdminPortalModule", nor other
 * base modules such as "AdminPortalSharedModule".
 *
 * Tip: If the import/export should be included in all modules, including the root AppModule at
 * startup, than consider putting it into "AdminPortalSharedModule" instead.
 */
@NgModule({
    declarations: [
        YesNoPipe,
        LocalizeDatePipe,
        SendEmailTemplateComponent,
        ResultsPerPageComponent,
        UserEntitySearchComponent,
        DonorSelectInventoryComponent,
    ],
    exports: [
        FeatureModule,
        MtManagedListControlModule,
        MtSearchFilterDaterangeModule,
        MtSearchFilterCheckboxModule,
        YesNoPipe,
        LocalizeDatePipe,
        SendEmailTemplateComponent,
        FileUploadModule,
        ResultsPerPageComponent,
        UserEntitySearchComponent,
        DonorSelectInventoryComponent,
    ],
    imports: [
        FeatureModule,
        MtManagedListControlModule,
        MtSearchFilterDaterangeModule,
        MtSearchFilterCheckboxModule,
        EmailModalModule,
        FileUploadModule,
        CommonModule,
    ],
})
export class AdminPortalFeatureModule {}
