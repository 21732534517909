<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        QC Investigation
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div *ngIf="formCreated">
        <div class="row">
            <div class="col-md-6">
                <dynamic-label [field]="abstractQcInvestigationControls.View.Investigation"></dynamic-label>
                <div *ngIf="activeInvestigation">
                    <dynamic-label [field]="abstractQcInvestigationControls.View.QcDecisionId"></dynamic-label>
                    <dynamic-label
                        *ngIf="qcDecision === decisions.NothingOffered"
                        [field]="abstractQcInvestigationControls.View.NothingOfferedReason"
                    ></dynamic-label>
                    <dynamic-label
                        *ngIf="qcDecision === decisions.EggReplacement"
                        [field]="abstractQcInvestigationControls.View.NumberFreeOva"
                    ></dynamic-label>
                    <div *ngIf="qcDecision === decisions.Discount">
                        <dynamic-label [field]="abstractQcInvestigationControls.View.DiscountTypeId"></dynamic-label>
                        <dynamic-label [field]="abstractQcInvestigationControls.View.DiscountAmount"></dynamic-label>
                    </div>
                    <dynamic-label [field]="abstractQcInvestigationControls.View.HaltSalesOrder"></dynamic-label>
                    <dynamic-label [field]="abstractQcInvestigationControls.View.WatchlistDonor"></dynamic-label>
                    <dynamic-label [field]="abstractQcInvestigationControls.View.DiscontinueDonor"></dynamic-label>
                </div>
            </div>
            <div class="col-md-6" *ngIf="activeInvestigation">
                <dynamic-label [field]="abstractQcInvestigationControls.View.DraftsSentForApproval"></dynamic-label>
                <dynamic-label [field]="abstractQcInvestigationControls.View.EmailApproved"></dynamic-label>
                <dynamic-label [field]="abstractQcInvestigationControls.View.EmailSentToRecipient"></dynamic-label>
                <dynamic-label [field]="abstractQcInvestigationControls.View.ResponseSentToClinic"></dynamic-label>
                <dynamic-label [field]="abstractQcInvestigationControls.View.QcComments"></dynamic-label>
                <dynamic-label [field]="abstractQcInvestigationControls.View.QcReasonId"></dynamic-label>
                <dynamic-label [field]="abstractQcInvestigationControls.View.QcProblemId"></dynamic-label>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isEditing" class="miles-form padded">
    <h4>QC Investigation</h4>
    <div *ngIf="formCreated">
        <form [formGroup]="qcInvestigationForm" (ngSubmit)="formSubmitted()">
            <div class="row">
                <div class="col-md-6">
                    <dynamic-field
                        [field]="abstractQcInvestigationControls.Form.Investigation"
                        [form]="qcInvestigationForm"
                        (valueChanges)="activeInvestigation = $event"
                    ></dynamic-field>
                    <div *ngIf="activeInvestigation">
                        <dynamic-field
                            [field]="abstractQcInvestigationControls.Form.QcDecisionId"
                            [form]="qcInvestigationForm"
                            (valueChanges)="onQCDecisionChange($event)"
                        ></dynamic-field>
                        <dynamic-field
                            *ngIf="qcDecision === decisions.NothingOffered"
                            [field]="abstractQcInvestigationControls.Form.NothingOfferedReason"
                            [form]="qcInvestigationForm"
                        ></dynamic-field>
                        <dynamic-field
                            *ngIf="qcDecision === decisions.EggReplacement"
                            [field]="abstractQcInvestigationControls.Form.NumberFreeOva"
                            [form]="qcInvestigationForm"
                        ></dynamic-field>
                        <div *ngIf="qcDecision === decisions.Discount">
                            <dynamic-field [field]="abstractQcInvestigationControls.Form.DiscountTypeId" [form]="qcInvestigationForm"></dynamic-field>
                            <dynamic-field [field]="abstractQcInvestigationControls.Form.DiscountAmount" [form]="qcInvestigationForm"></dynamic-field>
                        </div>
                        <dynamic-field [field]="abstractQcInvestigationControls.Form.HaltSalesOrder" [form]="qcInvestigationForm"></dynamic-field>
                        <dynamic-field [field]="abstractQcInvestigationControls.Form.WatchlistDonor" [form]="qcInvestigationForm"></dynamic-field>
                        <dynamic-field [field]="abstractQcInvestigationControls.Form.DiscontinueDonor" [form]="qcInvestigationForm"></dynamic-field>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="activeInvestigation">
                    <dynamic-field [field]="abstractQcInvestigationControls.Form.DraftsSentForApproval" [form]="qcInvestigationForm"></dynamic-field>
                    <dynamic-field [field]="abstractQcInvestigationControls.Form.EmailApproved" [form]="qcInvestigationForm"></dynamic-field>
                    <dynamic-field [field]="abstractQcInvestigationControls.Form.EmailSentToRecipient" [form]="qcInvestigationForm"></dynamic-field>
                    <dynamic-field [field]="abstractQcInvestigationControls.Form.ResponseSentToClinic" [form]="qcInvestigationForm"></dynamic-field>
                    <dynamic-field [field]="abstractQcInvestigationControls.Form.QcComments" [form]="qcInvestigationForm"></dynamic-field>
                    <dynamic-field [field]="abstractQcInvestigationControls.Form.QcReasonId" [form]="qcInvestigationForm"></dynamic-field>
                    <dynamic-field [field]="abstractQcInvestigationControls.Form.QcProblemId" [form]="qcInvestigationForm"></dynamic-field>
                </div>
                <div class="col-md-12">
                    <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                        Save
                    </button>
                    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>
