<div *ngIf="!isEditing && donor.DonorFamilyHistory">
    <h4>
        Family History
    </h4>
    <div>
       <individual-family-history-detail
            [individual]="donor.DonorFamilyHistory.Mother"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Maternal History"
       >
       </individual-family-history-detail>
       <individual-family-history-detail
            [individual]="donor.DonorFamilyHistory.Father"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Paternal History"
       >
       </individual-family-history-detail>
       <individual-family-history-detail
            [individual]="donor.DonorFamilyHistory.MaternalGrandmother"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Maternal Grandmother History"
       >
       </individual-family-history-detail>
       <individual-family-history-detail
            [individual]="donor.DonorFamilyHistory.MaternalGrandfather"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Maternal Grandfather History"
       >
       </individual-family-history-detail>
       <individual-family-history-detail
            [individual]="donor.DonorFamilyHistory.PaternalGrandmother"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Paternal Grandmother History"
       >
       </individual-family-history-detail>
       <individual-family-history-detail
            [individual]="donor.DonorFamilyHistory.PaternalGrandfather"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Paternal Grandfather History"
       >
       </individual-family-history-detail>
       <individual-family-history-detail
            *ngFor="let sibling of donor.DonorFamilyHistory.DonorSiblingFamilyHistories; let i = index"
            [individual]="sibling"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            [siblingIndex]="i"
            sectionTitle="Sibling {{ i + 1 }} History"
       ></individual-family-history-detail>
    </div>
</div>
<div *ngIf="isEditing">
    <h4>Family History</h4>
    <family-history
    [donor]="donor"
    (onFormValueChanges)="onFormValueChanges($event)"
    ></family-history>
    <div>
        <button [disabled]="!formChanged" class="btn btn-success btn-flat" (click)="onSubmit()">
            Save
        </button>
        <button class="btn btn-default btn-flat" (click)="cancelClick()">
            Cancel
        </button>
    </div>
</div>
