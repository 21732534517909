import { NgModule } from '@angular/core';

import { AdminPortalSharedModule } from '@common/shared.module';
import { AdminPortalFeatureModule } from '@common/feature.module';
import { CommonComponentsModule } from '@angular-common/components/components.module';

import { DonorDonationAddComponent } from './donor-donation-add/donor-donation-add.component';
import { DonorDonationBasicInfoComponent } from './donor-donation-basic-info/donor-donation-basic-info.component';
import { DonorDonationDetailComponent } from './donor-donation-detail/donor-donation-detail.component';
import { DonorDonationsListComponent } from './donor-donations-list/donor-donations-list.component';
import { DonationStatusIconComponent } from './donor-donations-list/donation-status-icon.component';
import { ViewDonationDetailsComponent } from './donor-donations-list/view-donation-details.component';
import { InventoryModule } from '../inventory/inventory.module';
import { DonorSampleLabDataModule } from '../donor-sample-lab-data/donor-sample-lab-data.module';
import { DonationLabDataComponent } from './donor-donation-lab-data/donor-donation-lab-data.component';
import { RemainderLabDataComponent } from './donor-donation-remainder-lab-data/remainder-lab-data.component';
import { BulkInventoryUpdateComponent } from './bulk-inventory-update/bulk-inventory-update.component';

@NgModule({
    declarations: [
        DonorDonationAddComponent,
        DonorDonationBasicInfoComponent,
        DonorDonationDetailComponent,
        DonorDonationsListComponent,
        DonationStatusIconComponent,
        ViewDonationDetailsComponent,
        DonationLabDataComponent,
        RemainderLabDataComponent,
        BulkInventoryUpdateComponent,
    ],
    exports: [DonorDonationsListComponent],
    imports: [AdminPortalSharedModule, AdminPortalFeatureModule, CommonComponentsModule, InventoryModule, DonorSampleLabDataModule],
})
export class DonorSampleModule {}
