import { UploadDocumentsComponent } from '@angular-common/components/upload-documents/upload-documents.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileItem } from 'ng2-file-upload';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IDynamicField } from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { DocumentEntities } from '@model/enums/document-entities.enum';
import { IDonor } from '@model/interfaces/donor';
import { IGeneticScreeningDocument } from '@model/interfaces/genetic-screening-document';
import { GeneticScreeningTypes } from '@model/enums/genetic-screening-types.enum';
import { IGeneticScreening } from '@model/interfaces/genetic-screening';

import { GeneticScreeningDynamicConfig } from './genetic-screening.dynamic-config';
import { GeneticScreeningService } from '../../services/genetic-screening.service';
import { GeneticScreeningUploadService } from '../../services/genetic-screening-upload.service';

@Component({
    selector: 'app-genetic-screening',
    styles: [
        `
            .readonly-table td {
                text-align: center;
                width: 60%;
            }
            th {
                width: 40%;
            }
            .addMoreButton {
                margin-top: -16px;
            }
            .indented {
                padding-left: 15px;
            }
            .no-border {
                border: none;
            }
        `,
    ],
    templateUrl: 'genetic-screening.component.html',
})
export class GeneticScreeningComponent extends UploadDocumentsComponent implements OnInit {
    @Input() donor: IDonor;

    @Output() emitIsEditing = new EventEmitter<boolean>();
    readonly GeneticScreeningTypes = GeneticScreeningTypes;
    showDocumentsCard = false;
    headerTitle = 'Uploaded Genetic Screening Documents';
    form: FormGroup;
    config: IDynamicField[];
    currentlyEditedFileName: string;
    currentlyEditedGeneticScreeningId: number;
    currentlyEditedGeneticScreeningDocumentId: number;
    canUpload: boolean;
    entityType: DocumentEntities;
    entityId: number;
    geneticScreeningType: GeneticScreeningTypes = null;

    constructor(
        private claimsService: ClaimsService,
        public uploadDocumentsService: GeneticScreeningUploadService,
        private notificationService: NotificationsService,
        private fb: FormBuilder,
        private geneticScreeningService: GeneticScreeningService,
    ) {
        super(uploadDocumentsService, notificationService);
    }

    ngOnInit(): void {
        this.modalOptions.width = '500px';
        this.tempFile = null;
        this.mustSelectType = false;
        this.entityId = this.donor.Id;
        this.entityType = DocumentEntities.GeneticScreening;
        this.documentArray = this.donor.GeneticScreeningDocuments;
        this.canUpload = this.claimsService.hasClaim(ClaimTypes.Donors, [ClaimValues.FullAccess]);
        this.showDocumentsCard = this.claimsService.hasClaim(ClaimTypes.Donors, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        if (!this.documentTypes || !this.documentTypes.length) {
            this.uploadDocumentsService.getDocumentTypes(DocumentEntities.GeneticScreening, this.donor.Id).subscribe((documentTypes) => {
                this.documentTypes = documentTypes;
                this.documentTypeSelected = this.documentTypes[0].Id;
            });
        }
    }

    confirm(file: FileItem): void {
        this.tempFile = file;
    }

    setConfigForCreate(): void {
        const configControls = this.getConfigControls();
        const config = new GeneticScreeningDynamicConfig(this.geneticScreeningService.getEmptyGeneticScreening(), configControls);
        this.config = config.getForCreate().formObject;
    }

    setConfigForUpdate(doc: IGeneticScreeningDocument): void {
        const configControls = this.getConfigControls();
        const config = new GeneticScreeningDynamicConfig(doc.GeneticScreenings[0], configControls);
        this.config = config.getForUpdate().formObject;
    }

    getConfigControls(): string[] {
        let configControls = null;
        switch (this.geneticScreeningType) {
            case GeneticScreeningTypes.CarrierTesting:
                configControls = [
                    'CarrierTestingDrawDate',
                    'CarrierTestingResultsReceivedDate',
                    'CarrierTestingResultsReceivedBy',
                    'CarrierTestingAcceptedRejected',
                    'CarrierTestingAcceptedRejectedDate',
                    'CarrierTestingAcceptedRejectedBy',
                ];
                break;
            case GeneticScreeningTypes.GeneticConsult:
                configControls = [
                    'GeneticConsultSentToCounselorDate',
                    'GeneticConsultPerformedDate',
                    'GeneticConsultReportReceivedDate',
                    'GeneticConsultReportReceivedBy',
                    'GeneticConsultAcceptedRejected',
                    'GeneticConsultAcceptedRejectedDate',
                    'GeneticConsultAcceptedRejectedBy',
                ];
                break;
            default:
                break;
        }
        return configControls;
    }

    getDocuments(): void {
        this.uploadDocumentsService
            .getDocuments<IGeneticScreeningDocument[]>(DocumentEntities.GeneticScreening, this.donor.Id)
            .subscribe((documents) => {
                const typeIds = this.documentTypes.map((x) => x.Id);
                this.documentArray = documents.filter((doc) => typeIds.includes(doc.DocumentTypeId));
            });
    }

    save(): void {
        let geneticScreeningFormValue = this.form.value.GeneticScreening;
        if (this.currentlyEditedGeneticScreeningDocumentId) {
            this.updateGeneticScreening(geneticScreeningFormValue);
        } else {
            this.saveNewGeneticScreening(geneticScreeningFormValue);
        }
    }

    private saveNewGeneticScreening(geneticScreeningFormValue: any): void {
        this.isUploading = true;
        let geneticScreeningTypeId = +this.geneticScreeningType;
        geneticScreeningFormValue.GeneticScreeningTypeId = geneticScreeningTypeId;
        const createGeneticScreening$ = this.tempFile
            ? this.uploadDocumentsService.saveGeneticScreeningDocument(
                  this.entityType,
                  this.entityId,
                  this.documentTypeSelected,
                  this.tempFile._file,
                  geneticScreeningTypeId,
              )
            : this.uploadDocumentsService.createWithoutUpload(this.entityType, this.entityId, this.documentTypeSelected);
        createGeneticScreening$.subscribe((response) => {
            let geneticScreeningDocumentId = response;
            this.saveNewGeneticScreeningCall(geneticScreeningFormValue, geneticScreeningDocumentId);
        });
    }

    private saveNewGeneticScreeningCall(geneticScreening: IGeneticScreening, geneticScreeningDocumentId: number): void {
        geneticScreening.DocumentEntityId = geneticScreeningDocumentId;
        this.geneticScreeningService.create(geneticScreening).subscribe(() => {
            this.success('Genetic Screening Document successfully uploaded');
        });
    }

    private updateGeneticScreening(geneticScreeningFormValue: any): void {
        geneticScreeningFormValue.Id = this.currentlyEditedGeneticScreeningId;
        geneticScreeningFormValue.DocumentEntityId = this.currentlyEditedGeneticScreeningDocumentId;
        geneticScreeningFormValue.GeneticScreeningTypeId = +this.geneticScreeningType;
        if (this.tempFile) {
            this.uploadDocumentsService
                .updateDocumentEntity(this.entityType, this.entityId, geneticScreeningFormValue.DocumentEntityId, this.tempFile._file)
                .subscribe(() => {
                    this.updateGeneticScreeningCall(geneticScreeningFormValue);
                });
        } else {
            this.updateGeneticScreeningCall(geneticScreeningFormValue);
        }
    }

    private updateGeneticScreeningCall(geneticScreening: IGeneticScreening): void {
        this.geneticScreeningService.update(geneticScreening).subscribe(() => {
            this.success('Genetic Screening Document successfully updated');
        });
    }

    editGeneticScreening(doc: IGeneticScreeningDocument): void {
        this.geneticScreeningType = doc.GeneticScreenings[0].GeneticScreeningTypeId;
        this.currentlyEditedGeneticScreeningId = doc.GeneticScreenings[0].Id;
        this.currentlyEditedGeneticScreeningDocumentId = doc.Id;
        this.currentlyEditedFileName = doc.Document?.Name || null;
        this.tempFile = null;
        this.showModal = true;
        this.form = this.fb.group({});
        this.setConfigForUpdate(doc);
    }

    deleteGeneticScreeningDocument(doc: IGeneticScreeningDocument): void {
        if (doc.DocumentId) {
            super.deleteDocument(doc.DocumentId);
        } else {
            this.uploadDocumentsService.deleteWithoutDocument(this.entityType, this.entityId, doc.Id).subscribe(() => {
                this.success('Genetic Screening Document Deleted');
            });
        }
    }

    closePreview(): void {
        this.showModal = false;
        this.showUploadArea = false;
        this.isUploading = false;
        this.isEditing = false;
        this.editedDocument = null;
        this.fileName = null;
        this.extension = null;
        this.currentlyEditedFileName = null;
        this.currentlyEditedGeneticScreeningDocumentId = null;
        this.currentlyEditedGeneticScreeningId = null;

        this.emitIsEditing.emit(false);
    }

    success(message: string): void {
        this.getDocuments();
        this.closePreview();
        this.currentlyEditedGeneticScreeningId = null;
        this.currentlyEditedGeneticScreeningDocumentId = null;
        this.tempFile = null;
        this.notificationService.success(message);
    }

    edit(): void {
        this.isEditing = true;
        this.emitIsEditing.emit(true);
    }

    cancel(): void {
        super.cancel();
        this.isEditing = false;
        this.emitIsEditing.emit(false);
    }

    selectType(gsType: GeneticScreeningTypes): void {
        this.geneticScreeningType = gsType;
        this.showModal = true;
        this.setConfigForCreate();
        this.form = this.fb.group({});
    }
}
