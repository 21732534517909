import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorWhoAreYou } from '../interfaces/donor-who-are-you';
import { IBloodType } from '../interfaces/blood-type';
import { IBodyStructure } from '../interfaces/body-structure';
import { IGpa } from '../interfaces/gpa';
import { IEducationCompletedType } from '../interfaces/education-completed-type';
import { IEyeColor } from '../interfaces/eye-color';
import { IHairTexture } from '../interfaces/hair-texture';
import { INaturalHairColor } from '../interfaces/natural-hair-color';
import { IRace } from '../interfaces/race';
import { IRhFactor } from '../interfaces/rh-factor';
import { ISkinType } from '../interfaces/skin-type';
import { ITraumaticEvent } from '../interfaces/traumatic-event';

export interface IDonorWhoAreYouDynamicControlsParameters {
    formGroup?: string;
    bodyStructures?: IBodyStructure[];
    eyeColors?: IEyeColor[];
    naturalHairColors?: INaturalHairColor[];
    hairTextures?: IHairTexture[];
    skinTypes?: ISkinType[];
    races?: IRace[];
    educationCompletedTypes?: IEducationCompletedType[];
    highSchoolGpas?: IGpa[];
    collegeGpas?: IGpa[];
    bloodTypes?: IBloodType[];
    rhFactors?: IRhFactor[];
    traumaticEvents?: ITraumaticEvent[];
}

export class DonorWhoAreYouDynamicControls {

    formGroup: string;
    bodyStructures: IBodyStructure[];
    eyeColors: IEyeColor[];
    naturalHairColors: INaturalHairColor[];
    hairTextures: IHairTexture[];
    skinTypes: ISkinType[];
    races: IRace[];
    educationCompletedTypes: IEducationCompletedType[];
    highSchoolGpas: IGpa[];
    collegeGpas: IGpa[];
    bloodTypes: IBloodType[];
    rhFactors: IRhFactor[];
    traumaticEvents: ITraumaticEvent[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorwhoareyou?: IDonorWhoAreYou, additionalParameters?: IDonorWhoAreYouDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorWhoAreYou';
        this.bodyStructures = additionalParameters && additionalParameters.bodyStructures || undefined;
        this.eyeColors = additionalParameters && additionalParameters.eyeColors || undefined;
        this.naturalHairColors = additionalParameters && additionalParameters.naturalHairColors || undefined;
        this.hairTextures = additionalParameters && additionalParameters.hairTextures || undefined;
        this.skinTypes = additionalParameters && additionalParameters.skinTypes || undefined;
        this.races = additionalParameters && additionalParameters.races || undefined;
        this.educationCompletedTypes = additionalParameters && additionalParameters.educationCompletedTypes || undefined;
        this.highSchoolGpas = additionalParameters && additionalParameters.highSchoolGpas || undefined;
        this.collegeGpas = additionalParameters && additionalParameters.collegeGpas || undefined;
        this.bloodTypes = additionalParameters && additionalParameters.bloodTypes || undefined;
        this.rhFactors = additionalParameters && additionalParameters.rhFactors || undefined;
        this.traumaticEvents = additionalParameters && additionalParameters.traumaticEvents || undefined;

        this.Form = {
            Anxiety: new DynamicField({
                formGroup: this.formGroup,
                label: 'Anxiety',
                name: 'Anxiety',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('Anxiety') && this.donorwhoareyou.Anxiety !== null ? this.donorwhoareyou.Anxiety : false,
            }),
            BleedingDisorders: new DynamicField({
                formGroup: this.formGroup,
                label: 'Bleeding Disorders',
                name: 'BleedingDisorders',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('BleedingDisorders') && this.donorwhoareyou.BleedingDisorders !== null ? this.donorwhoareyou.BleedingDisorders : false,
            }),
            BloodTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Blood Type',
                name: 'BloodTypeId',
                options: this.bloodTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.BloodTypeId || null,
            }),
            BodyStructureId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Body Structure',
                name: 'BodyStructureId',
                options: this.bodyStructures,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.BodyStructureId || null,
            }),
            CharacterDescription: new DynamicField({
                formGroup: this.formGroup,
                label: 'Character Description',
                name: 'CharacterDescription',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('CharacterDescription') && this.donorwhoareyou.CharacterDescription !== null ? this.donorwhoareyou.CharacterDescription.toString() : '',
            }),
            CollegeGpaId: new DynamicField({
                formGroup: this.formGroup,
                label: 'College Gpa',
                name: 'CollegeGpaId',
                options: this.collegeGpas,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.CollegeGpaId || null,
            }),
            CollegeMajor: new DynamicField({
                formGroup: this.formGroup,
                label: 'College Major',
                name: 'CollegeMajor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('CollegeMajor') && this.donorwhoareyou.CollegeMajor !== null ? this.donorwhoareyou.CollegeMajor.toString() : '',
            }),
            Completed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Completed',
                name: 'Completed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.Completed || null,
            }),
            CurrentOccupation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Current Occupation',
                name: 'CurrentOccupation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('CurrentOccupation') && this.donorwhoareyou.CurrentOccupation !== null ? this.donorwhoareyou.CurrentOccupation.toString() : '',
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorwhoareyou && this.donorwhoareyou.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.DateModified || null,
            }),
            EducationCompletedTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Education Completed Type',
                name: 'EducationCompletedTypeId',
                options: this.educationCompletedTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.EducationCompletedTypeId || null,
            }),
            ExerciseRoutine: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exercise Routine',
                name: 'ExerciseRoutine',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('ExerciseRoutine') && this.donorwhoareyou.ExerciseRoutine !== null ? this.donorwhoareyou.ExerciseRoutine.toString() : '',
            }),
            ExperiencedTraumaticEvent: new DynamicField({
                formGroup: this.formGroup,
                label: 'Experienced Traumatic Event',
                name: 'ExperiencedTraumaticEvent',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('ExperiencedTraumaticEvent') && this.donorwhoareyou.ExperiencedTraumaticEvent !== null ? this.donorwhoareyou.ExperiencedTraumaticEvent : false,
            }),
            ExtracurricularActivities: new DynamicField({
                formGroup: this.formGroup,
                label: 'Extracurricular Activities',
                name: 'ExtracurricularActivities',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('ExtracurricularActivities') && this.donorwhoareyou.ExtracurricularActivities !== null ? this.donorwhoareyou.ExtracurricularActivities.toString() : '',
            }),
            EyeColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Eye Color',
                name: 'EyeColorId',
                options: this.eyeColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.EyeColorId || null,
            }),
            Favorites: new DynamicField({
                formGroup: this.formGroup,
                label: 'Favorites',
                name: 'Favorites',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('Favorites') && this.donorwhoareyou.Favorites !== null ? this.donorwhoareyou.Favorites.toString() : '',
            }),
            GeneticConditions: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Conditions',
                name: 'GeneticConditions',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('GeneticConditions') && this.donorwhoareyou.GeneticConditions !== null ? this.donorwhoareyou.GeneticConditions.toString() : '',
            }),
            Goals: new DynamicField({
                formGroup: this.formGroup,
                label: 'Goals',
                name: 'Goals',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('Goals') && this.donorwhoareyou.Goals !== null ? this.donorwhoareyou.Goals.toString() : '',
            }),
            HairTextureId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hair Texture',
                name: 'HairTextureId',
                options: this.hairTextures,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.HairTextureId || null,
            }),
            HasPlasticSurgery: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Plastic Surgery',
                name: 'HasPlasticSurgery',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('HasPlasticSurgery') && this.donorwhoareyou.HasPlasticSurgery !== null ? this.donorwhoareyou.HasPlasticSurgery : false,
            }),
            HaveChildren: new DynamicField({
                formGroup: this.formGroup,
                label: 'Have Children',
                name: 'HaveChildren',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('HaveChildren') && this.donorwhoareyou.HaveChildren !== null ? this.donorwhoareyou.HaveChildren : false,
            }),
            HighSchoolGpaId: new DynamicField({
                formGroup: this.formGroup,
                label: 'High School Gpa',
                name: 'HighSchoolGpaId',
                options: this.highSchoolGpas,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.HighSchoolGpaId || null,
            }),
            HobbiesInterests: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hobbies Interests',
                name: 'HobbiesInterests',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('HobbiesInterests') && this.donorwhoareyou.HobbiesInterests !== null ? this.donorwhoareyou.HobbiesInterests.toString() : '',
            }),
            KnownGeneticConditions: new DynamicField({
                formGroup: this.formGroup,
                label: 'Known Genetic Conditions',
                name: 'KnownGeneticConditions',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('KnownGeneticConditions') && this.donorwhoareyou.KnownGeneticConditions !== null ? this.donorwhoareyou.KnownGeneticConditions : false,
            }),
            MoodSwings: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mood Swings',
                name: 'MoodSwings',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('MoodSwings') && this.donorwhoareyou.MoodSwings !== null ? this.donorwhoareyou.MoodSwings : false,
            }),
            NaturalHairColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Natural Hair Color',
                name: 'NaturalHairColorId',
                options: this.naturalHairColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.NaturalHairColorId || null,
            }),
            NumberOfChildren: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Children',
                name: 'NumberOfChildren',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.NumberOfChildren || null,
            }),
            OtherAncestryFather: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Ancestry Father',
                name: 'OtherAncestryFather',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('OtherAncestryFather') && this.donorwhoareyou.OtherAncestryFather !== null ? this.donorwhoareyou.OtherAncestryFather.toString() : '',
            }),
            OtherAncestryMother: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Ancestry Mother',
                name: 'OtherAncestryMother',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('OtherAncestryMother') && this.donorwhoareyou.OtherAncestryMother !== null ? this.donorwhoareyou.OtherAncestryMother.toString() : '',
            }),
            OtherTraumaticEvent: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Traumatic Event',
                name: 'OtherTraumaticEvent',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('OtherTraumaticEvent') && this.donorwhoareyou.OtherTraumaticEvent !== null ? this.donorwhoareyou.OtherTraumaticEvent.toString() : '',
            }),
            Paranoia: new DynamicField({
                formGroup: this.formGroup,
                label: 'Paranoia',
                name: 'Paranoia',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('Paranoia') && this.donorwhoareyou.Paranoia !== null ? this.donorwhoareyou.Paranoia : false,
            }),
            PlasticSurgeryTypeAndYear: new DynamicField({
                formGroup: this.formGroup,
                label: 'Plastic Surgery Type And Year',
                name: 'PlasticSurgeryTypeAndYear',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('PlasticSurgeryTypeAndYear') && this.donorwhoareyou.PlasticSurgeryTypeAndYear !== null ? this.donorwhoareyou.PlasticSurgeryTypeAndYear.toString() : '',
            }),
            RaceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Race',
                name: 'RaceId',
                options: this.races,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.RaceId || null,
            }),
            RhFactorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rh Factor',
                name: 'RhFactorId',
                options: this.rhFactors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.RhFactorId || null,
            }),
            SeriousDepression: new DynamicField({
                formGroup: this.formGroup,
                label: 'Serious Depression',
                name: 'SeriousDepression',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('SeriousDepression') && this.donorwhoareyou.SeriousDepression !== null ? this.donorwhoareyou.SeriousDepression : false,
            }),
            SkinTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Skin Type',
                name: 'SkinTypeId',
                options: this.skinTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.SkinTypeId || null,
            }),
            SpeaksOtherLanguages: new DynamicField({
                formGroup: this.formGroup,
                label: 'Speaks Other Languages',
                name: 'SpeaksOtherLanguages',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('SpeaksOtherLanguages') && this.donorwhoareyou.SpeaksOtherLanguages !== null ? this.donorwhoareyou.SpeaksOtherLanguages.toString() : '',
            }),
            TalentsAbilities: new DynamicField({
                formGroup: this.formGroup,
                label: 'Talents Abilities',
                name: 'TalentsAbilities',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3500) ],
                validators: { 'maxlength': 3500 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('TalentsAbilities') && this.donorwhoareyou.TalentsAbilities !== null ? this.donorwhoareyou.TalentsAbilities.toString() : '',
            }),
            TraumaticEventId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Traumatic Event',
                name: 'TraumaticEventId',
                options: this.traumaticEvents,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.TraumaticEventId || null,
            }),
            TreatedForDrugAlcoholAbuse: new DynamicField({
                formGroup: this.formGroup,
                label: 'Treated For Drug Alcohol Abuse',
                name: 'TreatedForDrugAlcoholAbuse',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('TreatedForDrugAlcoholAbuse') && this.donorwhoareyou.TreatedForDrugAlcoholAbuse !== null ? this.donorwhoareyou.TreatedForDrugAlcoholAbuse : false,
            }),
            UnderPsychiatricCare: new DynamicField({
                formGroup: this.formGroup,
                label: 'Under Psychiatric Care',
                name: 'UnderPsychiatricCare',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('UnderPsychiatricCare') && this.donorwhoareyou.UnderPsychiatricCare !== null ? this.donorwhoareyou.UnderPsychiatricCare : false,
            }),
            WhyInterestedInBecomingSpermDonor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Why Interested In Becoming Sperm Donor',
                name: 'WhyInterestedInBecomingSpermDonor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3000) ],
                validators: { 'maxlength': 3000 },
                value: this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('WhyInterestedInBecomingSpermDonor') && this.donorwhoareyou.WhyInterestedInBecomingSpermDonor !== null ? this.donorwhoareyou.WhyInterestedInBecomingSpermDonor.toString() : '',
            }),
        };

        this.View = {
            Anxiety: new DynamicLabel(
                'Anxiety',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('Anxiety') && this.donorwhoareyou.Anxiety !== null ? this.donorwhoareyou.Anxiety : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            BleedingDisorders: new DynamicLabel(
                'Bleeding Disorders',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('BleedingDisorders') && this.donorwhoareyou.BleedingDisorders !== null ? this.donorwhoareyou.BleedingDisorders : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            BloodTypeId: new DynamicLabel(
                'Blood Type',
                getMetaItemValue(this.bloodTypes, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('BloodTypeId') && this.donorwhoareyou.BloodTypeId !== null ? this.donorwhoareyou.BloodTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            BodyStructureId: new DynamicLabel(
                'Body Structure',
                getMetaItemValue(this.bodyStructures, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('BodyStructureId') && this.donorwhoareyou.BodyStructureId !== null ? this.donorwhoareyou.BodyStructureId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CharacterDescription: new DynamicLabel(
                'Character Description',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('CharacterDescription') && this.donorwhoareyou.CharacterDescription !== null ? this.donorwhoareyou.CharacterDescription.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CollegeGpaId: new DynamicLabel(
                'College Gpa',
                getMetaItemValue(this.collegeGpas, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('CollegeGpaId') && this.donorwhoareyou.CollegeGpaId !== null ? this.donorwhoareyou.CollegeGpaId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            CollegeMajor: new DynamicLabel(
                'College Major',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('CollegeMajor') && this.donorwhoareyou.CollegeMajor !== null ? this.donorwhoareyou.CollegeMajor.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Completed: new DynamicLabel(
                'Completed',
                this.donorwhoareyou && this.donorwhoareyou.Completed || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            CurrentOccupation: new DynamicLabel(
                'Current Occupation',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('CurrentOccupation') && this.donorwhoareyou.CurrentOccupation !== null ? this.donorwhoareyou.CurrentOccupation.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.donorwhoareyou && this.donorwhoareyou.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.donorwhoareyou && this.donorwhoareyou.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EducationCompletedTypeId: new DynamicLabel(
                'Education Completed Type',
                getMetaItemValue(this.educationCompletedTypes, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('EducationCompletedTypeId') && this.donorwhoareyou.EducationCompletedTypeId !== null ? this.donorwhoareyou.EducationCompletedTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ExerciseRoutine: new DynamicLabel(
                'Exercise Routine',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('ExerciseRoutine') && this.donorwhoareyou.ExerciseRoutine !== null ? this.donorwhoareyou.ExerciseRoutine.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ExperiencedTraumaticEvent: new DynamicLabel(
                'Experienced Traumatic Event',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('ExperiencedTraumaticEvent') && this.donorwhoareyou.ExperiencedTraumaticEvent !== null ? this.donorwhoareyou.ExperiencedTraumaticEvent : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ExtracurricularActivities: new DynamicLabel(
                'Extracurricular Activities',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('ExtracurricularActivities') && this.donorwhoareyou.ExtracurricularActivities !== null ? this.donorwhoareyou.ExtracurricularActivities.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            EyeColorId: new DynamicLabel(
                'Eye Color',
                getMetaItemValue(this.eyeColors, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('EyeColorId') && this.donorwhoareyou.EyeColorId !== null ? this.donorwhoareyou.EyeColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Favorites: new DynamicLabel(
                'Favorites',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('Favorites') && this.donorwhoareyou.Favorites !== null ? this.donorwhoareyou.Favorites.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            GeneticConditions: new DynamicLabel(
                'Genetic Conditions',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('GeneticConditions') && this.donorwhoareyou.GeneticConditions !== null ? this.donorwhoareyou.GeneticConditions.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Goals: new DynamicLabel(
                'Goals',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('Goals') && this.donorwhoareyou.Goals !== null ? this.donorwhoareyou.Goals.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            HairTextureId: new DynamicLabel(
                'Hair Texture',
                getMetaItemValue(this.hairTextures, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('HairTextureId') && this.donorwhoareyou.HairTextureId !== null ? this.donorwhoareyou.HairTextureId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasPlasticSurgery: new DynamicLabel(
                'Has Plastic Surgery',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('HasPlasticSurgery') && this.donorwhoareyou.HasPlasticSurgery !== null ? this.donorwhoareyou.HasPlasticSurgery : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HaveChildren: new DynamicLabel(
                'Have Children',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('HaveChildren') && this.donorwhoareyou.HaveChildren !== null ? this.donorwhoareyou.HaveChildren : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HighSchoolGpaId: new DynamicLabel(
                'High School Gpa',
                getMetaItemValue(this.highSchoolGpas, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('HighSchoolGpaId') && this.donorwhoareyou.HighSchoolGpaId !== null ? this.donorwhoareyou.HighSchoolGpaId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HobbiesInterests: new DynamicLabel(
                'Hobbies Interests',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('HobbiesInterests') && this.donorwhoareyou.HobbiesInterests !== null ? this.donorwhoareyou.HobbiesInterests.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            KnownGeneticConditions: new DynamicLabel(
                'Known Genetic Conditions',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('KnownGeneticConditions') && this.donorwhoareyou.KnownGeneticConditions !== null ? this.donorwhoareyou.KnownGeneticConditions : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            MoodSwings: new DynamicLabel(
                'Mood Swings',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('MoodSwings') && this.donorwhoareyou.MoodSwings !== null ? this.donorwhoareyou.MoodSwings : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            NaturalHairColorId: new DynamicLabel(
                'Natural Hair Color',
                getMetaItemValue(this.naturalHairColors, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('NaturalHairColorId') && this.donorwhoareyou.NaturalHairColorId !== null ? this.donorwhoareyou.NaturalHairColorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            NumberOfChildren: new DynamicLabel(
                'Number Of Children',
                this.donorwhoareyou && this.donorwhoareyou.NumberOfChildren || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            OtherAncestryFather: new DynamicLabel(
                'Other Ancestry Father',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('OtherAncestryFather') && this.donorwhoareyou.OtherAncestryFather !== null ? this.donorwhoareyou.OtherAncestryFather.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherAncestryMother: new DynamicLabel(
                'Other Ancestry Mother',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('OtherAncestryMother') && this.donorwhoareyou.OtherAncestryMother !== null ? this.donorwhoareyou.OtherAncestryMother.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherTraumaticEvent: new DynamicLabel(
                'Other Traumatic Event',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('OtherTraumaticEvent') && this.donorwhoareyou.OtherTraumaticEvent !== null ? this.donorwhoareyou.OtherTraumaticEvent.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Paranoia: new DynamicLabel(
                'Paranoia',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('Paranoia') && this.donorwhoareyou.Paranoia !== null ? this.donorwhoareyou.Paranoia : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            PlasticSurgeryTypeAndYear: new DynamicLabel(
                'Plastic Surgery Type And Year',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('PlasticSurgeryTypeAndYear') && this.donorwhoareyou.PlasticSurgeryTypeAndYear !== null ? this.donorwhoareyou.PlasticSurgeryTypeAndYear.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            RaceId: new DynamicLabel(
                'Race',
                getMetaItemValue(this.races, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('RaceId') && this.donorwhoareyou.RaceId !== null ? this.donorwhoareyou.RaceId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            RhFactorId: new DynamicLabel(
                'Rh Factor',
                getMetaItemValue(this.rhFactors, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('RhFactorId') && this.donorwhoareyou.RhFactorId !== null ? this.donorwhoareyou.RhFactorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SeriousDepression: new DynamicLabel(
                'Serious Depression',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('SeriousDepression') && this.donorwhoareyou.SeriousDepression !== null ? this.donorwhoareyou.SeriousDepression : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            SkinTypeId: new DynamicLabel(
                'Skin Type',
                getMetaItemValue(this.skinTypes, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('SkinTypeId') && this.donorwhoareyou.SkinTypeId !== null ? this.donorwhoareyou.SkinTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SpeaksOtherLanguages: new DynamicLabel(
                'Speaks Other Languages',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('SpeaksOtherLanguages') && this.donorwhoareyou.SpeaksOtherLanguages !== null ? this.donorwhoareyou.SpeaksOtherLanguages.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TalentsAbilities: new DynamicLabel(
                'Talents Abilities',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('TalentsAbilities') && this.donorwhoareyou.TalentsAbilities !== null ? this.donorwhoareyou.TalentsAbilities.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TraumaticEventId: new DynamicLabel(
                'Traumatic Event',
                getMetaItemValue(this.traumaticEvents, this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('TraumaticEventId') && this.donorwhoareyou.TraumaticEventId !== null ? this.donorwhoareyou.TraumaticEventId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TreatedForDrugAlcoholAbuse: new DynamicLabel(
                'Treated For Drug Alcohol Abuse',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('TreatedForDrugAlcoholAbuse') && this.donorwhoareyou.TreatedForDrugAlcoholAbuse !== null ? this.donorwhoareyou.TreatedForDrugAlcoholAbuse : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            UnderPsychiatricCare: new DynamicLabel(
                'Under Psychiatric Care',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('UnderPsychiatricCare') && this.donorwhoareyou.UnderPsychiatricCare !== null ? this.donorwhoareyou.UnderPsychiatricCare : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            WhyInterestedInBecomingSpermDonor: new DynamicLabel(
                'Why Interested In Becoming Sperm Donor',
                this.donorwhoareyou && this.donorwhoareyou.hasOwnProperty('WhyInterestedInBecomingSpermDonor') && this.donorwhoareyou.WhyInterestedInBecomingSpermDonor !== null ? this.donorwhoareyou.WhyInterestedInBecomingSpermDonor.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
