import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IEyeColor } from '@model/interfaces/eye-color';

@Injectable({
    providedIn: 'root',
})
export class EyeColorService extends StaticMetaItemService<IEyeColor> {
    constructor(public http: HttpClient) {
        super('EyeColorService', 'Color', 'ColorIds', '/meta-items/EyeColor', http);
    }
}
