import { IClinic } from '@model/interfaces/clinic';
import { IMetaItem } from '@mt-ng2/base-service';

export function UsersToMetaItems(users: any[]): IMetaItem[] {
    return users.map((u: any) => {
        return <IMetaItem>{
            Id: u.Id,
            Name: u.FirstName + ' ' + u.LastName,
        };
    });
}

export function ClinicsToMetaItem(clinics: IClinic[]): IMetaItem[] {
    return clinics.map((c: any) => {
        return <IMetaItem>{
            Id: c.Id,
            Name: c.Name,
        };
    });
}
