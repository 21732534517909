import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '@angular-common/services/common.service';
import { ICountry } from '@model/interfaces/country';
import { DonorService } from '../services/donor.service';

@Component({
    selector: 'app-potential-families',
    styles: [
        `
            .col-centered {
                height: 34px;
            }
            .potential-families-label {
                margin-top: 6px;
            }
        `,
    ],
    templateUrl: './donor-potential-families.component.html',
})
export class PotentialFamiliesComponent implements OnInit {
    @Input() donorId: number;
    countries: ICountry[];
    potentialFamilies: number;

    selectedCountry: any = '';

    selectedCountryCode = '';

    get isUnitedStatesSelected(): boolean {
        return this.selectedCountryCode === 'US';
    }

    allowedCountryCodes = ['US', 'GB', 'AU'];
    constructor(private commonService: CommonService, private donorService: DonorService) {}

    ngOnInit(): void {
        this.commonService.getCountries().subscribe((countries) => {
            this.countries = countries.filter((x) => this.allowedCountryCodes.some((y) => y === x.CountryCode));
            this.getPotentialFamilies();
        });
    }

    countrySelected(country: ICountry): void {
        if (country?.CountryCode) {
            this.selectedCountryCode = country.CountryCode;
        } else {
            this.selectedCountryCode = '';
        }
        this.getPotentialFamilies();
    }

    getPotentialFamilies(): void {
        this.donorService
            .getPotentialFamilies(this.donorId, this.selectedCountryCode)
            .subscribe((potentialFamiliesNumber) => {
                this.potentialFamilies = potentialFamiliesNumber;
            });
    }
}
