<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Shipping Info
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
        <span class="pull-right mr-4" *ngIf="salesOrder.Donors?.length">
            <button
                class="btn btn-primary header-button"
                title="SHSBDF6 Summary of Records - Shipping"
                (click)="downloadSHSBDF6(); $event.stopPropagation()"
            >
                SOR (US - External)
            </button>
            <button
                class="btn btn-primary header-button"
                title="SHSBDF8 Summary of Records - Shipping (Canada)"
                (click)="downloadSHSBDF8(); $event.stopPropagation()"
            >
                SOR (Canada)
            </button>
            <button
                class="btn btn-primary header-button"
                title="SHSBDF9 Summary of Records - Shipping (Australia)"
                (click)="downloadSHSBDF9(); $event.stopPropagation()"
            >
                SOR (International)
            </button>
        </span>
    </h4>
    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Shipping Info</h4>
    <app-dynamic-form
        [config]="config.formObject"
        ([isEditing])="(isEditing)"
        (submitted)="formSubmitted($event)"
        (formCreated)="onFormCreated($event)"
    >
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
    </app-dynamic-form>
</div>
