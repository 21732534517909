<div class="miles-editable miles-card padded" *ngIf="!isEditing && recipientUser.Id > 0" (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false" (click)="edit()" [style.cursor]="canEdit ? 'pointer' : 'default'">
    <h4>
        Recipient Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div *ngIf="formCreated">
        <div class="display-padded">
          <span><b>Recipient Id: </b></span>
          <span>{{ recipientUser.Id }}</span>
        </div>
        <dynamic-label [field]="abstractRecipientUserControls.FirstName"></dynamic-label>
        <dynamic-label [field]="abstractRecipientControls.MiddleInitial"></dynamic-label>
        <dynamic-label [field]="abstractRecipientUserControls.LastName"></dynamic-label>
        <dynamic-label [field]="abstractRecipientUserControls.Email"></dynamic-label>
        <dynamic-label [field]="abstractRecipientControls.MayWeContactYou"></dynamic-label>
        <dynamic-label [field]="abstractRecipientControls.Phone"></dynamic-label>
        <div class="display-padded">
          <span><b>Preferred Contact Type: </b></span>
          <span>{{ recipientUser.Recipient?.ContactType?.Name }}</span>
        </div>
        <div class="display-padded">
          <span><b>Status: </b></span>
          <span>{{ recipientUser.Recipient?.RecipientStatus?.Name }}</span>
        </div>
        <dynamic-label [field]="abstractRecipientControls.Dob"></dynamic-label>
        <dynamic-label [field]="abstractRecipientControls.PartnerName"></dynamic-label>
        <dynamic-label [field]="abstractRecipientControls.PartnerEmail"></dynamic-label>
        <dynamic-label [field]="abstractRecipientControls.PartnerDob"></dynamic-label>
        <div class="display-padded">
          <span><b>Clinic: </b></span>
          <span>{{ recipientUser.Recipient?.Clinic?.Name }}</span>
        </div>
        <div class="display-padded">
          <span><b>Referral Type: </b></span>
          <span>{{ recipientUser.Recipient?.ReferralType?.Name }}</span>
        </div>
        <dynamic-label [field]="abstractRecipientControls.Physician"></dynamic-label>
        <div class="display-padded">
          <span><b>Account Manager: </b></span>
          <span>{{ recipientUser.Recipient?.User?.FirstName }} {{ recipientUser.Recipient?.User?.LastName }}</span>
        </div>
        <div class="display-padded">
          <span><b>Registration Date: </b></span>
          <span>{{ recipientUser.DateCreated | date }}</span>
        </div>
    </div>
</div>
<div *ngIf="isEditing && formCreated" class="miles-form padded">
    <h4>Recipient Info</h4>
    <form [formGroup]="recipientUserForm" (ngSubmit)="formSubmitted()">
        <dynamic-field [field]="abstractRecipientUserControls.FirstName" [form]="recipientUserForm"></dynamic-field>
        <dynamic-field [field]="abstractRecipientControls.MiddleInitial" [form]="recipientUserForm"></dynamic-field>
        <dynamic-field [field]="abstractRecipientUserControls.LastName" [form]="recipientUserForm"></dynamic-field>
        <dynamic-field [field]="abstractRecipientUserControls.Email" [form]="recipientUserForm"></dynamic-field>
        <dynamic-field [field]="abstractRecipientControls.MayWeContactYou" [form]="recipientUserForm"></dynamic-field>
        <dynamic-field [field]="abstractRecipientControls.Phone" [form]="recipientUserForm"></dynamic-field>
        <dynamic-field [field]="abstractRecipientControls.PreferredContactTypeId" [form]="recipientUserForm"></dynamic-field>
        <div *ngIf="!isNewRecipientUser">
            <dynamic-field [field]="abstractRecipientControls.StatusId" [form]="recipientUserForm"></dynamic-field>
            <dynamic-field [field]="abstractRecipientControls.Dob" [form]="recipientUserForm"></dynamic-field>
            <dynamic-field [field]="abstractRecipientControls.PartnerName" [form]="recipientUserForm"></dynamic-field>
            <dynamic-field [field]="abstractRecipientControls.PartnerEmail" [form]="recipientUserForm"></dynamic-field>
            <dynamic-field [field]="abstractRecipientControls.PartnerDob" [form]="recipientUserForm"></dynamic-field>
            <dynamic-field [field]="abstractRecipientControls.ClinicId" [form]="recipientUserForm"></dynamic-field>
            <dynamic-field [field]="abstractRecipientControls.ReferralTypeId" [form]="recipientUserForm"></dynamic-field>
            <dynamic-field [field]="abstractRecipientControls.Physician" [form]="recipientUserForm"></dynamic-field>
            <dynamic-field [field]="abstractRecipientControls.AccountManagerId" [form]="recipientUserForm"></dynamic-field>
        </div>
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled"
            Class="btn btn-flat btn-success">
            Save
        </button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
            Cancel
        </button>
    </form>
</div>
