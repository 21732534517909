import { Component, Input, Output,  OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IAddress } from '@model/interfaces/address';
import { STATUS_CODES } from 'http';


@Component({
    selector: `address-container`,
    templateUrl: './address-container.component.html',
})
export class AddressContainerComponent implements OnInit {
    @Input() address: IAddress;
    @Input() addressType: string;
    @Input() canEdit: boolean;
    @Input() showDelete: boolean;
    @Output() onSave: EventEmitter<IAddress> = new EventEmitter<IAddress>();
    @Output() onDelete: EventEmitter<IAddress> = new EventEmitter<IAddress>();

    formattedAddress = "";
    addressFormGroup: FormGroup;
    isEditing = false;
    constructor(
        private fb: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.addressFormGroup = this.fb.group({});
    } 

    cancel(): void {
        this.isEditing = false;
    }

    edit(): void {
        this.isEditing = true;
    }

    save(address: IAddress): void {
        this.onSave.emit(address);
        this.cancel();
    }

    delete(address: IAddress): void {
        this.onDelete.emit(address);
    }

    newFormattedAddress(addressText : string) {
        this.formattedAddress = addressText;
    }

    createAddress() : void {
        const emptyAddress = {
            Id : null,
            Address1: '',
            Address2: '',
            City : '',
            StateCode: '',
            Province : '',
            Zip: '',
            CountryCode: ''
        };

        this.address = emptyAddress;
        this.isEditing = true;
    }

    noAddress() : boolean {
        return (this.address === undefined || this.address === null);
    }
}
