import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { DonorService } from '../services/donor.service';
import { DonorStatusService } from '../services/donor-status.service';
import { CmvStatusService } from '../services/cmv-status.service';
import { ReviewOutcomeService } from '../services/review-outcome.service';
import { DonorDynamicConfig } from '../donor.dynamic-config';

import { IDonor } from '@model/interfaces/donor';
import { IDonorStatus } from '@model/interfaces/donor-status';
import { IReviewOutcome as IReviewOutcome } from '@model/interfaces/review-outcome';
import { ICmvStatus } from '@model/interfaces/cmv-status';
import { forkJoin, Subscription } from 'rxjs';
import { IExpandableObject } from '@model/expandable-object';
import { DonorUserDynamicControls } from '@model/form-controls/donor-user.form-controls';
import { IModalOptions } from '@mt-ng2/modal-module';
import { DonorStatuses } from '@model/enums/donor-statuses.enum';
import { DonorDenialTypes } from '@model/enums/donor-denial-types.enum';

@Component({
    selector: 'app-donor-basic-info',
    templateUrl: './donor-basic-info.component.html',
})
export class DonorBasicInfoComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: DonorDynamicConfig<IDonor>;
    doubleClickIsDisabled = false;

    showDenyDonorModal = false;
    manualDenialReason = '';
    denyDonorModalOptions: IModalOptions = {
        showCloseButton: true,
        showConfirmButton: false,
    };

    undenyDonorModalOptions: IModalOptions = {
        allowOutsideClick: true,
        confirmButtonText: 'Confirm',
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: true,
    };

    donorStatuses: IDonorStatus[];
    cmvStatuses: ICmvStatus[];
    medicalReviewOutcomes: IReviewOutcome[];

    formCreated = false;
    donorUserForm: FormGroup;
    donorUserControls: IExpandableObject;
    subs = new Subscription();

    get donorIsDenied(): boolean {
        return this.donor.DonorStatusId === DonorStatuses.Denied;
    }

    get donorIsManuallyDenied(): boolean {
        return this.donor.DenialReasonId === DonorDenialTypes.Other;
    }

    constructor(
        private donorService: DonorService,
        private donorStatusService: DonorStatusService,
        private notificationsService: NotificationsService,
        private cmvStatusService: CmvStatusService,
        private reviewOutcomeService: ReviewOutcomeService,
        private fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        forkJoin([this.donorStatusService.getItems(), this.cmvStatusService.getItems(), this.reviewOutcomeService.getItems()]).subscribe(
            ([donorStatuses, cmvStatuses, medicalReviewOutcomes]) => {
                this.donorStatuses = donorStatuses;
                this.cmvStatuses = cmvStatuses;
                this.medicalReviewOutcomes = medicalReviewOutcomes;
                this.setConfig();
            },
        );
        this.subs.add(
            this.donorService.changeEmitted$.subscribe(() => {
                this.donorService.getById(this.donor.Id).subscribe((donor) => {
                    this.donor = donor;
                    this.setConfig();
                });
            }),
        );
    }

    setConfig(): void {
        this.formFactory = new DonorDynamicConfig<IDonor>(this.donor, {
            cmvStatuses: this.cmvStatuses,
            donorStatuses: this.donorStatuses,
            medicalReviews: this.medicalReviewOutcomes,
        });
        this.config = this.formFactory.getForUpdate();
        this.createControls();
    }

    createControls(): void {
        this.donorUserForm = this.fb.group({
            DonorUser: this.fb.group({}),
        });
        this.donorUserControls = new DonorUserDynamicControls(this.donor.DonorUser).Form;
        this.formCreated = true;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid && this.donorUserForm.valid) {
            this.formFactory.assignFormValues(this.donor, form.value.Donor);
            this.donor.DonorUser = Object.assign({}, this.donor.DonorUser, this.donorUserForm.value.DonorUser);
            this.saveDonor();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveDonor(): void {
        this.donorService
            .update(this.donor)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((success) => {
                this.saveDonorUser(success);
            });
    }

    private saveDonorUser(updatedDonor: IDonor): void {
        this.donorService.updateDonorUser(this.donor.DonorUser, this.donor.Id).subscribe(() => this.success(updatedDonor));
    }

    private success(updatedDonor: IDonor): void {
        // To capture updates to MedicalReivewModifiedBy and MedicalReivewModifiedDate
        this.donor = Object.assign({}, updatedDonor, { DonorUser: this.donor.DonorUser });
        this.setConfig();
        this.isEditing = false;
        this.donorService.emitChange(this.donor);
        this.notificationsService.success('Donor saved successfully.');
    }

    denyDonor(): void {
        const deniedDonor = Object.assign({}, this.donor,
            { DonorStatusId: DonorStatuses.Denied, DenialReasonId: DonorDenialTypes.Other, ManualDenialReason: this.manualDenialReason });
        this.donorService.update(deniedDonor).subscribe(() => {
            this.showDenyDonorModal = false;
            this.notificationsService.success('Donor denied successfully.');
            this.donorService.emitChange(this.donor);
            this.isEditing = false;
        });
    }

    undenyDonor(): void {
        this.donorService.undenyDonor(this.donor.Id).subscribe(() => {
            this.notificationsService.success('Donor un-denied successfully.');
            this.donorService.emitChange(this.donor);
            this.isEditing = false;
        });
    }
}
