import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IQcProblem } from '@model/interfaces/qc-problem';

@Injectable({
    providedIn: 'root',
})
export class QcProblemService extends StaticMetaItemService<IQcProblem> {
    constructor(public http: HttpClient) {
        super('QcProblemService', 'Problem', 'ProblemIds', '/meta-items/QcProblem', http);
    }
}
