import { DatePipe } from '@angular/common';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class DonorTestSamplesEntityListConfig extends EntityListConfig {
    constructor() {
        const datePipe = new DatePipe('en-US');

        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['SampleDate'],
                    name: 'Sample Date',
                    pipes: ['date'],
                }),
                new EntityListColumn({
                    accessors: ['TestSampleType', 'Name'],
                    name: 'Sample Type',
                }),
                new EntityListColumn({
                    accessors: ['SampleStatus', 'Name'],
                    name: 'Sample Status',
                }),
                new EntityListColumn({
                    accessorFunction: (testSample: IDonorSample): string => {
                        return testSample.SampleStatusModifiedBy
                            ? testSample.SampleStatusModifiedBy.FirstName +
                                  ' ' +
                                  testSample.SampleStatusModifiedBy.LastName +
                                  ' - ' +
                                  datePipe.transform(testSample.SampleStatusModifiedDate, 'short')
                            : '';
                    },
                    name: 'Status Modified',
                    sort: {
                        sortProperty: 'SampleStatusModifiedDate',
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
