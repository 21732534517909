import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IReferralType } from '@model/interfaces/referral-type';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReferralTypeService extends MetaItemService<IReferralType> {
    constructor(public http: HttpClient) {
        super('ReferralTypeService', 'Type', 'TypeIds', '/referral-types', http);
    }

    updateItems(items: IReferralType[]): Observable<Object> {
        items.map((i) => {
            i.Active = i.Active ? true : false;
            i.HasComments = i.HasComments ? true : false;
        });
        return super.updateItems(items);
    }
}
