import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorReview } from '../interfaces/donor-review';
import { IReviewType } from '../interfaces/review-type';
import { IReviewOutcome } from '../interfaces/review-outcome';
import { IUser } from '../interfaces/user';

export interface IDonorReviewDynamicControlsParameters {
    donorReview?: IDonorReview;
    formGroup?: string;
    users?: IUser[];
    reviewTypes?: IReviewType[];
    outcomes?: IReviewOutcome[];
}

export class DonorReviewDynamicControls {

    formGroup: string;
    users: IUser[];
    reviewTypes: IReviewType[];
    outcomes: IReviewOutcome[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorReview?: IDonorReview, additionalParameters?: IDonorReviewDynamicControlsParameters) {
        this.donorReview = donorReview;
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorReview';
        this.users = additionalParameters && additionalParameters.users || undefined;
        this.reviewTypes = additionalParameters && additionalParameters.reviewTypes || undefined;
        this.outcomes = additionalParameters && additionalParameters.outcomes || undefined;

        this.Form = {
            ReviewTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Type',
                name: 'ReviewTypeId',
                options: this.reviewTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorReview && this.donorReview.ReviewTypeId || null,
            }),
            ReviewType: new DynamicField({
                formGroup: this.formGroup,
                label: 'Type',
                name: 'ReviewType',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.donorReview && this.donorReview.hasOwnProperty('ReviewType') && this.donorReview.ReviewType !== null ? this.donorReview.ReviewType.Name.toString() : '',
            }),
            ReviewStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: this.donorReview.ReviewType.Name,
                name: 'ReviewStatusId',
                options: this.outcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.donorReview && this.donorReview.hasOwnProperty('ReviewStatusId') && this.donorReview.ReviewStatusId || null,
            }),
            ReviewModifiedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified Date',
                name: 'ReviewModifiedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorReview && this.donorReview.hasOwnProperty('ReviewModifiedDate') && this.donorReview.ReviewModifiedDate || null,
            }),
            ReviewModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ReviewModifiedById',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.donorReview && this.donorReview.hasOwnProperty('ReviewModifiedById') && this.donorReview.ReviewModifiedById || null,
            }),
        };

        this.View = {
            ReviewTypeId: new DynamicLabel(
                'Type',
                getMetaItemValue(this.reviewTypes, this.donorReview && this.donorReview.hasOwnProperty('ReviewTypeId') && this.donorReview.ReviewTypeId !== null ? this.donorReview.ReviewTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReviewType: new DynamicLabel(
                'Type',
                this.donorReview && this.donorReview.hasOwnProperty('ReviewType') && this.donorReview.ReviewType !== null ? this.donorReview.ReviewType.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textbox,
                    scale: null,
                }),
            ),
            ReviewStatusId: new DynamicLabel(
                'Status',
                getMetaItemValue(this.outcomes, this.donorReview && this.donorReview.hasOwnProperty('ReviewStatusId') && this.donorReview.ReviewStatusId !== null ? this.donorReview.ReviewStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReviewModifiedDate: new DynamicLabel(
                'Modified',
                this.donorReview && this.donorReview.ReviewModifiedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ReviewModifiedById: new DynamicLabel(
                'Modified By',
                getMetaItemValue(this.users, this.donorReview && this.donorReview.hasOwnProperty('ReviewModifiedById') && this.donorReview.ReviewModifiedById !== null ? this.donorReview.ReviewModifiedById : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };
    }
}
