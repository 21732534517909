<div class="miles-editable miles-card padded">
    <div *ngIf="!isEditing">
        <h4>
            Donor Test Sample
        <span class="pull-right" *ngIf="canEdit" (click)="edit()">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <app-dynamic-view *ngIf="config" [config]="config.viewOnly"></app-dynamic-view>
    </div>
    <div *ngIf="isEditing">
        <div>
            <app-dynamic-form *ngIf="config" [config]="config.formObject" (submitted)="formSubmitted($event)">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                    Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="close()">
                    Cancel
                </button>
            </app-dynamic-form>
        </div>
    </div>
</div>
