// This resolves a date issue with DateInput where time can offset dates from what's intended
// Works for both string dates and date dates
export const TreatDateAsLocal: (date: any) => Date = (date: any) => {
    return typeof date === 'string' ? ((date as any as string).replace('Z', '') as any) : new Date(date.toISOString().replace('Z', ''));
};

const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T00:00:00(\.0{1,3})?Z$/;

// Takes an object and mutates properties that fit the date regex
export function LocalizeDatesByValue<T extends Object>(t: T): T {
    if (!t) { return null; }
    for (let [key, value] of Object.entries(t).filter(([, value]) => value)) {
        if (value instanceof Object) {
            LocalizeDatesByValue(t[key]);
        } else if (value.toString().match(dateRegex)) {
            t[key] = TreatDateAsLocal(value);
        }
    }
    return t;
}

// Takes an object and mutates specified properties to localize dates
export function LocalizeDatesByName<T extends object>(entity: T, properties: string[]): T {
    if (!entity) { return null; }
    for (const prop of properties) {
        if (prop.includes('.')) {
            const subProps = prop.split('.');
            localizeInNestedProperties(entity, subProps);
            continue;
        }
        if (entity.hasOwnProperty(prop) && entity[prop]) {
            entity[prop] = TreatDateAsLocal(entity[prop]);
        }
    }
    return entity;
}

function localizeInNestedProperties<T extends object>(entity: T, subProps: string[]): void {
    if (subProps.length === 1) {
        localizeSubProperty(entity, subProps[0]);
        return;
    }
    if (entity.hasOwnProperty(subProps[0]) && entity[subProps[0]]) {
        if (Array.isArray(entity[subProps[0]])) {
            localizeInManyNestedProperties(entity[subProps[0]], subProps.slice(1));
        } else {
            localizeInNestedProperties(entity[subProps[0]], subProps.slice(1));
        }
    }
}

function localizeInManyNestedProperties<T extends object>(entities: T[], subProps: string[]): void {
    for (const entity of entities) {
        localizeInNestedProperties(entity, subProps);
    }
}

function localizeSubProperty<T extends object>(entity: T | T[], propName: string): void {
    if (entity.hasOwnProperty(propName) && entity[propName]) {
        entity[propName] = TreatDateAsLocal(entity[propName]);
    }
}
