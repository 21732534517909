import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocalizedDateBaseService } from '@angular-common/services/localized-date-base.service';
import { IReservation } from '@model/interfaces/reservation';
import { IVwDonorInventory } from '@model/interfaces/vw-donor-inventory';
import { Observable } from 'rxjs';
import { IReservationDto } from '@model/interfaces/custom/reservation-dto';
import { IReservationUpdateDto } from '@model/interfaces/custom/reservation-update-dto';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { map } from 'rxjs/operators';
import { LocalizeDatesByName } from '@angular-common/libraries/treat-date-as-local.library';

export const emptyReservation: IReservation = {
    Canceled: false,
    CreatedById: 0,
    CreatedDate: null,
    Id: 0,
    RecipientId: 0,
    SalesOrderCreated: false,
};

export const datesToLocalize = [
    'CreatedDate',
    'ExpirationDate',
];

@Injectable({
    providedIn: 'root',
})
export class ReservationService extends LocalizedDateBaseService<IReservation> {
    constructor(public http: HttpClient) {
        super('/reservations', http, datesToLocalize);
    }

    createSalesOrder(ReservationId: number): Observable<any> {
        return this.http.post(`/sales-orders/create/${ReservationId}`, null);
    }

    cancelReservation(ReservationId: number): Observable<any> {
        return this.http.delete(`/reservations/${ReservationId}/cancel`);
    }

    getReservations(searchparams: IEntitySearchParams): Observable<HttpResponse<IReservationDto[]>> {
        return (super.get(searchparams) as any as Observable<HttpResponse<IReservationDto[]>>).pipe(
            map((resp) => {
                resp.body.map((i) => {
                    LocalizeDatesByName(i, ['CreatedDate', 'ExpirationDate', 'Donors.MostRecentValidSampleDate']);
                });
                return resp;
            }),
        );
    }

    getReservationInventory(reservationId: number): Observable<IVwDonorInventory[]> {
        return this.http.get<IVwDonorInventory[]>(`/reservations/${reservationId}/inventory`);
    }

    updateReservation(reservationUpdatePayload: IReservationUpdateDto): Observable<IReservationDto> {
        return this.http.post<IReservationDto>('/reservations/update-reservation', reservationUpdatePayload);
    }

    setDonors(reservationId: number, donorIds: number[]): Observable<any> {
        return this.http.post(`/reservations/${reservationId}/donors`, donorIds);
    }

    setInventories(reservationId: number, inventorySelected: IVwDonorInventory[]): Observable<any> {
        return this.http.post(`/reservations/${reservationId}/inventory`, inventorySelected);
    }

    getEmptyReservation(): IReservation {
        return { ...emptyReservation };
    }
}
