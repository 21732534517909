import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { AppointmentDynamicControls, IAppointmentDynamicControlsParameters } from '@model/form-controls/appointment.form-controls';
import { IAppointment } from '@model/interfaces/appointment';
import { IUser } from '@model/interfaces/user';
import { AppointmentDynamicControlsPartial } from '@model/partials/appointment-partial.form-controls';
import { IMetaItem } from '@model/interfaces/base';

export class AppointmentDynamicConfig<T extends IAppointment> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private appointment: T, private assignedUsers: IMetaItem[], private configControls?: string[]) {
        super();
        const additionalParams: IAppointmentDynamicControlsParameters = { assignedUsers: assignedUsers as any };
        const dynamicControls = new AppointmentDynamicControlsPartial(this.appointment, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'DonorId',
                'Cancelled',
                'AssignedUserId',
                'StartTimeUtc',
                'EndTimeUtc',
                'StartTimeDonor',
                'EndTimeDonor',
                'Timezone',
                'PhoneNumber',
                'DateCreated',
                'TypeId',
                'InterviewPassed',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
