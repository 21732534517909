import { Component } from '@angular/core';
import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';

import { AbstractLabDataFormComponent } from '../base-components/abstract-lab-data-form.component';

@Component({
    selector: 'app-donor-test-thaw-info',
    templateUrl: './test-thaw-info.component.html',
})
export class TestThawInfoComponent extends AbstractLabDataFormComponent {
    sectionId = DonorTestSampleLabDataSections.TestThawInfo;
}
