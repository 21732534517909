import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorBasicInfo } from '../interfaces/donor-basic-info';
import { IGender } from '../interfaces/gender';
import { IUnitsOfMeasurement } from '../interfaces/units-of-measurement';
import { IReferralType } from '../interfaces/referral-type';

export interface IDonorBasicInfoDynamicControlsParameters {
    formGroup?: string;
    genders?: IGender[];
    heights?: IUnitsOfMeasurement[];
    weights?: IUnitsOfMeasurement[];
    referralTypes?: IReferralType[];
}

export class DonorBasicInfoDynamicControls {

    formGroup: string;
    genders: IGender[];
    heights: IUnitsOfMeasurement[];
    weights: IUnitsOfMeasurement[];
    referralTypes: IReferralType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorbasicinfo?: IDonorBasicInfo, additionalParameters?: IDonorBasicInfoDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorBasicInfo';
        this.genders = additionalParameters && additionalParameters.genders || undefined;
        this.heights = additionalParameters && additionalParameters.heights || undefined;
        this.weights = additionalParameters && additionalParameters.weights || undefined;
        this.referralTypes = additionalParameters && additionalParameters.referralTypes || undefined;

        this.Form = {
            ApplicantPhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Applicant Phone Number',
                name: 'ApplicantPhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('ApplicantPhoneNumber') && this.donorbasicinfo.ApplicantPhoneNumber !== null ? this.donorbasicinfo.ApplicantPhoneNumber.toString() : '',
            }),
            Comment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comment',
                name: 'Comment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('Comment') && this.donorbasicinfo.Comment !== null ? this.donorbasicinfo.Comment.toString() : '',
            }),
            Completed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Completed',
                name: 'Completed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.Completed || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorbasicinfo && this.donorbasicinfo.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.DateModified || null,
            }),
            Dob: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dob',
                name: 'Dob',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.Dob || null,
            }),
            EmergencyContactName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Emergency Contact',
                name: 'EmergencyContactName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.maxLength(125)],
                validators: { 'required': true, 'maxlength': 125 },
                value: this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('EmergencyContactName') && this.donorbasicinfo.EmergencyContactName !== null ? this.donorbasicinfo.EmergencyContactName.toString() : '',
            }),
            EmergencyContactNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Emergency Contact Number',
                name: 'EmergencyContactNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required, Validators.minLength(10), Validators.maxLength(20)],
                validators: { 'required': true, 'minlength': 10, 'maxlength': 20 },
                value: this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('EmergencyContactNumber') && this.donorbasicinfo.EmergencyContactNumber !== null ? this.donorbasicinfo.EmergencyContactNumber.toString() : '',
            }),
            GenderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gender',
                name: 'GenderId',
                options: this.genders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.GenderId || null,
            }),
            HasTextCommunication: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Text Communication',
                name: 'HasTextCommunication',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('HasTextCommunication') && this.donorbasicinfo.HasTextCommunication !== null ? this.donorbasicinfo.HasTextCommunication : true,
            }),
            Height: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height',
                name: 'Height',
                options: this.heights,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.Height || null,
            }),
            HeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height Unit',
                name: 'HeightUnitId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.HeightUnitId || 1,
            }),
            IsUsCitizen: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Us Citizen',
                name: 'IsUsCitizen',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('IsUsCitizen') && this.donorbasicinfo.IsUsCitizen !== null ? this.donorbasicinfo.IsUsCitizen : true,
            }),
            ReferralTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Referral Type',
                name: 'ReferralTypeId',
                options: this.referralTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.ReferralTypeId || null,
            }),
            Weight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight',
                name: 'Weight',
                options: this.weights,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.Weight || null,
            }),
            WeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight Unit',
                name: 'WeightUnitId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorbasicinfo && this.donorbasicinfo.WeightUnitId || 3,
            }),
        };

        this.View = {
            ApplicantPhoneNumber: new DynamicLabel(
                'Applicant Phone Number',
                this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('ApplicantPhoneNumber') && this.donorbasicinfo.ApplicantPhoneNumber !== null ? this.donorbasicinfo.ApplicantPhoneNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Comment: new DynamicLabel(
                'Comment',
                this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('Comment') && this.donorbasicinfo.Comment !== null ? this.donorbasicinfo.Comment.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Completed: new DynamicLabel(
                'Completed',
                this.donorbasicinfo && this.donorbasicinfo.Completed || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.donorbasicinfo && this.donorbasicinfo.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.donorbasicinfo && this.donorbasicinfo.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Dob: new DynamicLabel(
                'Dob',
                this.donorbasicinfo && this.donorbasicinfo.Dob || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EmergencyContactName: new DynamicLabel(
                'Emergency Contact Name',
                this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('EmergencyContactName') && this.donorbasicinfo.EmergencyContactName !== null ? this.donorbasicinfo.EmergencyContactName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            EmergencyContactNumber: new DynamicLabel(
                'Emergency Contact Number',
                this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('EmergencyContactNumber') && this.donorbasicinfo.EmergencyContactNumber !== null ? this.donorbasicinfo.EmergencyContactNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            GenderId: new DynamicLabel(
                'Gender',
                getMetaItemValue(this.genders, this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('GenderId') && this.donorbasicinfo.GenderId !== null ? this.donorbasicinfo.GenderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasTextCommunication: new DynamicLabel(
                'Has Text Communication',
                this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('HasTextCommunication') && this.donorbasicinfo.HasTextCommunication !== null ? this.donorbasicinfo.HasTextCommunication : true,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Height: new DynamicLabel(
                'Height',
                getMetaItemValue(this.heights, this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('Height') && this.donorbasicinfo.Height !== null ? this.donorbasicinfo.Height : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HeightUnitId: new DynamicLabel(
                'Height Unit',
                this.donorbasicinfo && this.donorbasicinfo.HeightUnitId || 1,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            IsUsCitizen: new DynamicLabel(
                'Is Us Citizen',
                this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('IsUsCitizen') && this.donorbasicinfo.IsUsCitizen !== null ? this.donorbasicinfo.IsUsCitizen : true,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReferralTypeId: new DynamicLabel(
                'Referral Type',
                getMetaItemValue(this.referralTypes, this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('ReferralTypeId') && this.donorbasicinfo.ReferralTypeId !== null ? this.donorbasicinfo.ReferralTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Weight: new DynamicLabel(
                'Weight',
                getMetaItemValue(this.weights, this.donorbasicinfo && this.donorbasicinfo.hasOwnProperty('Weight') && this.donorbasicinfo.Weight !== null ? this.donorbasicinfo.Weight : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            WeightUnitId: new DynamicLabel(
                'Weight Unit',
                this.donorbasicinfo && this.donorbasicinfo.WeightUnitId || 3,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
        };

    }
}
