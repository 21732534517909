import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SampleTypes } from '@model/enums/sample-types.enum';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { IMetaItem } from '@mt-ng2/base-service';
import { LocalizedDateBaseService } from '@angular-common/services/localized-date-base.service';
import { Observable } from 'rxjs';
import { IInventory } from '@model/interfaces/inventory';
import { LocalizeDatesByName } from '@angular-common/libraries/treat-date-as-local.library';
import { map } from 'rxjs/operators';
import { inventoryDatesToLocalize } from '../inventory/services/inventory-vessel.service';

export const emptyDonorTestSample: IDonorSample = {
    DonorId: 0,
    Id: 0,
    SampleDate: null,
    SampleStatusId: 0,
    SampleTypeId: 0,
    TestSampleTypeId: null,
};

export const datesToLocalize = [
    'SampleDate',
    'InitialScreeningTesting.BloodDrawDate',
    'FollowUpScreeningTesting.BloodDrawDate',
    'PreQuarantineTesting.BloodDrawDate',
    'QuarantineReleaseTesting.BloodDrawDate',
    'EligibilityDeterminationDate',
];

@Injectable({
    providedIn: 'root',
})
export class DonorSampleService extends LocalizedDateBaseService<IDonorSample> {
    constructor(public http: HttpClient) {
        super('/donor-samples', http, datesToLocalize);
    }

    update(sample: IDonorSample): Observable<IDonorSample> {
        return super.update(sample).pipe(map((s) => LocalizeDatesByName(s, datesToLocalize)));
    }

    getEmptyDonorTestSample(donorId = 0): IDonorSample {
        return { ...emptyDonorTestSample, DonorId: donorId, SampleTypeId: SampleTypes.TestSample };
    }

    getEmptyDonorSample(donorId = 0): IDonorSample {
        return { ...emptyDonorTestSample, DonorId: donorId, SampleTypeId: SampleTypes.Donation };
    }

    getSHSBDF8Pdf(ongoingScreeningId: number): Observable<any> {
        return this.http.get(`/donor-samples/${ongoingScreeningId}/shsbdf8-pdf`, {
            responseType: 'blob' as 'blob',
        });
    }

    getSHSBDF7Pdf(ongoingScreeningId: number): Observable<any> {
        return this.http.get(`/donor-samples/${ongoingScreeningId}/shsbdf7-pdf`, {
            responseType: 'blob' as 'blob',
        });
    }

    getSHSBDF9Pdf(ongoingScreeningId: number): Observable<any> {
        return this.http.get(`/donor-samples/${ongoingScreeningId}/shsbdf9-pdf`, {
            responseType: 'blob' as 'blob',
        });
    }

    getInventoryDetails(donationId: number): Observable<IInventory[]> {
        return this.http.get<IInventory[]>(`/donor-samples/${donationId}/inventory`).pipe(
            map((s) => {
                s.forEach((inv) => LocalizeDatesByName(inv, inventoryDatesToLocalize));
                return s;
            }),
        );
    }
}
