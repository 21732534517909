import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { ISalesOrderDynamicControlsParametersPartial, SalesOrderDynamicControlsPartial } from '@model/partials/sales-order-partial.form-controls';
import { ISalesOrder } from '@model/interfaces/sales-order';

export class SalesOrderDynamicConfig<T extends ISalesOrder> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private salesOrder: T,
        private additionalParams: ISalesOrderDynamicControlsParametersPartial,
        private configControls?: string[],
        private isNew?: boolean,
    ) {
        super();
        const dynamicControls = new SalesOrderDynamicControlsPartial(this.salesOrder, this.additionalParams, isNew);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'StatusId',
                'ProductTypeId',
                'VialStatusId',
                'ContractSent',
                'ContractReceived',
                'Cost',
                'Deposit',
                'ReplacementOrder',
                'Notes',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
