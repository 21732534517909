import { Component } from '@angular/core';
import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';
import { AbstractLabDataFormComponent } from '../base-components/abstract-lab-data-form.component';

@Component({
    selector: 'app-donor-remainder-vial-details',
    templateUrl: './remainder-vial-details.component.html',
})
export class RemainderVialDetailsComponent extends AbstractLabDataFormComponent {
    sectionId = DonorTestSampleLabDataSections.RemainderVialDetails;
}
