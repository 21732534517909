import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtraParams } from '@common/extra-params/extra-params.library';
import { entityListModuleConfig } from '@common/shared.module';
import { DonorTestSampleDynamicControlsPartial } from '@model/partials/donor-test-sample-partial.form-controls';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IColumnSortedEvent, IItemSelectedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin, Observable } from 'rxjs';

import { IDonor } from '@model/interfaces/donor';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { DonorTestSamplesEntityListConfig } from './donor-test-samples.entity-list-config';
import { DonorSampleService } from '../../services/donor-sample.service';
import { DonorSampleStatusService } from './services/donor-sample-status.service';
import { TestSampleTypeService } from './services/test-sample-type.service';
import { DonorService } from 'admin-portal/donors/services/donor.service';
import { IDonorSampleStatus } from '@model/interfaces/donor-sample-status';
import { SampleTypes } from '@model/enums/sample-types.enum';

@Component({
    selector: 'app-donor-test-samples',
    templateUrl: './donor-test-samples.component.html',
})
export class DonorTestSamplesComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;

    @Output() emitIsEditing = new EventEmitter<boolean>();

    samples: IDonorSample[] = [];
    total: number;
    donorTestSample: IDonorSample;

    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    entityListConfig = new DonorTestSamplesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    // abstract controls
    abstractDonorTestSampleControls: any;

    donorTestSamplesForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private testSampleTypeService: TestSampleTypeService,
        private testSampleStatusService: DonorSampleStatusService,
        private donorTestSampleService: DonorSampleService,
        private donorService: DonorService,
    ) {}

    ngOnInit(): void {
        this.donorTestSample = this.donorTestSampleService.getEmptyDonorTestSample(this.donor.Id);
        forkJoin([this.testSampleTypeService.getItems(), this.testSampleStatusService.getItems()]).subscribe(([]) => {
            this.getSamples();
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.donorTestSamplesForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractDonorTestSampleControls = new DonorTestSampleDynamicControlsPartial(null, {
            donors: null,
            formGroup: 'DonorTestSample',
            prepMethods: null,
            sampleStatuses: this.testSampleStatusService.items,
            testSampleTypes: this.testSampleTypeService.items,
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            DonorTestSample: this.fb.group({}),
        });
    }

    getSamples(): void {
        this.getSamplesCall().subscribe((answer) => {
            this.samples = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getSamplesCall(): Observable<HttpResponse<IDonorSample[]>> {
        const _extraSearchParams: ExtraSearchParams[] = ExtraParams.build({
            DonorId: this.donor.Id,
            SampleTypeId: SampleTypes.TestSample,
        });
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.donorTestSampleService.get(searchparams);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getSamples();
    }

    sampleSelected(event: IItemSelectedEvent): void {
        this.router.navigate([`./test-samples`, event.entity.Id], { relativeTo: this.route });
    }

    formSubmitted(): void {
        if (this.donorTestSamplesForm.valid) {
            this.donorTestSample = Object.assign({}, this.donorTestSample, this.donorTestSamplesForm.value.DonorTestSample);
            this.donorTestSampleService.create(this.donorTestSample).subscribe((answer) => {
                this.donorTestSample.Id = answer;
                this.addDonorTestSampleFks();
                this.samples.push(this.donorTestSample);
                this.donorService.emitChange(this.donor);
                this.cancelClick();
            });
        } else {
            markAllFormFieldsAsTouched(this.donorTestSamplesForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    addDonorTestSampleFks(): void {
        this.donorTestSample.TestSampleType = this.testSampleTypeService.items.find((i) => i.Id === this.donorTestSample.TestSampleTypeId);
        this.donorTestSample.SampleStatus = this.testSampleStatusService.items.find((i) => i.Id === this.donorTestSample.SampleStatusId);
    }

    createTestSample(): void {
        this.isEditing = true;
        this.emitIsEditing.emit(true);
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
        this.donorTestSample = this.donorTestSampleService.getEmptyDonorTestSample(this.donor.Id);
        this.emitIsEditing.emit(false);
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Donor test sample saved successfully.');
    }
}
