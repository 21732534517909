import { DonorRelationshipTypeService } from '@angular-common/donor-application-forms/services/medical-issue-meta-items/donor-relationship-type.service';
import { Component } from '@angular/core';
import { ApplicationSections } from '@model/enums/custom/application-sections.enum';
import { IDonorMedicalHistory } from '@model/interfaces/donor-medical-history';
import { IDonorRelationshipType } from '@model/interfaces/donor-relationship-type';
import { IMedicalIssueType } from '@model/interfaces/medical-issue-type';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DonorService } from 'admin-portal/donors/services/donor.service';
import { MedicalIssueTypeService } from 'admin-portal/donors/services/medical-issue-type.service';
import { forkJoin } from 'rxjs';
import { DonorApplicationDetailsService } from '../../services/donor-application-details.service';
import { DonorApplicationDetailsSectionBaseComponent } from '../donor-application-details-section.base.component';

@Component({
    selector: 'app-medical-issues-details',
    templateUrl: './medical-issues-details.component.html',
})
export class MedicalIssuesDetailsComponent extends DonorApplicationDetailsSectionBaseComponent {
    formId = ApplicationSections.MedicalIssues;
    medicalIssueTypes: IMedicalIssueType[];
    donorRelationshipTypes: IDonorRelationshipType[];

    constructor(
        service: DonorApplicationDetailsService,
        notificationsService: NotificationsService,
        donorService: DonorService,
        private medicalIssueTypeService: MedicalIssueTypeService,
        private donorRelationshipTypeService: DonorRelationshipTypeService,
        ) {
        super(service, notificationsService, donorService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        forkJoin(this.donorRelationshipTypeService.getItems(), this.medicalIssueTypeService.getItems()).subscribe(() => {
            this.medicalIssueTypes = this.medicalIssueTypeService.items;
            this.donorRelationshipTypes = this.donorRelationshipTypeService.items;
        });
    }

    getIssuesByTypeId(typeId: number): IDonorMedicalHistory[] {
        return this.donor.DonorIssue.DonorMedicalHistories.filter(
            (issue) => issue.MedicalIssue.MedicalIssueTypeId === typeId && !issue.MedicalIssue.ParentMedicalIssueId,
        );
    }
}
