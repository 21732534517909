<div class="row" *ngIf="donorTestSample">
    <div class="col-md-6">
        <app-donor-test-sample-basic-info
            [donorTestSample]="donorTestSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
        ></app-donor-test-sample-basic-info>
        <br />
        <app-notes [entityType]="noteEntity" [entityId]="donorTestSample.Id" [canEdit]="canEdit"></app-notes>
        <br />
        <app-upload-documents-detail [entityId]="donorTestSample.Id" [claimType]="claimType" [entityType]="docEntityType">
        </app-upload-documents-detail>
        <br />
    </div>
    <div class="col-md-6">
        <app-donor-raw-semen
            [testSample]="donorTestSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="donorSampleLabData"
            [formControls]="dynamicControls"
        ></app-donor-raw-semen>
        <br />
        <app-donor-post-gradient
            [testSample]="donorTestSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="donorSampleLabData"
            [formControls]="dynamicControls"
        ></app-donor-post-gradient>
        <br />
        <app-donor-cryo-prep [testSample]="donorTestSample" [dataService]="donorSampleLabData"></app-donor-cryo-prep>
        <br />
        <app-donor-ivf-vials
            [testSample]="donorTestSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="donorSampleLabData"
            [formControls]="dynamicControls"
        ></app-donor-ivf-vials>
        <br />
        <app-donor-test-thaw-info
            [testSample]="donorTestSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="donorSampleLabData"
            [formControls]="dynamicControls"
        ></app-donor-test-thaw-info>
        <br />
        <app-donor-lab-data-location
            [testSample]="donorTestSample"
            [canEdit]="canEdit"
            (openEditing)="setCanEdit($event)"
            [dataService]="donorSampleLabData"
            [formControls]="dynamicControls"
        ></app-donor-lab-data-location>
    </div>
</div>
