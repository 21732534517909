import { getUserFullName } from '@angular-common/libraries/common-functions.library';
import { DonationStatuses } from '@model/enums/donation-statuses.enum';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { DateParts } from '@mt-ng2/date-module';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SelectTypes } from '@mt-ng2/entity-list-module';
import { DonationStatusIconComponent } from './donation-status-icon.component';
import { ViewDonationDetailsComponent } from './view-donation-details.component';
import { getEligibleSampleDate } from '../donor-donations.library';

export class DonorDonationsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    component: DonationStatusIconComponent,
                    name: '',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: ViewDonationDetailsComponent,
                    name: 'View/Edit',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['SampleDate'],
                    name: 'Donation Date',
                    pipes: ['date:mediumDate'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (value: IDonorSample) => {
                        return getEligibleSampleDate(value.SampleDate)
                    },
                    name: 'Eligible Quarantine Release Date',
                    pipes: ['date:mediumDate'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['SampleStatus', 'Name'],
                    name: 'Lab Status',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['DonationStatus', 'Name'],
                    name: 'Status',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (value: IDonorSample) => {
                        return value.SampleStatusModifiedBy ? getUserFullName(value.SampleStatusModifiedBy) : '';
                    },
                    name: 'Last Modified By',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['SampleStatusModifiedDate'],
                    name: 'Last Modified Date',
                    pipes: ['date:short'],
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
            select: {
                selectable: (value: IDonorSample) => {
                    return value.DonationStatusId === DonationStatuses.EligibleforRelease;
                },
                selectedColor: '#b0dfef',
                type: SelectTypes.Multi,
            },
        };
        super(listConfig);
    }
}
