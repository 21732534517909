import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IDonorSampleStatus } from '@model/interfaces/donor-sample-status';

@Injectable({
    providedIn: 'root',
})
export class DonorSampleStatusService extends StaticMetaItemService<IDonorSampleStatus> {
    constructor(public http: HttpClient) {
        super('DonorSampleStatusService', 'Status', 'StatusIds', '/meta-items/DonorSampleStatus', http);
    }
}
