import { DonorDynamicControls, IDonorDynamicControlsParameters } from '../form-controls/donor.form-controls';
import { IDonor } from '../interfaces/donor';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    InputTypes,
    LabelPositions,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { DatePipe } from '@angular/common';
import { getUserFullName } from '@angular-common/libraries/common-functions.library';
import { FormYesNoButtonComponent } from '@angular-common/components/form-yes-no-button.component';
import { Validators } from '@angular/forms';
import { CheckboxWithTooltipComponent, ICheckboxWithToolTipDynamicField } from '@angular-common/components/checkbox-with-tooltip.component';

export class DonorDynamicControlsPartial extends DonorDynamicControls {
    datePipe = new DatePipe('en-US');

    constructor(donorPartial?: IDonor, additionalParameters?: IDonorDynamicControlsParameters) {
        super(donorPartial, additionalParameters);

        (<DynamicField>this.Form.AcknowledgedWaiver) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: '',
            labelPosition: { position: LabelPositions.Hidden, colsForLabel: null },
            name: 'AcknowledgedWaiver',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: donorPartial.AcknowledgedWaiver,
        });

        (<DynamicField>this.Form.Id) = new DynamicField({
            disabled: true,
            formGroup: this.formGroup,
            label: 'Donor Id',
            name: 'Id',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
                scale: null,
            }),
            value: (donorPartial && donorPartial.Id) || null,
        });
        (<DynamicLabel>this.View.Id) = new DynamicLabel(
            'Donor Id',
            (donorPartial && donorPartial.Id) || null,
            new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textarea,
                scale: null,
            }),
        );

        (<DynamicField>this.Form.DonorFee).type.inputType = NumericInputTypes.Currency;

        if (this.donorStatuses) {
            let statusEditable = !this.donorStatuses.find((ds) => ds.Id === donorPartial.DonorStatusId).Manual;
            (<DynamicField>this.Form.DonorStatusId).disabled = statusEditable;
            (<DynamicField>this.Form.DonorStatusId).options = this.donorStatuses.filter((ds) => ds.Manual || ds.Id === donorPartial.DonorStatusId);
            (<DynamicField>this.Form.DonorStatusId).type.inputType = SelectInputTypes.Dropdown;
        }

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        (<DynamicLabel>this.View.DonorFee).type.inputType = NumericInputTypes.Currency;

        if (donorPartial.PublishedModifiedBy) {
            (<DynamicLabel>this.View.Published).valueHtml = `${donorPartial.Published ? 'Yes' : 'No'}<br/>
                <p style="margin:0"><em>Modified By ${getUserFullName(donorPartial.PublishedModifiedBy)} ${
                    this.datePipe.transform(donorPartial.PublishedModifiedDate, 'short')
                }</em></p>`;
        }

        (<DynamicLabel>this.View.Ivf).label = 'IVF';
        (<DynamicLabel>this.View.Iui).label = 'IUI';
        (<DynamicLabel>this.View.Icsi).label = 'ICSI';
        (<DynamicField>this.Form.Ivf).labelHtml = '<label>IVF</label>';
        (<DynamicField>this.Form.Iui).labelHtml = '<label>IUI</label>';
        (<DynamicField>this.Form.Icsi).labelHtml = '<label>ICSI</label>';

        (<ICheckboxWithToolTipDynamicField>this.Form.Active).component = CheckboxWithTooltipComponent;
        (<ICheckboxWithToolTipDynamicField>this.Form.Active).toolTipText =
            'Donor is actively donating. Published donors with this unchecked will have an \'Inactive\' badge in the recipient portal.';

        (<ICheckboxWithToolTipDynamicField>this.Form.IsTemporarilyInactive).component = CheckboxWithTooltipComponent;
        (<ICheckboxWithToolTipDynamicField>this.Form.IsTemporarilyInactive).toolTipText =
            'Donor is temporarily taking a break from making donations.';

        (<ICheckboxWithToolTipDynamicField>this.Form.PermanentlyInactive).component = CheckboxWithTooltipComponent;
        (<ICheckboxWithToolTipDynamicField>this.Form.PermanentlyInactive).toolTipText = 'Donor will no longer be making donations.';
    }
}
