import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService, IEntity } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { LocalizeDatesByName, LocalizeDatesByValue } from '../libraries/treat-date-as-local.library';

export abstract class LocalizedDateBaseService<T extends IEntity> extends BaseService<T> {
    protected datesToLocalize?: string[];
    private localizationMethod: Function;

    constructor(baseurl: string, protected http: HttpClient, datesToLocalize?: string[]) {
        super(baseurl, http);
        this.datesToLocalize = datesToLocalize;
        if (!this.datesToLocalize) {
            this.localizationMethod = LocalizeDatesByValue;
        } else {
            this.localizationMethod = function (t: T): T {
                return LocalizeDatesByName(t, this.datesToLocalize);
            };
        }
    }

    getById(id: number): Observable<T> {
        return super.getById(id).pipe(
            map((t) => this.localizationMethod(t)),
        );
    }

    get(searchparameters: SearchParams): Observable<HttpResponse<T[]>> {
        return super.get(searchparameters).pipe(
            map((resp) => {
                resp.body.map((t) => {
                    this.localizationMethod(t);
                });
                return resp;
            }),
        );
    }
}
