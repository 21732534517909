import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorAvailability } from '../interfaces/donor-availability';
import { IDonor } from '../interfaces/donor';

export interface IDonorAvailabilityDynamicControlsParameters {
    formGroup?: string;
    Donors?: IDonor[];
}

export class DonorAvailabilityDynamicControls {

    formGroup: string;
    Donors: IDonor[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private DonorAvailability?: IDonorAvailability, additionalParameters?: IDonorAvailabilityDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorAvailability';
        this.Donors = additionalParameters && additionalParameters.Donors || undefined;

        this.Form = {
            CountryCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Country Code',
                name: 'CountryCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(2) ],
                validators: { 'required': true, 'maxlength': 2 },
                value: this.DonorAvailability && this.DonorAvailability.hasOwnProperty('CountryCode') && this.DonorAvailability.CountryCode !== null ? this.DonorAvailability.CountryCode.toString() : '',
            }),
            DonorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor',
                name: 'DonorId',
                options: this.Donors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.DonorAvailability && this.DonorAvailability.DonorId || null,
            }),
            StateCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'State Code',
                name: 'StateCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3) ],
                validators: { 'maxlength': 3 },
                value: this.DonorAvailability && this.DonorAvailability.hasOwnProperty('StateCode') && this.DonorAvailability.StateCode !== null ? this.DonorAvailability.StateCode.toString() : '',
            }),
        };

        this.View = {
            CountryCode: new DynamicLabel(
                'Country Code',
                this.DonorAvailability && this.DonorAvailability.hasOwnProperty('CountryCode') && this.DonorAvailability.CountryCode !== null ? this.DonorAvailability.CountryCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DonorId: new DynamicLabel(
                'Donor',
                getMetaItemValue(this.Donors, this.DonorAvailability && this.DonorAvailability.hasOwnProperty('DonorId') && this.DonorAvailability.DonorId !== null ? this.DonorAvailability.DonorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            StateCode: new DynamicLabel(
                'State Code',
                this.DonorAvailability && this.DonorAvailability.hasOwnProperty('StateCode') && this.DonorAvailability.StateCode !== null ? this.DonorAvailability.StateCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            )
        };

    }
}
