import { DonorAvailabilityDynamicControls, IDonorAvailabilityDynamicControlsParameters } from '../form-controls/Donor-availability.form-controls';
import { IDonorAvailability } from '../interfaces/donor-availability';
import { IDonor } from '../interfaces/donor';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { ICountry } from '@model/interfaces/country';
import { IState } from '@model/interfaces/state';
import { IMetaItem } from '@mt-ng2/base-service';

export interface IDonorAvailabilityDynamicControlsParametersPartial extends IDonorAvailabilityDynamicControlsParameters {
    countries?: ICountry[];
    regions: IState[];
}


export class DonorAvailabilityDynamicControlsPartial extends DonorAvailabilityDynamicControls {
   
    countries?: ICountry[];
    regions?: IState[];

    constructor(donorAvailabilityPartial?: IDonorAvailability[], additionalParameters?: IDonorAvailabilityDynamicControlsParametersPartial) {
        super(null, additionalParameters);

   
        this.countries = additionalParameters?.countries;
        this.regions = additionalParameters.regions
        const auStates = this.regions.filter((val) => val.CountryCode === 'AU' && val.FamilyLimit)
        .map((s) => {
            return {
                Id: s.Id,
                StateCode: s.StateCode,
                Name: s.StateCode + (s.FamilyLimit ? " (Family Limit: "+s.FamilyLimit+")" : "")
            };
        });
        this.Form = {
            CountryCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor Availabilities',
                name: 'CountryCodes',
                options: this.countries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.MultiselectDropdown,
                }),
                value: this.getCountryMultiselectValue(donorAvailabilityPartial),
            }),
            AuStateCodes: new DynamicField({
                formGroup: this.formGroup,
                label: 'AU States',
                name: 'AuStateCodes',
                options: auStates,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.MultiselectDropdown,
                }),
                value: this.getStateMultiselectValue(this.parseStates(donorAvailabilityPartial, 'AU'), auStates),
            }),
        };
    }

    getCountryMultiselectValue(value?: IDonorAvailability[]): number[] {
        if (value && this.countries) {
            const countryCodes = value.map((itm) => itm.CountryCode);
            return this.countries.filter((itm) => countryCodes.includes(itm.CountryCode)).map((itm) => itm.Id);
        }
        else {
            return [];
        }
    }

    getStateMultiselectValue(value: IDonorAvailability[], options: any[]): number[] {

        if (value) {
            const stateCodes = value.map((itm) => itm.StateCode);
            let selectedStates = options.filter(itm => stateCodes.includes(itm.StateCode));
            selectedStates = selectedStates.map((itm) => itm.Id);
            return selectedStates;
        }
        else {
            return null;
        }
    }

    parseStates(partial: IDonorAvailability[], country: string): IDonorAvailability[] {

        if (partial) {
            return partial.filter((itm) => itm.CountryCode === country && itm.StateCode);
        } else {
            return null;
        }
    }
}
