import { Component, Input, OnInit } from '@angular/core';
import { IDonorUser } from '@model/interfaces/donor-user';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { DonorRegistrationDynamicConfig } from '../../../donor-portal/donor-portal/login/registration/donor-registration.dynamic-config';

@Component({
    selector: 'app-donor-application-donor-user-form',
    templateUrl: './donor-application-donor-user-form.component.html',
})
export class DonorApplicationDonorUserFormComponent implements OnInit {
    @Input() donorUser: IDonorUser;

    config: IDynamicFormConfig;
    constructor() {}

    ngOnInit(): void {
        this.config = new DonorRegistrationDynamicConfig(this.donorUser).getForUpdate();
    }
}
