import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { ThawingDynamicControls, IThawingDynamicControlsParameters } from '@model/form-controls/thawing.form-controls';
import { IThawingDynamicControlsParametersPartial, ThawingDynamicControlsPartial } from '@model/partials/thawing-partial.form-controls';
import { IThawing } from '@model/interfaces/thawing';

export class ThawingDynamicConfig<T extends IThawing> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private thawing: T, private additionalParams: IThawingDynamicControlsParametersPartial, private configControls?: string[]) {
        super();

        const dynamicControls = new ThawingDynamicControlsPartial(this.thawing, this.additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'DonorId',
                'ClinicId',
                'Technician',
                'ProcedureTypeId',
                'TreatingPhysician',
                'ProcedureDate',
                'ActualThawingDate',
                'VialTypeId',
                'VialsThawed',
                'VialsUtilized',
                'Volume',
                'Concentration',
                'Motility',
                'TotalMotalCount',
                'ReasonNotToCountId',
                'Comments',
                'FollowUpDate',
                'FollowUpReasonId',
                'GuaranteeMet',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
