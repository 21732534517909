import { UploadDocumentsService } from '@angular-common/services/upload-documents.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GeneticScreeningUploadService extends UploadDocumentsService {
    constructor(public http: HttpClient) {
        super(http);
    }

    saveGeneticScreeningDocument(entityType: number, entityId: number, documentTypeId: number, file: File, geneticScreeningTypeId = 0): any {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        let url = `/genetic-screening/documents/${entityId}/uploads/${documentTypeId}`;
        if (geneticScreeningTypeId) {
            url = url.concat(`/genetic-screening-type/${geneticScreeningTypeId}`);
            return this.http.post(url, formData).pipe(catchError(super.handleError));
        }

        return this.http.post(url, formData).pipe(catchError(super.handleError));
    }
}
