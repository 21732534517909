import { IRecipientUser } from '@model/interfaces/recipient-user';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';

export class RecipientUsersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Id'],
                    name: 'Recipient Id',
                }),
                new EntityListColumn({
                    accessors: ['FirstName'],
                    name: 'First Name',
                }),
                new EntityListColumn({
                    accessors: ['LastName'],
                    name: 'Last Name',
                }),
                new EntityListColumn({
                    accessorFunction: (recipientUser: IRecipientUser): string => {
                        return recipientUser.Recipient?.RecipientStatus?.Name || '';
                    },
                    name: 'Recipient Status',
                    sort: {
                        sortProperty: 'Recipient.RecipientStatus.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (recipientUser: IRecipientUser): string {
                        if (!recipientUser.SalesOrders?.length) {
                            return null;
                        }
                        const latestCycle = recipientUser.SalesOrders.reduce((prev, current) =>
                            prev.CreatedDate > current.CreatedDate ? prev : current,
                        );
                        return `${latestCycle.SalesOrderStatus.Name}`;
                    },
                    name: 'Latest Cycle Status',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (recipientUser: IRecipientUser): string => {
                        const manager = (recipientUser.Recipient as any)?.User;
                        return manager ? `${manager.FirstName} ${manager.LastName}` : '';
                    },
                    name: 'Assigned To',
                    sort: {
                        sortProperty: 'Recipient.User.Id',
                    },
                }),
                new EntityListColumn({
                    name: 'Email',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (recipientUser: IRecipientUser): string => {
                        return recipientUser.Recipient?.Clinic?.Name || '';
                    },
                    name: 'Clinic',
                    sort: {
                        sortProperty: 'Recipient.Clinic.Name',
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
