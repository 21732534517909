import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IDonorStatus } from '@model/interfaces/donor-status';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

@Injectable({
    providedIn: 'root',
})
export class DonorStatusService extends StaticMetaItemService<IDonorStatus> {
    constructor(public http: HttpClient) {
        super('DonorStatusService', 'Status', 'StatusIds', '/meta-items/DonorStatus', http);
    }

    getItems(): Observable<IDonorStatus[]> {
        return super.getItems().pipe(map((statuses) => statuses.sort((a, b) => a.Sort - b.Sort)));
    }

    getSearchFilterItems(): Observable<MtSearchFilterItem[]> {
        return super.getItems().pipe(
            map((statuses) => statuses.sort((a, b) => a.Sort - b.Sort)),
            map((statuses) => statuses.map((s) => new MtSearchFilterItem({ Id: s.Id, Name: s.Name }, false))),
        );
    }
}
