export enum DonorTestSampleLabDataSections {
    RawSemen = 1,
    PostGradient = 2,
    CryoPrep = 3,
    IVFVials = 4,
    TestThawInfo = 5,
    Location = 6,
    RemainderVialDetails = 7,
    RemainderVials = 8,
    RemainderTestThawInfo = 9,
}
