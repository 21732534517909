import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { BirthOutcomeDynamicControls, IBirthOutcomeDynamicControlsParameters } from '../form-controls/birth-outcome.form-controls';
import { IBirthOutcome } from '../interfaces/birth-outcome';

export class BirthOutcomeDynamicControlsPartial extends BirthOutcomeDynamicControls {
    constructor(birthoutcomePartial?: IBirthOutcome, additionalParameters?: IBirthOutcomeDynamicControlsParameters) {
        super(birthoutcomePartial, additionalParameters);

        (<DynamicField>this.Form.NumberBorn).labelHtml = `# Born`;
        (<DynamicField>this.Form.NumberBorn).type.doNotAllowZero = false;

        (<DynamicLabel>this.View.NumberBorn).value =
            birthoutcomePartial?.NumberBorn || birthoutcomePartial?.NumberBorn === 0 ? birthoutcomePartial.NumberBorn : null;
    }
}
