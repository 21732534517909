import { Component } from '@angular/core';
import { ApplicationSections } from '@model/enums/custom/application-sections.enum';
import { DonorApplicationDetailsSectionBaseComponent } from '../donor-application-details-section.base.component';

@Component({
    selector: 'app-preliminary-history-details',
    templateUrl: './prelim-history-details.component.html',
})
export class PreliminaryHistoryDetailsComponent extends DonorApplicationDetailsSectionBaseComponent {
    formId = ApplicationSections.PreliminaryHistory;
}
