<div class="miles-card padded">
    <h4>
        Donations
        <span class="pull-right" *ngIf="canAddDonorSample">
            <i class="fa fa-lg fa-plus" (click)="createDonation()"></i>
        </span>
    </h4>
    <div *ngIf="!isEditing" class="row">
        <div class="padding">
            <div class="col-md-12">
                <button
                    class="btn btn-warning pull-right"
                    style="margin: 5px"
                    [disabled]="!selectedDonations.length"
                    (click)="modalOptions.titleText = 'Discard'; updateType = bulkUpdateTypes.Discard; updateDonations = true"
                >
                    Discard
                </button>
                <button
                    class="btn btn-primary pull-right"
                    style="margin: 5px"
                    [disabled]="!selectedDonations.length"
                    (click)="modalOptions.titleText = 'Approve for Release'; updateType = bulkUpdateTypes.Approved; updateDonations = true"
                >
                    Approve for Release
                </button>
            </div>
            <div class="col-md-12">
                <entity-list
                    [entities]="donorSamples"
                    [total]="total"
                    [(currentPage)]="currentPage"
                    [(itemsPerPage)]="itemsPerPage"
                    (onPageChanged)="getDonorSamples()"
                    (onSelectionChanged)="donorSampleSelected($event)"
                    (onColumnSorted)="columnSorted($event)"
                    [entityListConfig]="entityListConfig"
                    [selectedEntities]="selectedDonations"
                >
                </entity-list>
            </div>
        </div>
    </div>
    <div *ngIf="isEditing">
        <app-donation-add [donorId]="donorId" (onFinished)="OnAddNewFinished($event)"></app-donation-add>
    </div>
</div>

<modal-wrapper *ngIf="updateDonations" [options]="modalOptions" (cancelClick)="updateDonations = false">
    <div>
        <div class="row col-md-12" *ngFor="let donation of selectedDonations">
            Donation Date: {{ donation.SampleDate | date: medium }}
            <app-bulk-inventory-update
                [donation]="donation"
                [updateType]="updateType"
                (onEditFinished)="onInventoryLocationEditFinished($event)"
                (saveReady)="updateSaveReady = true"
            ></app-bulk-inventory-update>
            <br />
        </div>
        <div class="row col-md-12">
            <button (click)="saveBulkUpdate(); updateSaveReady = false" class="btn btn-flat btn-success" [disabled]="!updateSaveReady">
                Save Changes
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelBulkUpdate(); updateSaveReady = false">Cancel</button>
        </div>
    </div>
</modal-wrapper>
