import { Injector } from '@angular/core';
import { Component } from '@angular/core';

import { pluralize } from '@mt-ng2/common-functions';
import { CustomFormControlComponentBase, FormCheckBoxComponent, FormSelectBaseWithNullableOption, IDynamicField } from '@mt-ng2/dynamic-form';
import { IModalOptions } from '@mt-ng2/modal-module';

/**
 * This component displays a checkbox with a tool tip
 * It extends the Dynamic Field interface to allow a custom tool tip text
 * to be configured per instance.
 * See qc-investigation-partial.form-controls.ts lines 23-26 for implementation
 * 5/18/2021 - B.C.
 */
export interface ICheckboxWithToolTipDynamicField extends IDynamicField {
    toolTipText?: string;
}

@Component({
    template: `
        <div
            class="dynamic-field form-check"
            [class.form-horizontal]="config.labelLeft"
            [class.form-group]="config.labelLeft"
            [formGroup]="getGroup()"
        >
            <span *ngIf="config.labelLeft" class="control-label" [ngClass]="'col-sm-' + config.labelCols">
                <span class="form-check-label" (click)="labelClicked()" [innerHTML]="config.labelHtml"></span>
                <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-html="true"
                    [title]="toolTipText"
                ></i>
            </span>
            <span [ngClass]="config.labelLeft ? 'col-sm-' + config.controlCols : null">
                <input
                    #input
                    type="checkbox"
                    (focus)="onFocus()"
                    [id]="config.name"
                    (blur)="onBlur()"
                    [attr.placeholder]="config.placeholder"
                    [formControlName]="config.name"
                    class="form-check-input"
                    [style.marginTop.px]="config.labelLeft ? 10 : null"
                />
                <span *ngIf="!config.labelLeft" class="form-check-label" (click)="labelClicked()" [innerHTML]="config.labelHtml"></span>
                <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-html="true"
                    [title]="toolTipText"
                ></i>
            </span>
        </div>
    `,
})
export class CheckboxWithTooltipComponent extends CustomFormControlComponentBase {
    get checkboxConfig(): ICheckboxWithToolTipDynamicField {
        return this.config as any;
    }

    toolTipText?: string;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.toolTipText = this.checkboxConfig.toolTipText;
    }

    onConfirm(): void {
        this.thisControl.getControl().setValue(true);
    }
}
