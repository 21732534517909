import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { ISamplePrepMethod } from '@model/interfaces/sample-prep-method';

@Injectable({
    providedIn: 'root',
})
export class SamplePrepMethodService extends StaticMetaItemService<ISamplePrepMethod> {
    constructor(public http: HttpClient) {
        super('SamplePrepMethodService', 'Method', 'MethodIds', '/meta-items/SamplePrepMethod', http);
    }
}
