import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorUser } from '../interfaces/donor-user';
import { IAuthUser } from '../interfaces/auth-user';

export interface IDonorUserDynamicControlsParameters {
    formGroup?: string;
    authUsers?: IAuthUser[];
}

export class DonorUserDynamicControls {

    formGroup: string;
    authUsers: IAuthUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donoruser?: IDonorUser, additionalParameters?: IDonorUserDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorUser';
        this.authUsers = additionalParameters && additionalParameters.authUsers || undefined;

        this.Form = {
            AuthUserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auth User',
                name: 'AuthUserId',
                options: this.authUsers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donoruser && this.donoruser.AuthUserId || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donoruser && this.donoruser.DateCreated || null,
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50), Validators.email ],
                validators: { 'required': true, 'maxlength': 50, 'email': true },
                value: this.donoruser && this.donoruser.hasOwnProperty('Email') && this.donoruser.Email !== null ? this.donoruser.Email.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.donoruser && this.donoruser.hasOwnProperty('FirstName') && this.donoruser.FirstName !== null ? this.donoruser.FirstName.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.donoruser && this.donoruser.hasOwnProperty('LastName') && this.donoruser.LastName !== null ? this.donoruser.LastName.toString() : '',
            }),
            MiddleInitial: new DynamicField({
                formGroup: this.formGroup,
                label: 'Middle Initial',
                name: 'MiddleInitial',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1) ],
                validators: { 'maxlength': 1 },
                value: this.donoruser && this.donoruser.hasOwnProperty('MiddleInitial') && this.donoruser.MiddleInitial !== null ? this.donoruser.MiddleInitial.toString() : '',
            }),
        };

        this.View = {
            AuthUserId: new DynamicLabel(
                'Auth User',
                getMetaItemValue(this.authUsers, this.donoruser && this.donoruser.hasOwnProperty('AuthUserId') && this.donoruser.AuthUserId !== null ? this.donoruser.AuthUserId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.donoruser && this.donoruser.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Email: new DynamicLabel(
                'Email',
                this.donoruser && this.donoruser.hasOwnProperty('Email') && this.donoruser.Email !== null ? this.donoruser.Email.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            FirstName: new DynamicLabel(
                'First Name',
                this.donoruser && this.donoruser.hasOwnProperty('FirstName') && this.donoruser.FirstName !== null ? this.donoruser.FirstName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LastName: new DynamicLabel(
                'Last Name',
                this.donoruser && this.donoruser.hasOwnProperty('LastName') && this.donoruser.LastName !== null ? this.donoruser.LastName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            MiddleInitial: new DynamicLabel(
                'Middle Initial',
                this.donoruser && this.donoruser.hasOwnProperty('MiddleInitial') && this.donoruser.MiddleInitial !== null ? this.donoruser.MiddleInitial.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
