import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IDonorIssue } from '@model/interfaces/donor-issue';
import { IApplicationPartOneValidationDTO } from '@model/interfaces/custom/application-part-one-validation-dto';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DonorIssueService extends BaseService<IDonorIssue> {
    constructor(public http: HttpClient) {
        super('/applicaton-part-one/medical-history', http);
    }

    update(data: IDonorIssue): Observable<IApplicationPartOneValidationDTO> {
        return super.update(data);
    }

    updateWithFks(data: IDonorIssue): Observable<IApplicationPartOneValidationDTO> {
        return super.updateWithFks(data);
    }
}
