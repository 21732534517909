import { Pipe, PipeTransform } from '@angular/core';

/**
 * Strictly for parsing HTML
 */

@Pipe({
    name: 'localize',
})
export class LocalizeDatePipe implements PipeTransform {
    transform(value: string): string {
        return value ? ((value as any as string).replace('Z', '') as any) : '';
    }
}
