<div class="mile-editable miles-card padded">
    <div *ngIf="!isEditing">
        <h4>
            Lab Data: Raw Semen
            <span class="pull-right" *ngIf="canEdit" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h4>
        <div *ngIf="formCreated">
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.RawSemenVolume" [label]="volumeLabelText" suffix=" ml"></dynamic-label>
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.NonMotileConcentration"></dynamic-label>
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.RawSemenMotileConcentration"></dynamic-label>
            <div class="display-padded">
                <b><label>Total Concentration (x10<sup>6</sup>/ml)</label>:</b>
                <span >{{ calculatedFields.TotalConcentration }}</span>
            </div>
             <div class="display-padded">
                <b><label>Motility</label>:</b>
                <span >{{ calculatedFields.Motility }}</span>
            </div>
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.RawSemenDilutionFactor"></dynamic-label>
            <div class="display-padded">
                <b><label>TMC Per Sample (x10<sup>6</sup>/ml)</label>:</b>
                <span >{{ calculatedFields.TMCPerSample }}</span>
            </div>
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.Viscosity"></dynamic-label>
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.RoundCells"></dynamic-label>
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.CountTech"></dynamic-label>
        </div>
    </div>
    <div *ngIf="isEditing">
        <form *ngIf="formCreated" [formGroup]="donorTestSampleLabDataForm" (ngSubmit)="formSubmitted()">
            <div>
                <h4>Lab Data: Raw Semen</h4>
                <dynamic-field [field]="abstractDonorTestSampleLabDataControls.RawSemenVolume" [form]="donorTestSampleLabDataForm"></dynamic-field>
                <dynamic-field [field]="abstractDonorTestSampleLabDataControls.NonMotileConcentration" [form]="donorTestSampleLabDataForm"></dynamic-field>
                <dynamic-field [field]="abstractDonorTestSampleLabDataControls.RawSemenMotileConcentration" [form]="donorTestSampleLabDataForm" ></dynamic-field>
                <div class="calculated-field">
                    <b>Total Concentration (x10<sup>6</sup>/ml)</b>
                    <input class="form-control" disabled value="{{ calculatedFields.TotalConcentration }}" />
                </div>
                <div class="calculated-field">
                    <b>Motility</b>
                    <input class="form-control" disabled
                    value="{{ calculatedFields.Motility }}" />
                </div>
                <dynamic-field [field]="abstractDonorTestSampleLabDataControls.RawSemenDilutionFactor" [form]="donorTestSampleLabDataForm"></dynamic-field>
                <div class="calculated-field">
                    <b>TMC Per Sample (x10<sup>6</sup>/ml)</b>
                    <input class="form-control" disabled
                    value="{{ calculatedFields.TMCPerSample }}" />
                </div>
                <dynamic-field [field]="abstractDonorTestSampleLabDataControls.Viscosity" [form]="donorTestSampleLabDataForm"></dynamic-field>
                <dynamic-field [field]="abstractDonorTestSampleLabDataControls.RoundCells" [form]="donorTestSampleLabDataForm"></dynamic-field>
                <dynamic-field [field]="abstractDonorTestSampleLabDataControls.CountTech" [form]="donorTestSampleLabDataForm"></dynamic-field>
            </div>
            <div>
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
                    Cancel
                </button>
            </div>
        </form>
    </div>
</div>
