import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IPassFailOption } from '@model/interfaces/pass-fail-option';

@Injectable({
    providedIn: 'root',
})
export class PassFailOptionService extends StaticMetaItemService<IPassFailOption> {
    constructor(public http: HttpClient) {
        super('PassFailOptionService', 'Option', 'OptionIds', '/meta-items/PassFailOption', http);
    }
}
