import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDonorHelloSignDocument } from '@model/interfaces/donor-hello-sign-document';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DonorHelloSignService {
    constructor(public http: HttpClient) {}

    sendHelloSignSignatureRequestEmail(donorId: number, templateId: number): Observable<null> {
        return this.http.get<null>(`/donors/hello-sign/${donorId}/${templateId}/signatureReminder`);
    }

    getDonorHelloSignDocument(donorId: number, templateId: number): Observable<Blob> {
        return this.http.get(`/donors/hello-sign/${donorId}/${templateId}`, { responseType: 'blob' as 'blob' });
    }

    updateDocumentSignature(donorId: number, templateId: number, signatureDate: Date): Observable<IDonorHelloSignDocument[]> {
        return this.http.put<IDonorHelloSignDocument[]>(`/donors/hello-sign/${donorId}/${templateId}/signed`, signatureDate);
    }

    clearDocumentSignature(donorId: number, templateId: number): Observable<IDonorHelloSignDocument[]> {
        return this.http.delete<IDonorHelloSignDocument[]>(`/donors/hello-sign/${donorId}/${templateId}/signed`);
    }
}
