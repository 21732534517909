import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UnitsOfMeasurement } from '@model/enums/units-of-measurement.enum';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DonorFamilyHistoryService } from '@angular-common/donor-application-forms/services/donor-family-history.service';
import { IFamilyHistory } from '@model/interfaces/family-history';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IDonorFamilyHistory } from '@model/interfaces/donor-family-history';
import { convertInchesToFeetAndInches } from '@angular-common/libraries/parse-height.library';

const CONFIG_CONTROLS = [
    'EyeColorId',
    'HairColorId',
    'HairTextureId',
    'SkinTypeId',
    'RaceId',
    'Height',
    'HeightUnitId',
    'Weight',
    'WeightUnitId',
    'EducationCompletedTypeId',
    'Occupation',
    'Skills',
];

@Component({
    selector: 'individual-family-history-detail',
    templateUrl: './individual-family-history.component.html',
})
export class IndividualFamilyHistoryDetailComponent implements OnInit {
    @Input('individual') individual: IFamilyHistory;
    @Input('metaData') metaData: IPersonalInformationMetaData;
    @Input() heightUnits: IUnitsOfMeasurement[];
    @Input() weightUnits: IUnitsOfMeasurement[];
    @Input() siblingIndex: number;
    @Input() sectionTitle: string;

    heightUnitId: number;
    isDeceased: boolean;

    // abstract controls
    abstractFamilyHistoryControls: any;
    FamilyHistoryForm: FormGroup;

    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;

    get isMetric(): boolean {
        return this.heightUnitId === UnitsOfMeasurement.Meters ? true : false;
    }

    get isSibling(): boolean {
        return this.siblingIndex !== null && this.siblingIndex !== undefined;
    }

    get isHalfSibling(): boolean {
        return !this.individual.IsFullSibling;
    }

    get adjustedHeight(): string {
        if (this.individual.HeightUnitId === UnitsOfMeasurement.FeetInches) {
            return convertInchesToFeetAndInches(this.individual.HeightValue);
        }
        return this.individual.HeightValue.toString();
    }

    get heightUnit(): string {
        return this.heightUnits.find((h) => h.Id === this.individual.HeightUnitId).Name;
    }

    get weightUnit(): string {
        return this.weightUnits.find((w) => w.Id === this.individual.WeightUnitId).Name;
    }

    get eyeColor(): string {
        return this.individual.EyeColorId ?
        this.metaData.EyeColors.find((e) => e.Id === this.individual.EyeColorId).Name :
        '';
    }

    get hairColor(): string {
        return this.individual.HairColorId ?
        this.metaData.HairColors.find((h) => h.Id === this.individual.HairColorId).Name :
        '';
    }

    get hairTexture(): string {
        return this.individual.HairTextureId ?
        this.metaData.HairTextures.find((h) => h.Id === this.individual.HairTextureId).Name :
        '';
    }

    get skinType(): string {
        return this.individual.SkinTypeId ?
        this.metaData.SkinTypes.find((s) => s.Id === this.individual.SkinTypeId).Name :
        '';
    }

    get race(): string {
        return this.individual.RaceId ?
        this.metaData.Races.find((r) => r.Id === this.individual.RaceId).Name :
        '';
    }

    constructor(
        private cdr: ChangeDetectorRef,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.heightUnitId = this.individual.HeightUnitId;
        this.isDeceased = this.individual.Deceased;
    }
}
