<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && inventory?.length > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Sales Order Inventory
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <entity-list
        [entities]="inventory"
        [entityListConfig]="entityListConfig"
        emptyMessage="Link donor(s) to select inventory"
        [total]="total"
    ></entity-list>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Sales Order Inventory</h4>
    <donor-select-available-inventory
        [donorIds]="donorIds"
        [productTypeId]="productTypeId"
        [vialStatusId]="vialStatusId"
        [selectedInventory]="inventory"
        [salesOrderId]="salesOrderId"
        [zoom]="false"
        (onCancelSearch)="isEditing = false"
        (onSelectionComplete)="onInventorySelected($event)"
    >
    </donor-select-available-inventory>
</div>
