import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ISelectionChangedEvent } from '@mt-ng2/entity-list-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IModalOptions } from '@mt-ng2/modal-module';

import { entityListModuleConfig } from '@common/shared.module';
import { ExtraParams } from '@common/extra-params/extra-params.library';

import { IDonorSample } from '@model/interfaces/donor-sample';
import { ClaimTypes } from '@model/ClaimTypes';
import { SampleTypes } from '@model/enums/sample-types.enum';
import { DonationStatuses } from '@model/enums/donation-statuses.enum';
import { IInventory } from '@model/interfaces/inventory';
import { BulkUpdateTypes } from '@model/enums/custom/bulk-update-types.enum';

import { DonorDonationsEntityListConfig } from './donor-donations.entity-list-config';
import { DonorSampleService } from '../../services/donor-sample.service';
import { InventoryVesselService } from '../../inventory/services/inventory-vessel.service';

@Component({
    selector: 'app-donor-donations',
    templateUrl: './donor-donations-list.component.html',
})
export class DonorDonationsListComponent implements OnInit {
    @Input() donorId: number;
    @Output() samplesUpdated = new EventEmitter<IDonorSample[]>();

    donorSamples: IDonorSample[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new DonorDonationsEntityListConfig();
    order = 'SampleDate';
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddDonorSample = false;
    isEditing = false;
    bulkUpdateTypes = BulkUpdateTypes;

    selectedDonations: IDonorSample[] = [];
    selectedDonationInventory: IInventory[] = [];
    updateDonations: boolean;
    updateSaveReady = false;
    updateType: BulkUpdateTypes;

    modalOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        padding: '35px',
        showCloseButton: true,
        showConfirmButton: false,
        width: '50%',
    };

    constructor(
        private donorSampleService: DonorSampleService,
        private claimsService: ClaimsService,
        private notificationService: NotificationsService,
        private inventoryService: InventoryVesselService,
    ) {}

    ngOnInit(): void {
        this.canAddDonorSample = this.claimsService.hasClaim(ClaimTypes.Donors_ProcessSamples, [ClaimValues.FullAccess]);
        this.getDonorSamples();
    }

    getDonorSamplesCall(): Observable<HttpResponse<IDonorSample[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = ExtraParams.build({
            DonorId: this.donorId,
            SampleTypeId: SampleTypes.Donation,
        });

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.donorSampleService.get(searchparams);
    }

    getDonorSamples(): void {
        this.getDonorSamplesCall().subscribe((answer) => {
            this.donorSamples = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            this.emitSamples();
        });
    }

    donorSampleSelected(event: ISelectionChangedEvent): void {
        this.selectedDonations = <IDonorSample[]>event.selectedEntities;
    }

    OnAddNewFinished(newDonation: IDonorSample): void {
        if (newDonation) {
            this.donorSamples.push(newDonation);
            this.emitSamples();
        }
        this.isEditing = false;
    }

    createDonation(): void {
        this.isEditing = true;
    }

    emitSamples(): void {
        this.samplesUpdated.emit(this.donorSamples);
    }

    saveBulkUpdate(): void {
        this.inventoryService.updateMultipleInventoryLocations(this.selectedDonationInventory).subscribe((answer) => {
            this.selectedDonations.map((d) => {
                switch (this.updateType) {
                    case BulkUpdateTypes.Approved:
                        d.DonationStatusId = DonationStatuses.ApprovedforRelease;
                        break;
                    case BulkUpdateTypes.Discard:
                        d.DonationStatusId = DonationStatuses.Discard;
                        break;
                    default:
                        break;
                }
            });
            this.bulkSaveUpdate();
        });
    }

    cancelBulkUpdate(): void {
        this.updateDonations = false;
        this.selectedDonationInventory = null;
    }

    onInventoryLocationEditFinished(updates: IInventory[]): void {
        this.selectedDonationInventory = updates;
    }

    discardSelected(): void {
        this.selectedDonations.map((d) => (d.DonationStatusId = DonationStatuses.Discard));
        this.bulkSaveUpdate();
    }

    bulkSaveUpdate(): void {
        this.donorSampleService.updateList(this.selectedDonations).subscribe((resp) => {
            this.selectedDonations = [];
            this.donorSamples = null;
            this.updateDonations = false;
            setTimeout((this.donorSamples = resp), 0);
            this.notificationService.success('Donations updated successfully');
        });
    }
}
