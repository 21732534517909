<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && donorSample.Id > 0"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Donation Info
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
        <span class="pull-right mr-4">
            <button class="btn btn-primary header-button" title="SHSBDF7 Summary of Records" (click)="downloadSHSBDF7(); $event.stopPropagation()">
                SOR
            </button>
            <button class="btn btn-primary header-button" title="SHSBDF8 Summary of Records (Canada)" (click)="downloadSHSBDF8(); $event.stopPropagation()">
                SOR (Canada)
            </button>
            <button class="btn btn-primary header-button" title="SHSBDF9 Summary of Records (Australia)" (click)="downloadSHSBDF9(); $event.stopPropagation()">
                SOR (International)
            </button>
        </span>
    </h4>
    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Donation Info</h4>
    <div class="col-md-12">
        <button
            class="btn btn-warning pull-right"
            style="margin: 5px"
            [disabled]="!eligibleForRelease"
            (click)="modalOptions.titleText = 'Discard'; bulkUpdateDonation = true; updateType = bulkUpdateTypes.Discard"
        >
            Discard
        </button>
        <button
            class="btn btn-primary pull-right"
            style="margin: 5px"
            [disabled]="!eligibleForRelease"
            (click)="modalOptions.titleText = 'Approve for Release'; bulkUpdateDonation = true; updateType = bulkUpdateTypes.Approved"
        >
            Approve for Release
        </button>
    </div>
    <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
    </app-dynamic-form>
</div>

<modal-wrapper *ngIf="bulkUpdateDonation" [options]="modalOptions" (cancelClick)="bulkUpdateDonation = false">
    <div>
        <div class="row col-md-12">
            Donation Date: {{ donorSample.SampleDate | date: medium }}
            <app-bulk-inventory-update
                [donation]="donorSample"
                [updateType]="updateType"
                (onEditFinished)="onInventoryLocationEditFinished($event)"
                (saveReady)="updateSaveReady = true"
            ></app-bulk-inventory-update>
            <br />
        </div>
        <div class="row col-md-12">
            <button (click)="saveBulkUpdate(); updateSaveReady = false" class="btn btn-flat btn-success" [disabled]="!updateSaveReady">
                Save Changes
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelBulkUpdate(); updateSaveReady = false">Cancel</button>
        </div>
    </div>
</modal-wrapper>
