import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService } from '@angular-common/services/common.service';
import { DonorPsychologicalScreeningDynamicControls } from '@model/partials/donor-psychological-screening.form-controls';
import { IDonor } from '@model/interfaces/donor';
import { IDonorPsychologicalScreening } from '@model/interfaces/donor-psychological-screening';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DonorService } from '../../services/donor.service';
import { IDonorPsychologicalScreeningDynamicControlsParameters } from '@model/form-controls/donor-psychological-screening.form-controls';

@Component({
    selector: 'app-donor-psychological-screening',
    styleUrls: ['./donor-psychological-screening.component.css'],
    templateUrl: './donor-psychological-screening.component.html',
})
export class DonorPsychologicalScreeningComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;

    @Output() emitIsEditing = new EventEmitter<boolean>();

    doubleClickIsDisabled: boolean;
    isEditing = false;
    isHovered: boolean;

    additionalParams: IDonorPsychologicalScreeningDynamicControlsParameters;
    config: IDynamicFormConfig = { formObject: [] };

    constructor(private optionService: CommonService, private donorService: DonorService, private notificaitionService: NotificationsService) {}

    ngOnInit(): void {
        this.optionService.getPsychologicalScreeningStatuses().subscribe((psychologicalScreeningStatuses) => {
            this.additionalParams = { psychologicalScreeningStatuses };
            this.setConfig();
        });
    }

    setConfig(): void {
        const controls = new DonorPsychologicalScreeningDynamicControls(
            this?.donor?.DonorPsychologicalScreening ?? <IDonorPsychologicalScreening>{},
            this.additionalParams,
        );
        {
            const {
                Form: {
                    PsychCompletedDate,
                    AnzicaRecievedDate,
                    AnzicaSentDate,
                    EvalInstructionsSentDate,
                    MmpiCompleteDate,
                    PsychologicalScreeningStatusId,
                    ReportReceivedDate,
                },
            } = controls;
            this.config.formObject = [
                PsychCompletedDate,
                EvalInstructionsSentDate,
                MmpiCompleteDate,
                ReportReceivedDate,
                PsychologicalScreeningStatusId,
                AnzicaSentDate,
                AnzicaRecievedDate,
            ];
        }
        {
            const {
                View: {
                    PsychCompletedDate,
                    AnzicaRecievedDate,
                    AnzicaSentDate,
                    EvalInstructionsSentDate,
                    MmpiCompleteDate,
                    PsychologicalScreeningStatusId,
                    ReportReceivedDate,
                    ModifiedById,
                    DateModified,
                    StatusChangedDate,
                },
            } = controls;
            this.config.viewOnly = [
                PsychCompletedDate,
                EvalInstructionsSentDate,
                MmpiCompleteDate,
                ReportReceivedDate,
                PsychologicalScreeningStatusId,
                StatusChangedDate,
                AnzicaSentDate,
                AnzicaRecievedDate,
                ModifiedById,
                DateModified,
            ];
        }
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            const { Id, DonorPsychologicalScreeningId } = this.donor;
            if (DonorPsychologicalScreeningId) {
                this.donorService
                    .patchPsychScreen(Id, { Id: DonorPsychologicalScreeningId, ...form.value.DonorPsychologicalScreening })
                    .subscribe(this.onSuccess);
            } else {
                this.donorService.postPsychScreen(Id, form.value.DonorPsychologicalScreening).subscribe(this.onSuccess);
            }
        } else {
            this.notificaitionService.error('Please check the form and try again');
        }
    }

    onSuccess = (resp: IDonorPsychologicalScreening) => {
        this.donor.DonorPsychologicalScreeningId = resp.Id;
        this.donor.DonorPsychologicalScreening = resp;
        this.doubleClickIsDisabled = false;
        this.isEditing = false;
        this.setConfig();
        this.donorService.emitChange(this.donor);
        this.notificaitionService.success('Psychological Screening Updated');
        this.emitIsEditing.emit(false);
    }

    edit(): void {
        this.isEditing = true;
        this.emitIsEditing.emit(true);
    }

    cancelClick(): void {
        this.isEditing = false;
        this.emitIsEditing.emit(false);
    }
}
