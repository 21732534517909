import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBloodType } from '@model/interfaces/blood-type';
import { StaticMetaItemService } from '@mt-ng2/base-service';

@Injectable({
    providedIn: 'root',
})
export class BloodTypeService extends StaticMetaItemService<IBloodType> {
    constructor(public http: HttpClient) {
        super('BloodTypeService', 'Type', 'TypeIds', '/meta-items/BloodType', http);
    }
}
