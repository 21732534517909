<div class="miles-card padded">
    <h4>
        Inventory Details
        <div class="pull-right" *ngIf="canEdit">
            <button type="button" class="btn btn-flat btn-success" (click)="addInventoryPosition()">
                <span class="fa fa-plus"></span> Add Single
            </button>
            <button type="button" class="btn btn-flat btn-success" (click)="addMultipleInventories()">
                <span class="fa fa-plus"></span> Add Multiple
            </button>
        </div>

        <div style="clear: both"></div>
    </h4>
    <div *ngIf="discardedInventory?.length">
        <b>{{ discardedInventory?.length }} Vials Discarded</b>
    </div>
    <div *ngIf="showAddMultipleForm">
        <donor-multiple-inventory-locations-form
            [donation]="donation"
            (cancel)="cancelMultiple()"
            (savedInventories)="pushMultipleInventories($event)"
        ></donor-multiple-inventory-locations-form>
        <hr />
    </div>
    <div *ngIf="inventory?.length">
        <div *ngFor="let inv of inventory; let last = last">
            <donor-inventory-location-form
                [inventory]="inv"
                [canEdit]="canEdit"
                [donation]="donation"
                (onCancel)="clearEmptyInventory()"
                (onDelete)="deleteInventory(inv)"
            ></donor-inventory-location-form>
            <hr *ngIf="!last" />
        </div>
    </div>
    <div *ngIf="!inventory?.length && !showAddMultipleForm && !discardedInventory?.length">
        <span><em>No Inventory available</em></span>
    </div>
</div>
