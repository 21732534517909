import { DateParts } from "@mt-ng2/date-module";

const MONTHS_TO_RELEASE_DATE = 6;

export function getEligibleSampleDate(date?: Date): Date {
    let result = null;

    if (date) {
        const newDate = new Date(date);
        result = newDate.mtDate.add(MONTHS_TO_RELEASE_DATE, DateParts.months).date;
    }
    return result;
}
