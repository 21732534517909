import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonorFamilyHistory } from '../interfaces/donor-family-history';
import { IFamilyHistory } from '../interfaces/family-history';

export interface IDonorFamilyHistoryDynamicControlsParameters {
    formGroup?: string;
    fathers?: IFamilyHistory[];
    mothers?: IFamilyHistory[];
    maternalGrandmothers?: IFamilyHistory[];
    maternalGrandfathers?: IFamilyHistory[];
    paternalGrandfathers?: IFamilyHistory[];
    paternalGrandmothers?: IFamilyHistory[];
}

export class DonorFamilyHistoryDynamicControls {

    formGroup: string;
    fathers: IFamilyHistory[];
    mothers: IFamilyHistory[];
    maternalGrandmothers: IFamilyHistory[];
    maternalGrandfathers: IFamilyHistory[];
    paternalGrandfathers: IFamilyHistory[];
    paternalGrandmothers: IFamilyHistory[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorfamilyhistory?: IDonorFamilyHistory, additionalParameters?: IDonorFamilyHistoryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorFamilyHistory';
        this.fathers = additionalParameters && additionalParameters.fathers || undefined;
        this.mothers = additionalParameters && additionalParameters.mothers || undefined;
        this.maternalGrandmothers = additionalParameters && additionalParameters.maternalGrandmothers || undefined;
        this.maternalGrandfathers = additionalParameters && additionalParameters.maternalGrandfathers || undefined;
        this.paternalGrandfathers = additionalParameters && additionalParameters.paternalGrandfathers || undefined;
        this.paternalGrandmothers = additionalParameters && additionalParameters.paternalGrandmothers || undefined;

        this.Form = {
            Completed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Completed',
                name: 'Completed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilyhistory && this.donorfamilyhistory.Completed || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donorfamilyhistory && this.donorfamilyhistory.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorfamilyhistory && this.donorfamilyhistory.DateModified || null,
            }),
            FatherId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Father',
                name: 'FatherId',
                options: this.fathers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorfamilyhistory && this.donorfamilyhistory.FatherId || null,
            }),
            MaternalGrandfatherId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Maternal Grandfather',
                name: 'MaternalGrandfatherId',
                options: this.maternalGrandfathers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorfamilyhistory && this.donorfamilyhistory.MaternalGrandfatherId || null,
            }),
            MaternalGrandmotherId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Maternal Grandmother',
                name: 'MaternalGrandmotherId',
                options: this.maternalGrandmothers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorfamilyhistory && this.donorfamilyhistory.MaternalGrandmotherId || null,
            }),
            MotherId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mother',
                name: 'MotherId',
                options: this.mothers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorfamilyhistory && this.donorfamilyhistory.MotherId || null,
            }),
            PaternalGrandfatherId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Paternal Grandfather',
                name: 'PaternalGrandfatherId',
                options: this.paternalGrandfathers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorfamilyhistory && this.donorfamilyhistory.PaternalGrandfatherId || null,
            }),
            PaternalGrandmotherId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Paternal Grandmother',
                name: 'PaternalGrandmotherId',
                options: this.paternalGrandmothers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donorfamilyhistory && this.donorfamilyhistory.PaternalGrandmotherId || null,
            }),
        };

        this.View = {
            Completed: new DynamicLabel(
                'Completed',
                this.donorfamilyhistory && this.donorfamilyhistory.Completed || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.donorfamilyhistory && this.donorfamilyhistory.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.donorfamilyhistory && this.donorfamilyhistory.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            FatherId: new DynamicLabel(
                'Father',
                getMetaItemValue(this.fathers, this.donorfamilyhistory && this.donorfamilyhistory.hasOwnProperty('FatherId') && this.donorfamilyhistory.FatherId !== null ? this.donorfamilyhistory.FatherId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            MaternalGrandfatherId: new DynamicLabel(
                'Maternal Grandfather',
                getMetaItemValue(this.maternalGrandfathers, this.donorfamilyhistory && this.donorfamilyhistory.hasOwnProperty('MaternalGrandfatherId') && this.donorfamilyhistory.MaternalGrandfatherId !== null ? this.donorfamilyhistory.MaternalGrandfatherId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            MaternalGrandmotherId: new DynamicLabel(
                'Maternal Grandmother',
                getMetaItemValue(this.maternalGrandmothers, this.donorfamilyhistory && this.donorfamilyhistory.hasOwnProperty('MaternalGrandmotherId') && this.donorfamilyhistory.MaternalGrandmotherId !== null ? this.donorfamilyhistory.MaternalGrandmotherId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            MotherId: new DynamicLabel(
                'Mother',
                getMetaItemValue(this.mothers, this.donorfamilyhistory && this.donorfamilyhistory.hasOwnProperty('MotherId') && this.donorfamilyhistory.MotherId !== null ? this.donorfamilyhistory.MotherId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            PaternalGrandfatherId: new DynamicLabel(
                'Paternal Grandfather',
                getMetaItemValue(this.paternalGrandfathers, this.donorfamilyhistory && this.donorfamilyhistory.hasOwnProperty('PaternalGrandfatherId') && this.donorfamilyhistory.PaternalGrandfatherId !== null ? this.donorfamilyhistory.PaternalGrandfatherId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            PaternalGrandmotherId: new DynamicLabel(
                'Paternal Grandmother',
                getMetaItemValue(this.paternalGrandmothers, this.donorfamilyhistory && this.donorfamilyhistory.hasOwnProperty('PaternalGrandmotherId') && this.donorfamilyhistory.PaternalGrandmotherId !== null ? this.donorfamilyhistory.PaternalGrandmotherId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
