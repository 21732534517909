import { Component } from '@angular/core';
import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';

import { AbstractLabDataFormComponent } from '../base-components/abstract-lab-data-form.component';

@Component({
    selector: 'app-donor-post-gradient',
    templateUrl: './post-gradient.component.html',
})
export class PostGradientComponent extends AbstractLabDataFormComponent {
    sectionId = DonorTestSampleLabDataSections.PostGradient;
    volumeLabelText = '<label>Volume<label>';
}
