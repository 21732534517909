import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IOutcome } from '@model/interfaces/outcome';

export const emptySalesOrderOutcome: IOutcome = {
    Comment: null,
    DateUs: null,
    FetalSacCount: null,
    FhbCount: 0,
    FollowUpDate: null,
    FollowUpReason: null,
    HcgDate: null,
    HcgValue: 0,
    Id: 0,
    IsFinalized: false,
    OutcomeOptionId: null,
    ThawingId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class SalesOrderOutcomeService extends BaseService<IOutcome> {
    constructor(public http: HttpClient) {
        super('/outcomes', http);
    }

    getEmptySalesOrderOutcome(thawingId: number): IOutcome {
        const newEmptyOutcome = { ...emptySalesOrderOutcome };
        newEmptyOutcome.ThawingId = thawingId;
        return newEmptyOutcome;
    }
}
