import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { BloodWorkDetailDynamicControlsPartial } from '@model/partials/blood-work-detail-partial.form-controls';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDonorOngoingScreening } from '@model/interfaces/donor-ongoing-screening';
import { ScreeningResultOptionservice } from '@common/services/screening-result-option.service';
import { SyphilisTestService } from '../services/syphilis-test.service';
import { BloodWorkDetailService } from '../services/blood-work-detail.service';
import { ScreeningResultOptions as AO } from '@model/enums/screening-result-options.enum';
import { PassFailOptionService } from '../services/pass-fail-option.service';

@Component({
    selector: 'app-blood-work-detail',
    templateUrl: './blood-work-detail.component.html',
})
export class BloodWorkDetailComponent implements OnInit {
    @Input() donorOngoingScreening: IDonorOngoingScreening;
    @Input() canEdit: boolean;

    // abstract controls
    abstractBloodWorkDetailControls: any;

    bloodWorkDetailForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private ScreeningResultOptionservice: ScreeningResultOptionservice,
        private syphilisTestService: SyphilisTestService,
        private bloodWorkDetailService: BloodWorkDetailService,
        private passFailOptionService: PassFailOptionService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.ScreeningResultOptionservice.getItems(),
            this.syphilisTestService.getItems(),
            this.passFailOptionService.getItems(),
            this.bloodWorkDetailService.getById(this.donorOngoingScreening.Id),
        ]).subscribe(([, , , detail]) => {
            this.donorOngoingScreening.BloodWorkDetail = detail ?? this.bloodWorkDetailService.getEmptyBloodWorkDetail(this.donorOngoingScreening);
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.bloodWorkDetailForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        const bloodWorkDetail = this.donorOngoingScreening.BloodWorkDetail;
        this.abstractBloodWorkDetailControls = new BloodWorkDetailDynamicControlsPartial(
            bloodWorkDetail,
            {
                antiHepCS: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                chlamydiaNatOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                cmvIggResults: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                cmvIgmResults: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                cmvOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive]),
                cmvTotalAntibodyResults: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                formGroup: 'BloodWorkDetail',
                gonorrheaNatOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                hbvNatOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                hcvNatOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                hepBCoreOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                hepBsAgOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                hiv102AbOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                hiv1NatOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                htlvIAndIiOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive]),
                syphilisOutcomes: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult]),
                syphilisTests: this.syphilisTestService.items,
                wnvNats: this.ScreeningResultOptionservice.getOptions([AO.Negative, AO.Positive, AO.NoResult, AO.Fail]),
            },
            this.passFailOptionService.items,
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            BloodWorkDetail: this.fb.group({}),
        });
    }

    fdaLabDateChanges(value: any): void {
        const bloodWorkDetailsFormControls = (this.bloodWorkDetailForm.controls.BloodWorkDetail as FormGroup).controls;
        const autoSetDateFields = [
            'HepBsAgDrawDate',
            'HepBCoreDrawDate',
            'Hiv102AbDate',
            'Hiv1NatDate',
            'HbvNatDrawDate',
            'HcvNatDrawDate',
            'AntiHepCDrawDate',
            'SyphilisDrawDate',
            'ChlamydiaNatDrawDate',
            'GonorrheaNatDrawDate',
            'CmvDrawDate',
            'CmvTotalAntibodyDrawDate',
            'CmvIggDrawDate',
            'CmvIgmDrawDate',
            'HtlvIAndIiDrawDate',
            'WnvNatDrawDate',
        ];
        autoSetDateFields.forEach((field) => {
            bloodWorkDetailsFormControls[field].patchValue(value);
        });
        this.cdr.detectChanges();
    }

    formSubmitted(): void {
        if (this.bloodWorkDetailForm.valid) {
            const bloodWorkDetail = Object.assign({}, this.bloodWorkDetailForm.value.BloodWorkDetail, { Id: this.donorOngoingScreening.Id });
            this.bloodWorkDetailService.saveBloodWorkDetail(bloodWorkDetail).subscribe((answer) => {
                this.donorOngoingScreening.BloodWorkDetail = Object.assign({}, this.donorOngoingScreening.BloodWorkDetail, answer);
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.bloodWorkDetailForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.isEditing = false;
        this.enableDoubleClick();
        this.createForm();
        this.notificationsService.success('Blood work detail saved successfully.');
    }
}
