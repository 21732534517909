import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDonorSampleLabData } from '@model/interfaces/donor-sample-lab-data';
import { LabDataBaseService } from './abstract-lab-data.service';

export const EmptyDonorTestSampleLabData: IDonorSampleLabData = {
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class DonorSampleLabDataService extends LabDataBaseService<IDonorSampleLabData> {
    constructor(public http: HttpClient) {
        super('/donor-samples/lab-data', http);
    }

    getEmptyLabData(testSampleId = 0): IDonorSampleLabData {
        return { ...EmptyDonorTestSampleLabData, Id: testSampleId };
    }
}
