import { Component, Input, OnInit } from '@angular/core';

import { IInventory } from '@model/interfaces/inventory';
import { IDonor } from '@model/interfaces/donor';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { InventoryVesselService } from '../services/inventory-vessel.service';
import { DonationStatuses } from '@model/enums/donation-statuses.enum';
import { DonorSampleService } from '../../../donors/services/donor-sample.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-inventory-info',
    templateUrl: './inventory-info.component.html',
})
export class InventoryInfoComponent implements OnInit {
    @Input() donation: IDonorSample;
    @Input() donor: IDonor;
    @Input() canEdit: boolean;

    discardedInventory: IInventory[];
    inventory: IInventory[];
    showAddMultipleForm = false;
    retrievalStepRetrievalDate: Date = null;

    private newInventoryEntry: IInventory = {
        CaneId: null,
        Comments: null,
        DonationSampleId: null,
        Id: 0,
        ReserveForResearch: null,
        SamplePrepMethodId: null,
        VialNumber: 0,
    };

    constructor(
        private donationService: DonorSampleService,
        private inventoryService: InventoryVesselService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.donationService.getInventoryDetails(this.donation.Id).subscribe((inventory) => {
            this.inventory = inventory;
            if (this.donation.DonationStatusId === DonationStatuses.Discard) {
                this.discardedInventory = this.inventory.filter((i) => i.Discarded);
                this.inventory = this.inventory.filter((i) => !i.Discarded);
            }
            if (!this.inventory.length && !this.discardedInventory?.length) {
                this.addMultipleInventories();
            }
        });
        this.inventoryService.inventoryUpdates.subscribe(() => {
            if (this.inventory?.length) {
                this.donationService.getInventoryDetails(this.donation.Id).subscribe((inv) => {
                    this.inventory = inv;
                });
            }
        });
    }

    addInventoryPosition(): void {
        this.inventory.unshift(this.getNewInventoryEntry(this.donation));
    }

    addMultipleInventories(): void {
        this.showAddMultipleForm = this.canEdit;
    }

    cancelMultiple(): void {
        this.showAddMultipleForm = false;
    }

    pushMultipleInventories(event: IInventory[]): void {
        this.inventory.unshift(...event);
    }

    getNewInventoryEntry(donation: IDonorSample): IInventory {
        return { ...this.newInventoryEntry, DonationSampleId: donation.Id };
    }

    clearEmptyInventory(): void {
        this.inventory = this.inventory.filter((i) => i.Id);
    }

    deleteInventory(inventory: IInventory): void {
        this.inventoryService.delete(inventory.Id).subscribe(() => {
            this.inventory = this.inventory.filter((i) => i.Id !== inventory.Id);
            this.notificationsService.success('Inventory deleted');
        });
    }
}
