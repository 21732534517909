import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

/**
 *  Utility class for MtSearchFilterItem
 */
export abstract class SearchFilterItems {
    /**
     * Gets the ids of the selected items from a MtSearchFilterItem[]
     * @param items
     * @returns number[]
     */
    static getSelectedIds(items: MtSearchFilterItem[]): number[] {
        return items.filter((item) => item.Selected).map((item) => item.Item.Id);
    }
}
