import { DonorWhoAreYouDynamicControls, IDonorWhoAreYouDynamicControlsParameters } from '../form-controls/donor-who-are-you.form-controls';
import { IDonorWhoAreYou } from '../interfaces/donor-who-are-you';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { FormYesNoButtonComponent } from '@angular-common/components/form-yes-no-button.component';
import { BuildDonorFormLabel } from '@angular-common/libraries/build-donor-form-label.library';

export class DonorWhoAreYouDynamicControlsPartial extends DonorWhoAreYouDynamicControls {

    constructor(donorwhoareyouPartial?: IDonorWhoAreYou, additionalParameters?: IDonorWhoAreYouDynamicControlsParameters) {
        super(donorwhoareyouPartial, additionalParameters);

        (<DynamicField>this.Form.BodyStructureId).validation = [Validators.required];
        (<DynamicField>this.Form.BodyStructureId).validators = { required: true};

        (<DynamicField>this.Form.EyeColorId).validation = [Validators.required];
        (<DynamicField>this.Form.EyeColorId).validators = { required: true};

        (<DynamicField>this.Form.NaturalHairColorId).validation = [Validators.required];
        (<DynamicField>this.Form.NaturalHairColorId).validators = { required: true};

        (<DynamicField>this.Form.HairTextureId).validation = [Validators.required];
        (<DynamicField>this.Form.HairTextureId).validators = { required: true};

        (<DynamicField>this.Form.SkinTypeId).validation = [Validators.required];
        (<DynamicField>this.Form.SkinTypeId).validators = { required: true};

        (<DynamicField>this.Form.RaceId).validation = [Validators.required];
        (<DynamicField>this.Form.RaceId).validators = { required: true};

        // Ancestry Information
        (<DynamicField>this.Form.OtherAncestryMother).validation = [Validators.required];
        (<DynamicField>this.Form.OtherAncestryMother).validators = { required: true};

        (<DynamicField>this.Form.OtherAncestryFather).validation = [Validators.required];
        (<DynamicField>this.Form.OtherAncestryFather).validators = { required: true};

        // Education Information
        (<DynamicField>this.Form.EducationCompletedTypeId).validation = [Validators.required];
        (<DynamicField>this.Form.EducationCompletedTypeId).validators = { required: true};

        (<DynamicField>this.Form.HighSchoolGpaId).validation = [Validators.required];
        (<DynamicField>this.Form.HighSchoolGpaId).validators = { required: true};

        (<DynamicField>this.Form.CollegeGpaId).validation = [Validators.required];
        (<DynamicField>this.Form.CollegeGpaId).validators = { required: true};

        (<DynamicField>this.Form.CurrentOccupation).validation = [Validators.required];
        (<DynamicField>this.Form.CurrentOccupation).validators = { required: true};

        // Introduce Yourself
        (<DynamicField>this.Form.TalentsAbilities).validation = [Validators.required, Validators.minLength(100), Validators.maxLength(3000)];
        (<DynamicField>this.Form.TalentsAbilities).validators = { required: true, minlength: 100, maxlength: 3000};
        (<DynamicField>this.Form.TalentsAbilities).label = 'Talents / Abilities';

        (<DynamicField>this.Form.Favorites).validation = [Validators.required, Validators.minLength(100), Validators.maxLength(3000)];
        (<DynamicField>this.Form.Favorites).validators = { required: true, minlength: 100, maxlength: 3000};
        (<DynamicField>this.Form.Favorites).label = 'Favorite (Books, Food, Music)';

        (<DynamicField>this.Form.CharacterDescription).validation = [Validators.required, Validators.minLength(100), Validators.maxLength(3000)];
        (<DynamicField>this.Form.CharacterDescription).validators = { required: true, minlength: 100, maxlength: 3000};
        (<DynamicField>this.Form.CharacterDescription).label = 'How would a family member or friend describe your character?';

        (<DynamicField>this.Form.SpeaksOtherLanguages).validation = [Validators.required, Validators.maxLength(3000)];
        (<DynamicField>this.Form.SpeaksOtherLanguages).validators = { required: true, maxlength: 3000};
        (<DynamicField>this.Form.SpeaksOtherLanguages).label = 'Do you speak any other languages? If yes, which ones?';

        (<DynamicField>this.Form.WhyInterestedInBecomingSpermDonor).validation = [Validators.required, Validators.minLength(100), Validators.maxLength(3000)];
        (<DynamicField>this.Form.WhyInterestedInBecomingSpermDonor).validators = { required: true, minlength: 100, maxlength: 3000};
        (<DynamicField>this.Form.WhyInterestedInBecomingSpermDonor).label = 'Why are you interested in becoming a sperm donor?';

        (<DynamicField>this.Form.Goals).validation = [Validators.required, Validators.minLength(100), Validators.maxLength(3000)];
        (<DynamicField>this.Form.Goals).validators = { required: true, minlength: 100, maxlength: 3000};
        (<DynamicField>this.Form.Goals).label = 'What would some of your personal and/or career goals be?';

        (<DynamicField>this.Form.HobbiesInterests).validation = [Validators.required, Validators.minLength(100), Validators.maxLength(3000)];
        (<DynamicField>this.Form.HobbiesInterests).validators = { required: true, minlength: 100, maxlength: 3000};
        (<DynamicField>this.Form.HobbiesInterests).label = 'What are your current hobbies and interests?';

        (<DynamicField>this.Form.ExerciseRoutine).validation = [Validators.required, Validators.minLength(100), Validators.maxLength(3000)];
        (<DynamicField>this.Form.ExerciseRoutine).validators = { required: true, minlength: 100, maxlength: 3000};
        (<DynamicField>this.Form.ExerciseRoutine).label = 'What is your exercise routine?';

        (<DynamicField>this.Form.ExtracurricularActivities).validation = [Validators.required, Validators.minLength(100), Validators.maxLength(3000)];
        (<DynamicField>this.Form.ExtracurricularActivities).validators = { required: true, minlength: 100, maxlength: 3000};
        (<DynamicField>this.Form.ExtracurricularActivities).label = 'What extracurricular activities did you participate in high school and college?';

        // Personal Medical History
        (<DynamicField>this.Form.HasPlasticSurgery).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HasPlasticSurgery).labelHtml = BuildDonorFormLabel(`Have you ever had plastic surgery?`);
        (<DynamicField>this.Form.HasPlasticSurgery).value = donorwhoareyouPartial.HasPlasticSurgery;
        (<DynamicField>this.Form.HasPlasticSurgery).validation = [Validators.required];
        (<DynamicField>this.Form.HasPlasticSurgery).validators = { required: true};

        (<DynamicField>this.Form.PlasticSurgeryTypeAndYear).validation = [Validators.required];
        (<DynamicField>this.Form.PlasticSurgeryTypeAndYear).validators = { required: true};

        (<DynamicField>this.Form.HaveChildren).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.HaveChildren).labelHtml = BuildDonorFormLabel(`Do you have children?`);
        (<DynamicField>this.Form.HaveChildren).value = donorwhoareyouPartial.HaveChildren;
        (<DynamicField>this.Form.HaveChildren).validation = [Validators.required];
        (<DynamicField>this.Form.HaveChildren).validators = { required: true};

        (<DynamicField>this.Form.NumberOfChildren).validation = [Validators.required];
        (<DynamicField>this.Form.NumberOfChildren).validators = { required: true};

        (<DynamicField>this.Form.BloodTypeId).validation = [Validators.required];
        (<DynamicField>this.Form.BloodTypeId).validators = { required: true};

        (<DynamicField>this.Form.RhFactorId).validation = [Validators.required];
        (<DynamicField>this.Form.RhFactorId).validators = { required: true};

        (<DynamicField>this.Form.BleedingDisorders).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.BleedingDisorders).labelHtml = BuildDonorFormLabel(`Do you have any bleeding disorders?`);
        (<DynamicField>this.Form.BleedingDisorders).value = donorwhoareyouPartial.BleedingDisorders;
        (<DynamicField>this.Form.BleedingDisorders).validation = [Validators.required];
        (<DynamicField>this.Form.BleedingDisorders).validators = { required: true};

        // Psychological Questionnaire
        (<DynamicField>this.Form.ExperiencedTraumaticEvent).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.ExperiencedTraumaticEvent).labelHtml = BuildDonorFormLabel(`Have you been the victim of a serious traumatic event?`);
        (<DynamicField>this.Form.ExperiencedTraumaticEvent).value = donorwhoareyouPartial.ExperiencedTraumaticEvent;
        (<DynamicField>this.Form.ExperiencedTraumaticEvent).validation = [Validators.required];
        (<DynamicField>this.Form.ExperiencedTraumaticEvent).validators = { required: true};

        (<DynamicField>this.Form.OtherTraumaticEvent).validation = [Validators.required];
        (<DynamicField>this.Form.OtherTraumaticEvent).validators = { required: true };
        (<DynamicField>this.Form.OtherTraumaticEvent).label = 'What type of event?';
        (<DynamicField>this.Form.OtherTraumaticEvent).labelHtml = BuildDonorFormLabel('What type of event?');

        (<DynamicField>this.Form.TraumaticEventId).validation = [Validators.required];
        (<DynamicField>this.Form.TraumaticEventId).validators = { required: true};

        (<DynamicField>this.Form.SeriousDepression).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.SeriousDepression).labelHtml = BuildDonorFormLabel(`Have you or an immediate family member experienced or been diagnosed with serious depression lasting a month or more?`);
        (<DynamicField>this.Form.SeriousDepression).value = donorwhoareyouPartial.SeriousDepression;
        (<DynamicField>this.Form.SeriousDepression).validation = [Validators.required];
        (<DynamicField>this.Form.SeriousDepression).validators = { required: true};

        (<DynamicField>this.Form.MoodSwings).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.MoodSwings).labelHtml = BuildDonorFormLabel(`Have you or an immediate family member experienced or been diagnosed with severe mood swings ranging from blue to extraordinarily happy?`);
        (<DynamicField>this.Form.MoodSwings).value = donorwhoareyouPartial.MoodSwings;
        (<DynamicField>this.Form.MoodSwings).validation = [Validators.required];
        (<DynamicField>this.Form.MoodSwings).validators = { required: true};

        (<DynamicField>this.Form.Anxiety).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.Anxiety).labelHtml = BuildDonorFormLabel(`Have you or an immediate family member experienced or been diagnosed with anxiety?`);
        (<DynamicField>this.Form.Anxiety).value = donorwhoareyouPartial.Anxiety;
        (<DynamicField>this.Form.Anxiety).validation = [Validators.required];
        (<DynamicField>this.Form.Anxiety).validators = { required: true};

        (<DynamicField>this.Form.Paranoia).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.Paranoia).labelHtml = BuildDonorFormLabel(`Have you or an immediate family member had thoughts of being watched or controlled, hearing voices, or having trouble knowing what is real or not?`);
        (<DynamicField>this.Form.Paranoia).value = donorwhoareyouPartial.Paranoia;
        (<DynamicField>this.Form.Paranoia).validation = [Validators.required];
        (<DynamicField>this.Form.Paranoia).validators = { required: true};

        (<DynamicField>this.Form.UnderPsychiatricCare).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.UnderPsychiatricCare).labelHtml = BuildDonorFormLabel(`Have you been under the care of a psychiatrist?`);
        (<DynamicField>this.Form.UnderPsychiatricCare).value = donorwhoareyouPartial.UnderPsychiatricCare;
        (<DynamicField>this.Form.UnderPsychiatricCare).validation = [Validators.required];
        (<DynamicField>this.Form.UnderPsychiatricCare).validators = { required: true};

        (<DynamicField>this.Form.TreatedForDrugAlcoholAbuse).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.TreatedForDrugAlcoholAbuse).labelHtml = BuildDonorFormLabel(`Have you ever received treatment for drug/alcohol abuse?`);
        (<DynamicField>this.Form.TreatedForDrugAlcoholAbuse).value = donorwhoareyouPartial.TreatedForDrugAlcoholAbuse;
        (<DynamicField>this.Form.TreatedForDrugAlcoholAbuse).validation = [Validators.required];
        (<DynamicField>this.Form.TreatedForDrugAlcoholAbuse).validators = { required: true};

        (<DynamicField>this.Form.KnownGeneticConditions).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.KnownGeneticConditions).labelHtml = BuildDonorFormLabel(`Are there any known genetic conditions or birth defects in your family?`);
        (<DynamicField>this.Form.KnownGeneticConditions).value = donorwhoareyouPartial.KnownGeneticConditions;
        (<DynamicField>this.Form.KnownGeneticConditions).validation = [Validators.required];
        (<DynamicField>this.Form.KnownGeneticConditions).validators = { required: true};
        (<DynamicField>this.Form.GeneticConditions).validation = [Validators.required];
        (<DynamicField>this.Form.GeneticConditions).validators = { required: true};

    }
}
