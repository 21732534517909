<div class="miles-card padded">
    <div>
        <h4>
            Blood Work Detail
            <span *ngIf="canEdit && !isEditing" class="pull-right">
                <i class="fa fa-lg fa-edit" (click)="edit()"></i>
            </span>
        </h4>
    </div>
    <div *ngIf="formCreated">
        <div *ngIf="!isEditing">
            <dynamic-label [field]="abstractBloodWorkDetailControls.FdaLabsDate"></dynamic-label>
            <dynamic-label [field]="abstractBloodWorkDetailControls.AdditionalLabs"></dynamic-label>
            <dynamic-label [field]="abstractBloodWorkDetailControls.SorReceivedDate"></dynamic-label>
            <dynamic-label [field]="abstractBloodWorkDetailControls.FdaBookDate"></dynamic-label>
            <div class="display-padded">
                <span><b>Hep BsAg Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.HepBsAgOutcome?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.HepBsAgDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>Hep B Core Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.HepBCoreOutcome?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.HepBCoreDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>HIV 1/0/2 Ab Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.Hiv102AbOutcome?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.Hiv102AbDate"></dynamic-label>
            <div class="display-padded">
                <span><b>HIV 1 NAT Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.Hiv1NatOutcome?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.Hiv1NatDate"></dynamic-label>
            <div class="display-padded">
                <span><b>HBV NAT Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.HbvNatOutcome?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.HbvNatDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>HCV NAT Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.HcvNatOutcome?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.HcvNatDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>Anti Hep C: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.AntiHepC?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.AntiHepCDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>Syphilis Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.SyphilisOutcome?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.SyphilisDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>Syphilis Test: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.SyphilisTest?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.ChlamydiaNatDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>Chlamydia NAT Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.ChlamydiaNatOutcome?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.GonorrheaNatDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>Gonorrhea NAT Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.GonorrheaNatOutcome?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.WnvNatDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>WNV NAT: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.WnvNat?.Name }}</span>
            </div>
            <div class="display-padded">
                <span><b>CMV Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.CmvOutcome?.Name }}</span>
            </div>
            <div class="display-padded">
                <span><b>CMV Status: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.PassFailOption?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.CmvDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>CMV Total Antibody Result: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.CmvTotalAntibodyResult?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.CmvTotalAntibodyDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>CMV IgG Result: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.CmvIggResult?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.CmvIggDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>CMV IgM Result: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.CmvIgmResult?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.CmvIgmDrawDate"></dynamic-label>
            <div class="display-padded">
                <span><b>HTLV I and II Outcome: </b></span>
                <span>{{ donorOngoingScreening.BloodWorkDetail?.HtlvIAndIiOutcome?.Name }}</span>
            </div>
            <dynamic-label [field]="abstractBloodWorkDetailControls.HtlvIAndIiDrawDate"></dynamic-label>
        </div>
        <form *ngIf="isEditing" [formGroup]="bloodWorkDetailForm" (ngSubmit)="formSubmitted()">
            <dynamic-field
                [field]="abstractBloodWorkDetailControls.FdaLabsDate"
                [form]="bloodWorkDetailForm"
                (valueChanges)="fdaLabDateChanges($event)"
            ></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.AdditionalLabs" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.SorReceivedDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.FdaBookDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.HepBsAgOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.HepBsAgDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.HepBCoreOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.HepBCoreDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.Hiv102AbOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.Hiv102AbDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.Hiv1NatOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.Hiv1NatDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.HbvNatOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.HbvNatDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.HcvNatOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.HcvNatDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.AntiHepCId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.AntiHepCDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.SyphilisOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.SyphilisDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.SyphilisTestId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.ChlamydiaNatOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.ChlamydiaNatDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.GonorrheaNatOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.GonorrheaNatDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.WnvNatId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.WnvNatDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.CmvOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.CmvStatusId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.CmvDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.CmvTotalAntibodyResultId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.CmvTotalAntibodyDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.CmvIggResultId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.CmvIggDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.CmvIgmResultId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.CmvIgmDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.HtlvIAndIiOutcomeId" [form]="bloodWorkDetailForm"></dynamic-field>
            <dynamic-field [field]="abstractBloodWorkDetailControls.HtlvIAndIiDrawDate" [form]="bloodWorkDetailForm"></dynamic-field>

            <div class="row col-md-12">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
            <br />
            <br />
        </form>
    </div>
</div>
