import { Component, Input, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDonor } from '@model/interfaces/donor';
import { environment } from '@environment';
import { IDonorImage } from '@model/interfaces/donor-image';
import { DonorPhotoService } from '../services/donor-photo.service';
import { IModalOptions } from '@mt-ng2/modal-module';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-donor-photos',
    templateUrl: './donor-photo-management.component.html',
})
export class DonorPhotoManagementComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;

    imagePath: string;
    orderedPhotos: IDonorImage[];
    selectedPhoto: IDonorImage;
    modalOptions: IModalOptions = {
        background: 'rgb(0,0,0,.8)',
        showCloseButton: true,
        showConfirmButton: false,
        width: '50%',
    };

    showUploadPhotoModal: boolean;
    uploadModalOptions: IModalOptions = {
        showCloseButton: true,
        showConfirmButton: false,
        width: '50%',
    };

    constructor(private notificationsService: NotificationsService, private donorPhotoService: DonorPhotoService) {}

    ngOnInit(): void {
        this.imagePath = environment.imgPath;
        this.orderPhotos();
    }

    orderPhotos(): void {
        this.orderedPhotos = this.donor.DonorImages.sort((a, b) => a.PhotoOrder - b.PhotoOrder)
            .sort((a, b) => +b.IsApproved - +a.IsApproved)
            .sort((a, b) => (a.IsMainPhoto === b.IsMainPhoto ? 0 : a.IsMainPhoto ? -1 : 1));
    }

    deletePhoto(photo: IDonorImage, e: Event): void {
        e.stopPropagation();
        this.donorPhotoService.deletePhoto(photo.DonorId, photo.ImageId).subscribe(() => {
            const index = this.donor.DonorImages.findIndex((di) => di.ImageId === photo.ImageId);
            this.donor.DonorImages.splice(index, 1);
            this.notificationsService.success('Photo deleted successfully.');
        });
    }

    approvePhoto(photo: IDonorImage, e: Event): void {
        e.stopPropagation();
        this.donorPhotoService.togglePhotoApproval(this.donor.Id, photo.ImageId).subscribe((result) => {
            this.notificationsService.success('Photo updated successfully.');
            photo.IsApproved = result;
        });
    }

    setMainPhoto(photo: IDonorImage, e: Event): void {
        e.stopPropagation();
        if (!photo.IsApproved) {
            return this.notificationsService.error('Photo must be approved to be set as main.');
        }
        photo.IsMainPhoto = photo.IsMainPhoto ? false : true; // toggle value
        this.donorPhotoService.setPhotoAsMain(this.donor.Id, photo).subscribe((result) => {
            this.notificationsService.success(`Photo successfully ${photo.IsMainPhoto ? 'set' : 'removed'} as main.`);
            this.donor.DonorImages = result;
            this.orderPhotos();
        });
    }

    drop(event: CdkDragDrop<any>): void {
        let currentIndex = event.container.data.index,
            previousIndex = event.previousContainer.data.index,
            selectedItem = event.previousContainer.data.item;

        if (event.previousContainer.data.index > event.container.data.index) {
            for (previousIndex; previousIndex > currentIndex; previousIndex--) {
                this.orderedPhotos[previousIndex] = this.orderedPhotos[previousIndex - 1];
                this.orderedPhotos[previousIndex].PhotoOrder = previousIndex;
            }
        } else {
            for (previousIndex; previousIndex < currentIndex; previousIndex++) {
                this.orderedPhotos[previousIndex] = this.orderedPhotos[previousIndex + 1];
                this.orderedPhotos[previousIndex].PhotoOrder = previousIndex;
            }
        }
        this.orderedPhotos[currentIndex] = selectedItem;
        this.orderedPhotos[currentIndex].PhotoOrder = currentIndex;
    }

    previewImage(photo: IDonorImage): void {
        this.selectedPhoto = photo;
    }

    closePreview(): void {
        this.selectedPhoto = null;
    }

    savePhotoOrder(): void {
        this.donorPhotoService.savePhotoOrder(this.donor.Id, this.orderedPhotos).subscribe((response) => {
            this.notificationsService.success(`Photo order saved.`);
            this.donor.DonorImages = response;
            this.orderPhotos();
        });
    }

    onFinishedUpload(newImages: IDonorImage[]): void {
        this.showUploadPhotoModal = false;
        if (newImages) {
            this.donor.DonorImages.push(...newImages);
        }
    }
}
