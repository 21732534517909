import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IDonorPreliminaryHistory } from '../interfaces/donor-preliminary-history';

export interface IDonorPreliminaryHistoryDynamicControlsParameters {
    formGroup?: string;
}

export class DonorPreliminaryHistoryDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorpreliminaryhistory?: IDonorPreliminaryHistory, additionalParameters?: IDonorPreliminaryHistoryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorPreliminaryHistory';

        this.Form = {
            Completed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Completed',
                name: 'Completed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.Completed || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.DateModified || null,
            }),
            DateOfStdTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Of Std Treatment',
                name: 'DateOfStdTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('DateOfStdTreatment') && this.donorpreliminaryhistory.DateOfStdTreatment !== null ? this.donorpreliminaryhistory.DateOfStdTreatment.toString() : '',
            }),
            HadSexWithMan: new DynamicField({
                formGroup: this.formGroup,
                label: 'Had Sex With Man',
                name: 'HadSexWithMan',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HadSexWithMan') && this.donorpreliminaryhistory.HadSexWithMan !== null ? this.donorpreliminaryhistory.HadSexWithMan : false,
            }),
            HadVasectomy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Had Vasectomy',
                name: 'HadVasectomy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HadVasectomy') && this.donorpreliminaryhistory.HadVasectomy !== null ? this.donorpreliminaryhistory.HadVasectomy : false,
            }),
            HasAdoptedParents: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Adopted Parents',
                name: 'HasAdoptedParents',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HasAdoptedParents') && this.donorpreliminaryhistory.HasAdoptedParents !== null ? this.donorpreliminaryhistory.HasAdoptedParents : false,
            }),
            HasDonatedBefore: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Donated Before',
                name: 'HasDonatedBefore',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HasDonatedBefore') && this.donorpreliminaryhistory.HasDonatedBefore !== null ? this.donorpreliminaryhistory.HasDonatedBefore : false,
            }),
            HasHiv: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Hiv',
                name: 'HasHiv',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HasHiv') && this.donorpreliminaryhistory.HasHiv !== null ? this.donorpreliminaryhistory.HasHiv : false,
            }),
            HasStd: new DynamicField({
                formGroup: this.formGroup,
                label: 'Has Std',
                name: 'HasStd',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HasStd') && this.donorpreliminaryhistory.HasStd !== null ? this.donorpreliminaryhistory.HasStd : false,
            }),
            IsAdopted: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Adopted',
                name: 'IsAdopted',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('IsAdopted') && this.donorpreliminaryhistory.IsAdopted !== null ? this.donorpreliminaryhistory.IsAdopted : false,
            }),
            KnowsFamilyHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Knows Family History',
                name: 'KnowsFamilyHistory',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('KnowsFamilyHistory') && this.donorpreliminaryhistory.KnowsFamilyHistory !== null ? this.donorpreliminaryhistory.KnowsFamilyHistory : false,
            }),
            KnowsParentsFamilyHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Knows Parents Family History',
                name: 'KnowsParentsFamilyHistory',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('KnowsParentsFamilyHistory') && this.donorpreliminaryhistory.KnowsParentsFamilyHistory !== null ? this.donorpreliminaryhistory.KnowsParentsFamilyHistory : false,
            }),
            ListedOnAnotherRoster: new DynamicField({
                formGroup: this.formGroup,
                label: 'Listed On Another Roster',
                name: 'ListedOnAnotherRoster',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('ListedOnAnotherRoster') && this.donorpreliminaryhistory.ListedOnAnotherRoster !== null ? this.donorpreliminaryhistory.ListedOnAnotherRoster : false,
            }),
            OtherRosters: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Rosters',
                name: 'OtherRosters',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('OtherRosters') && this.donorpreliminaryhistory.OtherRosters !== null ? this.donorpreliminaryhistory.OtherRosters.toString() : '',
            }),
            StdName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Std Name',
                name: 'StdName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('StdName') && this.donorpreliminaryhistory.StdName !== null ? this.donorpreliminaryhistory.StdName.toString() : '',
            }),
            TimesDonated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Times Donated',
                name: 'TimesDonated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorpreliminaryhistory && this.donorpreliminaryhistory.TimesDonated || null,
            }),
        };

        this.View = {
            Completed: new DynamicLabel(
                'Completed',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.Completed || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateOfStdTreatment: new DynamicLabel(
                'Date Of Std Treatment',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('DateOfStdTreatment') && this.donorpreliminaryhistory.DateOfStdTreatment !== null ? this.donorpreliminaryhistory.DateOfStdTreatment.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            HadSexWithMan: new DynamicLabel(
                'Had Sex With Man',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HadSexWithMan') && this.donorpreliminaryhistory.HadSexWithMan !== null ? this.donorpreliminaryhistory.HadSexWithMan : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HadVasectomy: new DynamicLabel(
                'Had Vasectomy',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HadVasectomy') && this.donorpreliminaryhistory.HadVasectomy !== null ? this.donorpreliminaryhistory.HadVasectomy : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasAdoptedParents: new DynamicLabel(
                'Has Adopted Parents',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HasAdoptedParents') && this.donorpreliminaryhistory.HasAdoptedParents !== null ? this.donorpreliminaryhistory.HasAdoptedParents : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasDonatedBefore: new DynamicLabel(
                'Has Donated Before',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HasDonatedBefore') && this.donorpreliminaryhistory.HasDonatedBefore !== null ? this.donorpreliminaryhistory.HasDonatedBefore : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasHiv: new DynamicLabel(
                'Has Hiv',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HasHiv') && this.donorpreliminaryhistory.HasHiv !== null ? this.donorpreliminaryhistory.HasHiv : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            HasStd: new DynamicLabel(
                'Has Std',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('HasStd') && this.donorpreliminaryhistory.HasStd !== null ? this.donorpreliminaryhistory.HasStd : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsAdopted: new DynamicLabel(
                'Is Adopted',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('IsAdopted') && this.donorpreliminaryhistory.IsAdopted !== null ? this.donorpreliminaryhistory.IsAdopted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            KnowsFamilyHistory: new DynamicLabel(
                'Knows Family History',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('KnowsFamilyHistory') && this.donorpreliminaryhistory.KnowsFamilyHistory !== null ? this.donorpreliminaryhistory.KnowsFamilyHistory : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            KnowsParentsFamilyHistory: new DynamicLabel(
                'Knows Parents Family History',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('KnowsParentsFamilyHistory') && this.donorpreliminaryhistory.KnowsParentsFamilyHistory !== null ? this.donorpreliminaryhistory.KnowsParentsFamilyHistory : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ListedOnAnotherRoster: new DynamicLabel(
                'Listed On Another Roster',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('ListedOnAnotherRoster') && this.donorpreliminaryhistory.ListedOnAnotherRoster !== null ? this.donorpreliminaryhistory.ListedOnAnotherRoster : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            OtherRosters: new DynamicLabel(
                'Other Rosters',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('OtherRosters') && this.donorpreliminaryhistory.OtherRosters !== null ? this.donorpreliminaryhistory.OtherRosters.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            StdName: new DynamicLabel(
                'Std Name',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.hasOwnProperty('StdName') && this.donorpreliminaryhistory.StdName !== null ? this.donorpreliminaryhistory.StdName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TimesDonated: new DynamicLabel(
                'Times Donated',
                this.donorpreliminaryhistory && this.donorpreliminaryhistory.TimesDonated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
        };

    }
}
