import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IBirthDetail } from '../interfaces/birth-detail';
import { IBirthDefect } from '../interfaces/birth-defect';
import { IBirthOutcome } from '../interfaces/birth-outcome';
import { IGender } from '../interfaces/gender';
import { IUnitsOfMeasurement } from '../interfaces/units-of-measurement';

export interface IBirthDetailDynamicControlsParameters {
    formGroup?: string;
    birthOutcomes?: IBirthOutcome[];
    genders?: IGender[];
    heights?: IUnitsOfMeasurement[];
    heightUnits?: IUnitsOfMeasurement[];
    weights?: IUnitsOfMeasurement[];
    weightUnits?: IUnitsOfMeasurement[];
    birthDefects?: IBirthDefect[];
}

export class BirthDetailDynamicControls {

    formGroup: string;
    birthOutcomes: IBirthOutcome[];
    genders: IGender[];
    heights: IUnitsOfMeasurement[];
    heightUnits: IUnitsOfMeasurement[];
    weights: IUnitsOfMeasurement[];
    weightUnits: IUnitsOfMeasurement[];
    birthDefects: IBirthDefect[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private birthdetail?: IBirthDetail, additionalParameters?: IBirthDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'BirthDetail';
        this.birthOutcomes = additionalParameters && additionalParameters.birthOutcomes || undefined;
        this.genders = additionalParameters && additionalParameters.genders || undefined;
        this.heights = additionalParameters && additionalParameters.heights || undefined;
        this.heightUnits = additionalParameters && additionalParameters.heightUnits || undefined;
        this.weights = additionalParameters && additionalParameters.weights || undefined;
        this.weightUnits = additionalParameters && additionalParameters.weightUnits || undefined;
        this.birthDefects = additionalParameters && additionalParameters.birthDefects || undefined;

        this.Form = {
            BirthDefectsId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Birth Defects',
                name: 'BirthDefectsId',
                options: this.birthDefects,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.birthdetail && this.birthdetail.BirthDefectsId || null,
            }),
            BirthOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Birth Outcome',
                name: 'BirthOutcomeId',
                options: this.birthOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.birthdetail && this.birthdetail.BirthOutcomeId || null,
            }),
            Comments: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comments',
                name: 'Comments',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.birthdetail && this.birthdetail.hasOwnProperty('Comments') && this.birthdetail.Comments !== null ? this.birthdetail.Comments.toString() : '',
            }),
            GenderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Gender',
                name: 'GenderId',
                options: this.genders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.birthdetail && this.birthdetail.GenderId || null,
            }),
            Height: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height',
                name: 'Height',
                options: this.heights,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.birthdetail && this.birthdetail.Height || null,
            }),
            HeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height Unit',
                name: 'HeightUnitId',
                options: this.heightUnits,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.birthdetail && this.birthdetail.HeightUnitId || null,
            }),
            Weight: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight',
                name: 'Weight',
                options: this.weights,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.birthdetail && this.birthdetail.Weight || null,
            }),
            WeightUnitId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Weight Unit',
                name: 'WeightUnitId',
                options: this.weightUnits,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.birthdetail && this.birthdetail.WeightUnitId || null,
            }),
        };

        this.View = {
            BirthDefectsId: new DynamicLabel(
                'Birth Defects',
                getMetaItemValue(this.birthDefects, this.birthdetail && this.birthdetail.hasOwnProperty('BirthDefectsId') && this.birthdetail.BirthDefectsId !== null ? this.birthdetail.BirthDefectsId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            BirthOutcomeId: new DynamicLabel(
                'Birth Outcome',
                getMetaItemValue(this.birthOutcomes, this.birthdetail && this.birthdetail.hasOwnProperty('BirthOutcomeId') && this.birthdetail.BirthOutcomeId !== null ? this.birthdetail.BirthOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Comments: new DynamicLabel(
                'Comments',
                this.birthdetail && this.birthdetail.hasOwnProperty('Comments') && this.birthdetail.Comments !== null ? this.birthdetail.Comments.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            GenderId: new DynamicLabel(
                'Gender',
                getMetaItemValue(this.genders, this.birthdetail && this.birthdetail.hasOwnProperty('GenderId') && this.birthdetail.GenderId !== null ? this.birthdetail.GenderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Height: new DynamicLabel(
                'Height',
                getMetaItemValue(this.heights, this.birthdetail && this.birthdetail.hasOwnProperty('Height') && this.birthdetail.Height !== null ? this.birthdetail.Height : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            HeightUnitId: new DynamicLabel(
                'Height Unit',
                getMetaItemValue(this.heightUnits, this.birthdetail && this.birthdetail.hasOwnProperty('HeightUnitId') && this.birthdetail.HeightUnitId !== null ? this.birthdetail.HeightUnitId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Weight: new DynamicLabel(
                'Weight',
                getMetaItemValue(this.weights, this.birthdetail && this.birthdetail.hasOwnProperty('Weight') && this.birthdetail.Weight !== null ? this.birthdetail.Weight : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            WeightUnitId: new DynamicLabel(
                'Weight Unit',
                getMetaItemValue(this.weightUnits, this.birthdetail && this.birthdetail.hasOwnProperty('WeightUnitId') && this.birthdetail.WeightUnitId !== null ? this.birthdetail.WeightUnitId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
