<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Appointments"></mt-page-title>
        </h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    <mt-search-filter-select [items]="assignedUsers" entity="Assigned User"
        (onSelectionChanged)="filterSelectionChanged()">
    </mt-search-filter-select>
    <mt-search-filter-select [items]="appointmentTypes" entity="Appointment Type"
        (onSelectionChanged)="filterSelectionChanged()">
    </mt-search-filter-select>

</div>

<entity-list
    [entities]="appointments"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (onPageChanged)="getAppointments()"
    (onItemSelected)="appointmentSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig">
</entity-list>
