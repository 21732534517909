import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IAppointment } from '../interfaces/appointment';
import { IUser } from '../interfaces/user';
import { IAppointmentType } from '../interfaces/appointment-type';

export interface IAppointmentDynamicControlsParameters {
    formGroup?: string;
    types?: IAppointmentType[];
    assignedUsers?: IUser[];
}

export class AppointmentDynamicControls {

    formGroup: string;
    types: IAppointmentType[];
    assignedUsers: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private appointment?: IAppointment, additionalParameters?: IAppointmentDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Appointment';
        this.types = additionalParameters && additionalParameters.types || undefined;
        this.assignedUsers = additionalParameters && additionalParameters.assignedUsers || undefined;

        this.Form = {
            AssignedUserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Assigned User',
                name: 'AssignedUserId',
                options: this.assignedUsers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.appointment && this.appointment.AssignedUserId || null,
            }),
            Cancelled: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cancelled',
                name: 'Cancelled',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.appointment && this.appointment.hasOwnProperty('Cancelled') && this.appointment.Cancelled !== null ? this.appointment.Cancelled : false,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.appointment && this.appointment.DateCreated || null,
            }),
            DonorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor',
                name: 'DonorId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.appointment && this.appointment.DonorId || null,
            }),
            EndTimeDonor: new DynamicField({
                formGroup: this.formGroup,
                label: 'End Time Donor',
                name: 'EndTimeDonor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.appointment && this.appointment.EndTimeDonor || null,
            }),
            EndTimeUtc: new DynamicField({
                formGroup: this.formGroup,
                label: 'End Time Utc',
                name: 'EndTimeUtc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.appointment && this.appointment.EndTimeUtc || null,
            }),
            EventUuid: new DynamicField({
                formGroup: this.formGroup,
                label: 'Event Uu',
                name: 'EventUuid',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(250) ],
                validators: { 'required': true, 'maxlength': 250 },
                value: this.appointment && this.appointment.hasOwnProperty('EventUuid') && this.appointment.EventUuid !== null ? this.appointment.EventUuid.toString() : '',
            }),
            InterviewPassed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Interview Passed',
                name: 'InterviewPassed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.appointment && this.appointment.hasOwnProperty('InterviewPassed') && this.appointment.InterviewPassed !== null ? this.appointment.InterviewPassed : false,
            }),
            PhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone Number',
                name: 'PhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.appointment && this.appointment.hasOwnProperty('PhoneNumber') && this.appointment.PhoneNumber !== null ? this.appointment.PhoneNumber.toString() : '',
            }),
            StartTimeDonor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Start Time Donor',
                name: 'StartTimeDonor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.appointment && this.appointment.StartTimeDonor || null,
            }),
            StartTimeUtc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Start Time Utc',
                name: 'StartTimeUtc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.appointment && this.appointment.StartTimeUtc || null,
            }),
            Timezone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Timezone',
                name: 'Timezone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(150) ],
                validators: { 'required': true, 'maxlength': 150 },
                value: this.appointment && this.appointment.hasOwnProperty('Timezone') && this.appointment.Timezone !== null ? this.appointment.Timezone.toString() : '',
            }),
            TypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Type',
                name: 'TypeId',
                options: this.types,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.appointment && this.appointment.TypeId || 1,
            }),
        };

        this.View = {
            AssignedUserId: new DynamicLabel(
                'Assigned User',
                getMetaItemValue(this.assignedUsers, this.appointment && this.appointment.hasOwnProperty('AssignedUserId') && this.appointment.AssignedUserId !== null ? this.appointment.AssignedUserId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Cancelled: new DynamicLabel(
                'Cancelled',
                this.appointment && this.appointment.hasOwnProperty('Cancelled') && this.appointment.Cancelled !== null ? this.appointment.Cancelled : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.appointment && this.appointment.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DonorId: new DynamicLabel(
                'Donor',
                this.appointment && this.appointment.DonorId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            EndTimeDonor: new DynamicLabel(
                'End Time Donor',
                this.appointment && this.appointment.EndTimeDonor || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EndTimeUtc: new DynamicLabel(
                'End Time Utc',
                this.appointment && this.appointment.EndTimeUtc || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EventUuid: new DynamicLabel(
                'Event Uu',
                this.appointment && this.appointment.hasOwnProperty('EventUuid') && this.appointment.EventUuid !== null ? this.appointment.EventUuid.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            InterviewPassed: new DynamicLabel(
                'Interview Passed',
                this.appointment && this.appointment.hasOwnProperty('InterviewPassed') && this.appointment.InterviewPassed !== null ? this.appointment.InterviewPassed : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            PhoneNumber: new DynamicLabel(
                'Phone Number',
                this.appointment && this.appointment.hasOwnProperty('PhoneNumber') && this.appointment.PhoneNumber !== null ? this.appointment.PhoneNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            StartTimeDonor: new DynamicLabel(
                'Start Time Donor',
                this.appointment && this.appointment.StartTimeDonor || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            StartTimeUtc: new DynamicLabel(
                'Start Time Utc',
                this.appointment && this.appointment.StartTimeUtc || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Timezone: new DynamicLabel(
                'Timezone',
                this.appointment && this.appointment.hasOwnProperty('Timezone') && this.appointment.Timezone !== null ? this.appointment.Timezone.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TypeId: new DynamicLabel(
                'Type',
                getMetaItemValue(this.types, this.appointment && this.appointment.hasOwnProperty('TypeId') && this.appointment.TypeId !== null ? this.appointment.TypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
