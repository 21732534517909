<div *ngIf="!isEditing && donor.DonorWhoAreYou">
    <h4>Who Are You</h4>
    <div>
        <div class="display-padded">
            <b>Body Structure:</b>
            <span>{{ donor.DonorWhoAreYou.BodyStructure?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>Eye Color:</b>
            <span>{{ donor.DonorWhoAreYou.EyeColor?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>Natural Hair Color:</b>
            <span>{{ donor.DonorWhoAreYou.NaturalHairColor?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>Hair Texture:</b>
            <span>{{ donor.DonorWhoAreYou.HairTexture?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>Skin Type:</b>
            <span>{{ donor.DonorWhoAreYou.SkinType?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>Race:</b>
            <span>{{ donor.DonorWhoAreYou.Race?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>Ancestry Father:</b>
            <span>{{ fatherAncestries }}</span>
        </div>
        <div class="display-padded">
            <b>Ancestry Mother:</b>
            <span>{{ motherAncestries }}</span>
        </div>
        <div class="display-padded">
            <b>Education Completed:</b>
            <span>{{ donor.DonorWhoAreYou.EducationCompletedType?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>College Major:</b>
            <span>{{ donor.DonorWhoAreYou.CollegeMajor }}</span>
        </div>
    </div>
    <div class="display-padded">
        <b>High School Gpa:</b>
        <span>{{ donor.DonorWhoAreYou.HighSchoolGpa?.Name }}</span>
    </div>
    <div class="display-padded">
        <b>College Gpa:</b>
        <span>{{ donor.DonorWhoAreYou.CollegeGpa?.Name }}</span>
    </div>
    <div class="display-padded">
        <b>Current Occupation:</b>
        <span>{{ donor.DonorWhoAreYou.CurrentOccupation }}</span>
    </div>
    <div class="display-padded">
        <b>Talents/Abilities:</b>
        <span>{{ donor.DonorWhoAreYou.TalentsAbilities }}</span>
    </div>
    <div class="display-padded">
        <b>Favorites:</b>
        <span>{{ donor.DonorWhoAreYou.Favorites }}</span>
    </div>
    <div class="display-padded">
        <b>CharacterDescription:</b>
        <span>{{ donor.DonorWhoAreYou.CharacterDescription }}</span>
    </div>
    <div class="display-padded">
        <b>Speaks Other Languages:</b>
        <span>{{ donor.DonorWhoAreYou.SpeaksOtherLanguages }}</span>
    </div>
    <div class="display-padded">
        <b>Why Interested in Becoming Sperm Donor:</b>
        <span>{{ donor.DonorWhoAreYou.WhyInterestedInBecomingSpermDonor }}</span>
    </div>
    <div class="display-padded">
        <b>Goals:</b>
        <span>{{ donor.DonorWhoAreYou.Goals }}</span>
    </div>
    <div class="display-padded">
        <b>Hobbies/Interests:</b>
        <span>{{ donor.DonorWhoAreYou.HobbiesInterests }}</span>
    </div>
    <div class="display-padded">
        <b>Exercise Routine:</b>
        <span>{{ donor.DonorWhoAreYou.ExerciseRoutine }}</span>
    </div>
    <div class="display-padded">
        <b>Extracurricular Activities:</b>
        <span>{{ donor.DonorWhoAreYou.ExtracurricularActivities }}</span>
    </div>
    <div class="display-padded">
        <b>Has Plastic Surgery:</b>
        <span>{{ donor.DonorWhoAreYou.HasPlasticSurgery | yesNoPipe }}</span>
    </div>
    <div *ngIf="donor.DonorWhoAreYou.HasPlasticSurgery" class="display-padded">
        <b>Plastic Surgery Type and Year:</b>
        <span>{{ donor.DonorWhoAreYou.PlasticSurgeryTypeAndYear }}</span>
    </div>
    <div class="display-padded">
        <b>Has Children:</b>
        <span>{{ donor.DonorWhoAreYou.HaveChildren | yesNoPipe }}</span>
    </div>
    <div *ngIf="donor.DonorWhoAreYou.HaveChildren" class="display-padded">
        <b>Number of Children:</b>
        <span>{{ donor.DonorWhoAreYou.NumberOfChildren }}</span>
    </div>
    <div class="display-padded">
        <b>Blood Type:</b>
        <span>{{ donor.DonorWhoAreYou.BloodType?.Name }}</span>
    </div>
    <div class="display-padded">
        <b>Rh Factor:</b>
        <span>{{ donor.DonorWhoAreYou.RhFactor?.Name }}</span>
    </div>
    <div class="display-padded">
        <b>Has Bleeding Disorder:</b>
        <span>{{ donor.DonorWhoAreYou.BleedingDisorders | yesNoPipe }}</span>
    </div>
    <div class="display-padded">
        <b>Has Experienced Traumatic Event:</b>
        <span>{{ donor.DonorWhoAreYou.ExperiencedTraumaticEvent | yesNoPipe }}</span>
    </div>
    <div *ngIf="donor.DonorWhoAreYou.ExperiencedTraumaticEvent" class="display-padded">
        <b>Traumatic Event:</b>
        <span>{{ donor.DonorWhoAreYou.TraumaticEvent?.Name }}</span>
    </div>
    <div *ngIf="showOtherTraumaticEvent()" class="display-padded">
        <b>What Type of Event?:</b>
        <span>{{ donor.DonorWhoAreYou.OtherTraumaticEvent }}</span>
    </div>
    <div class="display-padded">
        <b>Has Serious Depression:</b>
        <span>{{ donor.DonorWhoAreYou.SeriousDepression | yesNoPipe }}</span>
    </div>
    <div class="display-padded">
        <b>Has Mood Swings:</b>
        <span>{{ donor.DonorWhoAreYou.MoodSwings | yesNoPipe }}</span>
    </div>
    <div class="display-padded">
        <b>Has Anxiety:</b>
        <span>{{ donor.DonorWhoAreYou.Anxiety | yesNoPipe }}</span>
    </div>
    <div class="display-padded">
        <b>Has Paranoia:</b>
        <span>{{ donor.DonorWhoAreYou.Paranoia | yesNoPipe }}</span>
    </div>
    <div class="display-padded">
        <b>Is Under Psychiatric Care:</b>
        <span>{{ donor.DonorWhoAreYou.UnderPsychiatricCare | yesNoPipe }}</span>
    </div>
    <div class="display-padded">
        <b>Has Been Treated for Drug/Alcohol Abuse:</b>
        <span>{{ donor.DonorWhoAreYou.TreatedForDrugAlcoholAbuse | yesNoPipe }}</span>
    </div>
    <div class="display-padded">
        <b>Has Known Genetic Conditions:</b>
        <span>{{ donor.DonorWhoAreYou.KnownGeneticConditions | yesNoPipe }}</span>
    </div>
    <div *ngIf="donor.DonorWhoAreYou.KnownGeneticConditions" class="display-padded">
        <b>Genetic Conditions:</b>
        <span>{{ donor.DonorWhoAreYou.GeneticConditions }}</span>
    </div>
    <div class="display-padded">
        <b>Date Created:</b>
        <span>{{ donor.DonorWhoAreYou.DateCreated | date }}</span>
    </div>
    <div class="display-padded">
        <b>Date Modified:</b>
        <span>{{ donor.DonorWhoAreYou.DateModified | date }}</span>
    </div>
    <div class="display-padded">
        <b>Completed:</b>
        <span>{{ donor.DonorWhoAreYou.Completed | date }}</span>
    </div>
</div>
<div *ngIf="isEditing">
    <h4>Who Are You</h4>
    <who-are-you [donor]="donor" (onFormValueChanges)="onFormValueChanges($event)"></who-are-you>
    <div>
        <button [disabled]="!formChanged" class="btn btn-success btn-flat" (click)="onSubmit()">Save</button>
        <button class="btn btn-default btn-flat" (click)="cancelClick()">Cancel</button>
    </div>
</div>
