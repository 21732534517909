import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DynamicField, DynamicFieldTypes, DynamicLabel, InputTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { formatPhone } from '@mt-ng2/format-functions';

/**
 * This is a view equivalent of dynamic-field
 */
@Component({
    selector: 'dynamic-label',
    template: `
        <div class="display-padded">
            <span [innerHTML]="label"></span>
            <span *ngIf="displayValue" innerHtml="{{ prefix + getValue(field) + suffix }}"></span>
        </div>
    `,
})
export class DyanmicLabelComponent {
    @Input() field: DynamicLabel | DynamicField;
    @Input() prefix = '';
    @Input() suffix = '';
    @Input('label') inputLabel: string;

    displayValue = true;

    get label(): string {
        let labelText: string;
        if (this.inputLabel) {
            labelText = this.inputLabel;
        } else {
            const useLabelHtml = this.field?.hasOwnProperty('labelHtml') && (this.field as DynamicField).labelHtml;
            labelText = useLabelHtml ? (this.field as DynamicField).labelHtml : this.field.label;
        }
        return `<b>${labelText}:</b>`;
    }

    constructor(private currencyPipe: CurrencyPipe, private datePipe: DatePipe, private sanitizer: DomSanitizer) {}

    getValue(field: DynamicLabel): string | SafeHtml {
        if (!field || (!field.value && field.value !== 0 && field.type.fieldType !== DynamicFieldTypes.Checkbox)) {
            this.displayValue = false;
            return '';
        }
        if (field.valueHtml != null) {
            return this.sanitizer.bypassSecurityTrustHtml(field.valueHtml);
        }
        if (field.type.fieldType === DynamicFieldTypes.Numeric && field.type.inputType === NumericInputTypes.Currency) {
            return this.currencyPipe.transform(field.value);
        }
        if (
            field.type.fieldType === DynamicFieldTypes.Input &&
            (field.type.inputType === InputTypes.Datepicker || field.type.inputType === InputTypes.DateTimeInput)
        ) {
            return this.datePipe.transform(field.value);
        }
        if (field.type.fieldType === DynamicFieldTypes.Input && field.type.inputType === InputTypes.Phone) {
            return formatPhone(<string>field.value);
        }
        if (field.type.fieldType === DynamicFieldTypes.Checkbox) {
            return field.value ? 'Yes' : 'No';
        }
        if (field.type.fieldType === DynamicFieldTypes.Input && field.type.inputType === InputTypes.DateInput) {
            return this.datePipe.transform(field.value, 'mediumDate', 'UTC');
        }
        return field.value.toString();
    }
}
