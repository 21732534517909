import { LabDataCalculatedFieldsService } from './lab-data-calculated-fields.service';
import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';
import { ILabDataBase } from '@model/interfaces/custom/lab-data-base';

export interface ILabDataRawSemenCalculatedFieldValues {
    TotalConcentration: string;
    Motility: string;
    TMCPerSample: string;
}

export interface ILabDataPostGradientCalculatedFieldValues {
    TMCPerSample: string;
    RecoveryEfficiency: string;
}

export interface ILabDataCryoPrepCalculatedFieldValues {
    GuaranteeTMCPerSample: string;
    DesiredFinalMotileConcentration: string;
    FinalVolume: string;
    ArcticAdded: string;
    AddedWashMedia: string;
}

export interface ILabDataIVFVialsCalculatedFieldValues {
    NumberOfVials: string;
    VialAddVolume: string;
    AproxVolLeft: string;
}

export interface ILabDataTestThawInfoCalculatedFieldValues {
    TMCTestVialPerSample: string;
    TMCAllVials: string;
    Survival: string;
    OverallEfficiency: string;
}

export interface ILabDataRemainderVialDetailsCalculatedFieldValues {
    GuaranteeTMCPerSample: string;
    DesiredFinalMotileConcentration: string;
    TMCPerSample: string;
    FinalVolume: string;
    ArcticAdded: string;
    AddedWashMedia: string;
}

export interface ILabDataRemainderVialsCalculatedFieldValues {
    NumberOfVials: string;
    VialAddVolume: string;
    AproxVolLeft: string;
}

export class LabDataLibrary {
    public static microSymbol = '\u00B5';

    public static getEmptyCalculatedFields(sectionId: number): any {
        switch (sectionId) {
            case DonorTestSampleLabDataSections.PostGradient:
                return this.getEmptyPostGradientCalculatedFields();
            case DonorTestSampleLabDataSections.CryoPrep:
                return this.getEmptyCryoPrepCalculatedFields();
            case DonorTestSampleLabDataSections.IVFVials:
                return this.getEmptyIVFVialsCalculatedFields();
            case DonorTestSampleLabDataSections.TestThawInfo:
                return this.getEmptyTestThawInfoCalculatedFields();
            case DonorTestSampleLabDataSections.RemainderVialDetails:
                return this.getEmptyRemainderVialDetailsCalculatedFields();
            case DonorTestSampleLabDataSections.RemainderVials:
                return this.getEmptyRemainderVialsCalculatedFields();
            case DonorTestSampleLabDataSections.RemainderTestThawInfo:
                return this.getEmptyTestThawInfoCalculatedFields();
            case DonorTestSampleLabDataSections.RawSemen:
            default:
                return this.getEmptyRawSemenCalculatedFields();
        }
    }

    private static getEmptyRawSemenCalculatedFields(): ILabDataRawSemenCalculatedFieldValues {
        return {
            Motility: '',
            TMCPerSample: '',
            TotalConcentration: '',
        };
    }

    private static getEmptyPostGradientCalculatedFields(): ILabDataPostGradientCalculatedFieldValues {
        return {
            RecoveryEfficiency: '',
            TMCPerSample: '',
        };
    }

    private static getEmptyCryoPrepCalculatedFields(): ILabDataCryoPrepCalculatedFieldValues {
        return {
            AddedWashMedia: '',
            ArcticAdded: '',
            DesiredFinalMotileConcentration: '',
            FinalVolume: '',
            GuaranteeTMCPerSample: '',
        };
    }

    private static getEmptyIVFVialsCalculatedFields(): ILabDataIVFVialsCalculatedFieldValues {
        return {
            AproxVolLeft: '',
            NumberOfVials: '',
            VialAddVolume: '',
        };
    }

    private static getEmptyTestThawInfoCalculatedFields(): ILabDataTestThawInfoCalculatedFieldValues {
        return {
            OverallEfficiency: '',
            Survival: '',
            TMCAllVials: '',
            TMCTestVialPerSample: '',
        };
    }

    private static getEmptyRemainderVialDetailsCalculatedFields(): ILabDataRemainderVialDetailsCalculatedFieldValues {
        return {
            AddedWashMedia: '',
            ArcticAdded: '',
            DesiredFinalMotileConcentration: '',
            FinalVolume: '',
            GuaranteeTMCPerSample: '',
            TMCPerSample: '',
        };
    }

    private static getEmptyRemainderVialsCalculatedFields(): ILabDataRemainderVialsCalculatedFieldValues {
        return {
            AproxVolLeft: '',
            NumberOfVials: '',
            VialAddVolume: '',
        };
    }

    public static getCalculatedFields(
        sectionId: number,
        currentValues: ILabDataBase,
    ):
        | ILabDataRawSemenCalculatedFieldValues
        | ILabDataPostGradientCalculatedFieldValues
        | ILabDataCryoPrepCalculatedFieldValues
        | ILabDataIVFVialsCalculatedFieldValues
        | ILabDataTestThawInfoCalculatedFieldValues
        | ILabDataRemainderVialDetailsCalculatedFieldValues
        | ILabDataRemainderVialsCalculatedFieldValues {
        switch (sectionId) {
            case DonorTestSampleLabDataSections.PostGradient:
                return this.getPostGradientCalculatedFieldValues(currentValues);
            case DonorTestSampleLabDataSections.CryoPrep:
                return this.getCryoPrepCalculatedFieldValues(currentValues, sectionId);
            case DonorTestSampleLabDataSections.IVFVials:
                return this.getIVFVialsCalculatedFieldValues(currentValues, sectionId);
            case DonorTestSampleLabDataSections.TestThawInfo:
                return this.getTestThawInfoCalculatedFieldValues(currentValues, sectionId);
            case DonorTestSampleLabDataSections.RemainderVialDetails:
                return this.getRemainderVialDetailsCalculatedFieldValues(currentValues, sectionId);
            case DonorTestSampleLabDataSections.RemainderVials:
                return this.getRemainderVialsCalulatedFieldValues(currentValues, sectionId);
            case DonorTestSampleLabDataSections.RemainderTestThawInfo:
                return this.getRemainderTestThawCalculatedFieldValues(currentValues, sectionId);
            case DonorTestSampleLabDataSections.RawSemen:
            default:
                return this.getRawSemenCalculatedFieldValues(currentValues);
        }
    }

    private static getRawSemenCalculatedFieldValues(currentValues: ILabDataBase): ILabDataRawSemenCalculatedFieldValues {
        let values: ILabDataRawSemenCalculatedFieldValues = this.getEmptyRawSemenCalculatedFields();
        if (!currentValues) {
            return values;
        }

        const totalConcentration = LabDataCalculatedFieldsService.calculateTotalConcentration(currentValues);
        const motility = LabDataCalculatedFieldsService.calculateMotility(currentValues);
        const tmcPerSample = LabDataCalculatedFieldsService.calculateRawSemenTMCPerSample(currentValues);
        if (totalConcentration) {
            values.TotalConcentration = totalConcentration.toFixed(1);
        }
        if (motility) {
            values.Motility = motility.toFixed(1) + '%';
        }
        if (tmcPerSample) {
            values.TMCPerSample = tmcPerSample.toFixed(1);
        }

        return values;
    }

    private static getPostGradientCalculatedFieldValues(currentValues: ILabDataBase): ILabDataPostGradientCalculatedFieldValues {
        let values: ILabDataPostGradientCalculatedFieldValues = this.getEmptyPostGradientCalculatedFields();
        if (!currentValues) {
            return values;
        }

        const tmcPerSample = LabDataCalculatedFieldsService.calculatePostGradientTMCPerSample(currentValues);
        const recoveryEfficiency = LabDataCalculatedFieldsService.calculateRecoveryEfficiency(currentValues);
        if (tmcPerSample) {
            values.TMCPerSample = tmcPerSample.toFixed(1);
        }
        if (recoveryEfficiency) {
            values.RecoveryEfficiency = recoveryEfficiency.toFixed(1) + '%';
        }

        return values;
    }

    private static getCryoPrepCalculatedFieldValues(currentValues: ILabDataBase, sectionId: number): ILabDataCryoPrepCalculatedFieldValues {
        let values: ILabDataCryoPrepCalculatedFieldValues = this.getEmptyCryoPrepCalculatedFields();
        if (!currentValues || !currentValues.DonorTestSample || !currentValues.DonorTestSample.PrepMethodId) {
            return values;
        }

        const prepMethodId = LabDataCalculatedFieldsService.isRemainderData(sectionId)
            ? currentValues.DonorTestSample.RemainderPrepMethodId
            : currentValues.DonorTestSample.PrepMethodId;

        const guaranteeTMCPerSample = LabDataCalculatedFieldsService.getGuaranteeTMCValue(prepMethodId);
        const desiredFinal = LabDataCalculatedFieldsService.calculateDesiredFinalMotileConcentration(currentValues, sectionId);
        const finalVolume = LabDataCalculatedFieldsService.calculateFinalVolume(currentValues, sectionId);
        const arcticAdded = LabDataCalculatedFieldsService.calculateArcticAdded(currentValues, sectionId);
        const addedWashMedia = LabDataCalculatedFieldsService.calculateAddedWashMedia(currentValues, sectionId);
        if (guaranteeTMCPerSample) {
            values.GuaranteeTMCPerSample = guaranteeTMCPerSample.toString();
        }
        if (desiredFinal) {
            values.DesiredFinalMotileConcentration = desiredFinal.toFixed(1);
        }
        if (finalVolume) {
            values.FinalVolume = finalVolume.toFixed() + ` ${this.microSymbol}l`;
        }
        if (arcticAdded) {
            values.ArcticAdded = arcticAdded.toFixed() + ` ${this.microSymbol}l`;
        }
        if (addedWashMedia) {
            values.AddedWashMedia = addedWashMedia.toFixed() + ` ${this.microSymbol}l`;
        }

        return values;
    }

    public static getIVFVialsCalculatedFieldValues(currentValues: ILabDataBase, sectionId: number): ILabDataIVFVialsCalculatedFieldValues {
        let values = this.getEmptyIVFVialsCalculatedFields();
        values.VialAddVolume = LabDataCalculatedFieldsService.getVialAddVolume() + ` ${this.microSymbol}l`;
        if (!currentValues || !currentValues.DonorTestSample || !currentValues.DonorTestSample.PrepMethodId) {
            return values;
        }

        const numberOfVials = LabDataCalculatedFieldsService.calculateNumberOfVials(currentValues, sectionId);
        const aproxVolLeft = LabDataCalculatedFieldsService.calculateAproxVolLeft(currentValues, sectionId);
        values.NumberOfVials = numberOfVials.toFixed();
        if (aproxVolLeft) {
            values.AproxVolLeft = aproxVolLeft.toFixed() + ` ${this.microSymbol}l`;
        }

        return values;
    }

    public static getTestThawInfoCalculatedFieldValues(currentValues: ILabDataBase, sectionId: number): ILabDataTestThawInfoCalculatedFieldValues {
        let values = this.getEmptyTestThawInfoCalculatedFields();
        if (!currentValues || !currentValues.DonorTestSample || !currentValues.DonorTestSample.PrepMethodId) {
            return values;
        }

        const tmcTestVialPerSample = LabDataCalculatedFieldsService.calculateTMCTestVialPerSample(currentValues);
        const tmcAllVials = LabDataCalculatedFieldsService.calculateTMCAllVials(currentValues, sectionId);
        const survival = LabDataCalculatedFieldsService.calculateSurvival(currentValues, sectionId);
        const overallEfficiency = LabDataCalculatedFieldsService.calculateOverallEfficiency(currentValues, sectionId);
        if (tmcTestVialPerSample) {
            values.TMCTestVialPerSample = tmcTestVialPerSample.toFixed(1);
        }
        if (tmcAllVials) {
            values.TMCAllVials = tmcAllVials.toFixed(1);
        }
        if (survival) {
            values.Survival = survival.toFixed(1) + '%';
        }
        if (overallEfficiency) {
            values.OverallEfficiency = overallEfficiency.toFixed(1) + '%';
        }

        return values;
    }

    public static getRemainderVialDetailsCalculatedFieldValues(
        currentValues: ILabDataBase,
        sectionId: number,
    ): ILabDataRemainderVialDetailsCalculatedFieldValues {
        let values: ILabDataRemainderVialDetailsCalculatedFieldValues = this.getEmptyRemainderVialDetailsCalculatedFields();
        if (!currentValues || !currentValues.DonorTestSample || !currentValues.DonorTestSample.RemainderPrepMethodId) {
            return values;
        }

        const prepMethodId = currentValues.DonorTestSample.RemainderPrepMethodId;
        const guaranteeTMCPerSample = LabDataCalculatedFieldsService.getGuaranteeTMCValue(prepMethodId);
        const desiredFinal = LabDataCalculatedFieldsService.calculateDesiredFinalMotileConcentration(currentValues, sectionId);
        const tmcPerSample = LabDataCalculatedFieldsService.calculateRemainderTMCPerSample(currentValues);
        const finalVolume = LabDataCalculatedFieldsService.calculateRemainderFinalVolume(currentValues);
        const arcticAdded = LabDataCalculatedFieldsService.calculateRemainderArcticAdded(currentValues);
        const addedWashMedia = LabDataCalculatedFieldsService.calculateRemainderAddedWashMedia(currentValues);
        if (guaranteeTMCPerSample) {
            values.GuaranteeTMCPerSample = guaranteeTMCPerSample.toString();
        }
        if (desiredFinal) {
            values.DesiredFinalMotileConcentration = desiredFinal.toFixed(1);
        }
        if (finalVolume) {
            values.FinalVolume = finalVolume.toFixed() + ` ${this.microSymbol}l`;
        }
        if (arcticAdded) {
            values.ArcticAdded = arcticAdded.toFixed() + ` ${this.microSymbol}l`;
        }
        if (addedWashMedia) {
            values.AddedWashMedia = addedWashMedia.toFixed() + ` ${this.microSymbol}l`;
        }
        if (tmcPerSample) {
            values.TMCPerSample = tmcPerSample.toFixed(1);
        }
        return values;
    }

    public static getRemainderVialsCalulatedFieldValues(currentValues: ILabDataBase, sectionId: number): ILabDataRemainderVialsCalculatedFieldValues {
        let values = this.getEmptyRemainderVialsCalculatedFields();
        values.VialAddVolume = LabDataCalculatedFieldsService.getVialAddVolume() + ` ${this.microSymbol}l`;
        if (!currentValues || !currentValues.DonorTestSample || !currentValues.DonorTestSample.PrepMethodId) {
            return values;
        }

        const numberOfVials = LabDataCalculatedFieldsService.calculateRemainderNumberOfVials(currentValues);
        const aproxVolLeft = LabDataCalculatedFieldsService.calculateAproxVolLeft(currentValues, sectionId);
        if (numberOfVials) {
            values.NumberOfVials = numberOfVials.toFixed();
        }
        if (aproxVolLeft) {
            values.AproxVolLeft = aproxVolLeft.toFixed() + ` ${this.microSymbol}l`;
        } else {
            values.AproxVolLeft = '0';
        }

        return values;
    }

    public static getRemainderTestThawCalculatedFieldValues(
        currentValues: ILabDataBase,
        sectionId: number,
    ): ILabDataTestThawInfoCalculatedFieldValues {
        let values = this.getEmptyTestThawInfoCalculatedFields();
        if (!currentValues || !currentValues.DonorTestSample || !currentValues.DonorTestSample.PrepMethodId) {
            return values;
        }

        const tmcTestVialPerSample = LabDataCalculatedFieldsService.calculateTMCTestVialPerSample(currentValues);
        const tmcAllVials = LabDataCalculatedFieldsService.calculateRemainderTMCAllVials(currentValues);
        const survival = LabDataCalculatedFieldsService.calculateRemainderSurvival(currentValues);
        const overallEfficiency = LabDataCalculatedFieldsService.calculateRemainderOverallEfficiency(currentValues);
        if (tmcTestVialPerSample) {
            values.TMCTestVialPerSample = tmcTestVialPerSample.toFixed(1);
        }
        if (tmcAllVials) {
            values.TMCAllVials = tmcAllVials.toFixed(1);
        }
        if (survival) {
            values.Survival = survival.toFixed(1) + '%';
        }
        if (overallEfficiency) {
            values.OverallEfficiency = overallEfficiency.toFixed(1) + '%';
        }

        return values;
    }
}
