import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DonorApplicationFormsModule } from './donor-application-forms/donor-application-forms.module';
import { CommonComponentsModule } from './components/components.module';
import { FallbackImgDirective } from './directives/fallback-image.directive';

@NgModule({
    declarations: [FallbackImgDirective],
    exports: [DonorApplicationFormsModule, CommonComponentsModule, FallbackImgDirective],
    imports: [CommonModule, DonorApplicationFormsModule, CommonComponentsModule],
})
export class AngularCommonModule {}
