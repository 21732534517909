import { Component, Input } from '@angular/core';
import { ISalesOrder } from '@model/interfaces/sales-order';
import { IShipping } from '@model/interfaces/shipping';
import { SalesOrderService } from 'admin-portal/recipient-users/services/sales-order.service';
import { Subscription } from 'rxjs';
import { ShippingService } from './services/shipping.service';

@Component({
    selector: 'sales-order-shipping-details',
    template: `
        <div *ngIf="shippingDetails">
            <div class="row">
                <div class="col-md-6">
                    <app-shipping-basic-info
                        [salesOrder]="salesOrder"
                        [canEdit]="canEdit"
                        [shippingDetails]="shippingDetails"
                    ></app-shipping-basic-info>
                </div>
                <div class="col-md-6">
                    <app-shipping-emails [shippingDetails]="shippingDetails" [recipientId]="salesOrder.RecipientId"></app-shipping-emails>
                </div>
            </div>
        </div>
    `,
})
export class ShippingDetailsComponent {
    @Input() canEdit: boolean;
    @Input() salesOrder: ISalesOrder;

    shippingDetails: IShipping;

    subs: Subscription = new Subscription();

    constructor(private salesOrderService: SalesOrderService, private shippingService: ShippingService) {}

    ngOnInit(): void {
        this.salesOrderService.getShippingDetailsBySalesOrderId(this.salesOrder.Id).subscribe((shipping) => {
            this.shippingDetails = shipping ? shipping : this.shippingService.getEmptyShipping(this.salesOrder.Id);
        });
        this.subs.add(
            this.shippingService.changeEmitted$.subscribe((shipping) => {
                this.shippingDetails = shipping;
            }),
        );
    }
}
