import { DynamicField, DynamicFieldType, DynamicFieldTypes, NumericInputTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { BirthDetailDynamicControls, IBirthDetailDynamicControlsParameters } from '../form-controls/birth-detail.form-controls';
import { IBirthDetail } from '../interfaces/birth-detail';

export class BirthDetailDynamicControlsPartial extends BirthDetailDynamicControls {
    constructor(birthdetailPartial?: IBirthDetail, additionalParameters?: IBirthDetailDynamicControlsParameters) {
        super(birthdetailPartial, additionalParameters);

        (<DynamicField>this.Form.Height) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Height',
            name: 'Height',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Float,
                scale: 2,
            }),
            validation: [],
            validators: {},
            value: (birthdetailPartial && birthdetailPartial.Height) || null,
        });
        (<DynamicField>this.Form.Weight) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Weight',
            name: 'Weight',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Float,
                scale: 2,
            }),
            validation: [],
            validators: {},
            value: (birthdetailPartial && birthdetailPartial.Weight) || null,
        });

        (<DynamicLabel>this.View.Height).value = birthdetailPartial?.Height || null;
        (<DynamicLabel>this.View.Weight).value = birthdetailPartial?.Weight || null;
        (<DynamicLabel>this.View.Height).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Float,
            scale: 2,
        });
        (<DynamicLabel>this.View.Weight).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Float,
            scale: 2,
        });
    }
}
