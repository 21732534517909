import { Component } from '@angular/core';
import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';

import { AbstractLabDataFormComponent } from '../base-components/abstract-lab-data-form.component';

@Component({
    selector: 'app-donor-raw-semen',
    templateUrl: './raw-semen.component.html',
})
export class RawSemenComponent extends AbstractLabDataFormComponent {
    sectionId = DonorTestSampleLabDataSections.RawSemen;
    volumeLabelText = '<label>Volume</label>';
}
