import { BloodWorkStatuses } from '@model/enums/blood-work-statuses.enum';
import { RegulatoryQuestionnaireStatuses } from '@model/enums/regulatory-questionnaire-statuses.enum';
import { PhysicalStatuses } from '@model/enums/physical-statuses.enum';
import { DynamicField, DynamicLabel, SelectInputTypes } from '@mt-ng2/dynamic-form';

import {
    DonorOngoingScreeningDynamicControls,
    IDonorOngoingScreeningDynamicControlsParameters,
} from '../form-controls/donor-ongoing-screening.form-controls';
import { IDonorOngoingScreening } from '../interfaces/donor-ongoing-screening';

export class DonorOngoingScreeningDynamicControlsPartial extends DonorOngoingScreeningDynamicControls {
    constructor(donorongoingscreeningPartial?: IDonorOngoingScreening, additionalParameters?: IDonorOngoingScreeningDynamicControlsParameters) {
        super(donorongoingscreeningPartial, additionalParameters);

        (<DynamicField>this.Form.BloodWorkStatusId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.BloodWorkStatusId).value = donorongoingscreeningPartial?.BloodWorkStatusId ?? BloodWorkStatuses.Pending;

        (<DynamicField>this.Form.RegulatoryQuestionnaireStatusId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.RegulatoryQuestionnaireStatusId).value =
            donorongoingscreeningPartial?.RegulatoryQuestionnaireStatusId ?? RegulatoryQuestionnaireStatuses.NotAvailable;

        (<DynamicField>this.Form.PhysicalStatusId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.PhysicalStatusId).value = donorongoingscreeningPartial?.PhysicalStatusId ?? PhysicalStatuses.NotAvailable;

        (<DynamicField>this.Form.BloodDrawDate).labelHtml = '<label>Screening/Testing Date</label>';
        (<DynamicLabel>this.View.BloodDrawDate).label = 'Screening/Testing Date';
    }
}
