<ngx-skeleton-loader count="15" *ngIf="!formCreated"></ngx-skeleton-loader>
<div *ngIf="formCreated">
    <form class="miles-form padded row application-section" [formGroup]="preliminaryHistoryForm">
        <div class="input-scaffold">
            <dynamic-field
                [field]="abstractPreliminaryHistoryControls.HasStd"
                [form]="preliminaryHistoryForm"
                (valueChanges)="onHasStdValueChange($event)"
            ></dynamic-field>
        </div>
        <div *ngIf="showStdFollowUps" class="input-scaffold">
            <dynamic-field [field]="abstractPreliminaryHistoryControls.StdName" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>
        <div *ngIf="showStdFollowUps" class="input-scaffold">
            <dynamic-field [field]="abstractPreliminaryHistoryControls.DateOfStdTreatment" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field [field]="abstractPreliminaryHistoryControls.HadSexWithMan" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field [field]="abstractPreliminaryHistoryControls.HasHiv" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field [field]="abstractPreliminaryHistoryControls.HadVasectomy" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field
                [field]="abstractPreliminaryHistoryControls.HasDonatedBefore"
                [form]="preliminaryHistoryForm"
                (valueChanges)="onHasDonatedBeforeValueChanges($event)"
            ></dynamic-field>
        </div>
        <div class="input-scaffold" *ngIf="showHasDonatedBeforeFollowUps">
            <dynamic-field [field]="abstractPreliminaryHistoryControls.TimesDonated" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field
                [field]="abstractPreliminaryHistoryControls.ListedOnAnotherRoster"
                [form]="preliminaryHistoryForm"
                (valueChanges)="onListedOnAnotherRosterValueChanges($event)"
            ></dynamic-field>
        </div>
        <div class="input-scaffold" *ngIf="showListedOnAnotherRosterFollowUps">
            <dynamic-field [field]="abstractPreliminaryHistoryControls.OtherRosters" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field
                [field]="abstractPreliminaryHistoryControls.IsAdopted"
                [form]="preliminaryHistoryForm"
                (valueChanges)="onIsAdoptedValueChanges($event)"
            ></dynamic-field>
        </div>
        <div class="input-scaffold" *ngIf="showIsAdoptedFollowUps">
            <dynamic-field [field]="abstractPreliminaryHistoryControls.KnowsFamilyHistory" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>

        <div class="input-scaffold">
            <dynamic-field
                [field]="abstractPreliminaryHistoryControls.HasAdoptedParents"
                [form]="preliminaryHistoryForm"
                (valueChanges)="onHasAdoptedParentsValueChanges($event)"
            ></dynamic-field>
        </div>
        <div class="input-scaffold" *ngIf="showHasAdoptedParentsFollowUps">
            <dynamic-field [field]="abstractPreliminaryHistoryControls.KnowsParentsFamilyHistory" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>
    </form>
</div>
