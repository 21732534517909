<div>
    <div [style]="zoom ? 'zoom: 80%' : ''">
        <entity-list
            [entities]="donorInventory"
            [entityListConfig]="entityListConfig"
            [emptyMessage]="noResultText"
            [total]="total"
            [showPagination]="false"
            [selectedEntities]="selectedInventory"
            (onSelectionChanged)="inventorySelected($event)"
            (onColumnSorted)="onColumnSorted($event)"
        ></entity-list>
    </div>
    <button type="submit" Class="btn btn-flat btn-success" (click)="inventorySelectionFinished()">Add Donor Inventory</button>
    <button type="button" Class="btn btn-flat btn-default" (click)="cancelEdit()">Cancel</button>
</div>
