<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && thawing.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    [style.cursor]="canEdit && !isFinalized ? 'pointer' : 'default'"
>
    <h4>
        Thawing Info <span *ngIf="isFinalized"><em style="color: red">Thawing is finalized</em></span>
        <span class="pull-right" *ngIf="canEdit && !isFinalized" (click)="edit()" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>

    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Thawing Info</h4>
    <app-dynamic-form
        [config]="config.formObject"
        ([isEditing])="(isEditing)"
        (submitted)="formSubmitted($event)"
        (formCreated)="onFormCreated($event)"
    >
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        <button
            type="button"
            class="btn btn-flat btn-primary pull-right"
            (mtConfirm)="finalizeThawing($event)"
            [mtConfirmOptions]="finalizeConfirmOptions"
        >
            Finalize Thawing
        </button>
    </app-dynamic-form>
</div>
