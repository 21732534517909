import { ISalesOrder } from '@model/interfaces/sales-order';
import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class SalesOrdersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Id'],
                    name: 'Sales Order Id',
                }),
                new EntityListColumn({
                    accessors: ['ProductType', 'Name'],
                    name: 'Product Type',
                }),
                new EntityListColumn({
                    accessors: ['VialStatus', 'Name'],
                    name: 'Vial Status',
                }),
                new EntityListColumn({
                    accessors: ['SalesOrderStatus', 'Name'],
                    name: 'Status',
                }),
                new EntityListColumn({
                    accessorFunction: (value: ISalesOrder) => {
                        return value.ReplacementOrder ? 'Yes' : 'No';
                    },
                    name: 'Replacement Order',
                }),
                new EntityListColumn({
                    accessors: ['CreatedDate'],
                    name: 'Created Date',
                    pipes: ['date:mediumDate'],
                }),
            ],
        };
        super(listConfig);
    }
}
