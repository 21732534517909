import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { IDonorTestSampleDynamicControlsPartialParameters } from '@model/partials/donor-test-sample-partial.form-controls';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';

import { DonorTestSampleDynamicConfig } from '../donor-test-sample.dynamic-config';
import { DonorSampleService } from '../../../services/donor-sample.service';
import { SamplePrepMethodService } from '../../../services/sample-prep-method.service';
import { DonorSampleStatusService } from '../services/donor-sample-status.service';
import { TestSampleTypeService } from '../services/test-sample-type.service';
import { UserService } from 'admin-portal/users/user.service';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-donor-test-sample-basic-info',
    templateUrl: './donor-test-sample-basic-info.html',
})
export class DonorTestSampleBasicInfoComponent implements OnInit {
    @Input() canEdit: boolean;
    @Input() donorTestSample: IDonorSample;
    @Output() openEditing = new EventEmitter<boolean>();
    donorId: number;

    doubleClickIsDisabled: boolean;
    isEditing = false;

    additionalParams: IDonorTestSampleDynamicControlsPartialParameters;
    config: IDynamicFormConfig = { formObject: [] };
    formFactory: any;

    constructor(
        private donorTestSampleService: DonorSampleService,
        private testSampleTypeService: TestSampleTypeService,
        private testSampleStatusService: DonorSampleStatusService,
        private testSamplePrepMethodService: SamplePrepMethodService,
        private notificaitionService: NotificationsService,
        private notificationsService: NotificationsService,
        private usersService: UserService,
    ) {}

    ngOnInit(): void {
        this.donorId = this.donorTestSample.DonorId;
        forkJoin([
            this.testSampleTypeService.getItems(),
            this.testSampleStatusService.getItems(),
            this.testSamplePrepMethodService.getItems(),
            this.usersService.getUsersByClaimAsMetaItem(ClaimTypes.Donors_ProcessSamples),
        ]).subscribe(([, , , sampleProcessors]) => {
            this.additionalParams = {
                prepMethods: this.testSamplePrepMethodService.items,
                sampleProcessorMetaItems: sampleProcessors,
                sampleStatuses: this.testSampleStatusService.items,
                testSampleTypes: this.testSampleTypeService.items,
            };
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new DonorTestSampleDynamicConfig(this.donorTestSample, this.additionalParams);
        this.config = this.formFactory.getForUpdate();
    }

    edit(): void {
        this.isEditing = true;
        this.openEditing.emit(true);
    }

    close(): void {
        this.isEditing = false;
        this.openEditing.emit(false);
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.donorTestSample, form.value.DonorSample);
            this.donorTestSampleService.update(this.donorTestSample).subscribe(() => {
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success = () => {
        this.notificaitionService.success('Test Sample Updated');
        this.donorTestSampleService.emitChange(this.donorTestSample);
        this.close();
        this.enableDoubleClick();
        this.setConfig();
    }
}
