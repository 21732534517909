import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDonor } from '@model/interfaces/donor';
import { CommonService } from '@angular-common/services/common.service';
import { DonorAvailabilityDynamicControlsPartial } from '@model/partials/donor-availability-partial.form-controls';
import { ICountry } from '@model/interfaces/country';
import { IDonorAvailability } from '@model/interfaces/donor-availability';
import { DonorService } from '../services/donor.service';
import { IState } from '@model/interfaces/state';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-donor-availabilities',
    templateUrl: './donor-availabilities.component.html',
})


export class DonorAvailabilitiesComponent implements OnInit {
    @Input() canEdit: boolean;
    @Input() donor: IDonor;
    // abstract controls
    abstractDonorAvailabilityControls: any;

    donorAvailabilitiesForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    donorAvailabilities: IDonorAvailability[];
    showSelectAuStates: boolean;
    isEditing: boolean;

    availableInCountries: ICountry[];
    availableInStates?: IState[];

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        private donorService: DonorService,
    ) { }

    ngOnInit(): void {


        forkJoin(
            this.commonService.getCountries(),
            this.commonService.getStates())
            .subscribe
            (([countries, states]) => {
                this.availableInCountries = countries.filter(c => c.IsAvailableInRegion).sort((c1, c2) => c1.SortOrder - c2.SortOrder);
                this.availableInStates = states;
                this.createForm();
            }
            );
    }

    createForm(): void {
        this.donorAvailabilities = this.donor.DonorAvailabilities;
        this.getControls();
        this.donorAvailabilitiesForm = this.assignFormGroups();
        this.determineFormFields();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractDonorAvailabilityControls = new DonorAvailabilityDynamicControlsPartial(this.donorAvailabilities, {
            formGroup: 'donorAvailabilitiesForm',
            countries: this.availableInCountries,
            regions: this.availableInStates
        }).Form;
    }


    determineFormFields(): void {
        if (this.donorAvailabilities && this.donorAvailabilities.length > 0 && this.availableInStates.some((r) => r.CountryCode === 'AU')) {
            this.showSelectAuStates = true;
        }
    }

    onCountryChanged(value): void {
        if (value) {
            const regions = this.donorAvailabilities.filter((itm) => value.includes(itm.CountryCode));
            this.showSelectAuStates = regions && regions.find((itm) => itm.CountryCode === 'AU') ? true : false;

        } else {
            this.showSelectAuStates = false;
        }
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            donorAvailabilitiesForm: this.fb.group({}),
        });
    }

    edit(): void {
        this.isEditing = true;
    }

    formSubmitted(): void {
        if (this.donorAvailabilitiesForm.valid) {
            this.donorAvailabilities = this.assignFormValues(this.donorAvailabilitiesForm.value.donorAvailabilitiesForm);
            this.donorService.saveDonorAvailabilities(this.donor.Id, this.donorAvailabilities).subscribe((answer) => {
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.donorAvailabilitiesForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    assignFormValues(value: any): IDonorAvailability[] {
        let  auStates;

        if (this.showSelectAuStates) {
            auStates = this.parseStateFormValues(value.AuStateCodes);
        }

        return [].concat(this.parseCountryFormValues(value.CountryCodes), auStates).filter((itm) => itm);
    }


    parseCountryFormValues(values: string[]): IDonorAvailability[] {
        if (!values) {
            return [];
        }
        return this.availableInCountries
            .filter((itm) => values.includes(itm.CountryCode))
            .map((region) => {
                return {
                    CountryCode: region.CountryCode,
                    Id: 0,
                    DonorId: this.donor.Id,
                };
            });
    }

    
    parseStateFormValues(values: number[]): IDonorAvailability[] {
        if (!values) {
            return [];
        }
        return this.availableInStates
            .filter((itm) => values.includes(itm.Id))
            .map((region) => {
                return {
                    CountryCode: region.CountryCode,
                    Id: 0,
                    StateCode: region.StateCode,
                    State: null,
                    DonorId: this.donor.Id
                };
            });
    }

    parseCountryName(region: IDonorAvailability): string {
        return this.availableInCountries.find((itm) => itm.CountryCode === region.CountryCode).Name;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Donor availabilities saved successfully.');
        this.enableDoubleClick();
        this.isEditing = false;
    }


    displayRegionNames(availabilities: IDonorAvailability[]): string[] {
        let strArray = [];
        const countries = availabilities.filter((itm) => itm.CountryCode && !itm.StateCode);
        countries.forEach((country) => {
            let regionStr = '';
            regionStr += `${this.parseCountryName(country)}`;
            const availableStates = availabilities.filter((itm) => itm.CountryCode === country.CountryCode && itm.StateCode);

            if (availableStates.length) {
                regionStr += ` (${availableStates
                    .map((availableState) => {
                        return this.parseStates(availableState);
                    })
                    .join(', ')}) `;
            }
            strArray.push(regionStr);
        });
        return strArray;
    }

    parseStates(availableState: IDonorAvailability): string {
        let state = this.availableInStates.find((itm) => itm.CountryCode === availableState.CountryCode && itm.StateCode.trim() === availableState.StateCode.trim());
        return state.Name + (state.FamilyLimit ? " [Family Limit: "+state.FamilyLimit+"]" : "");
    }
}
