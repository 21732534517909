import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { SalesOrderService } from '../../services/sales-order.service';
import { SalesOrderDynamicConfig } from '../sales-order.dynamic-config';
import { VialStatusService } from '../../services/vial-status.service';
import { SalesOrderStatusService } from '../../services/recipient-cycle-status.service';

import { ISalesOrder } from '@model/interfaces/sales-order';
import { SamplePrepMethodService } from 'admin-portal/donors/services/sample-prep-method.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IVwDonorInventorySearchEntity } from '@model/interfaces/vw-donor-inventory-search-entity';
import { IVwDonorInventory } from '@model/interfaces/vw-donor-inventory';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-sales-order-basic-info',
    styles: [
        `
            .header-button {
                font-size: 11px;
                margin-top: -10px;
            }
            .mr-28 {
                margin-right: 28px;
            }
            .mr-4 {
                margin-right: 4px;
            }
        `,
    ],
    templateUrl: './sales-order-basic-info.component.html',
})
export class SalesOrderBasicInfoComponent implements OnInit {
    @Input() salesOrder: ISalesOrder;
    @Input() canEdit: boolean;

    isEditing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: SalesOrderDynamicConfig<ISalesOrder>;
    doubleClickIsDisabled = false;

    viewAuditLog: boolean;

    showDonorSearchModal: boolean;
    searchModalOptions: IModalOptions = {
        showConfirmButton: false,
        titleText: 'Search Donors',
        width: '80%',
    };
    selectedDonor: IVwDonorInventorySearchEntity;

    showDonorInventoryModal: boolean;
    searchDonorInventoryOptions: IModalOptions = {
        showConfirmButton: false,
        titleText: 'Select Donor Inventory',
        width: '80%',
    };
    selectedInventory: IVwDonorInventory[];

    productTypeId: number;
    vialStatusId: number;

    get isNewSalesOrder(): boolean {
        return this.salesOrder && this.salesOrder.Id ? false : true;
    }

    constructor(
        private salesOrderService: SalesOrderService,
        private vialStatusService: VialStatusService,
        private salesOrderStatusService: SalesOrderStatusService,
        private samplePrepService: SamplePrepMethodService,
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.viewAuditLog = this.claimsService.hasClaim(ClaimTypes.Audit, [ClaimValues.FullAccess || ClaimValues.ReadOnly]);
        this.productTypeId = this.salesOrder.ProductTypeId;
        this.vialStatusId = this.salesOrder.VialStatusId;
        forkJoin([this.vialStatusService.getItems(), this.salesOrderStatusService.getItems(), this.samplePrepService.getItems()]).subscribe(
            (answers) => {
                this.setConfig();
            },
        );
    }

    setConfig(): void {
        let configControls: string[];
        if (this.isNewSalesOrder) {
            configControls = ['ProductTypeId', 'VialStatusId', 'ReplacementOrder'];
        }
        this.formFactory = new SalesOrderDynamicConfig<ISalesOrder>(
            this.salesOrder,
            {
                productTypes: this.samplePrepService.items,
                salesOrderStatuses: this.salesOrderStatusService.items,
                vialStatuses: this.vialStatusService.items,
            },
            configControls,
            this.isNewSalesOrder,
        );

        if (this.isNewSalesOrder) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewSalesOrder) {
            this.router.navigate(['../../..'], { relativeTo: this.route });
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.salesOrder, form.value.SalesOrder);
            this.saveSalesOrder();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    onFormCreated(formGroup: FormGroup): void {
        formGroup.get('SalesOrder.ProductTypeId').valueChanges.subscribe((value) => {
            if (this.salesOrder.Donors?.length && this.productTypeId && this.productTypeId !== value) {
                alert('Changing product type may conflict with selected inventory');
            }
            this.productTypeId = value;
        });
        formGroup.get('SalesOrder.VialStatusId').valueChanges.subscribe((value) => {
            if (this.salesOrder.Donors?.length && this.vialStatusId && this.vialStatusId !== value) {
                alert('Changing vial status may conflict with selected inventory');
            }
            this.vialStatusId = value;
        });
    }

    private saveSalesOrder(): void {
        if (this.isNewSalesOrder) {
            this.salesOrderService
                .create(this.salesOrder)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.salesOrder.Id = answer;
                    this.success(true);
                });
        } else {
            this.salesOrderService
                .update(this.salesOrder)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((success) => {
                    this.success();
                });
        }
    }

    private success(newSalesOrderSave?: boolean): void {
        if (newSalesOrderSave) {
            this.router.navigate([`recipient-users/${this.salesOrder.RecipientId}/sales-orders/${this.salesOrder.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.salesOrderService.emitChange(this.salesOrder);
        this.notificationsService.success('Sales order saved successfully.');
    }

    onDonorSelected(donorInvSummary: IVwDonorInventorySearchEntity): void {
        this.selectedDonor = donorInvSummary;
        this.showDonorSearchModal = false;
        this.showDonorInventoryModal = true;
    }

    onInventorySelected(selectedInv: IVwDonorInventory[]): void {
        this.selectedInventory = selectedInv;
        this.salesOrderService.AddDonorAndInventory(this.salesOrder.Id, this.selectedDonor.Id, this.selectedInventory).subscribe(() => {
            this.setConfig();
            this.showDonorInventoryModal = false;
        });
    }

    removeDonorFromSalesOrder(index: number): void {
        let [donorToRemove] = this.salesOrder.Donors.splice(index, 1);
        this.salesOrderService.RemoveDonorAndInventory(this.salesOrder.Id, donorToRemove.Id).subscribe(
            (so) => {
                this.salesOrder = so;
                this.success();
            },
            () => {
                this.salesOrder.Donors.splice(index, 0, donorToRemove);
                this.notificationsService.error('There was an error removing the donor');
            },
        );
    }

    downloadSHSBDF3(): void {
        this.salesOrderService.getSHSBDF3Pdf(this.salesOrder.Id).subscribe((bytes: any) => {
            const thefile = new Blob([bytes as ArrayBuffer], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, `SHSBDF3 RDI - Domestic - File.pdf`);
        });
    }

    downloadSHSBDF2(): void {
        this.salesOrderService.getSHSBDF2Pdf(this.salesOrder.Id).subscribe((bytes: any) => {
            const thefile = new Blob([bytes as ArrayBuffer], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, `SHSBDF2 RDI - International - File.pdf`);
        });
    }
}
