import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IClinic } from '@model/interfaces/clinic';
import { Observable } from 'rxjs';
import { SearchParams } from '@mt-ng2/common-classes';
import { IClinicDto } from '@model/interfaces/custom/clinic-dto';
import { catchError } from 'rxjs/operators';

export const emptyClinic: IClinic = {
    Id: 0,
    Name: null,
};

@Injectable({
    providedIn: 'root',
})
export class ClinicService extends BaseService<IClinic> {
    constructor(public http: HttpClient) {
        super('/clinics', http);
    }

    search(searchparams: SearchParams): Observable<HttpResponse<IClinicDto[]>> {
        let params = this.getHttpParams(searchparams);
        return this.http
            .get<IClinicDto[]>('/clinics/_search', {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }

    getEmptyClinic(): IClinic {
        return { ...emptyClinic };
    }

    addVerification(clinic: IClinic): Observable<void> {
        return this.http.post<void>(`/clinics/${clinic.Id}/verify`, clinic);
    }
}
