import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { DonorService } from '../services/donor.service';
import { IDonor } from '@model/interfaces/donor';
import { ClaimTypes } from '@model/ClaimTypes';
import { NoteEntities } from '@model/enums/note-entities.enum';
import { DocumentEntities } from '@model/enums/document-entities.enum';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { IMetaItem } from '@mt-ng2/base-service';
import { formatDate } from '@angular/common';

@Component({
    templateUrl: './donor-detail.component.html',
})
export class DonorDetailComponent implements OnInit {
    donor: IDonor;
    canEdit: boolean;
    noteEntity = NoteEntities.Donor;

    claimType = ClaimTypes.Donors;
    docEntityType = DocumentEntities.Donors;

    donationDates: IMetaItem[] = [];

    constructor(
        private donorService: DonorService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Donors, [ClaimValues.FullAccess]);
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'donorId');
        if (id) {
            this.getDonorById(id);
        } else {
            this.router.navigate(['donors']); // if no id found, go back to list
        }
    }

    getDonorById(id: number): void {
        this.donorService.getById(id).subscribe((donor) => {
            if (donor === null) {
                this.notificationsService.error('Donor not found');
                this.router.navigate(['donors']);
            }

            this.donor = donor;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        let id = route.snapshot.paramMap.get(param);
        return parseInt(id, 10) === NaN ? null : parseInt(id, 10);
    }

    getDonationDates(event: IDonorSample[]): void {
        this.donationDates = event.map((e) => ({Id: e.Id, Name: formatDate(e.SampleDate, 'mediumDate', 'en-US')} as IMetaItem));
    }
}
