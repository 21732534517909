import { NgModule } from '@angular/core';
import { AngularCommonModule } from '@angular-common/angular-common.module';
import { AdminPortalFeatureModule } from '@common/feature.module';
import { AdminPortalSharedModule } from '@common/shared.module';

import { AbstractLabDataComponent } from './base-components/abstract-lab-data.component';
import { AbstractLabDataFormComponent } from './base-components/abstract-lab-data-form.component';
import { RawSemenComponent } from './raw-semen/raw-semen.component';
import { PostGradientComponent } from './post-gradient/post-gradient.component';
import { CryoPrepComponent } from './cryo-prep/cryo-prep.component';
import { IVFVialsComponent } from './ivf-vials/ivf-vials.component';
import { TestThawInfoComponent } from './test-thaw-info/test-thaw-info.component';
import { LabDataLocationComponent } from './location/lab-data-location.component';
import { RemainderVialDetailsComponent } from './remainder-vial-details/remainder-vial-details.component';
import { RemainderVialsComponent } from './remainder-vials/remainder-vials.component';
import { RemainderTestThawComponent } from './remainder-test-thaw/remainder-test-thaw.component';

@NgModule({
    declarations: [
        AbstractLabDataComponent,
        AbstractLabDataFormComponent,
        RawSemenComponent,
        PostGradientComponent,
        CryoPrepComponent,
        IVFVialsComponent,
        TestThawInfoComponent,
        LabDataLocationComponent,
        RemainderVialDetailsComponent,
        RemainderVialsComponent,
        RemainderTestThawComponent,
    ],
    exports: [
        RawSemenComponent,
        PostGradientComponent,
        CryoPrepComponent,
        IVFVialsComponent,
        TestThawInfoComponent,
        LabDataLocationComponent,
        RemainderVialDetailsComponent,
        RemainderVialsComponent,
        RemainderTestThawComponent,
    ],
    imports: [AdminPortalFeatureModule, AdminPortalSharedModule, AngularCommonModule],
    providers: [],
})
export class DonorSampleLabDataModule {}
