import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Input } from '@angular/core';
import { DonorSsnService } from '@angular-common/services/donor-ssn.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
  selector: 'app-ssn-editor',
  styleUrls: ['./ssn-editor.component.css'],
  templateUrl: './ssn-editor.component.html',
})
export class SsnEditorComponent implements OnInit {

  @Input() id: number;
  ssn = new FormControl({
    validation: [Validators.required, Validators.maxLength(9), Validators.minLength(9)],
    validators: { required: true, maxlength: 9, minlength: 9 },
  });

  formattedSsn: string;
  showSsn = false;
  canViewEdit = false;
  isEditing = false;
  private rawSsn: string;

  get showEdit(): boolean {
      return this.canViewEdit;
  }

  constructor(
       private donorSsnService: DonorSsnService,
      private claimService: ClaimsService,
      private notificationsService: NotificationsService,
  ) {
      this.canViewEdit = this.claimService.hasClaim(ClaimTypes.ViewEditSSN, [ClaimValues.FullAccess]);
   }

  ngOnInit(): void {
   }

  formatSsn(ssn: string): void {
      this.formattedSsn = ssn.slice(0, 3) + '-' + ssn.slice(3, 5) + '-' + ssn.slice(5);
  }

  getSsn(): void {
      if (this.rawSsn) {
          this.formatSsn(this.rawSsn);
          this.showSsn = true;
      } else {
              this.donorSsnService.getDonorSsn(this.id).subscribe((data) => {
                  this.rawSsn = data;
                  this.formatSsn(data);
                  this.showSsn = true;
              });
      }
  }

  hideSsn(): void {
      this.formatSsn('*********');
      this.showSsn = false;
  }

  edit(): void {
      if (this.canViewEdit) {
          if (this.rawSsn) {
              this.buildSsnField();
          } else {
              this.donorSsnService.getDonorSsn(this.id).subscribe((data) => {
                  this.rawSsn = data;
                  this.buildSsnField();
              });
          }
      }
  }

  buildSsnField(): void {
    this.isEditing = true;
    this.showSsn = false;
    this.ssn.setValue(this.rawSsn);
  }

  save(): void {
    let Ssn = this.ssn.value;
    if (Ssn && Ssn.length === 9) {
      this.donorSsnService.updateDonorSsn(this.id, Ssn).subscribe((resp) => {
          this.rawSsn = Ssn;
          this.isEditing = false;
          this.showSsn = false;
          this.notificationsService.success('Social security number successfully updated');
      }, (error) => {                              // Error callback
        this.notificationsService.error(error.error.message);
      },
      );
    } else {
        this.ssn.markAsTouched();
        this.notificationsService.error('Please check the SSN and try again.');
    }
}

  cancel(): void {
      this.isEditing = false;
  }

}
