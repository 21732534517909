<div class="miles-card padded">
    <h4>Notes</h4>
    <div *ngIf="!isEditing && !isViewing">
        <!-- If we are already filtering the notes we're gonna leave off the search -->
        <div *ngIf="!allowedNoteCategories">
            <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
            <mt-search-filter-select
                *ngIf="allCategorySearchFilterItems && allCategorySearchFilterItems.length > 1"
                [items]="allCategorySearchFilterItems"
                entity="Note Category"
                (onSelectionChanged)="filterSelectionChanged()"
            >
            </mt-search-filter-select>
        </div>
        <ul class="list-group" style="max-height: 305px; overflow-y: auto">
            <li
                [style.cursor]="canEdit ? 'pointer' : 'default'"
                class="list-group-item"
                *ngFor="let note of notes; last as isLast"
                (click)="showNote(note)"
            >
                <span
                    ><strong>{{ note.NoteCategory.Name }}</strong></span
                >
                <span class="pull-right">{{ note.DateCreated | date: 'medium' }}</span>
                <br />
                <span> {{ note.Title }}</span>
                <span class="pull-right">{{ note.User.FirstName }} {{ note.User.LastName }}</span>
                <scroll-check *ngIf="isLast" (inViewHasChanged)="onCheckInView($event)"></scroll-check>
            </li>
        </ul>
        <div *ngIf="canEdit" class="fab-wrap">
            <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="createNote()">
                <span class="fa fa-plus"></span>
            </button>
        </div>
    </div>
    <div *ngIf="isViewing">
        <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Close</button>
        <button type="button" class="btn btn-flat btn-danger pull-right" (click)="openDeleteNote()">Delete</button>
    </div>
    <div *ngIf="isEditing">
        <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            <button *ngIf="selectedNote.Id > 0" type="button" class="btn btn-flat btn-danger pull-right" (click)="openDeleteNote()">Delete</button>
        </app-dynamic-form>
    </div>
</div>
<!--Confirm Delete-->
<modal-wrapper *ngIf="showConfirm" [options]="confirmOptions">
    <div>
        <h3>Are you sure you want to delete?</h3>
        <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
    </div>
    <div class="row col-md-12">
        <button (click)="deleteNote()" class="btn btn-flat btn-danger">Delete</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelDeleteNote()">Cancel</button>
    </div>
</modal-wrapper>

