import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEmailDto } from '@model/interfaces/custom/email-dto';
import { AuthService } from '@mt-ng2/auth-module';
import { parseEmailAttachment } from '@common/libraries/parse-email-attachment.library';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    constructor(public http: HttpClient, private authService: AuthService) {}

    sendEmail(email: IEmailDto): Observable<any> {
        email.CurrentUserId = this.authService.currentUser.getValue().Id;
        if (email.Attachments) {
            const formData = parseEmailAttachment(email);
            return this.http.post<any>(`/api/v1/emails/send-email-with-attachments`, formData);
        } else {
            return this.http.post<any>(`/api/v1/emails/send-email`, email);
        }
    }
}
