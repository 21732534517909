<div class="mile-editable miles-card padded">
    <div *ngIf="!isEditing">
        <h4>
            Lab Data: Remainder Vial Details
            <span class="pull-right" *ngIf="canEdit" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h4>
        <div *ngIf="formCreated">
            <div class="display-padded">
                <b><label>Guarantee TMC Per Sample (x10<sup>6</sup>/ml)</label>:</b>
                <span>{{ calculatedFields.GuaranteeTMCPerSample }}</span>
            </div>
            <div class="display-padded">
                <b><label>Desired Final Motile Concentration (x10<sup>6</sup>/ml)</label>:</b>
                <span>{{ calculatedFields.DesiredFinalMotileConcentration }}</span>
            </div>
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.Volume" [label]="volumeLabelText" suffix=" ml"></dynamic-label>
            <div class="display-padded">
                <b><label>TMC Per Sample (x10<sup>6</sup>/ml)</label>:</b>
                <span>{{ calculatedFields.TMCPerSample }}</span>
            </div>
            <div class="display-padded">
                <b><label>Final Volume</label>:</b>
                <span>{{ calculatedFields.FinalVolume }}</span>
            </div>
            <div class="display-padded">
                <b><label>Arctic Added</label>:</b>
                <span>{{ calculatedFields.ArcticAdded }}</span>
            </div>
            <div class="display-padded">
                <b><label>Added Wash Media</label>:</b>
                <span>{{ calculatedFields.AddedWashMedia }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="isEditing">
        <form *ngIf="formCreated" [formGroup]="donorTestSampleLabDataForm" (ngSubmit)="formSubmitted()">
            <div>
                <h4>Lab Data: Remainder Vial Details</h4>
                <div class="calculated-field">
                    <b>Guarantee TMC Per Sample (x10<sup>6</sup>/ml)</b>
                    <input class="form-control" disabled value="{{ calculatedFields.GuaranteeTMCPerSample }}" />
                </div>
                <div class="calculated-field">
                    <b>Desired Final Motile Concentration (x10<sup>6</sup>/ml)</b>
                    <input class="form-control" disabled value="{{ calculatedFields.DesiredFinalMotileConcentration }}" />
                </div>
                <dynamic-field [field]="abstractDonorTestSampleLabDataControls.Volume" [form]="donorTestSampleLabDataForm"></dynamic-field>
                <div class="calculated-field">
                    <b>TMC Per Sample (x10<sup>6</sup>/ml)</b>
                    <input class="form-control" disabled value="{{ calculatedFields.FinalVolume }}" />
                </div>
                <div class="calculated-field">
                    <b>Final Volume</b>
                    <input class="form-control" disabled value="{{ calculatedFields.TMCPerSample }}" />
                </div>
                <div class="calculated-field">
                    <b>Arctic Added</b>
                    <input class="form-control" disabled value="{{ calculatedFields.ArcticAdded }}" />
                </div>
                <div class="calculated-field">
                    <b>Added Wash Media</b>
                    <input class="form-control" disabled value="{{ calculatedFields.AddedWashMedia }}" />
                </div>
            </div>
            <div>
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
        </form>
    </div>
</div>
