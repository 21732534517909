import { Component, Input } from '@angular/core';

import { IThawing } from '@model/interfaces/thawing';
import { SalesOrderService } from 'admin-portal/recipient-users/services/sales-order.service';

@Component({
    selector: 'app-outcomes',
    template: `
        <div *ngIf="thawingDetails?.length">
            <div class="row" *ngFor="let thawing of thawingDetails">
                <app-outcome-details [canEdit]="canEdit" [salesOrderId]="salesOrderId" [thawing]="thawing"></app-outcome-details>
            </div>
            <br />
        </div>
    `,
})
export class OutcomesComponent {
    @Input() salesOrderId: number;
    @Input() canEdit: boolean;

    thawingDetails: IThawing[];

    constructor(private salesOrderService: SalesOrderService) {}

    ngOnInit(): void {
        this.salesOrderService.GetThawingsBySalesOrderId(this.salesOrderId).subscribe((thawings) => {
            this.thawingDetails = thawings;
        });
    }
}
