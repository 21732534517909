import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDonorOngoingScreening } from '@model/interfaces/donor-ongoing-screening';
import { DonorOngoingScreeningDynamicControlsPartial } from '@model/partials/donor-ongoing-screening-partial.form-controls';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';

import { BloodWorkStatusService } from '../services/blood-work-status.service';
import { DonorOngoingScreeningService } from '../services/donor-ongoing-screening.service';
import { PhysicalStatusService } from '../services/physical-status.service';
import { RegulatoryQuestionnaireStatusService } from '../services/regulatory-questionnaire-status.service';

@Component({
    selector: 'app-donor-ongoing-screening-basic-info',
    templateUrl: './donor-ongoing-screening-basic-info.component.html',
})
export class DonorOngoingScreeningBasicInfoComponent implements OnInit {
    @Input() donorOngoingScreening: IDonorOngoingScreening;
    @Input() canEdit: boolean;

    // abstract controls
    abstractDonorOngoingScreeningControls: any;

    donorOngoingScreeningsForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;
    isHovered = false;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private bloodWorkStatusService: BloodWorkStatusService,
        private regulatoryQuestionnaireStatusService: RegulatoryQuestionnaireStatusService,
        private physicalStatusService: PhysicalStatusService,
        private donorOngoingScreeningService: DonorOngoingScreeningService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.bloodWorkStatusService.getItems(),
            this.regulatoryQuestionnaireStatusService.getItems(),
            this.physicalStatusService.getItems(),
        ]).subscribe(() => {
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.donorOngoingScreeningsForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractDonorOngoingScreeningControls = new DonorOngoingScreeningDynamicControlsPartial(this.donorOngoingScreening, {
            bloodWorkStatuses: this.bloodWorkStatusService.items,
            formGroup: 'DonorOngoingScreening',
            physicalStatuses: this.physicalStatusService.items,
            regulatoryQuestionnaireStatuses: this.regulatoryQuestionnaireStatusService.items,
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            DonorOngoingScreening: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.donorOngoingScreeningsForm.valid) {
            const screening = Object.assign({}, this.donorOngoingScreening, this.donorOngoingScreeningsForm.value.DonorOngoingScreening, {
                BloodWorkDetail: null,
            });
            this.donorOngoingScreeningService.update(screening).subscribe((resp) => {
                this.donorOngoingScreening = resp;
                this.donorOngoingScreeningService.emitChange(resp);
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.donorOngoingScreeningsForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
        this.cdr.detectChanges();
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.isEditing = false;
        this.enableDoubleClick();
        this.createForm();
        this.notificationsService.success('Donor ongoing screening saved successfully.');
    }
}
