import { Component, Input } from '@angular/core';
import { IThawing } from '@model/interfaces/thawing';

import { OutcomeStateService } from './outcome.state.service';
/**
 * This component exists just to scope OutcomeStateService to warming
 */
@Component({
    providers: [OutcomeStateService],
    selector: 'app-outcome-details',
    template: `
        <h4 class="col-md-12">Thawing {{ thawing?.ActualThawingDate | date: 'EE, MMM d, yyyy' }}</h4>
        <div class="col-md-6">
            <app-sales-order-outcome [canEdit]="canEdit" [salesOrderId]="salesOrderId" [thawingId]="thawing.Id"></app-sales-order-outcome>
            <br />
            <qc-investigation [canEdit]="canEdit" [salesOrderId]="salesOrderId" [thawingId]="thawing.Id"></qc-investigation>
        </div>
        <div class="col-md-6">
            <birth-outcome [canEdit]="canEdit" [salesOrderId]="salesOrderId" [thawingId]="thawing.Id"></birth-outcome>
        </div>
    `,
})
export class OutcomeDetailsComponent {
    @Input() salesOrderId: number;
    @Input() canEdit: boolean;
    @Input() thawing: IThawing;

    constructor() {}
}
