import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { GenderService } from '@angular-common/donor-application-forms/services/basic-info-meta-items/gender.service';
import { UnitsOfMeasurementService } from '@angular-common/donor-application-forms/services/basic-info-meta-items/units-of-measurement.service';

import { MeasurementTypeEnums } from '@model/enums/measurement-types.enum';
import { IBirthDetail } from '@model/interfaces/birth-detail';
import { BirthDetailDynamicControlsPartial } from '@model/partials/birth-detail-partial.form-controls';

import { BirthDefectService } from '../services/birth-defect.service';

@Component({
    selector: 'birth-detail',
    templateUrl: './birth-detail.component.html',
})
export class BirthDetailComponent implements OnInit {
    @Input()
    set birthDetail(value: IBirthDetail) {
        this._details = value;
        if (this.formCreated) {
            this.formCreated = false;
            this.createForm();
        }
    }
    get birthDetail(): IBirthDetail {
        return this._details;
    }
    _details: IBirthDetail;
    @Input() form: FormGroup;
    @Input() isEditing: boolean;
    // abstract controls
    abstractBirthDetailControls: any;

    formCreated = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private genderService: GenderService,
        private unitsOfMeasurementService: UnitsOfMeasurementService,
        private birthDefectService: BirthDefectService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.genderService.getItems(), this.unitsOfMeasurementService.getItems(), this.birthDefectService.getItems()]).subscribe(([]) => {
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractBirthDetailControls = new BirthDetailDynamicControlsPartial(this.birthDetail, {
            birthDefects: this.birthDefectService.items,
            formGroup: 'BirthDetail',
            genders: this.genderService.items,
            heightUnits: this.unitsOfMeasurementService.items.filter((unit) => unit.UnitsOfMeasurementTypeId === MeasurementTypeEnums.HeightNewborn),
            weightUnits: this.unitsOfMeasurementService.items.filter((unit) => unit.UnitsOfMeasurementTypeId === MeasurementTypeEnums.WeightNewborn),
        });
    }
}
