import { ApplicationSections } from '@model/enums/custom/application-sections.enum';
import { BasicInfoService } from '@angular-common/donor-application-forms/services/basic-info.service';
import { DonorIssueService } from '@angular-common/donor-application-forms/services/donor-issue.service';
import { PreliminaryHistoryService } from '@angular-common/donor-application-forms/services/prelim-history.service';
import { DonorWhoAreYouService } from '@angular-common/donor-application-forms/services/donor-who-are-you.service';
import { DonorFamilyHistoryService } from '@angular-common/donor-application-forms/services/donor-family-history.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IDonor } from '@model/interfaces/donor';
import { map } from 'rxjs/operators';
import { TreatDateAsLocal } from '@angular-common/libraries/treat-date-as-local.library';
@Injectable({
    providedIn: 'root',
})
export class DonorApplicationDetailsService {
    constructor(
        private basicInfoService: BasicInfoService,
        private preliminaryHistoryService: PreliminaryHistoryService,
        private donorIssueService: DonorIssueService,
        private whoAreYouService: DonorWhoAreYouService,
        private familyHistoryService: DonorFamilyHistoryService,
    ) {}

    update(data: any, formId: number): Observable<any> {
        switch (formId) {
            case ApplicationSections.Basicinfo:
                return this.basicInfoService.updateWithFks(data);
            case ApplicationSections.PreliminaryHistory:
                return this.preliminaryHistoryService.update(data);
            case ApplicationSections.MedicalIssues:
                return this.donorIssueService.updateWithFks(data);
            case ApplicationSections.WhoAreYou:
                return this.whoAreYouService.updateWithFks(data);
            case ApplicationSections.FamilyHistory:
                return this.familyHistoryService.updateWithFks(data);
            default:
                throw new Error('Section not found');
        }
    }

    getDonorFks(donor: IDonor, formId: number): void {
        switch (formId) {
            case ApplicationSections.Basicinfo:
                this.basicInfoService.getById(donor.Id)
                .pipe(
                    map((bi) => {
                        if (bi.Dob) {
                            bi.Dob = TreatDateAsLocal(bi.Dob);
                        }
                        return bi;
                    }),
                )
                .subscribe((res) => {
                    donor.DonorBasicInfo = res;
                });
                break;
            case ApplicationSections.PreliminaryHistory:
                this.preliminaryHistoryService.getById(donor.Id).subscribe((res) => {
                    donor.DonorPreliminaryHistory = res;
                });
                break;
            case ApplicationSections.MedicalIssues:
                this.donorIssueService.getById(donor.Id).subscribe((res) => {
                    donor.DonorIssue = res;
                });
                break;
            case ApplicationSections.WhoAreYou:
                this.whoAreYouService.getById(donor.Id).subscribe((res) => {
                    donor.DonorWhoAreYou = res;
                });
                break;
            case ApplicationSections.FamilyHistory:
                this.familyHistoryService.getById(donor.Id).subscribe((res) => {
                    donor.DonorFamilyHistory = res;
                });
                break;
            default:
                throw new Error('Section not found');
        }
    }
}
