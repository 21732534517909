import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IMedicalIssueType } from '@model/interfaces/medical-issue-type';

@Injectable({
    providedIn: 'root',
})
export class MedicalIssueTypeService extends StaticMetaItemService<IMedicalIssueType> {
    constructor(public http: HttpClient) {
        super('MedicalIssueTypeService', 'Type', 'TypeIds', '/meta-items/MedicalIssueType', http);
    }
}
