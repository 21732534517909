import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges, Input } from '@angular/core';
import { entityListModuleConfig } from '@common/shared.module';
import { IDonor } from '@model/interfaces/donor';
import { IEntity } from '@mt-ng2/base-service';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { EntityListConfig, IColumnSortedEvent, IItemSelectedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { Observable } from 'rxjs';

import { UserEntitySearchService } from './services/user-entity-search.service';

@Component({
    selector: 'app-user-entity-search',
    template: `
        <div>
            <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
            <em *ngIf="!userEntities" style="zoom: 70%">Search by first name, last name, id, or email</em>
            <div *ngIf="!searchOnInit && !userEntities">
                <em style="zoom: 70%">Or click 'X' to search all</em>
            </div>
            <br />
            <ng-content></ng-content>
            <div style="zoom: 80%" *ngIf="userEntities">
                <entity-list
                    [entities]="userEntities"
                    [total]="total"
                    [(currentPage)]="currentPage"
                    [(itemsPerPage)]="itemsPerPage"
                    (onPageChanged)="getItems()"
                    (onItemSelected)="onItemSelected($event)"
                    (onColumnSorted)="columnSorted($event)"
                    [entityListConfig]="entityListConfig"
                    [emptyMessage]="emptyMessage"
                >
                </entity-list>
            </div>
        </div>
    `,
})
export class UserEntitySearchComponent<T extends IEntity> implements OnInit, OnChanges {
    @Input() entityListConfig: EntityListConfig;
    @Input() service: UserEntitySearchService<T>;
    @Input() extraParams: ExtraSearchParams[];
    @Input() searchOnInit: boolean;
    @Input() emptyMessage = 'No results found';
    @Output() itemSelected = new EventEmitter<IItemSelectedEvent>();

    userEntities: any[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    order;
    orderDirection: string;
    donorCreationStartDate: Date;
    donorCreationEndDate: Date;
    includeInactive = false;
    overrideScreeningWorkflow = false;

    constructor() {}

    ngOnInit(): void {
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
        if (this.searchOnInit) {
            this.getItems();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.extraParams && !changes.extraParams.isFirstChange()) {
            this.filterSelectionChanged();
        }
    }

    getItemsCall(): Observable<HttpResponse<T[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.extraParams;

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.service.getUserEntities(searchparams);
    }

    getItems(): void {
        this.getItemsCall().subscribe((answer) => {
            this.userEntities = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getItems();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getItems();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getItems();
    }

    onItemSelected(event: IItemSelectedEvent): void {
        this.itemSelected.emit(event);
    }
}
