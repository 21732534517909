import { LocalizeDatesByName } from '@angular-common/libraries/treat-date-as-local.library';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInventoryPositionDto } from '@model/interfaces/custom/inventory-position-dto';
import { IInventory } from '@model/interfaces/inventory';
import { IInventoryVessel } from '@model/interfaces/inventory-vessel';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export const emptyInventoryVessel: IInventoryVessel = {
    Id: 0,
    Level: null,
    Name: null,
    Number: null,
};

export const inventoryDatesToLocalize = ['DonorSample.SampleDate'];

@Injectable({
    providedIn: 'root',
})
export class InventoryVesselService {
    inventoryUpdates: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    constructor(public http: HttpClient) {}

    getEmptyInventoryVessel(): IInventoryVessel {
        return { ...emptyInventoryVessel };
    }

    getTanks(): Observable<IInventoryVessel[]> {
        return this.http.get<IInventoryVessel[]>('/inventory-vessels/tanks');
    }

    getDirectChildren(parentNodeId: number): Observable<IInventoryVessel[]> {
        return this.http.get<IInventoryVessel[]>(`/inventory-vessels/${parentNodeId}/children`);
    }

    create(vessel: IInventoryVessel, parentNodeId?: number): Observable<number> {
        if (!parentNodeId) {
            return this.http.post<number>('/inventory-vessels', vessel);
        } else {
            return this.http.post<number>(`/inventory-vessels/${parentNodeId}/children`, vessel);
        }
    }

    update(vessel: IInventoryVessel): Observable<number> {
        return this.http.put<number>(`/inventory-vessels/${vessel.Id}`, vessel);
    }

    delete(vesselId: number): Observable<any> {
        return this.http.delete(`/inventory-vessels/${vesselId}`);
    }

    getInventoryPositionByCane(caneId: number): Observable<IInventoryPositionDto> {
        return this.http.get<IInventoryPositionDto>(`/inventory-vessels/position/${caneId}`);
    }

    saveInventoryLocation(inventoryData: IInventory): Observable<IInventory> {
        return this.http
            .post<IInventory>(`/inventory-vessels/donations/inventory`, inventoryData)
            .pipe(map((s) => LocalizeDatesByName(s, inventoryDatesToLocalize)));
    }

    saveMultipleInventoryLocations(inventoryData: IInventory, numberOfInventories: number): Observable<IInventory[]> {
        let inventoryPayload = [];

        for (let i = 1; i <= numberOfInventories; i++) {
            // VialNumber here is a place holder, bankend will generate the next number in sequence
            inventoryPayload.push(Object.assign({}, inventoryData, { VailNumber: i }));
        }
        return this.http.post<IInventory[]>(`/inventory-vessels/donations/multiple-inventories`, inventoryPayload);
    }

    updateMultipleInventoryLocations(inventories: IInventory[]): Observable<IInventory[]> {
        return this.http
            .post<IInventory[]>(`/inventory-vessels/donations/multiple-inventories`, inventories)
            .pipe(tap(() => this.inventoryUpdates.next()));
    }
}
