import { Component, OnInit } from '@angular/core';
import { IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';
import { DonorOngoingScreeningService } from '../services/donor-ongoing-screening.service';
import { IDonorOngoingScreening } from '@model/interfaces/donor-ongoing-screening';

@Component({
    selector: 'app-delete-donor-ongoing-screening',
    templateUrl: './delete-donor-ongoing-screening.component.html',
})

export class DeleteDonorOngoingScreeningComponent implements OnInit, IEntityListDynamicCellComponentGeneric<IDonorOngoingScreening> {
    entity: IDonorOngoingScreening;
    entityListComponentMembers: IEntityListComponentMembers;

    constructor(private donorOngoingScreeningService: DonorOngoingScreeningService) { }

    ngOnInit(): void { }

    get canDelete(): boolean {
        let oktoDelete = (
                            !this.entity.DonorSamples_FollowUpScreeningTestingId &&
                            !this.entity.DonorSamples_InitialScreeningTestingId &&
                            !this.entity.DonorSamples_PreQuarantineTestingId &&
                            !this.entity.DonorSamples_QuarantineReleaseTestingId &&
                            !this.entity.BloodWorkDetail &&
                            !this.entity.DonorOngoingScreeningDocuments);

        return oktoDelete;
    }

    deleteDonorOngoingScreening(e: Event): void {
        e.stopPropagation();
        this.donorOngoingScreeningService.delete(this.entity.Id).subscribe();
    }
}
