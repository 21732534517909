import { NgModule } from '@angular/core';

import { AdminPortalSharedModule } from '@common/shared.module';
import { AdminPortalFeatureModule } from '@common/feature.module';
import { DonorOngoingScreeningsComponent } from './donor-ongoing-screenings-list-add/donor-ongoing-screenings.component';
import { DonorOngoingScreeningDetailComponent } from './donor-ongoing-screening-detail/donor-ongoing-screening-detail.component';
import { DonorOngoingScreeningHeaderComponent } from './donor-ongoing-screening-header/donor-ongoing-screening-header.component';
import { DonorOngoingScreeningBasicInfoComponent } from './donor-ongoing-screening-basic-info/donor-ongoing-screening-basic-info.component';
import { AngularCommonModule } from '@angular-common/angular-common.module';
import { BloodWorkDetailComponent } from './blood-work-detail/blood-work-detail.component';

@NgModule({
    declarations: [
        DonorOngoingScreeningsComponent,
        DonorOngoingScreeningDetailComponent,
        DonorOngoingScreeningHeaderComponent,
        DonorOngoingScreeningBasicInfoComponent,
        BloodWorkDetailComponent,
    ],
    exports: [DonorOngoingScreeningsComponent, DonorOngoingScreeningDetailComponent],
    imports: [AdminPortalSharedModule, AdminPortalFeatureModule, AngularCommonModule],
})
export class DonorOngoingScreeningModule {}
