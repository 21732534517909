<div *ngIf="formCreated && !isEditing">
    <div class="row">
        <div class="col-md-12">
            <dynamic-label [field]="abstractBirthDetailControls.View.GenderId"></dynamic-label>
            <dynamic-label [field]="abstractBirthDetailControls.View.HeightUnitId"></dynamic-label>
            <dynamic-label [field]="abstractBirthDetailControls.View.Height"></dynamic-label>
            <dynamic-label [field]="abstractBirthDetailControls.View.WeightUnitId"></dynamic-label>
            <dynamic-label [field]="abstractBirthDetailControls.View.Weight"></dynamic-label>
            <dynamic-label [field]="abstractBirthDetailControls.View.BirthDefectsId"></dynamic-label>
            <dynamic-label [field]="abstractBirthDetailControls.View.Comments"></dynamic-label>
        </div>
    </div>
</div>
<div *ngIf="formCreated && isEditing">
    <div class="row">
        <div class="col-md-12">
            <dynamic-field
                [field]="abstractBirthDetailControls.Form.GenderId"
                [form]="form"
                [overrideForm]="true"
                [removeControlOnDestroy]="false"
            ></dynamic-field>
            <dynamic-field
                [field]="abstractBirthDetailControls.Form.HeightUnitId"
                [form]="form"
                [overrideForm]="true"
                [removeControlOnDestroy]="false"
            ></dynamic-field>
            <dynamic-field
                [field]="abstractBirthDetailControls.Form.Height"
                [form]="form"
                [overrideForm]="true"
                [removeControlOnDestroy]="false"
            ></dynamic-field>
            <dynamic-field
                [field]="abstractBirthDetailControls.Form.WeightUnitId"
                [form]="form"
                [overrideForm]="true"
                [removeControlOnDestroy]="false"
            ></dynamic-field>
            <dynamic-field
                [field]="abstractBirthDetailControls.Form.Weight"
                [form]="form"
                [overrideForm]="true"
                [removeControlOnDestroy]="false"
            ></dynamic-field>
            <dynamic-field
                [field]="abstractBirthDetailControls.Form.BirthDefectsId"
                [form]="form"
                [overrideForm]="true"
                [removeControlOnDestroy]="false"
            ></dynamic-field>
            <dynamic-field
                [field]="abstractBirthDetailControls.Form.Comments"
                [form]="form"
                [overrideForm]="true"
                [removeControlOnDestroy]="false"
            ></dynamic-field>
        </div>
    </div>
</div>
