import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IShipping } from '../interfaces/shipping';
import { ISalesOrder } from '../interfaces/sales-order';
import { IShipmentType } from '../interfaces/shipment-type';
import { IShipper } from '../interfaces/shipper';
import { IShippingMethod } from '../interfaces/shipping-method';
import { IShippingStatus } from '../interfaces/shipping-status';
import { IClinic } from '../interfaces/clinic';

export interface IShippingDynamicControlsParameters {
    formGroup?: string;
    salesOrders?: ISalesOrder[];
    shippingStatuses?: IShippingStatus[];
    shipmentTypes?: IShipmentType[];
    shippingMethods?: IShippingMethod[];
    shipToClinics?: IClinic[];
    shippers?: IShipper[];
}

export class ShippingDynamicControls {

    formGroup: string;
    salesOrders: ISalesOrder[];
    shippingStatuses: IShippingStatus[];
    shipmentTypes: IShipmentType[];
    shippingMethods: IShippingMethod[];
    shipToClinics: IClinic[];
    shippers: IShipper[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private shipping?: IShipping, additionalParameters?: IShippingDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Shipping';
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;
        this.shippingStatuses = additionalParameters && additionalParameters.shippingStatuses || undefined;
        this.shipmentTypes = additionalParameters && additionalParameters.shipmentTypes || undefined;
        this.shippingMethods = additionalParameters && additionalParameters.shippingMethods || undefined;
        this.shipToClinics = additionalParameters && additionalParameters.shipToClinics || undefined;
        this.shippers = additionalParameters && additionalParameters.shippers || undefined;

        this.Form = {
            ActualShipDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Actual Ship Date',
                name: 'ActualShipDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.ActualShipDate || null,
            }),
            ClinicalBoundAirwayBillNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Clinical Bound Airway Bill Number',
                name: 'ClinicalBoundAirwayBillNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.shipping && this.shipping.hasOwnProperty('ClinicalBoundAirwayBillNumber') && this.shipping.ClinicalBoundAirwayBillNumber !== null ? this.shipping.ClinicalBoundAirwayBillNumber.toString() : '',
            }),
            DataLoggerNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Data Logger Number',
                name: 'DataLoggerNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.DataLoggerNumber || null,
            }),
            DeliveryDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Delivery Date',
                name: 'DeliveryDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.DeliveryDate || null,
            }),
            ExpectedShipDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Expected Ship Date',
                name: 'ExpectedShipDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.ExpectedShipDate || null,
            }),
            NumberOfVialsShipped: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Vials Shipped',
                name: 'NumberOfVialsShipped',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.NumberOfVialsShipped || null,
            }),
            PrintSummary: new DynamicField({
                formGroup: this.formGroup,
                label: 'Print Summary',
                name: 'PrintSummary',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.hasOwnProperty('PrintSummary') && this.shipping.PrintSummary !== null ? this.shipping.PrintSummary : false,
            }),
            ProposedShipDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Proposed Ship Date',
                name: 'ProposedShipDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.ProposedShipDate || null,
            }),
            ReturnDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Return Date',
                name: 'ReturnDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.ReturnDate || null,
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.shipping && this.shipping.SalesOrderId || null,
            }),
            ShipmentTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipment Type',
                name: 'ShipmentTypeId',
                options: this.shipmentTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.ShipmentTypeId || null,
            }),
            ShipperId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipper',
                name: 'ShipperId',
                options: this.shippers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.ShipperId || null,
            }),
            ShippingInvoiceNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipping Invoice Number',
                name: 'ShippingInvoiceNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.shipping && this.shipping.hasOwnProperty('ShippingInvoiceNumber') && this.shipping.ShippingInvoiceNumber !== null ? this.shipping.ShippingInvoiceNumber.toString() : '',
            }),
            ShippingMethodId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipping Method',
                name: 'ShippingMethodId',
                options: this.shippingMethods,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.ShippingMethodId || null,
            }),
            ShippingStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipping Status',
                name: 'ShippingStatusId',
                options: this.shippingStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.ShippingStatusId || null,
            }),
            ShipToClinicId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ship To Clinic',
                name: 'ShipToClinicId',
                options: this.shipToClinics,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.ShipToClinicId || null,
            }),
            TankNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tank Number',
                name: 'TankNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.shipping && this.shipping.TankNumber || null,
            }),
            TwebBoundAirwayBillNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tweb Bound Airway Bill Number',
                name: 'TwebBoundAirwayBillNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.shipping && this.shipping.hasOwnProperty('TwebBoundAirwayBillNumber') && this.shipping.TwebBoundAirwayBillNumber !== null ? this.shipping.TwebBoundAirwayBillNumber.toString() : '',
            }),
        };

        this.View = {
            ActualShipDate: new DynamicLabel(
                'Actual Ship Date',
                this.shipping && this.shipping.ActualShipDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ClinicalBoundAirwayBillNumber: new DynamicLabel(
                'Clinical Bound Airway Bill Number',
                this.shipping && this.shipping.hasOwnProperty('ClinicalBoundAirwayBillNumber') && this.shipping.ClinicalBoundAirwayBillNumber !== null ? this.shipping.ClinicalBoundAirwayBillNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DataLoggerNumber: new DynamicLabel(
                'Data Logger Number',
                this.shipping && this.shipping.DataLoggerNumber || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            DeliveryDate: new DynamicLabel(
                'Delivery Date',
                this.shipping && this.shipping.DeliveryDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ExpectedShipDate: new DynamicLabel(
                'Expected Ship Date',
                this.shipping && this.shipping.ExpectedShipDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            NumberOfVialsShipped: new DynamicLabel(
                'Number Of Vials Shipped',
                this.shipping && this.shipping.NumberOfVialsShipped || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            PrintSummary: new DynamicLabel(
                'Print Summary',
                this.shipping && this.shipping.hasOwnProperty('PrintSummary') && this.shipping.PrintSummary !== null ? this.shipping.PrintSummary : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ProposedShipDate: new DynamicLabel(
                'Proposed Ship Date',
                this.shipping && this.shipping.ProposedShipDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ReturnDate: new DynamicLabel(
                'Return Date',
                this.shipping && this.shipping.ReturnDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            SalesOrderId: new DynamicLabel(
                'Sales Order',
                getMetaItemValue(this.salesOrders, this.shipping && this.shipping.hasOwnProperty('SalesOrderId') && this.shipping.SalesOrderId !== null ? this.shipping.SalesOrderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShipmentTypeId: new DynamicLabel(
                'Shipment Type',
                getMetaItemValue(this.shipmentTypes, this.shipping && this.shipping.hasOwnProperty('ShipmentTypeId') && this.shipping.ShipmentTypeId !== null ? this.shipping.ShipmentTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShipperId: new DynamicLabel(
                'Shipper',
                getMetaItemValue(this.shippers, this.shipping && this.shipping.hasOwnProperty('ShipperId') && this.shipping.ShipperId !== null ? this.shipping.ShipperId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShippingInvoiceNumber: new DynamicLabel(
                'Shipping Invoice Number',
                this.shipping && this.shipping.hasOwnProperty('ShippingInvoiceNumber') && this.shipping.ShippingInvoiceNumber !== null ? this.shipping.ShippingInvoiceNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShippingMethodId: new DynamicLabel(
                'Shipping Method',
                getMetaItemValue(this.shippingMethods, this.shipping && this.shipping.hasOwnProperty('ShippingMethodId') && this.shipping.ShippingMethodId !== null ? this.shipping.ShippingMethodId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShippingStatusId: new DynamicLabel(
                'Shipping Status',
                getMetaItemValue(this.shippingStatuses, this.shipping && this.shipping.hasOwnProperty('ShippingStatusId') && this.shipping.ShippingStatusId !== null ? this.shipping.ShippingStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ShipToClinicId: new DynamicLabel(
                'Ship To Clinic',
                getMetaItemValue(this.shipToClinics, this.shipping && this.shipping.hasOwnProperty('ShipToClinicId') && this.shipping.ShipToClinicId !== null ? this.shipping.ShipToClinicId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            TankNumber: new DynamicLabel(
                'Tank Number',
                this.shipping && this.shipping.TankNumber || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            TwebBoundAirwayBillNumber: new DynamicLabel(
                'Tweb Bound Airway Bill Number',
                this.shipping && this.shipping.hasOwnProperty('TwebBoundAirwayBillNumber') && this.shipping.TwebBoundAirwayBillNumber !== null ? this.shipping.TwebBoundAirwayBillNumber.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
