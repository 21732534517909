import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IBirthOutcome } from '../interfaces/birth-outcome';
import { IOutcomeSource } from '../interfaces/outcome-source';
import { IPregnancyOutcomeOption } from '../interfaces/pregnancy-outcome-option';
import { IThawing } from '../interfaces/thawing';

export interface IBirthOutcomeDynamicControlsParameters {
    formGroup?: string;
    thawings?: IThawing[];
    pregnancyOutcomes?: IPregnancyOutcomeOption[];
    outcomeSources?: IOutcomeSource[];
}

export class BirthOutcomeDynamicControls {

    formGroup: string;
    thawings: IThawing[];
    pregnancyOutcomes: IPregnancyOutcomeOption[];
    outcomeSources: IOutcomeSource[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private birthoutcome?: IBirthOutcome, additionalParameters?: IBirthOutcomeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'BirthOutcome';
        this.thawings = additionalParameters && additionalParameters.thawings || undefined;
        this.pregnancyOutcomes = additionalParameters && additionalParameters.pregnancyOutcomes || undefined;
        this.outcomeSources = additionalParameters && additionalParameters.outcomeSources || undefined;

        this.Form = {
            NumberBorn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Born',
                name: 'NumberBorn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.birthoutcome && this.birthoutcome.NumberBorn || null,
            }),
            OutcomeSourceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Outcome Source',
                name: 'OutcomeSourceId',
                options: this.outcomeSources,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.birthoutcome && this.birthoutcome.OutcomeSourceId || null,
            }),
            PregnancyOutcomeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pregnancy Outcome',
                name: 'PregnancyOutcomeId',
                options: this.pregnancyOutcomes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.birthoutcome && this.birthoutcome.PregnancyOutcomeId || null,
            }),
            ThawingId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Thawing',
                name: 'ThawingId',
                options: this.thawings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.birthoutcome && this.birthoutcome.ThawingId || null,
            }),
        };

        this.View = {
            NumberBorn: new DynamicLabel(
                'Number Born',
                this.birthoutcome && this.birthoutcome.NumberBorn || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            OutcomeSourceId: new DynamicLabel(
                'Outcome Source',
                getMetaItemValue(this.outcomeSources, this.birthoutcome && this.birthoutcome.hasOwnProperty('OutcomeSourceId') && this.birthoutcome.OutcomeSourceId !== null ? this.birthoutcome.OutcomeSourceId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            PregnancyOutcomeId: new DynamicLabel(
                'Pregnancy Outcome',
                getMetaItemValue(this.pregnancyOutcomes, this.birthoutcome && this.birthoutcome.hasOwnProperty('PregnancyOutcomeId') && this.birthoutcome.PregnancyOutcomeId !== null ? this.birthoutcome.PregnancyOutcomeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ThawingId: new DynamicLabel(
                'Thawing',
                getMetaItemValue(this.thawings, this.birthoutcome && this.birthoutcome.hasOwnProperty('ThawingId') && this.birthoutcome.ThawingId !== null ? this.birthoutcome.ThawingId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
