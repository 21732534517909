import { Component, OnInit } from '@angular/core';

import { IRecipientUser } from '@model/interfaces/recipient-user';
import { RecipientUserService } from '../services/recipient-user.service';

@Component({
    templateUrl: './recipient-user-add.component.html',
})
export class RecipientUserAddComponent implements OnInit {
    recipientUser: IRecipientUser;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private recipientuserService: RecipientUserService) {}

    ngOnInit(): void {
        this.recipientUser = this.recipientuserService.getEmptyRecipientUser();
    }
}
