import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { RecipientUserService } from '../services/recipient-user.service';
import { RecipientUserDynamicConfig } from '../recipient-user.dynamic-config';

import { IRecipientUser } from '@model/interfaces/recipient-user';
import { RecipientUserDynamicControls } from '@model/form-controls/recipient-user.form-controls';
import { RecipientDynamicControlsPartial } from '@model/partials/recipient-partial.form-controls';
import { IClinic } from '@model/interfaces/clinic';
import { ContactTypeService } from '../services/contact-type.service';
import { RecipientStatusService } from '../services/recipient-status.service';
import { ReferralTypeService } from '../services/referral-type.service';
import { RecipientService } from '../services/recipient.service';
import { IRecipient } from '@model/interfaces/recipient';
import { IUser } from '@model/interfaces/user';
import { IReferralType } from '@model/interfaces/referral-type';

@Component({
    selector: 'app-recipient-user-basic-info',
    templateUrl: './recipient-user-basic-info.component.html',
})
export class RecipientUserBasicInfoComponent implements OnInit {
    @Input() recipientUser: IRecipientUser;
    @Input() canEdit: boolean;

    // abstract controls
    abstractRecipientUserControls: any;
    abstractRecipientControls: any;

    recipientUserForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: RecipientUserDynamicConfig<IRecipientUser>;

    clinics: IClinic[];
    accountManagers: IUser[];
    referralTypes: IReferralType[];

    get isNewRecipientUser(): boolean {
        return this.recipientUser && this.recipientUser.Id ? false : true;
    }

    constructor(
        private recipientUserService: RecipientUserService,
        private notificationsService: NotificationsService,
        private contactTypeService: ContactTypeService,
        private recipientStatusService: RecipientStatusService,
        private referralTypeService: ReferralTypeService,
        private recipientService: RecipientService,
        private router: Router,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.isEditing = this.isNewRecipientUser;
        forkJoin([
            this.referralTypeService.getItems(),
            this.contactTypeService.getItems(),
            this.recipientStatusService.getItems(),
            this.recipientService.getClinics(),
            this.recipientService.getAccountManagers(),
        ]).subscribe(([referralTypes, , , clinics, accountManagers]) => {
            this.referralTypes = referralTypes;
            this.clinics = clinics;
            this.accountManagers = accountManagers;
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.recipientUserForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        const recipientUser: IRecipientUser = this.isNewRecipientUser ? this.recipientUserService.getEmptyRecipientUser() : this.recipientUser;
        const recipient: IRecipient = recipientUser.Recipient ? recipientUser.Recipient : this.recipientService.getEmptyRecipient();
        this.abstractRecipientUserControls = new RecipientUserDynamicControls(recipientUser, {
            authUsers: null,
            formGroup: 'RecipientUser',
        }).Form;
        this.abstractRecipientControls = new RecipientDynamicControlsPartial(
            recipient,
            {
                clinics: this.clinics,
                formGroup: 'Recipient',
                preferredContactTypes: this.contactTypeService.items,
                referralTypes: this.referralTypes,
                statuses: this.recipientStatusService.items,
            },
            this.accountManagers,
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Recipient: this.fb.group({}),
            RecipientUser: this.fb.group({}),
        });
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewRecipientUser) {
            this.router.navigate(['/recipient-users']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(): void {
        if (this.recipientUserForm.valid) {
            this.recipientUser.Recipient = Object.assign(
                {},
                this.recipientUser.Recipient ?? this.recipientService.getEmptyRecipient(this.recipientUser.Id),
                this.recipientUserForm.value.Recipient,
            );
            this.recipientUser = Object.assign({}, this.recipientUser, this.recipientUserForm.value.RecipientUser);
            this.saveRecipientUser();
        } else {
            markAllFormFieldsAsTouched(this.recipientUserForm);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveRecipientUser(): void {
        if (this.isNewRecipientUser) {
            this.recipientUserService
                .createRecipientUserWithRecipient(this.recipientUser)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.recipientUser.Id = answer;
                    this.success(true);
                });
        } else {
            this.recipientUserService
                .updateRecipientUserWithRecipient(this.recipientUser)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.recipientUser = answer;
                    this.success();
                });
        }
    }

    private success(newRecipientUserSave?: boolean): void {
        this.isEditing = false;
        if (newRecipientUserSave) {
            this.router.navigate([`/recipient-users/${this.recipientUser.Id}`]);
        } else {
            this.createForm();
        }
        this.recipientUserService.emitChange(this.recipientUser);
        this.notificationsService.success('Recipient saved successfully.');
    }
}
