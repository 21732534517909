import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IDonorImage } from '@model/interfaces/donor-image';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { DonorPhotoService } from '../services/donor-photo.service';

@Component({
    selector: 'donor-photo-uploader',
    styles: [''],
    template: `
        <div class="row">
            <div class="col-md-2 col-xs-12">
                <div (click)="uploadSelect.click()" ng2FileDrop [uploader]="uploader" class="drop-box" multiple>
                    Drop files here
                    <br />
                    - OR -
                    <br />
                    Click to upload
                </div>
                <span style="visibility: hidden">
                    <input #uploadSelect id="chooseFileInput" multiple type="file" ng2FileSelect [uploader]="uploader" accept=".jpg,.jpeg" />
                </span>
            </div>
            <div class="col-md-10 col-xs-12" style="margin-bottom: 40px">
                <div class="img-container">
                    <div *ngFor="let thumb of thumbnails; let i = index" class="img-box">
                        <div class="img">
                            <img [src]="thumb.path" />
                            <button type="button" class="trash-button btn btn-sm btn-danger fa fa-times" (click)="removeFile(i)"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12">
                <button type="button" class="btn btn-default" (click)="cancelUpload()">Cancel</button>
                <button
                    *ngIf="photosForUpload.length"
                    type="button"
                    class="btn btn-primary"
                    (click)="tryUploadPhotos()"
                    mt-doubleClickDisabled
                    [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                >
                    Upload
                </button>
            </div>
        </div>
    `,
})
export class DonorPhotoUploaderComponent {
    @Input() donorId: number;
    @Output('onFinished') onFinished: EventEmitter<IDonorImage[]> = new EventEmitter<IDonorImage[]>();
    allowedMimeType: string[] = ['image/jpg', 'image/jpeg'];
    maxFileSize = 100 * 1024 * 1024;
    isHTML5 = true;
    uploader: FileUploader;
    localImageUrl: string;
    thumbnails: { file: FileItem; path: SafeUrl; ready: boolean }[] = [];

    photosForUpload: FileItem[] = [];
    doubleClickIsDisabled = false;

    constructor(
        public sanitizer: DomSanitizer,
        public notificationService: NotificationsService,
        private donorPhotoService: DonorPhotoService,
    ) {}

    ngOnInit(): void {
        this.uploader = new FileUploader({
            allowedMimeType: this.allowedMimeType,
            isHTML5: this.isHTML5,
            maxFileSize: this.maxFileSize,
        });
        this.uploader.onAfterAddingFile = (file) => {
            const image_file = file._file;
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.thumbnails.push({ file: file, path: this.sanitizer.bypassSecurityTrustUrl(reader.result.toString()), ready: true });
            });
            reader.readAsDataURL(image_file);
            this.setPhotosForUpload(this.uploader.queue);
        };

        this.uploader.onWhenAddingFileFailed = (item: any, filter: any, options: any) => {
            this.notificationService.error('File must be jpg or jpeg format.');
        };
    }

    removeFile(index: number): void {
        this.thumbnails = this.thumbnails.filter((f, i) => i !== index);
        this.uploader.queue = this.uploader.queue.filter((f, i) => i !== index);
        this.setPhotosForUpload(this.uploader.queue);
    }

    tryUploadPhotos(): void {
        const files = this.photosForUpload.map((p) => p._file);
        if (files.length <= 0) {
            return this.notificationService.error('No files to upload');
        }
        this.donorPhotoService.uploadPhotos(this.donorId, files).subscribe((files) => {
            this.completePhotoUploadSave(files);
        });
    }

    setPhotosForUpload(queue: FileItem[]): void {
        this.photosForUpload = queue;
    }

    completePhotoUploadSave(files: IDonorImage[]): void {
        this.onFinished.emit(files);
        this.notificationService.success('Photos uploaded successfully.');
    }

    cancelUpload(): void {
        this.onFinished.emit();
        this.photosForUpload = [];
    }
}
