import { Validators } from '@angular/forms';
import { IDonorSampleLabData } from '@model/interfaces/donor-sample-lab-data';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import {
    DonorSampleLabDataDynamicControls,
    IDonorSampleLabDataDynamicControlsParameters,
} from '../form-controls/donor-sample-lab-data.form-controls';

export class DonorSampleLabDataDynamicControlsPartial extends DonorSampleLabDataDynamicControls {
    constructor(donortestsamplelabdataPartial?: IDonorSampleLabData, additionalParameters?: IDonorSampleLabDataDynamicControlsParameters) {
        super(donortestsamplelabdataPartial, additionalParameters);

        // Validation
        (<DynamicField>this.Form.Viscosity).validation.push(Validators.required);
        (<DynamicField>this.Form.Viscosity).validators.required = true;
        (<DynamicField>this.Form.RoundCells).validation.push(Validators.required);
        (<DynamicField>this.Form.RoundCells).validators.required = true;
        (<DynamicField>this.Form.NonMotileConcentration).validation.push(Validators.required);
        (<DynamicField>this.Form.NonMotileConcentration).validators.required = true;
        (<DynamicField>this.Form.RawSemenMotileConcentration).validation.push(Validators.required);
        (<DynamicField>this.Form.RawSemenMotileConcentration).validators.required = true;
        (<DynamicField>this.Form.RawSemenVolume).validation.push(Validators.required);
        (<DynamicField>this.Form.RawSemenVolume).validators.required = true;
        (<DynamicField>this.Form.RawSemenDilutionFactor).validation.push(Validators.required);
        (<DynamicField>this.Form.RawSemenDilutionFactor).validators.required = true;
        (<DynamicField>this.Form.PostGradientDilutionFactor).validation.push(Validators.required);
        (<DynamicField>this.Form.PostGradientDilutionFactor).validators.required = true;
        (<DynamicField>this.Form.PostGradientVolume).validation.push(Validators.required);
        (<DynamicField>this.Form.PostGradientVolume).validators.required = true;
        (<DynamicField>this.Form.PostGradientVolume).type.scale = 3;
        (<DynamicField>this.Form.PostGradientMotileConcentration).validation.push(Validators.required);
        (<DynamicField>this.Form.PostGradientMotileConcentration).validators.required = true;
        (<DynamicField>this.Form.LabelVerifiedBy).validation.push(Validators.required);
        (<DynamicField>this.Form.LabelVerifiedBy).validators.required = true;
        (<DynamicField>this.Form.ProcessingTech).validation.push(Validators.required);
        (<DynamicField>this.Form.ProcessingTech).validators.required = true;
        (<DynamicField>this.Form.TestThawMotileConcentration).validation.push(Validators.required);
        (<DynamicField>this.Form.TestThawMotileConcentration).validators.required = true;
        (<DynamicField>this.Form.TestThawTech).validation.push(Validators.required);
        (<DynamicField>this.Form.TestThawTech).validators.required = true;
        (<DynamicField>this.Form.Tank).validation.push(Validators.required);
        (<DynamicField>this.Form.Tank).validators.required = true;
        (<DynamicField>this.Form.Can).validation.push(Validators.required);
        (<DynamicField>this.Form.Can).validators.required = true;
        (<DynamicField>this.Form.Cane).validation.push(Validators.required);
        (<DynamicField>this.Form.Cane).validators.required = true;

        // Raw Semen Form Labels
        (<DynamicLabel>this.View.RawSemenDilutionFactor).label = 'Dilution Factor';
        (<DynamicLabel>this.View.RawSemenMotileConcentration).label = 'Motile Concentration (x10<sup>6</sup>/ml)';
        (<DynamicLabel>this.View.RawSemenVolume).label = 'Volume';
        (<DynamicField>this.Form.RawSemenDilutionFactor).label = 'Dilution Factor';
        (<DynamicField>this.Form.RawSemenMotileConcentration).label = 'Motile Concentration (x10<sup>6</sup>/ml)';
        (<DynamicField>this.Form.RawSemenVolume).label = 'Volume';
        (<DynamicField>this.Form.RawSemenDilutionFactor).labelHtml = '<label>Dilution Factor</label>';
        (<DynamicField>this.Form.RawSemenMotileConcentration).labelHtml = '<label>Motile Concentration (x10<sup>6</sup>/ml)</label>';
        (<DynamicField>this.Form.RawSemenVolume).labelHtml = '<label>Volume (ml)</label>';
        (<DynamicField>this.Form.NonMotileConcentration).label = 'Non-Motile Concentration (x10<sup>6</sup>/ml)';
        (<DynamicField>this.Form.NonMotileConcentration).labelHtml = '<label>Non-Motile Concentration (x10<sup>6</sup>/ml)</label>';
        (<DynamicLabel>this.Form.NonMotileConcentration).label = 'Non-Motile Concentration (x10<sup>6</sup>/ml)';

        // Post Gradient Form Labels
        (<DynamicField>this.Form.PostGradientVolume).label = 'Volume';
        (<DynamicField>this.Form.PostGradientVolume).labelHtml = '<label>Volume (ml)</label>';
        (<DynamicLabel>this.View.PostGradientVolume).label = 'Volume';
        (<DynamicField>this.Form.PostGradientMotileConcentration).label = 'Motile Concentration (x10<sup>6</sup>/ml)';
        (<DynamicField>this.Form.PostGradientMotileConcentration).labelHtml = '<label>Motile Concentration (x10<sup>6</sup>/ml)</label>';
        (<DynamicLabel>this.View.PostGradientMotileConcentration).label = 'Motile Concentration (x10<sup>6</sup>/ml)';
        (<DynamicField>this.Form.PostGradientDilutionFactor).label = 'Dilution Factor';
        (<DynamicField>this.Form.PostGradientDilutionFactor).labelHtml = '<label>Dilution Factor</label>';
        (<DynamicLabel>this.View.PostGradientDilutionFactor).label = 'Dilution Factor';

        // Test Thaw Form Labels
        (<DynamicField>this.Form.TestThawMotileConcentration).label = 'Motile Concentration (x10<sup>6</sup>/ml)';
        (<DynamicField>this.Form.TestThawMotileConcentration).labelHtml = '<label>Motile Concentration (x10<sup>6</sup>/ml)</label>';
        (<DynamicLabel>this.View.TestThawMotileConcentration).label = 'Motile Concentration (x10<sup>6</sup>/ml)';
    }
}
