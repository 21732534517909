import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IGeneticScreening } from '../interfaces/genetic-screening';
import { IGeneticScreeningType } from '../interfaces/genetic-screening-type';

export interface IGeneticScreeningDynamicControlsParameters {
    formGroup?: string;
    geneticScreeningTypes?: IGeneticScreeningType[];
}

export class GeneticScreeningDynamicControls {

    formGroup: string;
    geneticScreeningTypes: IGeneticScreeningType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private geneticscreening?: IGeneticScreening, additionalParameters?: IGeneticScreeningDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'GeneticScreening';
        this.geneticScreeningTypes = additionalParameters && additionalParameters.geneticScreeningTypes || undefined;

        this.Form = {
            CarrierTestingAcceptedRejected: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Testing Accepted Rejected',
                name: 'CarrierTestingAcceptedRejected',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.hasOwnProperty('CarrierTestingAcceptedRejected') && this.geneticscreening.CarrierTestingAcceptedRejected !== null ? this.geneticscreening.CarrierTestingAcceptedRejected : false,
            }),
            CarrierTestingAcceptedRejectedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Testing Accepted Rejected By',
                name: 'CarrierTestingAcceptedRejectedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.geneticscreening && this.geneticscreening.hasOwnProperty('CarrierTestingAcceptedRejectedBy') && this.geneticscreening.CarrierTestingAcceptedRejectedBy !== null ? this.geneticscreening.CarrierTestingAcceptedRejectedBy.toString() : '',
            }),
            CarrierTestingAcceptedRejectedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Testing Accepted Rejected Date',
                name: 'CarrierTestingAcceptedRejectedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.CarrierTestingAcceptedRejectedDate || null,
            }),
            CarrierTestingDrawDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Testing Draw Date',
                name: 'CarrierTestingDrawDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.CarrierTestingDrawDate || null,
            }),
            CarrierTestingResultsReceivedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Testing Results Received By',
                name: 'CarrierTestingResultsReceivedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.geneticscreening && this.geneticscreening.hasOwnProperty('CarrierTestingResultsReceivedBy') && this.geneticscreening.CarrierTestingResultsReceivedBy !== null ? this.geneticscreening.CarrierTestingResultsReceivedBy.toString() : '',
            }),
            CarrierTestingResultsReceivedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Testing Results Received Date',
                name: 'CarrierTestingResultsReceivedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.CarrierTestingResultsReceivedDate || null,
            }),
            DocumentEntityId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Document Entity',
                name: 'DocumentEntityId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.geneticscreening && this.geneticscreening.DocumentEntityId || null,
            }),
            GeneticConsultAcceptedRejected: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Consult Accepted Rejected',
                name: 'GeneticConsultAcceptedRejected',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticConsultAcceptedRejected') && this.geneticscreening.GeneticConsultAcceptedRejected !== null ? this.geneticscreening.GeneticConsultAcceptedRejected : false,
            }),
            GeneticConsultAcceptedRejectedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Consult Accepted Rejected By',
                name: 'GeneticConsultAcceptedRejectedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticConsultAcceptedRejectedBy') && this.geneticscreening.GeneticConsultAcceptedRejectedBy !== null ? this.geneticscreening.GeneticConsultAcceptedRejectedBy.toString() : '',
            }),
            GeneticConsultAcceptedRejectedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Consult Accepted Rejected Date',
                name: 'GeneticConsultAcceptedRejectedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.GeneticConsultAcceptedRejectedDate || null,
            }),
            GeneticConsultPerformedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Consult Performed Date',
                name: 'GeneticConsultPerformedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.GeneticConsultPerformedDate || null,
            }),
            GeneticConsultReportReceivedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Consult Report Received By',
                name: 'GeneticConsultReportReceivedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticConsultReportReceivedBy') && this.geneticscreening.GeneticConsultReportReceivedBy !== null ? this.geneticscreening.GeneticConsultReportReceivedBy.toString() : '',
            }),
            GeneticConsultReportReceivedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Consult Report Received Date',
                name: 'GeneticConsultReportReceivedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.GeneticConsultReportReceivedDate || null,
            }),
            GeneticConsultSentToCounselorDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Consult Sent To Counselor Date',
                name: 'GeneticConsultSentToCounselorDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.GeneticConsultSentToCounselorDate || null,
            }),
            GeneticScreeningAccepted: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Screening Accepted',
                name: 'GeneticScreeningAccepted',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticScreeningAccepted') && this.geneticscreening.GeneticScreeningAccepted !== null ? this.geneticscreening.GeneticScreeningAccepted : false,
            }),
            GeneticScreeningAcceptedRejectedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Screening Accepted Rejected By',
                name: 'GeneticScreeningAcceptedRejectedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticScreeningAcceptedRejectedBy') && this.geneticscreening.GeneticScreeningAcceptedRejectedBy !== null ? this.geneticscreening.GeneticScreeningAcceptedRejectedBy.toString() : '',
            }),
            GeneticScreeningAcceptedRejectedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Screening Accepted Rejected Date',
                name: 'GeneticScreeningAcceptedRejectedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.GeneticScreeningAcceptedRejectedDate || null,
            }),
            GeneticScreeningTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetic Screening Type',
                name: 'GeneticScreeningTypeId',
                options: this.geneticScreeningTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.GeneticScreeningTypeId || null,
            }),
            GeneticsLabsAccepted: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetics Labs Accepted',
                name: 'GeneticsLabsAccepted',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticsLabsAccepted') && this.geneticscreening.GeneticsLabsAccepted !== null ? this.geneticscreening.GeneticsLabsAccepted : false,
            }),
            GeneticsLabsApprovedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetics Labs Approved By',
                name: 'GeneticsLabsApprovedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticsLabsApprovedBy') && this.geneticscreening.GeneticsLabsApprovedBy !== null ? this.geneticscreening.GeneticsLabsApprovedBy.toString() : '',
            }),
            GeneticsLabsApprovedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetics Labs Approved Date',
                name: 'GeneticsLabsApprovedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.GeneticsLabsApprovedDate || null,
            }),
            GeneticsLabsPerformedByDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetics Labs Performed By Date',
                name: 'GeneticsLabsPerformedByDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.GeneticsLabsPerformedByDate || null,
            }),
            GeneticsLabsReceivedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetics Labs Received Date',
                name: 'GeneticsLabsReceivedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.GeneticsLabsReceivedDate || null,
            }),
            GeneticsLabsSentDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Genetics Labs Sent Date',
                name: 'GeneticsLabsSentDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.geneticscreening && this.geneticscreening.GeneticsLabsSentDate || null,
            }),
        };

        this.View = {
            CarrierTestingAcceptedRejected: new DynamicLabel(
                'Carrier Testing Accepted Rejected',
                this.geneticscreening && this.geneticscreening.hasOwnProperty('CarrierTestingAcceptedRejected') && this.geneticscreening.CarrierTestingAcceptedRejected !== null ? this.geneticscreening.CarrierTestingAcceptedRejected : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CarrierTestingAcceptedRejectedBy: new DynamicLabel(
                'Carrier Testing Accepted Rejected By',
                this.geneticscreening && this.geneticscreening.hasOwnProperty('CarrierTestingAcceptedRejectedBy') && this.geneticscreening.CarrierTestingAcceptedRejectedBy !== null ? this.geneticscreening.CarrierTestingAcceptedRejectedBy.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CarrierTestingAcceptedRejectedDate: new DynamicLabel(
                'Carrier Testing Accepted Rejected Date',
                this.geneticscreening && this.geneticscreening.CarrierTestingAcceptedRejectedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            CarrierTestingDrawDate: new DynamicLabel(
                'Carrier Testing Draw Date',
                this.geneticscreening && this.geneticscreening.CarrierTestingDrawDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            CarrierTestingResultsReceivedBy: new DynamicLabel(
                'Carrier Testing Results Received By',
                this.geneticscreening && this.geneticscreening.hasOwnProperty('CarrierTestingResultsReceivedBy') && this.geneticscreening.CarrierTestingResultsReceivedBy !== null ? this.geneticscreening.CarrierTestingResultsReceivedBy.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CarrierTestingResultsReceivedDate: new DynamicLabel(
                'Carrier Testing Results Received Date',
                this.geneticscreening && this.geneticscreening.CarrierTestingResultsReceivedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DocumentEntityId: new DynamicLabel(
                'Document Entity',
                this.geneticscreening && this.geneticscreening.DocumentEntityId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            GeneticConsultAcceptedRejected: new DynamicLabel(
                'Genetic Consult Accepted Rejected',
                this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticConsultAcceptedRejected') && this.geneticscreening.GeneticConsultAcceptedRejected !== null ? this.geneticscreening.GeneticConsultAcceptedRejected : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            GeneticConsultAcceptedRejectedBy: new DynamicLabel(
                'Genetic Consult Accepted Rejected By',
                this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticConsultAcceptedRejectedBy') && this.geneticscreening.GeneticConsultAcceptedRejectedBy !== null ? this.geneticscreening.GeneticConsultAcceptedRejectedBy.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            GeneticConsultAcceptedRejectedDate: new DynamicLabel(
                'Genetic Consult Accepted Rejected Date',
                this.geneticscreening && this.geneticscreening.GeneticConsultAcceptedRejectedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GeneticConsultPerformedDate: new DynamicLabel(
                'Genetic Consult Performed Date',
                this.geneticscreening && this.geneticscreening.GeneticConsultPerformedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GeneticConsultReportReceivedBy: new DynamicLabel(
                'Genetic Consult Report Received By',
                this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticConsultReportReceivedBy') && this.geneticscreening.GeneticConsultReportReceivedBy !== null ? this.geneticscreening.GeneticConsultReportReceivedBy.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            GeneticConsultReportReceivedDate: new DynamicLabel(
                'Genetic Consult Report Received Date',
                this.geneticscreening && this.geneticscreening.GeneticConsultReportReceivedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GeneticConsultSentToCounselorDate: new DynamicLabel(
                'Genetic Consult Sent To Counselor Date',
                this.geneticscreening && this.geneticscreening.GeneticConsultSentToCounselorDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GeneticScreeningAccepted: new DynamicLabel(
                'Genetic Screening Accepted',
                this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticScreeningAccepted') && this.geneticscreening.GeneticScreeningAccepted !== null ? this.geneticscreening.GeneticScreeningAccepted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            GeneticScreeningAcceptedRejectedBy: new DynamicLabel(
                'Genetic Screening Accepted Rejected By',
                this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticScreeningAcceptedRejectedBy') && this.geneticscreening.GeneticScreeningAcceptedRejectedBy !== null ? this.geneticscreening.GeneticScreeningAcceptedRejectedBy.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            GeneticScreeningAcceptedRejectedDate: new DynamicLabel(
                'Genetic Screening Accepted Rejected Date',
                this.geneticscreening && this.geneticscreening.GeneticScreeningAcceptedRejectedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GeneticScreeningTypeId: new DynamicLabel(
                'Genetic Screening Type',
                getMetaItemValue(this.geneticScreeningTypes, this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticScreeningTypeId') && this.geneticscreening.GeneticScreeningTypeId !== null ? this.geneticscreening.GeneticScreeningTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            GeneticsLabsAccepted: new DynamicLabel(
                'Genetics Labs Accepted',
                this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticsLabsAccepted') && this.geneticscreening.GeneticsLabsAccepted !== null ? this.geneticscreening.GeneticsLabsAccepted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            GeneticsLabsApprovedBy: new DynamicLabel(
                'Genetics Labs Approved By',
                this.geneticscreening && this.geneticscreening.hasOwnProperty('GeneticsLabsApprovedBy') && this.geneticscreening.GeneticsLabsApprovedBy !== null ? this.geneticscreening.GeneticsLabsApprovedBy.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            GeneticsLabsApprovedDate: new DynamicLabel(
                'Genetics Labs Approved Date',
                this.geneticscreening && this.geneticscreening.GeneticsLabsApprovedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GeneticsLabsPerformedByDate: new DynamicLabel(
                'Genetics Labs Performed By Date',
                this.geneticscreening && this.geneticscreening.GeneticsLabsPerformedByDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GeneticsLabsReceivedDate: new DynamicLabel(
                'Genetics Labs Received Date',
                this.geneticscreening && this.geneticscreening.GeneticsLabsReceivedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            GeneticsLabsSentDate: new DynamicLabel(
                'Genetics Labs Sent Date',
                this.geneticscreening && this.geneticscreening.GeneticsLabsSentDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
        };

    }
}
