import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGeneticScreening } from '@model/interfaces/genetic-screening';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';

export const emptyGeneticScreening: IGeneticScreening = {
    DocumentEntityId: 0,
    GeneticScreeningAccepted: true,
    GeneticScreeningAcceptedRejectedBy: null,
    GeneticScreeningAcceptedRejectedDate: null,
    GeneticsLabsAccepted: true,
    GeneticsLabsApprovedBy: null,
    GeneticsLabsApprovedDate: null,
    GeneticsLabsPerformedByDate: null,
    GeneticsLabsReceivedDate: null,
    GeneticsLabsSentDate: null,
    Id: 0,
};

@Injectable({ providedIn: 'root' })
export class GeneticScreeningService extends BaseService<IGeneticScreening> {
    constructor(public http: HttpClient) {
        super('/genetic-screenings', http);
    }
    getEmptyGeneticScreening(): IGeneticScreening {
        return emptyGeneticScreening;
    }
}
