import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IBloodWorkDetail } from '@model/interfaces/blood-work-detail';
import { Observable } from 'rxjs';
import { IDonorOngoingScreening } from '@model/interfaces/donor-ongoing-screening';
import { map } from 'rxjs/operators';
import { LocalizeDatesByName } from '@angular-common/libraries/treat-date-as-local.library';

export const emptyBloodWorkDetail: IBloodWorkDetail = {
    CmvDrawDate: null,
    CmvOutcomeId: 0,
    HtlvIAndIiDrawDate: null,
    HtlvIAndIiOutcomeId: 0,
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class BloodWorkDetailService extends BaseService<IBloodWorkDetail> {
    constructor(public http: HttpClient) {
        super('/blood-work-details', http);
    }

    saveBloodWorkDetail(bloodWorkDetail: IBloodWorkDetail): Observable<IBloodWorkDetail> {
        return this.http.post<IBloodWorkDetail>(`/blood-work-details/${bloodWorkDetail.Id}`, bloodWorkDetail);
    }

    getEmptyBloodWorkDetail(ongoingScreening?: IDonorOngoingScreening): IBloodWorkDetail {
        let bloodWorkDetail = { ...emptyBloodWorkDetail };
        if (ongoingScreening) {
            bloodWorkDetail.DonorOngoingScreening = ongoingScreening;
            bloodWorkDetail.Id = ongoingScreening.Id;
            bloodWorkDetail.FdaLabsDate = ongoingScreening.BloodDrawDate;
        }
        return bloodWorkDetail;
    }

    getById(id: number): Observable<IBloodWorkDetail> {
        return super.getById(id).pipe(
            map((b) => {
                if (b) {
                    LocalizeDatesByName(b, [
                        'FdaLabsDate',
                        'AdditionalLabs',
                        'SorReceivedDate',
                        'FdaBookDate',
                        'HepBsAgDrawDate',
                        'HepBCoreDrawDate',
                        'Hiv102AbDate',
                        'Hiv1NatDate',
                        'HbvNatDrawDate',
                        'HcvNatDrawDate',
                        'AntiHepCDrawDate',
                        'SyphilisDrawDate',
                        'ChlamydiaNatDrawDate',
                        'GonorrheaNatDrawDate',
                        'WnvNatDrawDate',
                        'TissueRecoveryDate',
                        'EligibilityDeterminationDate',
                        'CmvDrawDate',
                        'CmvTotalAntibodyDrawDate',
                        'HtlvIAndIiDrawDate',
                        'CmvIggDrawDate',
                        'CmvIgmDrawDate',
                        'KaryotypeDrawDate',
                    ]);
                }
                return b;
            }),
        );
    }
}
