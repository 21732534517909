import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, ISelectionChangedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { IVwDonorInventory } from '@model/interfaces/vw-donor-inventory';
import { InventoryVesselService } from '../../../donors/inventory/services/inventory-vessel.service';
import { DonorService } from '../../../donors/services/donor.service';
import { DonorInventorySelectEntityListConfig } from './donor-inventory-select.entity-list-config';
import { ExtraParams } from '@common/extra-params/extra-params.library';

@Component({
    selector: 'donor-select-available-inventory',
    templateUrl: './donor-select-inventory.component.html',
})
export class DonorSelectInventoryComponent implements OnInit {
    @Input() donorIds: number[];
    @Input() productTypeId: number;
    @Input() vialStatusId: number;
    @Input() selectedInventory: IVwDonorInventory[];
    @Input() zoom = true;
    @Input() reservationId: number;
    @Input() salesOrderId: number;
    @Output() onCancelSearch: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSelectionComplete: EventEmitter<IVwDonorInventory[]> = new EventEmitter<IVwDonorInventory[]>();

    donorInventory: IVwDonorInventory[];
    entityListConfig = new DonorInventorySelectEntityListConfig();
    // Order By and Direction is layered and set on the backend
    // Will be overwritten by manual sort
    order = '';
    orderDirection = '';
    noResultText = 'No Inventory Found';
    total: number;

    doubleClickIsDisabled: boolean;

    constructor(private donorService: DonorService, private inventoryService: InventoryVesselService) {}

    ngOnInit(): void {
        this.getInventorySummary();
    }

    getInventoryCall(): Observable<HttpResponse<IVwDonorInventory[]>> {
        const _extraSearchParams: ExtraSearchParams[] = ExtraParams.build({
            DonorIds: this.donorIds,
            IsAvailable: true,
            ProductTypeId: this.productTypeId,
            VialStatusId: this.vialStatusId,
        });

        if (this.reservationId) {
            _extraSearchParams.push({
                name: 'ReservationId',
                value: this.reservationId.toString(),
            });
        }

        if (this.salesOrderId) {
            _extraSearchParams.push({
                name: 'SalesOrderId',
                value: this.salesOrderId.toString(),
            });
        }

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: '',
        };

        const searchparams = new SearchParams(searchEntity);
        return this.donorService.getDonorInventorySummary(searchparams);
    }

    getInventorySummary(): void {
        this.getInventoryCall().subscribe((answer) => {
            if (answer?.body?.length) {
                this.donorInventory = answer.body;
            }
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    onColumnSorted(event: IColumnSortedEvent): void {
        const order = event.column.sort.sortProperty;
        const direction = event.column.sort.direction;
        switch (order) {
            case 'DonationDate':
                this.donorInventory.sort((a, b) => {
                    if (direction === SortDirection.Desc) {
                        return new Date(b.DonationDate).getTime() - new Date(a.DonationDate).getTime();
                    } else {
                        return new Date(a.DonationDate).getTime() - new Date(b.DonationDate).getTime();
                    }
                });
                break;
            case 'VialType':
                this.donorInventory.sort((a, b) => {
                    if (direction === SortDirection.Desc) {
                        return b.VialType.localeCompare(a.VialType);
                    } else {
                        return a.VialType.localeCompare(b.VialType);
                    }
                });
                break;
            default:
                break;
        }
    }

    inventorySelected(value: ISelectionChangedEvent): void {
        this.selectedInventory = value.selectedEntities as any;
    }

    inventorySelectionFinished(): void {
        this.onSelectionComplete.emit(this.selectedInventory);
    }

    cancelEdit(): void {
        this.onCancelSearch.emit();
    }
}
