import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { DonorOngoingScreeningService } from '../services/donor-ongoing-screening.service';
import { IDonorOngoingScreening } from '@model/interfaces/donor-ongoing-screening';
import { ClaimTypes } from '@model/ClaimTypes';
import { DocumentEntities } from '@model/enums/document-entities.enum';

@Component({
    templateUrl: './donor-ongoing-screening-detail.component.html',
})
export class DonorOngoingScreeningDetailComponent implements OnInit {
    donorOngoingScreening: IDonorOngoingScreening;
    canEdit: boolean;
    canAdd: boolean;
    donorId: number;

    claimType = ClaimTypes.Donors;
    docEntityType = DocumentEntities.DonorOngoingScreenings;

    constructor(
        private donorOngoingScreeningService: DonorOngoingScreeningService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Donors, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = this.getIdFromRoute(this.route, 'donorOngoingScreeningId');
        this.donorId = this.getIdFromRoute(this.route, 'donorId');
        if (id) {
            this.getDonorOngoingScreeningById(id);
        } else {
            this.router.navigate([`donors/${this.donorId}`]); // if no id found, go back to list
        }
    }

    getDonorOngoingScreeningById(id: number): void {
        this.donorOngoingScreeningService.getById(id).subscribe((donorOngoingScreening) => {
            if (donorOngoingScreening === null) {
                this.notificationsService.error('Ongoing Screening not found');
                this.router.navigate([`donors/${this.donorId}`]);
            }
            this.donorOngoingScreening = donorOngoingScreening;
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        let id = route.snapshot.paramMap.get(param);
        return parseInt(id, 10) === NaN ? null : parseInt(id, 10);
    }
}
