import { Component, Input, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { EntityListExportConfig, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { DonorService } from '../services/donor.service';
import { DonorInventoryEntityListConfig } from './donor-inventory.entity-list-config';
import { IVwDonorInventory } from '@model/interfaces/vw-donor-inventory';
import { DonationStatuses } from '@model/enums/donation-statuses.enum';
import { InventoryVesselService } from '../inventory/services/inventory-vessel.service';
import { ExtraParams } from '@common/extra-params/extra-params.library';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-donor-inventory',
    templateUrl: './donor-inventory-card.component.html',
})
export class DonorInventoryCardComponent implements OnInit {
    @Input() donorId: number;

    donorInventory: IVwDonorInventory[];
    entityListConfig = new DonorInventoryEntityListConfig(this.router);
    // Order By and Direction is layered and set on the backend
    // Will be overwritten by manual sort
    order = '';
    orderDirection = '';
    noResultText = 'No Inventory Found';
    total: number;

    quarantinedInventory: IVwDonorInventory[];
    approvedInventory: IVwDonorInventory[];
    reservedInventory: IVwDonorInventory[];

    constructor(private donorService: DonorService, private inventoryService: InventoryVesselService, private router: Router) {}

    ngOnInit(): void {
        this.getInventorySummary();
        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Donor Cycles Report',
            getDataForExport: this.getDonorInventoryForExport.bind(this),
        });
        // Listen for bulk changes to inventory from Donation List actions
        this.inventoryService.inventoryUpdates.subscribe(() => {
            if (this.donorInventory) {
                this.donorInventory = null;
                setTimeout(() => {
                    this.getInventorySummary();
                });
            }
        });
    }

    getInventoryCall(): Observable<HttpResponse<IVwDonorInventory[]>> {
        const _extraSearchParams: ExtraSearchParams[] = ExtraParams.build({
            DonorIds: [this.donorId],
            IsAvailable: 'false',
        });

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: '',
        };

        const searchparams = new SearchParams(searchEntity);
        return this.donorService.getDonorInventorySummary(searchparams);
    }

    getInventorySummary(): void {
        this.getInventoryCall().subscribe((answer) => {
            if (answer?.body?.length) {
                this.donorInventory = answer.body.filter((i) => i.InventoryStatusId !== DonationStatuses.Discard);
                this.quarantinedInventory = this.donorInventory?.filter(
                    (i) => i.InventoryStatusId === DonationStatuses.Quarantine || i.InventoryStatusId === DonationStatuses.EligibleforRelease,
                );
                this.approvedInventory = this.donorInventory?.filter((i) => i.InventoryStatusId === DonationStatuses.ApprovedforRelease);
                this.reservedInventory = this.donorInventory?.filter((i) => i.ReservationId);
            }
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    onColumnSorted(event: IColumnSortedEvent): void {
        const order = event.column.sort.sortProperty;
        const direction = event.column.sort.direction;
        switch (order) {
            case 'DonationDate':
                this.donorInventory.sort((a, b) => {
                    if (direction === SortDirection.Desc) {
                        return new Date(b.DonationDate).getTime() - new Date(a.DonationDate).getTime();
                    } else {
                        return new Date(a.DonationDate).getTime() - new Date(b.DonationDate).getTime();
                    }
                });
                break;
            case 'VialType':
                this.donorInventory.sort((a, b) => {
                    if (direction === SortDirection.Desc) {
                        return b.VialType.localeCompare(a.VialType);
                    } else {
                        return a.VialType.localeCompare(b.VialType);
                    }
                });
                break;
            default:
                break;
        }
    }

    getDonorInventoryForExport(): Observable<IVwDonorInventory[]> {
        return this.getInventoryCall().pipe(map((answer) => answer.body));
    }
}
