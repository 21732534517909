import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IPhysicalStatus } from '@model/interfaces/physical-status';

@Injectable({
    providedIn: 'root',
})
export class PhysicalStatusService extends StaticMetaItemService<IPhysicalStatus> {
    constructor(public http: HttpClient) {
        super('PhysicalStatusService', 'Status', 'StatusIds', '/meta-items/PhysicalStatus', http);
    }
}
