import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IScreeningResultOption } from '@model/interfaces/screening-result-option';

@Injectable({
    providedIn: 'root',
})
export class ScreeningResultOptionservice extends StaticMetaItemService<IScreeningResultOption> {
    private cachedCombos = {};

    constructor(public http: HttpClient) {
        super('ScreeningResultOptionservice', 'Option', 'OptionIds', '/meta-items/ScreeningResultOption', http);
        // Pre-fetch items for getOptions
        this.getItems().subscribe(() => {});
    }

    getOptions(options: number[]): IScreeningResultOption[] {
        let ScreeningResultOptions = [];
        const total = options.reduce((a, b) => a + b, 0).toString();

        if (!(this.cachedCombos.hasOwnProperty(total) && this.cachedCombos[total])) {
            for (let i = 0; i < options.length; i++) {
                const nextItem = this.items.find((item) => item.Id === options[i]);
                ScreeningResultOptions.push(nextItem);
            }
            this.cachedCombos[total] = ScreeningResultOptions;
        }

        return this.cachedCombos[total];
    }
}
