import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IDonorOngoingScreening } from '@model/interfaces/donor-ongoing-screening';
import { DonorOngoingScreeningService } from '../services/donor-ongoing-screening.service';

@Component({
    templateUrl: './donor-ongoing-screening-header.component.html',
})
export class DonorOngoingScreeningHeaderComponent implements OnInit, OnDestroy {
    donorOngoingScreening: IDonorOngoingScreening;
    header: string;
    subscriptions: Subscription = new Subscription();

    urlSegmentsToIgnore = ['ongoing-screenings'];

    constructor(private donorOngoingScreeningService: DonorOngoingScreeningService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('donorOngoingScreeningId');
        // set the header based on the id
        if (id > 0) {
            this.donorOngoingScreeningService.getById(id).subscribe((donorOngoingScreening) => {
                this.setHeader(donorOngoingScreening);
            });
        }
        // subscribe to any changes in the donorOngoingScreening service
        // which should update the header accordingly
        this.subscriptions.add(
            this.donorOngoingScreeningService.changeEmitted$.subscribe((donorOngoingScreening) => {
                this.setHeader(donorOngoingScreening);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(donorOngoingScreening: IDonorOngoingScreening): void {
        this.donorOngoingScreening = donorOngoingScreening;
        this.header = `Ongoing Screening for Donor ID: ${donorOngoingScreening.DonorId}`;
    }
}
