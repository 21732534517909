import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { IDonor } from '@model/interfaces/donor';
import { IDonorBloodWorkDynamicControlsParameters } from '@model/partials/donor-blood-work.form-controls';
import { IDonorBloodWork } from '@model/interfaces/donor-blood-work';
import { ScreeningResultOptions as AO } from '@model/enums/screening-result-options.enum';
import { ScreeningResultOptionservice } from '@common/services/screening-result-option.service';

import { BloodTypeService } from '../../services/blood-type.service';
import { RhFactorService } from '../../services/rh-factor.service';
import { DonorService } from '../../services/donor.service';
import { DonorBloodWorkDynamicConfig } from './blood-work.dynamic-config';

@Component({
    selector: 'app-blood-work',
    styleUrls: ['./blood-work.component.css'],
    templateUrl: './blood-work.component.html',
})
export class BloodWorkComponent implements OnInit {
    @Input() donor: IDonor;
    @Input() canEdit: boolean;

    @Output() emitIsEditing = new EventEmitter<boolean>();

    doubleClickIsDisabled: boolean;
    isEditing = false;
    isHovered: boolean;

    additionalParams: IDonorBloodWorkDynamicControlsParameters;
    config: IDynamicFormConfig;
    formFactory: DonorBloodWorkDynamicConfig<IDonorBloodWork>;

    constructor(
        private bloodTypeService: BloodTypeService,
        private rhFactorService: RhFactorService,
        private donorService: DonorService,
        private notificaitionService: NotificationsService,
        private screeningResultsOptionService: ScreeningResultOptionservice,
    ) {}

    ngOnInit(): void {
        forkJoin({
            bloodTypes: this.bloodTypeService.getItems(),
            karyotypeOptions: this.screeningResultsOptionService.getItems(),
            rhFactors: this.rhFactorService.getItems(),
        }).subscribe((x) => {
            this.additionalParams = x;
            this.additionalParams.karyotypeOptions = this.screeningResultsOptionService.getOptions([AO.Negative, AO.Positive, AO.NoResult]);
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new DonorBloodWorkDynamicConfig(this.donor?.DonorBloodWork, this.additionalParams);
        this.config = this.formFactory.getForUpdate();
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            const Id = this.donor.Id;
            if (this.donor.DonorBloodWork) {
                this.formFactory.assignFormValues(this.donor.DonorBloodWork, form.value.DonorBloodWork);
                this.donorService.patchBloodWork(Id, this.donor.DonorBloodWork).subscribe(this.onSuccess);
            } else {
                this.donorService.postBloodWork(Id, form.value.DonorBloodWork).subscribe(this.onSuccess);
            }
        } else {
            this.notificaitionService.error('Please check the form and try again');
            this.doubleClickIsDisabled = false;
        }
    }

    onSuccess = (resp: IDonorBloodWork) => {
        this.donor.DonorBloodWork = resp;
        this.doubleClickIsDisabled = false;
        this.isEditing = false;
        this.setConfig();
        this.notificaitionService.success('Blood Work Updated');
        this.emitIsEditing.emit(false);
    }

    edit(): void {
        this.isEditing = true;
        this.emitIsEditing.emit(true);
    }

    cancelClick(): void {
        this.isEditing = false;
        this.emitIsEditing.emit(false);
    }
}
