import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DonorSampleLabDataDynamicControlsPartial } from '@model/partials/donor-test-sample-lab-data-partial.form-controls';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { pairwise } from 'rxjs/operators';

import { DonorSampleService } from '../../services/donor-sample.service';
import { AbstractLabDataComponent } from './abstract-lab-data.component';
import { ILabDataBase } from '@model/interfaces/custom/lab-data-base';
import { IDonorSampleRemainderLabData } from '@model/interfaces/donor-sample-remainder-lab-data';
import { IDonorSampleLabData } from '@model/interfaces/donor-sample-lab-data';
import { LabDataBaseService } from '../services/abstract-lab-data.service';

@Component({
    template: '',
})
export class AbstractLabDataFormComponent extends AbstractLabDataComponent {
    @Input() canEdit: boolean;
    @Input() dataService: LabDataBaseService<IDonorSampleLabData | IDonorSampleRemainderLabData>;
    @Input() formControls: any;
    @Output() openEditing = new EventEmitter<boolean>();

    // abstract controls
    abstractDonorTestSampleLabDataControls: any;

    donorTestSampleLabDataForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;

    constructor(
        protected fb: FormBuilder,
        protected cdr: ChangeDetectorRef,
        protected notificationsService: NotificationsService,
        protected testSampleService: DonorSampleService,
    ) {
        super(testSampleService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.createForm();
        this.subs.add(
            this.donorTestSampleLabDataForm.valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
                this.setCalculatedFields(next);
            }),
        );
    }

    createForm(): void {
        this.getControls();
        this.donorTestSampleLabDataForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractDonorTestSampleLabDataControls = new this.formControls(
            { ...this.labData, ...this.calculatedFields },
            {
                formGroup: 'DonorTestSampleLabData',
            },
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            DonorTestSampleLabData: this.fb.group({}),
        });
    }

    setCalculatedFields(event?: any): void {
        let currentValues = null;
        if (this.isEditing && event) {
            currentValues = { ...this.labData, ...event.DonorTestSampleLabData } as ILabDataBase;
        } else if (this.sectionLabData) {
            currentValues = this.labData;
        }

        this.calculateFields(currentValues);
    }

    formSubmitted(): void {
        if (this.donorTestSampleLabDataForm.valid) {
            this.labData = Object.assign({}, this.labData, this.donorTestSampleLabDataForm.value.DonorTestSampleLabData, {
                DonorTestSample: null,
                Id: this.testSample.Id,
            });
            if (this.sectionLabData) {
                this.dataService.update(this.labData).subscribe(() => {
                    this.success();
                });
            } else {
                this.dataService.create(this.labData).subscribe(() => {
                    this.success();
                });
            }
        } else {
            markAllFormFieldsAsTouched(this.donorTestSampleLabDataForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    edit(): void {
        this.isEditing = true;
        this.openEditing.emit(true);
    }

    cancelClick(): void {
        this.isEditing = false;
        this.openEditing.emit(false);
        this.cdr.detectChanges();
        this.setCalculatedFields();
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Sample lab data saved successfully.');
        this.setLabData();
        this.isEditing = false;
        this.openEditing.emit(false);
        this.getControls();
        this.enableDoubleClick();
        this.dataService.emitChange(this.labData);
    }

    setLabData(): void {
        if (this.isRemainderData) {
            this.testSample.DonorSampleRemainderLabData = this.labData;
        } else {
            this.testSample.DonorSampleLabData = this.labData;
        }
    }
}
