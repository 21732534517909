import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { QcDecisionService } from './services/qc-decision.service';
import { QcReasonService } from './services/qc-reason.service';
import { QcProblemService } from './services/qc-problem.service';
import { QcInvestigationService } from './services/qc-investigation.service';
import { DiscountTypeService } from './services/discount-types.service';

import { IQcInvestigation } from '@model/interfaces/qc-investigation';
import { QcDecisions } from '@model/enums/qc-decisions.enum';
import { QcInvestigationDynamicControlsPartial } from '@model/partials/qc-investigation-partial.form-controls';
import { ThawingService } from '../../thawings/services/thawing.service';
import { OutcomeStateService } from '../outcome.state.service';

@Component({
    selector: 'qc-investigation',
    templateUrl: './qc-investigation.component.html',
})
export class QcInvestigationComponent implements OnInit {
    @Input() canEdit: boolean;
    @Input() salesOrderId: number;
    @Input() thawingId: number;

    // abstract controls
    abstractQcInvestigationControls: any;

    qcInvestigation: IQcInvestigation;
    qcInvestigationForm: FormGroup;
    doubleClickIsDisabled = false;

    // UI controls
    formCreated = false;
    isEditing: boolean;
    activeInvestigation: boolean;

    get isNewInvestigation(): boolean {
        return this.qcInvestigation && this.qcInvestigation.Id ? false : true;
    }

    qcDecision: number;
    decisions = QcDecisions;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private qcDecisionService: QcDecisionService,
        private qcReasonService: QcReasonService,
        private qcProblemService: QcProblemService,
        private qcInvestigationService: QcInvestigationService,
        private discountTypeService: DiscountTypeService,
        private thawingService: ThawingService,
        private outcomeStateService: OutcomeStateService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.thawingService.getQcInvestigationByThawingId(this.thawingId),
            this.qcDecisionService.getItems(),
            this.qcReasonService.getItems(),
            this.qcProblemService.getItems(),
            this.discountTypeService.getItems(),
        ]).subscribe(([qcInvestigation]) => {
            this.qcInvestigation = qcInvestigation ? qcInvestigation : this.qcInvestigationService.getEmptyQcInvestigation(this.thawingId);
            this.outcomeStateService.qcInvestigation$.next(this.qcInvestigation);
            this.initView();
        });
    }

    initView(): void {
        this.activeInvestigation = !!this.qcInvestigation.Investigation;
        this.qcDecision = this.qcInvestigation.QcDecisionId;
        if (this.isNewInvestigation) {
            this.isEditing = this.canEdit;
        }
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.qcInvestigationForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractQcInvestigationControls = new QcInvestigationDynamicControlsPartial(this.qcInvestigation, {
            discountTypes: this.discountTypeService.items,
            formGroup: 'QcInvestigation',
            qcDecisions: this.qcDecisionService.items,
            qcProblems: this.qcProblemService.items,
            qcReasons: this.qcReasonService.items,
        });
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            QcInvestigation: this.fb.group({}),
        });
    }

    edit(): void {
        this.isEditing = true;
    }

    formSubmitted(): void {
        if (this.qcInvestigationForm.valid) {
            Object.assign(this.qcInvestigation, this.qcInvestigationForm.value.QcInvestigation);
            this.saveInvestigation();
        } else {
            markAllFormFieldsAsTouched(this.qcInvestigationForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    private saveInvestigation(): void {
        if (this.isNewInvestigation) {
            this.qcInvestigationService
                .create(this.qcInvestigation)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.qcInvestigation.Id = answer;
                    this.success();
                });
        } else {
            this.qcInvestigationService
                .update(this.qcInvestigation)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((success) => {
                    this.success();
                });
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('QC Investigation saved successfully.');
        this.qcInvestigationService.emitChange(this.qcInvestigation);
        this.outcomeStateService.qcInvestigation$.next(this.qcInvestigation);
        this.getControls();
        this.isEditing = false;
    }

    onQCDecisionChange(decisionId: number): void {
        this.qcDecision = decisionId;
    }
}
