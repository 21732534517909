<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && donor.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Donor Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>

    <div class="display-padded">
        <b>Name: </b>
        <span>{{ donor.DonorUser?.FirstName }}&nbsp;{{ donor.DonorUser?.LastName}}</span>
    </div>

    <div class="display-padded">
        <b>Email: </b>
        <span>{{ donor.DonorUser?.Email }}</span>
    </div>

    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Donor Info</h4>
    <div *ngIf="!donorIsDenied">
        <button class="btn btn-flat btn-danger" (click)="showDenyDonorModal = true">Deny Donor</button>
        <br />
        <br />
    </div>
    <div *ngIf="donorIsDenied">
        <button class="btn btn-flat btn-primary" [mtConfirmOptions]="undenyDonorModalOptions" (mtConfirm)="undenyDonor()">Un-Deny Donor</button>
        <br />
        <br />
    </div>
    <form *ngIf="formCreated" [formGroup]="donorUserForm">
        <dynamic-field [field]="donorUserControls.FirstName" [form]="donorUserForm"></dynamic-field>
        <dynamic-field [field]="donorUserControls.MiddleInitial" [form]="donorUserForm"></dynamic-field>
        <dynamic-field [field]="donorUserControls.LastName" [form]="donorUserForm"></dynamic-field>
        <dynamic-field [field]="donorUserControls.Email" [form]="donorUserForm"></dynamic-field>
    </form>
    <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
    </app-dynamic-form>
</div>

<modal-wrapper *ngIf="showDenyDonorModal" [options]="denyDonorModalOptions" (cancelClick)="showDenialModal = false">
    <div class="modal-header">
        <h3 class="modal-title">Specify Denial Reason</h3>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="denial-reason">Denial Reason</label>
            <textarea class="form-control" required [(ngModel)]="manualDenialReason" rows="3"></textarea>
        </div>
        <button type="button" (click)="denyDonor()" class="btn btn-flat btn-danger">Deny</button>
        <button type="button" class="btn btn-flat btn-default" (click)="showDenyDonorModal = false">Cancel</button>
    </div>
</modal-wrapper>

