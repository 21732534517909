<div *ngIf="!isEditing">
    <h4>
        Psychological Screening Info
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit" (click)="edit()"></i>
        </span>
    </h4>
    <app-dynamic-view *ngIf="config" [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Psychological Screening Info</h4>
    <app-dynamic-form *ngIf="config" [config]="config.formObject" ([isEditing])="isEditing"
        (submitted)="formSubmitted($event)">
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled"
            Class="btn btn-flat btn-success">
            Save
        </button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">
            Cancel
        </button>
    </app-dynamic-form>
</div>
