<div class="miles-card padded">
    <h4>Ongoing Screenings</h4>
    <div *ngIf="!isEditing">
        <entity-list
            [entities]="screenings"
            [total]="total"
            [(currentPage)]="currentPage"
            [(itemsPerPage)]="itemsPerPage"
            (onPageChanged)="getScreenings()"
            (onItemSelected)="screeningSelected($event)"
            (onColumnSorted)="columnSorted($event)"
            [entityListConfig]="entityListConfig"
        >
        </entity-list>
        <div *ngIf="canEdit" class="fab-wrap">
            <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="createOngoingScreening()">
                <span class="fa fa-plus"></span>
            </button>
        </div>
    </div>
    <div *ngIf="isEditing && formCreated">
        <div *ngIf="formCreated">
            <form [formGroup]="donorOngoingScreeningsForm" (ngSubmit)="formSubmitted()">
                <div class="row">
                    <div class="col-md-6">
                        <dynamic-field
                            [field]="abstractDonorOngoingScreeningControls.BloodDrawDate"
                            [form]="donorOngoingScreeningsForm"
                        ></dynamic-field>
                        <dynamic-field
                            [field]="abstractDonorOngoingScreeningControls.BloodWorkStatusId"
                            [form]="donorOngoingScreeningsForm"
                        ></dynamic-field>
                        <dynamic-field
                            [field]="abstractDonorOngoingScreeningControls.RegulatoryQuestionnaireStatusId"
                            [form]="donorOngoingScreeningsForm"
                        ></dynamic-field>
                        <dynamic-field
                            [field]="abstractDonorOngoingScreeningControls.PhysicalStatusId"
                            [form]="donorOngoingScreeningsForm"
                        ></dynamic-field>
                    </div>
                </div>
                <div class="row col-md-12">
                    <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                        Save
                    </button>
                    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
                </div>
                <br />
            </form>
        </div>
        <br />
    </div>
</div>
