import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IModalOptions } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '@model/expandable-object';
import { IInventoryPositionDto } from '@model/interfaces/custom/inventory-position-dto';
import { IInventory } from '@model/interfaces/inventory';
import { InventoryDynamicControlsPartial } from '@model/partials/inventory-partial.form-controls';
import { InventoryVesselService } from '../../services/inventory-vessel.service';
import { SamplePrepMethodService } from 'admin-portal/donors/services/sample-prep-method.service';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { BulkUpdateTypes } from '@model/enums/custom/bulk-update-types.enum';

@Component({
    selector: 'donor-multiple-inventory-locations-form',
    templateUrl: 'multiple-inventory-locations-form.component.html',
})
export class MultipleInventoryLocationsFormComponent implements OnInit {
    @Input() donation: IDonorSample;
    @Input() inventories: IInventory[];
    @Input() updateType: BulkUpdateTypes;
    @Output() savedInventories = new EventEmitter<IInventory[]>();
    @Output() cancel = new EventEmitter<void>();
    @Output() onEditFinished = new EventEmitter<IInventory[]>();

    quantity = 1;
    inventoryPosition: IInventoryPositionDto;

    doubleClickIsDisabled = false;

    showConfirm = false;
    confirmOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };

    inventory: IInventory;

    private newInventoryEntry: IInventory = {
        CaneId: null,
        Comments: null,
        DonationSampleId: 0,
        Id: 0,
        ReserveForResearch: false,
        SamplePrepMethodId: null,
        VialNumber: 0,
    };

    form: FormGroup;
    formCreated = false;
    controls: IExpandableObject;
    quantityControl: DynamicField;

    get isBulkUpdate(): boolean {
        return !!this.updateType;
    }

    get showKeepLocationButton(): boolean {
        return this.updateType === BulkUpdateTypes.Approved || this.updateType === BulkUpdateTypes.Discard;
    }

    get showDiscardInventoryButton(): boolean {
        return this.updateType === BulkUpdateTypes.Discard;
    }

    constructor(
        private notificationsService: NotificationsService,
        private inventoryService: InventoryVesselService,
        private fb: FormBuilder,
        private prepMethodService: SamplePrepMethodService,
    ) {}

    ngOnInit(): void {
        this.prepMethodService.getItems().subscribe(() => {
            this.createForm();
        });
    }

    createForm(): void {
        this.form = this.fb.group({
            Inventory: this.fb.group({}),
            Quantity: this.fb.group({}),
        });
        this.createControls();
        this.formCreated = true;
    }

    createControls(): void {
        let inventory;
        if (this.inventories?.length) {
            inventory = this.inventories[0];
        } else {
            inventory = this.newInventoryEntry;
        }
        this.controls = new InventoryDynamicControlsPartial(inventory, { samplePrepMethods: this.prepMethodService.items }).Form;
        this.quantityControl = new DynamicField({
            formGroup: 'Quantity',
            label: 'Quantity of Vials',
            name: 'Quantity',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: this.inventories?.length > 0 ? this.inventories.length : 1,
        });
    }

    cancelClick(): void {
        this.cancel.emit();
    }

    cancelSave(): void {
        this.showConfirm = false;
        this.doubleClickIsDisabled = false;
    }

    setQuantity(event: number): void {
        this.quantity = event;
    }

    formSubmitted(): void {
        if (this.form.valid && this.inventoryPosition?.Cane?.Id) {
            this.inventory = this.form.value.Inventory;
            this.inventory.CaneId = this.inventoryPosition.Cane.Id;
            this.inventory.DonationSampleId = this.donation.Id;
            this.quantity = this.form.value.Quantity.Quantity;
            if (this.isBulkUpdate) {
                if (this.updateType === BulkUpdateTypes.Discard) {
                    this.inventories.map((i) => (i.Unsellable = true));
                }
                const inventoryPayload = this.inventories.map((ei) => Object.assign({}, ei, this.inventory, { Id: ei.Id }));
                this.onEditFinished.emit(inventoryPayload);
            } else {
                this.showConfirm = true;
            }
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    saveEggInventory(): void {
        this.showConfirm = false;
        this.inventoryService
            .saveMultipleInventoryLocations(this.inventory, this.quantity)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((answer) => {
                this.savedInventories.emit(answer);
                this.cancel.emit();
                this.success();
            });
    }

    keepInventoryLocation(): void {
        if (this.updateType === BulkUpdateTypes.Discard) {
            this.inventories.map((i) => (i.Unsellable = true));
        }
        this.onEditFinished.emit(this.inventories);
    }

    discardInventory(): void {
        this.inventories.map((i) => (i.Discarded = true));
        this.onEditFinished.emit(this.inventories);
    }

    private success(): void {
        this.notificationsService.success('Inventory saved successfully.');
    }

    getPrepMethodName(id: number): string {
        return this.prepMethodService.items.find((pm) => pm.Id === id).Name;
    }
}
