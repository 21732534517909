import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IQcInvestigation } from '../interfaces/qc-investigation';
import { IDiscountType } from '../interfaces/discount-type';
import { IQcDecision } from '../interfaces/qc-decision';
import { IQcProblem } from '../interfaces/qc-problem';
import { IQcReason } from '../interfaces/qc-reason';
import { IThawing } from '../interfaces/thawing';

export interface IQcInvestigationDynamicControlsParameters {
    formGroup?: string;
    thawings?: IThawing[];
    qcDecisions?: IQcDecision[];
    discountTypes?: IDiscountType[];
    qcReasons?: IQcReason[];
    qcProblems?: IQcProblem[];
}

export class QcInvestigationDynamicControls {

    formGroup: string;
    thawings: IThawing[];
    qcDecisions: IQcDecision[];
    discountTypes: IDiscountType[];
    qcReasons: IQcReason[];
    qcProblems: IQcProblem[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private qcinvestigation?: IQcInvestigation, additionalParameters?: IQcInvestigationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'QcInvestigation';
        this.thawings = additionalParameters && additionalParameters.thawings || undefined;
        this.qcDecisions = additionalParameters && additionalParameters.qcDecisions || undefined;
        this.discountTypes = additionalParameters && additionalParameters.discountTypes || undefined;
        this.qcReasons = additionalParameters && additionalParameters.qcReasons || undefined;
        this.qcProblems = additionalParameters && additionalParameters.qcProblems || undefined;

        this.Form = {
            DiscontinueDonor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Discontinue Donor',
                name: 'DiscontinueDonor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.hasOwnProperty('DiscontinueDonor') && this.qcinvestigation.DiscontinueDonor !== null ? this.qcinvestigation.DiscontinueDonor : false,
            }),
            DiscountAmount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Discount Amount',
                name: 'DiscountAmount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.DiscountAmount || null,
            }),
            DiscountTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Discount Type',
                name: 'DiscountTypeId',
                options: this.discountTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.DiscountTypeId || null,
            }),
            DraftsSentForApproval: new DynamicField({
                formGroup: this.formGroup,
                label: 'Drafts Sent For Approval',
                name: 'DraftsSentForApproval',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.DraftsSentForApproval || null,
            }),
            EmailApproved: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email Approved',
                name: 'EmailApproved',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.EmailApproved || null,
            }),
            EmailSentToRecipient: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email Sent To Recipient',
                name: 'EmailSentToRecipient',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.EmailSentToRecipient || null,
            }),
            HaltSalesOrder: new DynamicField({
                formGroup: this.formGroup,
                label: 'Halt Sales Order',
                name: 'HaltSalesOrder',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.hasOwnProperty('HaltSalesOrder') && this.qcinvestigation.HaltSalesOrder !== null ? this.qcinvestigation.HaltSalesOrder : false,
            }),
            Investigation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Investigation',
                name: 'Investigation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.hasOwnProperty('Investigation') && this.qcinvestigation.Investigation !== null ? this.qcinvestigation.Investigation : false,
            }),
            NothingOfferedReason: new DynamicField({
                formGroup: this.formGroup,
                label: 'Nothing Offered Reason',
                name: 'NothingOfferedReason',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(2000) ],
                validators: { 'maxlength': 2000 },
                value: this.qcinvestigation && this.qcinvestigation.hasOwnProperty('NothingOfferedReason') && this.qcinvestigation.NothingOfferedReason !== null ? this.qcinvestigation.NothingOfferedReason.toString() : '',
            }),
            NumberFreeVials: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Free Vials',
                name: 'NumberFreeVials',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.NumberFreeVials || null,
            }),
            QcComments: new DynamicField({
                formGroup: this.formGroup,
                label: 'Qc Comments',
                name: 'QcComments',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(8000) ],
                validators: { 'maxlength': 8000 },
                value: this.qcinvestigation && this.qcinvestigation.hasOwnProperty('QcComments') && this.qcinvestigation.QcComments !== null ? this.qcinvestigation.QcComments.toString() : '',
            }),
            QcDecisionId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Qc Decision',
                name: 'QcDecisionId',
                options: this.qcDecisions,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.QcDecisionId || null,
            }),
            QcProblemId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Qc Problem',
                name: 'QcProblemId',
                options: this.qcProblems,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.QcProblemId || null,
            }),
            QcReasonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Qc Reason',
                name: 'QcReasonId',
                options: this.qcReasons,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.QcReasonId || null,
            }),
            ResponseSentToClinic: new DynamicField({
                formGroup: this.formGroup,
                label: 'Response Sent To Clinic',
                name: 'ResponseSentToClinic',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.ResponseSentToClinic || null,
            }),
            ThawingId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Thawing',
                name: 'ThawingId',
                options: this.thawings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.qcinvestigation && this.qcinvestigation.ThawingId || null,
            }),
            WatchlistDonor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Watchlist Donor',
                name: 'WatchlistDonor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.qcinvestigation && this.qcinvestigation.hasOwnProperty('WatchlistDonor') && this.qcinvestigation.WatchlistDonor !== null ? this.qcinvestigation.WatchlistDonor : false,
            }),
        };

        this.View = {
            DiscontinueDonor: new DynamicLabel(
                'Discontinue Donor',
                this.qcinvestigation && this.qcinvestigation.hasOwnProperty('DiscontinueDonor') && this.qcinvestigation.DiscontinueDonor !== null ? this.qcinvestigation.DiscontinueDonor : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            DiscountAmount: new DynamicLabel(
                'Discount Amount',
                this.qcinvestigation && this.qcinvestigation.DiscountAmount || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            ),
            DiscountTypeId: new DynamicLabel(
                'Discount Type',
                getMetaItemValue(this.discountTypes, this.qcinvestigation && this.qcinvestigation.hasOwnProperty('DiscountTypeId') && this.qcinvestigation.DiscountTypeId !== null ? this.qcinvestigation.DiscountTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            DraftsSentForApproval: new DynamicLabel(
                'Drafts Sent For Approval',
                this.qcinvestigation && this.qcinvestigation.DraftsSentForApproval || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EmailApproved: new DynamicLabel(
                'Email Approved',
                this.qcinvestigation && this.qcinvestigation.EmailApproved || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            EmailSentToRecipient: new DynamicLabel(
                'Email Sent To Recipient',
                this.qcinvestigation && this.qcinvestigation.EmailSentToRecipient || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            HaltSalesOrder: new DynamicLabel(
                'Halt Sales Order',
                this.qcinvestigation && this.qcinvestigation.hasOwnProperty('HaltSalesOrder') && this.qcinvestigation.HaltSalesOrder !== null ? this.qcinvestigation.HaltSalesOrder : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Investigation: new DynamicLabel(
                'Investigation',
                this.qcinvestigation && this.qcinvestigation.hasOwnProperty('Investigation') && this.qcinvestigation.Investigation !== null ? this.qcinvestigation.Investigation : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            NothingOfferedReason: new DynamicLabel(
                'Nothing Offered Reason',
                this.qcinvestigation && this.qcinvestigation.hasOwnProperty('NothingOfferedReason') && this.qcinvestigation.NothingOfferedReason !== null ? this.qcinvestigation.NothingOfferedReason.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            NumberFreeVials: new DynamicLabel(
                'Number Free Vials',
                this.qcinvestigation && this.qcinvestigation.NumberFreeVials || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            QcComments: new DynamicLabel(
                'Qc Comments',
                this.qcinvestigation && this.qcinvestigation.hasOwnProperty('QcComments') && this.qcinvestigation.QcComments !== null ? this.qcinvestigation.QcComments.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            QcDecisionId: new DynamicLabel(
                'Qc Decision',
                getMetaItemValue(this.qcDecisions, this.qcinvestigation && this.qcinvestigation.hasOwnProperty('QcDecisionId') && this.qcinvestigation.QcDecisionId !== null ? this.qcinvestigation.QcDecisionId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            QcProblemId: new DynamicLabel(
                'Qc Problem',
                getMetaItemValue(this.qcProblems, this.qcinvestigation && this.qcinvestigation.hasOwnProperty('QcProblemId') && this.qcinvestigation.QcProblemId !== null ? this.qcinvestigation.QcProblemId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            QcReasonId: new DynamicLabel(
                'Qc Reason',
                getMetaItemValue(this.qcReasons, this.qcinvestigation && this.qcinvestigation.hasOwnProperty('QcReasonId') && this.qcinvestigation.QcReasonId !== null ? this.qcinvestigation.QcReasonId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ResponseSentToClinic: new DynamicLabel(
                'Response Sent To Clinic',
                this.qcinvestigation && this.qcinvestigation.ResponseSentToClinic || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ThawingId: new DynamicLabel(
                'Thawing',
                getMetaItemValue(this.thawings, this.qcinvestigation && this.qcinvestigation.hasOwnProperty('ThawingId') && this.qcinvestigation.ThawingId !== null ? this.qcinvestigation.ThawingId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            WatchlistDonor: new DynamicLabel(
                'Watchlist Donor',
                this.qcinvestigation && this.qcinvestigation.hasOwnProperty('WatchlistDonor') && this.qcinvestigation.WatchlistDonor !== null ? this.qcinvestigation.WatchlistDonor : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
