<!-- Disabled controls used to display user presets uniformly -->
<div class="form-section-border">
    <h3>Donor Info</h3>
</div>
<div class="input-scaffold__group">
    <div class="input-scaffold">
        <label class="input-label">
            <span class="input-scaffold__label">First Name</span>
            <input type="text" [attr.placeholder]="donorUser.FirstName" disabled />
        </label>
    </div>
    <div class="input-scaffold">
        <label class="input-label">
            <span class="input-scaffold__label">Middle Initial</span>
            <input type="text" [attr.placeholder]="donorUser?.MiddleInitial || ''" disabled />
        </label>
    </div>
    <div class="input-scaffold">
        <label class="input-label">
            <span class="input-scaffold__label">Last Name</span>
            <input type="text" [attr.placeholder]="donorUser.LastName" disabled />
        </label>
    </div>
</div>
