import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IRhFactor } from '@model/interfaces/rh-factor';

@Injectable({
    providedIn: 'root',
})
export class RhFactorService extends StaticMetaItemService<IRhFactor> {
    constructor(public http: HttpClient) {
        super('RhFactorService', 'Factor', 'FactorIds', '/meta-items/RhFactor', http);
    }
}
