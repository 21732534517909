<div class="miles-editable miles-card padded" *ngIf="donor" [style.cursor]="canEdit ? 'pointer' : 'default'">
    <h4>
        Photo Management
        <button *ngIf="canEdit" type="button" class="btn btn-flat btn-success pull-right" (click)="showUploadPhotoModal = true">
            <span class="fa fa-plus"></span> Add Photos
        </button>
        <div style="clear: both"></div>
    </h4>
    <br />
    <div class="img-container" *ngIf="donor.DonorImages && donor.DonorImages.length" cdkDropListGroup>
        <div
            *ngFor="let photo of orderedPhotos; let i = index"
            cdkDropList
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{ index: i, item: photo }"
            (cdkDropListDropped)="drop($event)"
        >
            <div class="img-box" cdkDrag>
                <div class="img">
                    <img src="{{ imagePath }}lgThumb-{{ photo.Image.ImagePath }}" alt="Donor image" />
                    <button
                        type="button"
                        class="trash-button btn btn-sm fa fa-trash"
                        (mtConfirm)="deletePhoto(photo, $event)"
                        *ngIf="canEdit"
                    ></button>
                    <button
                        type="button"
                        class="main-photo-button btn btn-sm fa"
                        [ngClass]="{ 'is-main-photo': photo.IsMainPhoto, 'fa-star': photo.IsMainPhoto, 'fa-star-o': !photo.IsMainPhoto }"
                        (click)="setMainPhoto(photo, $event)"
                        *ngIf="canEdit"
                    ></button>
                </div>
                <div class="preview-overlay" (click)="previewImage(photo)"></div>
                <div class="status-bar" (click)="approvePhoto(photo, $event)" *ngIf="canEdit">
                    <div style="color: greenyellow" *ngIf="photo.IsApproved">
                        <strong>Approved</strong>
                    </div>
                    <div style="color: red" *ngIf="!photo.IsApproved">
                        <strong>Pending</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!donor.DonorImages || (donor.DonorImages && !donor.DonorImages.length)">
        <div class="text-center">
            <em>Donor has no photos.</em>
        </div>
    </div>
    <br />
    <button *ngIf="donor.DonorImages && donor.DonorImages.length" type="button" class="btn btn-flat btn-success" (click)="savePhotoOrder()">
        Save Order
    </button>
</div>

<!-- preview modal -->
<modal-wrapper *ngIf="selectedPhoto" [options]="modalOptions" (cancelClick)="closePreview()">
    <img
        style="max-width: 100%; height: auto"
        src="{{ imagePath }}{{ selectedPhoto.Image.ImagePath }}"
        alt="Donor image"
    />
</modal-wrapper>

<!-- preview modal -->
<modal-wrapper *ngIf="showUploadPhotoModal" [options]="uploadModalOptions" (cancelClick)="showUploadPhotoModal = false"
    ><donor-photo-uploader (onFinished)="onFinishedUpload($event)" [donorId]="donor.Id"></donor-photo-uploader>
</modal-wrapper>
