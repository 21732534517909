import { Injectable } from '@angular/core';
import { IQcInvestigation } from '@model/interfaces/qc-investigation';
import { IOutcome } from '@model/interfaces/outcome';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

/**
 * Provides sibling to sibling communication scoped to a donor's
 * sales order outcome
 *
 * Provided in Outcomes component
 */
@Injectable()
export class OutcomeStateService {
    public qcInvestigation$ = new BehaviorSubject<IQcInvestigation>(null);
    public outcome$ = new BehaviorSubject<IOutcome>(null);

    constructor() {}

    /**
     * Determines if QcInvestigation question has been answered
     */
    get isInvestigationComplete$(): Observable<boolean> {
        return this.qcInvestigation$.pipe(
            filter((qc) => !!qc),
            map((qc) => qc.Id !== 0),
            distinctUntilChanged(),
        );
    }

    /**
     * Determines if outcome has been finalized
     */
    get isOutcomeFinalized$(): Observable<boolean> {
        return this.outcome$.pipe(
            filter((o) => !!o),
            map((o) => o.IsFinalized),
            distinctUntilChanged(),
        );
    }
}
