import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DynamicField, DynamicFieldType, DynamicFieldTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { IFamilyHistory } from '@model/interfaces/family-history';
import { IDonorFamilyHistory } from '@model/interfaces/donor-family-history';
import { IDonor } from '@model/interfaces/donor';
import { IFamilyHistoryDynamicControlsParameters } from '@model/form-controls/family-history.form-controls';
import { DonorFamilyHistoryDynamicControls } from '@model/form-controls/donor-family-history.form-controls';
import { UnitsOfMeasurement } from '@model/enums/units-of-measurement.enum';
import { HalfSiblingTypes } from '@model/partials/family-history-partial.form-controls';
import { IDonorFamilyHistoryPartial } from '@model/partials/donor-family-history-partial';

import { DonorFamilyHistoryService } from '../services/donor-family-history.service';
import { FormYesNoButtonComponent } from '../../components/form-yes-no-button.component';
import { BuildDonorFormLabel } from '../../libraries/build-donor-form-label.library';

@Component({
    selector: 'family-history',
    templateUrl: './family-history.component.html',
})
export class FamilyHistoryComponent {
    @Input() donor: IDonor;
    @Output() onFormReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output('onFormValueChanges') onFormValueChanges: EventEmitter<IDonorFamilyHistory> = new EventEmitter<IDonorFamilyHistory>();

    // abstract controls
    abstractFamilyHistoryControls: any;

    familyHistory: IDonorFamilyHistoryPartial;
    familyHistoryForm: FormGroup;
    metaData: IFamilyHistoryDynamicControlsParameters;
    formCreated = false;
    unitsOfMeasurement: IUnitsOfMeasurement[];
    weightUnits;
    heightUnits;
    hasSiblings: boolean;
    siblingForms: FormArray;
    siblingsToAddNumber: number;

    formsReadyMap = {
        FatherHistory: false,
        MaternalGrandfatherHistory: false,
        MaternalGrandmotherHistory: false,
        MotherHistory: false,
        PaternalGrandfatherHistory: false,
        PaternalGrandmotherHistory: false,
    };
    get allFormsReady(): boolean {
        let ready = false;
        for (let form in this.formsReadyMap) {
            if (this.formsReadyMap.hasOwnProperty(form)) {
                ready = this.formsReadyMap[form];
            }
        }
        return ready;
    }
    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private familyHistoryService: DonorFamilyHistoryService,
        private notificationService: NotificationsService,
    ) {}

    @HostListener('window:beforeunload', ['$event'])
    warnOfUnsavedChanges(e): any {
        if (this.familyHistoryForm.dirty) {
            e.returnValue = true;
            return false;
        }
    }

    ngOnInit(): void {
        forkJoin([this.familyHistoryService.getFamilyHistoryAdditionalParameters(), this.familyHistoryService.getById(this.donor.Id)]).subscribe(
            ([metaData, familyHistory]) => {
                this.metaData = metaData;
                this.familyHistory = familyHistory;
                this.createForm();
                this.hasSiblings = this.familyHistory.DonorSiblingFamilyHistories && this.familyHistory.DonorSiblingFamilyHistories.length > 0;
            },
        );
    }

    setFormGroup(form: FormGroup, type: string): void {
        this.familyHistoryForm.addControl(type, form);
        this.formsReadyMap[type] = true;
        this.cdr.detectChanges();
    }

    addSiblingFormToFormArray(form: FormGroup): void {
        this.siblingForms.push(form);
        this.cdr.detectChanges();
    }

    optionIsSelected(controlName: string): boolean {
        const control = this.familyHistoryForm.get(`ApplicationFamilyHistory.${controlName}`);
        return control.value;
    }

    createForm(): void {
        this.getControls();
        this.familyHistoryForm = this.assignFormGroups();
        this.siblingForms = this.fb.array([]);
        this.familyHistoryForm.addControl('SiblingFamilyHistories', this.siblingForms);
        this.formCreated = true;
        this.onFormReady.emit(this.familyHistoryForm);
        this.cdr.detectChanges();
        this.familyHistoryForm.valueChanges.pipe(debounceTime(50)).subscribe(() => {
            if (this.familyHistoryForm.dirty) {
                this.assignFormValues();
                this.onFormValueChanges.emit(this.familyHistory);
            }
        });
    }

    getControls(): void {
        this.abstractFamilyHistoryControls = new DonorFamilyHistoryDynamicControls(null).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationFamilyHistory: this.fb.group({}),
        });
    }

    assignFormValues(): void {
        Object.assign(this.familyHistory, this.familyHistoryForm.get('ApplicationFamilyHistory').value);
        this.manuallyAssignFormValues(this.familyHistory.Mother, this.familyHistoryForm.get('Mother.FamilyHistory'));
        this.manuallyAssignFormValues(this.familyHistory.Father, this.familyHistoryForm.get('Father.FamilyHistory'));
        this.manuallyAssignFormValues(
            this.familyHistory.MaternalGrandfather,
            this.familyHistoryForm.get('MaternalGrandfather.FamilyHistory'),
        );
        this.manuallyAssignFormValues(
            this.familyHistory.MaternalGrandmother,
            this.familyHistoryForm.get('MaternalGrandmother.FamilyHistory'),
        );
        this.manuallyAssignFormValues(
            this.familyHistory.PaternalGrandmother,
            this.familyHistoryForm.get('PaternalGrandmother.FamilyHistory'),
        );
        this.manuallyAssignFormValues(
            this.familyHistory.PaternalGrandfather,
            this.familyHistoryForm.get('PaternalGrandfather.FamilyHistory'),
        );
        if (this.hasSiblings) {
            this.assignSiblingFormValues();
        }
        this.cdr.detectChanges();
    }

    manuallyAssignFormValues(familyHistory: IFamilyHistory, form: AbstractControl): void {
        Object.assign(familyHistory, form.value);
        this.calculateHeight(familyHistory);
    }

    calculateHeight(info: any): void {
        info.HeightValue = info.HeightUnitId !== UnitsOfMeasurement.FeetInches ? info.Meters : Number(info.Feet * 12) + Number(info.Inches);
        delete info.Meters;
        delete info.Feet;
        delete info.Inches;
    }

    assignSiblingFormValues(): void {
        for (const form of this.siblingForms.controls) {
            if (form.get('FamilyHistory.IsFullSibling')) {
                const siblingIndex = +form.get('siblingIndex').value;
                this.manuallyAssignFormValues(this.familyHistory.DonorSiblingFamilyHistories[siblingIndex], form.get('FamilyHistory'));
                this.familyHistory.DonorSiblingFamilyHistories[siblingIndex].IsFullSibling =
                    form.get('FamilyHistory.IsFullSibling').value === HalfSiblingTypes.Mother;
                const halfSiblingControl = form.get('FamilyHistory.HalfSiblingType');
                this.familyHistory.DonorSiblingFamilyHistories[siblingIndex].IsHalfSiblingMother =
                    halfSiblingControl && halfSiblingControl.value === HalfSiblingTypes.Mother;
                this.familyHistory.DonorSiblingFamilyHistories[siblingIndex].IsHalfSiblingFather =
                    halfSiblingControl && halfSiblingControl.value === HalfSiblingTypes.Father;
            }
        }
    }

    getSiblingsControl(): DynamicField {
        return new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: 'ApplicationFamilyHistory',
            label: BuildDonorFormLabel('Do you have any siblings?'),
            name: 'Siblings',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: this.familyHistory?.DonorSiblingFamilyHistories?.length > 0,
        });
    }

    getHowManySiblingsControl(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'How Many?',
            name: 'howManySiblings',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(20), Validators.min(0)],
            validators: { required: true },
            value: this.familyHistory?.DonorSiblingFamilyHistories?.length,
        });
    }

    showSiblingsControls(value: boolean): void {
        if (!value) {
            this.hasSiblings = false;
            this.familyHistory.DonorSiblingFamilyHistories = [];
            this.siblingForms = this.fb.array([]);
            setTimeout(() => this.familyHistoryForm.markAsDirty());
        } else {
            this.hasSiblings = true;
        }
    }

    addSiblingsToFamilyHistory(): void {
        if (this.siblingsToAddNumber <= 0) {
            this.notificationService.error('Sibling count must be greater than 0');
            return;
        }
        for (let i = 0; i < this.siblingsToAddNumber; i++) {
            const siblingFamilyHistory = this.familyHistoryService.getEmptyFamilyHistory();
            siblingFamilyHistory.IsSibling = true;
            this.familyHistory.DonorSiblingFamilyHistories.push(siblingFamilyHistory);
        }
        this.familyHistoryForm.markAsDirty();
    }

    removeSibling(index: number): void {
        this.familyHistoryForm.markAsDirty();
        this.familyHistory.DonorSiblingFamilyHistories.splice(index, 1);
        this.siblingForms.controls = this.siblingForms.controls.filter((form) => form.get('siblingIndex').value !== index);
        // Update sibling index value on form so we remove the correct form when clicking remove
        for (let i = index; i < this.siblingForms.length; i++) {
            this.siblingForms.controls[i].patchValue({ siblingIndex: this.siblingForms.controls[i].value.siblingIndex - 1 });
        }
        this.cdr.detectChanges();
    }
}
