import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';
import { IReservationDto } from '@model/interfaces/custom/reservation-dto';
import { DatePipe } from '@angular/common';

export class RecipientReservationsEntityListConfig extends EntityListConfig {
    constructor() {
        const datePipe = new DatePipe('en-US');
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['RecipientId'],
                    name: 'Recipient Id',
                    sort: {
                        sortProperty: 'Id',
                    },
                }),
                new EntityListColumn({
                    accessors: ['RecipientName'],
                    name: 'Recipient Name',
                    sort: {
                        sortProperty: 'Recipient.RecipientUser.LastName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: IReservationDto): string => {
                        return this.enforceLineBreaks(reservation.Donors.map((d) => d.DonorId));
                    },
                    bindToInnerHtml: true,
                    name: 'Donor Id',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: IReservationDto): string => {
                        return this.enforceLineBreaks(reservation.Donors.map((d) => `${d.IuiRelease} / ${d.IvfRelease} / ${d.IcsiRelease}`));
                    },
                    bindToInnerHtml: true,
                    name: 'Released Vials IUI / IVF / ICSI',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: IReservationDto): string => {
                        return this.enforceLineBreaks(reservation.Donors.map((d) => `${d.IuiQuarantine} / ${d.IvfQuarantine} / ${d.IcsiQuarantine}`));
                    },
                    bindToInnerHtml: true,
                    name: 'Quarantined Vials IUI / IVF / ICSI',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: IReservationDto): string => {
                        return this.enforceLineBreaks(reservation.Donors.map((d) => d.Total));
                    },
                    bindToInnerHtml: true,
                    name: 'Total Reserved',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: IReservationDto): string => {
                        return `${datePipe.transform(reservation.CreatedDate)} ${datePipe.transform(reservation.CreatedDate, 'mediumTime')}`;
                    },
                    name: 'Created Date',
                    sort: {
                        sortProperty: 'CreatedDate',
                    },
                }),
            ],
        };
        super(listConfig);
    }

    enforceLineBreaks(arr: any[]): string {
        return `${arr.join('<br/>')}`;
    }
}
