<div *ngIf="donorSample">
    <div class="row">
        <div class="col-md-6">
            <app-donor-donation-basic-info [donorSample]="donorSample" [canEdit]="canEdit"></app-donor-donation-basic-info>
            <br />
        </div>
        <div class="col-md-6">
            <app-notes [entityType]="noteEntity" [entityId]="donorSample.Id" [canEdit]="canEdit"></app-notes>
            <br />
            <app-upload-documents-detail [entityId]="donorSample.Id" [claimType]="claimType" [entityType]="docEntity"> </app-upload-documents-detail>
            <br />
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <ngb-tabset type="pills">
                <ngb-tab id="tab-labWork">
                    <ng-template ngbTabTitle><h4 style="color: white">Lab Work</h4></ng-template>
                    <ng-template ngbTabContent>
                        <div>
                            <br />
                            <app-donation-lab-data [donorSample]="donorSample" [canEdit]="canEdit"></app-donation-lab-data>
                            <br />
                            <app-remainder-lab-data [donorSample]="donorSample" [canEdit]="canEdit"></app-remainder-lab-data>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab id="tab-inventory">
                    <ng-template ngbTabTitle><h4 style="color: white">Inventory</h4></ng-template>
                    <ng-template ngbTabContent>
                        <br />
                        <app-inventory-info [donor]="donorSample.Donor" [donation]="donorSample" [canEdit]="canEdit"></app-inventory-info>
                        <br />
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
    <a [routerLink]="'/donors/' + donorSample.DonorId" class="btn btn-default">Close</a>
</div>
