import { NgModule } from '@angular/core';
import { AdminPortalFeatureModule } from '@common/feature.module';
import { AdminPortalSharedModule } from '@common/shared.module';

import { InventoryInfoComponent } from '../inventory/inventory-info/inventory-info.component';
import { InventoryLocationFormComponent } from '../inventory/inventory-location/inventory-location-form/inventory-location-form.component';
import { MultipleInventoryLocationsFormComponent } from '../inventory/inventory-location/multiple-inventory-locations-form/multiple-inventory-locations-form.component';
import { InventoryVesselFormComponent } from '../inventory/inventory-vessels/inventory-vessel-form/inventory-vessel-form.component';
import { InventoryVesselComponent } from './inventory-vessels/inventory-vessel.component';

@NgModule({
    declarations: [
        InventoryInfoComponent,
        InventoryLocationFormComponent,
        MultipleInventoryLocationsFormComponent,
        InventoryVesselFormComponent,
        InventoryVesselComponent,
    ],
    exports: [InventoryInfoComponent, MultipleInventoryLocationsFormComponent],
    imports: [AdminPortalSharedModule, AdminPortalFeatureModule],
})
export class InventoryModule {}
