import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { AppointmentService } from '../services/appointment.service';
import { AppointmentDynamicConfig } from '../appointment.dynamic-config';

import { IAppointment } from '@model/interfaces/appointment';
import { IMetaItem } from '@mt-ng2/base-service';
import { AppointmentDynamicControlsPartial } from '@model/partials/appointment-partial.form-controls';
import { UserService } from '../../users/user.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { AppointmentTypes } from '@model/enums/appointment-types.enum';

@Component({
    selector: 'app-appointment-basic-info',
    templateUrl: './appointment-basic-info.component.html',
})
export class AppointmentBasicInfoComponent implements OnInit {
    @Input() appointment: IAppointment;
    @Input() canEdit: boolean;

    isEditing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: AppointmentDynamicConfig<IAppointment>;
    doubleClickIsDisabled = false;
    assignedUsers: IMetaItem[];

    get isNewAppointment(): boolean {
        return this.appointment && this.appointment.Id ? false : true;
    }

    constructor(
        private appointmentService: AppointmentService,
        private userService: UserService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.userService.getUsersByClaimAsMetaItem(ClaimTypes.Donors_ConductInterviews).subscribe((receptionistUsers) => {
            this.assignedUsers = receptionistUsers;
            this.setConfig();
        });
    }

    setConfig(): void {
        let overrideConfig;
        if (this.appointment.TypeId === AppointmentTypes.TestSample) {
            overrideConfig = [
                'DonorId',
                'Cancelled',
                'StartTimeUtc',
                'EndTimeUtc',
                'StartTimeDonor',
                'EndTimeDonor',
                'Timezone',
                'PhoneNumber',
                'DateCreated',
                'TypeId',
            ];
            // No editable fields in a Test Sample appointment
            this.canEdit = false;
        }
        this.formFactory = new AppointmentDynamicConfig<IAppointment>(this.appointment, this.assignedUsers, overrideConfig);
        this.config = this.formFactory.getForUpdate();
        // Add donor name
        const donorControl = new AppointmentDynamicControlsPartial(this.appointment).View.Donor;
        this.config.viewOnly.unshift(donorControl);
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewAppointment) {
            this.router.navigate(['/appointments']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.appointment, form.value.Appointment);
            this.saveAppointment();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveAppointment(): void {
        this.appointmentService
            .update(this.appointment)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((success) => {
                this.success();
            });
    }

    private success(): void {
        this.setConfig();
        this.isEditing = false;
        this.appointmentService.emitChange(this.appointment);
        this.notificationsService.success('Appointment saved successfully.');
    }
}
