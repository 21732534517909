import { Pipe, PipeTransform } from '@angular/core';
import { TestSamplePrepMethods } from '@model/enums/test-sample-prep-methods.enum';
import { IVwDonorInventory } from '@model/interfaces/vw-donor-inventory';

@Pipe({
    name: 'vialTypeCounts',
})
export class VialTypeCountsPipe implements PipeTransform {
    transform(value: IVwDonorInventory[]): string {
        const IVF = value.filter((v) => v.VialTypeId === TestSamplePrepMethods.IVF).length;
        const IUI = value.filter((v) => v.VialTypeId === TestSamplePrepMethods.IUI).length;
        const ICSI = value.filter((v) => v.VialTypeId === TestSamplePrepMethods.ICSI).length;
        return `IVF: ${IVF} IUI: ${IUI} ICSI: ${ICSI}`;
    }
}
